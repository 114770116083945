import {Adapter} from '../../../global/adapter/adapter';

export class SectorData{
    constructor(public code: number,
        public id: string,
        public label: string,
        public uri: string ){}
}

export class Sector{
    constructor(public status: string,
        private message: string,
        public data: SectorData[]){}
}

export class SectorAdapt implements Adapter<Sector> {
    adapt(item:any): Sector{
        return new Sector(
            item.status,
            item.message,
            item.data
        );
    }
}
