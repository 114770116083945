<div class="bg-white job-details" *ngIf="googleJobResponse && googleJobResponse.length>0">
  <span class="save-btn" *ngIf = '!saveFunctionalityDisabled'>
    <button *ngIf="!saveFlag" class="btn btn-secondary btn-block" [disabled]="saveButtonClicked" (click) = "saveJob()"
            [translate]="'save_text_label'"></button></span>
  <span class="save-btn saved" *ngIf = '!saveFunctionalityDisabled'>
      <button *ngIf="saveFlag" class="btn btn-secondary btn-block" (click) = "deleteJob()"
              [translate]="'bookmark_saved_text'"></button></span>
  <h3 [textContent]="googleJobResponse[0].JobTitle"></h3>
  <img
    *ngIf="config && config.isEmployerLogoEnabled && userAgent==='-Desktop' && googleJobResponse[0].clientLogoUrl && googleJobResponse[0].isSponsored.toUpperCase()==='Y'"
    [src]="googleJobResponse[0].clientLogoUrl">

  <div class="job-help-text">
    <span [innerHTML]="removeHex(googleJobResponse[0].SearchTextSnippet)"></span>
    <i>
      <img
        *ngIf="userAgent==='-Desktop' && googleJobResponse[0].clientLogoUrl && googleJobResponse[0].isSponsored.toUpperCase()==='Y'"
        [src]="googleJobResponse[0].clientLogoUrl">
    </i>
  </div>
  <div class="job-description"
    [innerHTML]="removeHex(googleJobResponse[0].description)">
  </div>
  <div *ngIf="googleJobResponse[0].micrositeUrl">
    <span [translate]="'job_microsite_text'"></span><a id="gtm_job_desc_micrositeurl" [href]="googleJobResponse[0].micrositeUrl" target="_blank"
      [textContent]="googleJobResponse[0].micrositeUrl"></a>
  </div>
  <div class="action-bar">
    <button id="gtm_job_desc_apply_btn" [ngClass]="buttonClicked?'btn btn-default btn-loading':'btn btn-default'" [disabled]="googleJobResponse[0].isJobApplied || buttonClicked"
            [translate]="googleJobResponse[0].isJobApplied?'apply_button_text_disabled':'job_apply_label'"
            (click)="googleJobResponse[0].applicationURL?redirectToApplicationUrl(googleJobResponse[0].applicationURL):redirectToApply(googleJobResponse[0].jobId,googleJobResponse[0].jobName)"></button>
  </div>
  <div *ngIf="config.isGDPRPrivacyPolicyEnabled">
    <div class="privacy-text" id="GDPRPrivacyPolicy">
       <a id="gtm_job_desc_gdprpolicylink"
          [href]="(orgName && orgName == 'hts') ?liferayBaseUrl+'/'+'careers'+config.gdprPolicyLink : orgName ? liferayBaseUrl+'/'+orgName+config.gdprPolicyLink :liferayBaseUrl+config.gdprPolicyLink" target="_blank"
          [translate]="'gdpr_privacy_policy_job_description_click'"></a><em [textContent]="' '"> </em> <span
      [translate]="'gdpr_privacy_policy_job_description_text'"></span>
    </div>
  </div>

  <div class="handicap border" *ngIf="config.isHandicap">

    <div class="handicap-image"><img src="../assets/images/MissionHandicap-C.png"></div>
    <div class="handicap-text" id="handicap" [translate]="'mission_handicap_text'"></div>
  </div>
  <input type="hidden" name="jobCreatedDate" [value]="formatDate(googleJobResponse[0].createDate)" />
  <input type="hidden" name="jobExpiryDate"
    [value]="config.showExpiryDatePattern?'1970-01-01':formatDate(googleJobResponse[0].endDate)" />

</div>
