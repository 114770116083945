import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ExtraFieldsConfig} from '../../../model/response/google-job-response';
import {Router} from '@angular/router';
import {RequestService} from '../../../../global/services/request/request.service';
import {SiteService} from '../../../../global/services/site/site.service';
import {apiEndPoints, config, environment} from '../../../../../environments/root/environment';
import {JobSearchService} from "../../../service/job-search.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-jp-legal-consent-checkbox-popup',
  templateUrl: './jp-legal-consent-checkbox-popup.component.html',
  styleUrls: ['./jp-legal-consent-checkbox-popup.component.scss']
})
export class JpLegalConsentCheckboxPopupComponent implements OnInit {
  @Input() public domainConfig:ExtraFieldsConfig;
  @Input() public legalConsentVisible:boolean;
  @Output() public consentFlag = new EventEmitter<any>();
  @Output() public isConsentValid = new EventEmitter<boolean>();
  @Output() public close = new EventEmitter<boolean>();
  public consentBox1=[];
  public consentBox2=[];
  public consentBox3=[];
  public confirmationText=[];
  public checked1: boolean;
  public checked2: boolean;
  public checked3: boolean;
  public privacyPolicyErrorMsg1: boolean;
  public privacyPolicyErrorMsg2: boolean;
  public privacyPolicyErrorMsg3: boolean;
  public buttonClicked: boolean;
  public showValidationError: boolean;
  public isMarketingConsentForHU: boolean;
  public isPrivacyConsentEnabled: boolean;
  public baseUrl:string;
  private sharedConfigListRef: Subscription= new Subscription();
  constructor(public route:Router,private requestService:RequestService,private siteService:SiteService, private jobSearchService: JobSearchService) { }

  ngOnInit() {
    this.jobSearchService.sharedConfigList.subscribe(domainConfig => {
      if (domainConfig && domainConfig.isMarketingConsentForHU) {
        this.isMarketingConsentForHU = domainConfig.isMarketingConsentForHU;
      }
    });
    this.baseUrl = window.location.origin;
    if(this.route.url.indexOf('apply')>-1)
      this.getConfirmation();
  }
  getConfirmation(){
    let liferayBaseUrl = environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.liferaybaseUrl];
    let orgName = this.route.routerState.snapshot.root.firstChild.params["orgName"];
    this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI,apiEndPoints.getTermsAndConditions).subscribe(response => {
      if(response.status === config.SUCCESS && response.data ){
        this.confirmationText = response.data;
        this.confirmationText['checkBox1'].forEach((data)=>{
          if(data['link'].indexOf('email')==-1) {
            if (orgName)
              data['link'] = liferayBaseUrl+'/'+orgName + data['link'];
            else
              data['link'] = liferayBaseUrl + data['link'];

          }
          else {
            if (orgName)
              data['link'] = orgName + data['link'];
          }

          /*if(data['link'].indexOf('email')==-1)
            data['link']=liferayBaseUrl+data['link'];*/
          data['consentText1']= decodeURI(data['consentText1']);
          data['consentText2']= decodeURI(data['consentText2']);
          this.consentBox1.push(data);
        });

        this.confirmationText['checkBox2'].forEach((data)=>{
          if(data['link'].indexOf('email')==-1) {
            if (orgName)
              data['link'] = liferayBaseUrl+'/'+orgName + data['link'];
            else
              data['link'] = liferayBaseUrl + data['link'];

          }
          else {
            if (orgName)
              data['link'] = orgName + data['link'];
          }
          data['consentText1']= decodeURI(data['consentText1']);
          data['consentText2']= decodeURI(data['consentText2']);
          this.consentBox2.push(data);
        });

        this.confirmationText['checkBox3'].forEach((data)=>{
          if(data['link'].indexOf('email')==-1) {
            if (orgName)
              data['link'] = liferayBaseUrl+'/'+orgName + data['link'];
            else
              data['link'] = liferayBaseUrl + data['link'];

          }
          else {
            if (orgName)
              data['link'] = orgName + data['link'];
          }
          data['consentText1']= decodeURI(data['consentText1']);
          data['consentText2']= decodeURI(data['consentText2']);
          this.consentBox3.push(data);
        });
        this.sharedConfigListRef = this.jobSearchService.sharedConfigList.subscribe(config=>{
          if (config && config.isPrivacyConsentEnabled) {
            this.isPrivacyConsentEnabled = config.isPrivacyConsentEnabled;
          }
        });
      }
    }, (error) => {
    });
  }


  submitPrivacyConsent() {
    this.buttonClicked = true;
    if(this.consentBox1 && this.consentBox1.length>0  && this.consentBox2.length===0 && this.consentBox3.length===0){
      if(this.checked1) {
        let flag={type:'',event:true};
        this.consentFlag.emit(flag);
      }
      else {
        this.privacyPolicyErrorMsg1 = true;
        this.buttonClicked=false;
      }
    }
    else if(this.consentBox1 && this.consentBox1.length>0  && this.consentBox2.length>0 && this.consentBox3.length===0){
      if(this.checked1  && this.checked2) {
        let flag={type:'',event:true};
        this.consentFlag.emit(flag);
      }
      else if(this.checked1 && !this.checked2) {
        if(this.isMarketingConsentForHU || this.isPrivacyConsentEnabled) {
          let flag={type:'',event:false};
          this.consentFlag.emit(flag);
        } else {
          this.privacyPolicyErrorMsg2 = true;
          this.buttonClicked = false;
        }
      }
      else if(!this.checked1 && this.consentBox2) {
        this.privacyPolicyErrorMsg1 = true;
        this.buttonClicked = false;
      }
      else {
        this.buttonClicked = false;
        this.privacyPolicyErrorMsg1=true;
        if(!this.isMarketingConsentForHU)
          this.privacyPolicyErrorMsg2=true;
      }

    }else if(this.consentBox1 && this.consentBox1.length>0  && this.consentBox2 && this.consentBox2.length>0 && this.consentBox3.length>0){
      if(this.checked1) {
        let flag={type:'',event:true};
        this.consentFlag.emit(flag);

      }else {
        this.privacyPolicyErrorMsg1=true;
        this.buttonClicked = false;
      }
    }
  }

  closePopup() {
    this.close.emit(false);
  }
}
