import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../../../global/services/site/site.service';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../../../environments/root/environment';
import {map} from 'rxjs/operators';
import {MarketingPrefAdapt, MarketingPrefData, MarketingPrefJsonRequest} from '../../../../model/response/marketing-pref';
import {RequestService} from '../../../../../global/services/request/request.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DecryptIdentifierJsonRequest} from '../../../../model/response/decrypt-identifier';
import {SetTitleService} from '../../../../service/set-title.service';
import {TokenStorage} from '../../../../../global/services/tokenStorage/token-storage';
import {DataSharingService} from '../../../../service/data-sharing.service';
import {PersonalDetails} from '../../../../model/response/my-profile';

@Component({
  selector: 'app-jp-marketing-pref',
  templateUrl: './jp-marketing-pref.component.html',
  styleUrls: ['./jp-marketing-pref.component.scss'],
  providers:[MarketingPrefAdapt]
})
export class JpMarketingPrefComponent implements OnInit {
public gDPRTracking: boolean;
public buttonDisable: boolean;
public candidateId: string;
public emailAdd: string;
public portalProfileId: string;
public baseRoute: string;
public errorRoute: string;
private personalDetails:PersonalDetails;

public marketingPrefData: MarketingPrefData;
public isDataLoaded :boolean;
  private yesText: string;
  public updateClicked: boolean;
  constructor(private activatedRoute: ActivatedRoute,private router: Router,private requestService: RequestService, private dataSharingService: DataSharingService,
              private _translate: TranslateService, private siteService: SiteService, private marketingPrefAdapt: MarketingPrefAdapt,
              private titleService:SetTitleService,private token:TokenStorage) {
    this.dataSharingService.sharedPersonalDetails.subscribe(personalData=>{
      this.personalDetails = personalData;
    });
    this._translate.setDefaultLang(this.siteService.getSiteLocale());
    this._translate.getTranslation(siteService.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(res['marketing_preference_heading_text']);
    });
    this._translate.getTranslation(siteService.getSiteLocale()).subscribe((res)=>{
      this.yesText = (res['yes_text']);
    });
    this.buttonDisable=true;
    this.candidateId="0";
    this.portalProfileId="0";
  }

  ngOnInit() {
    this.setRoute();
    this.dataSharingService.getWidgetListName(keywords.marketingPrefPageName);
  }

  setRoute(){
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    if(orgName){
      let base = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org];
      this.baseRoute = (base[routingEndpoint.gdprThankyouPage]).replace(':orgName',orgName);
      this.errorRoute = (base[routingEndpoint.errorPage]).replace(':orgName',orgName);
    }
    else{
      let base = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
      this.baseRoute = base[routingEndpoint.gdprThankyouPage];
      this.errorRoute = base[routingEndpoint.errorPage];
    }
  }

  getGDPRTracking(event){
    this.gDPRTracking=event;
    this.activatedRoute.queryParams.subscribe((params)=> {
      if(params['gdprFlag']) {
        this._translate.setDefaultLang(this.siteService.getSiteLocale());
        this.decryptIdentifier(params['gdprFlag']);
      }
      else if(!params['gdprFlag'] && !this.token.getLoggedInToken())
        this.token.login('q','unauthorised','','','anonymous',0);
      else
        this.getMarketingPref(this.candidateId,this.emailAdd,this.gDPRTracking,this.portalProfileId);
    });
  }
  getMarketingPref(candidateId,emailAdd,gDPRTracking,portalProfileId){
    let jsonData= new MarketingPrefJsonRequest(candidateId,emailAdd,gDPRTracking,portalProfileId);
    this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getMarketingPreferences,jsonData).pipe(
      map((res: MarketingPrefAdapt) => this.marketingPrefAdapt.adapt(res)))
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
       this.marketingPrefData=res.data;
       this.isDataLoaded=true;
        }else{
          this.isDataLoaded=true;
          this.marketingPrefData=new MarketingPrefData(null,null,null,null,null,null,null,false,false,false,false,false,false,false,false,null,null,null);
        }
      }, (error) => {
        this.router.navigate([this.errorRoute],{queryParams:{gdprStatus:keywords.fail}});
      });
  }
  decryptIdentifier(identifier){
    let jsonData= new DecryptIdentifierJsonRequest(identifier,keywords.gdprFlag);
    this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.decryptIdentifier,jsonData)
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          if (res.data['statusCode']==='200') {
            let result = res.data['data'];
            this.emailAdd = result.split("&")[1].split("=")[1];
            this.portalProfileId = result.split("&")[2].split("=")[1];
            this.candidateId = result.split("&")[3].split("=")[1].split('"')[0];
            this.getMarketingPref(this.candidateId, this.emailAdd, this.gDPRTracking, this.portalProfileId);
          }else  this.router.navigate([this.errorRoute],{queryParams:{gdprFlag:keywords.expired}});
        }
      }, (error) => {
        this.router.navigate([this.errorRoute],{queryParams:{gdprFlag:keywords.invalid}});

      });
  }


  updateMarketingPref(){
    if(this.marketingPrefData.email_address || (this.personalDetails && this.personalDetails.email)){
      this.updateClicked=true;
    this.buttonDisable=true;
    this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.updateMarketingPreferences,this.marketingPrefData)
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          this.router.navigateByUrl(this.baseRoute);
        }
      }, (error) => {
        this.router.navigate([this.errorRoute],{queryParams:{gdprSubmit:keywords.fail}});
      });
    }
  }
  setService(event,type){
    this.buttonDisable=false;
    switch (type){
      case keywords.email:
        this.marketingPrefData.consent_email = event.currentTarget.innerText === this.yesText;
        break;
      case keywords.sms:
        this.marketingPrefData.mrktng_sms = event.currentTarget.innerText === this.yesText;
        break;
      case keywords.whatapp:
        this.marketingPrefData.whats_mrk = event.currentTarget.innerText === this.yesText;
        break;
      case keywords.survey:
        this.marketingPrefData.consent_survey = event.currentTarget.innerText === this.yesText;
        break;
    }

  }

}
