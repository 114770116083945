<div  class="recent-application">
  <h3 [translate]="'recent_history_text'"></h3>
  <div *ngIf="recentApplicationData && recentApplicationData.length>0">
  <ul>
    <li *ngFor="let recentApplication of recentApplicationData">
      <h4 *ngIf="!recentApplication.expired">
        <a id="gtm_recent_app_jobid" [href]="jobDetailsPage(recentApplication)" [textContent]="recentApplication.jobtitle"></a>
      </h4>
      <strong *ngIf="recentApplication.expired" [textContent]="recentApplication.jobtitle"></strong>
      <span>{{'applied_text'|translate}} {{recentApplication.lastediteddate}} {{'at_text'|translate}} {{recentApplication.lasteditedtime}}</span>
    </li>

  </ul>
  <div class="history-msg" [translate]="'app_history_note_text'" ></div>
  </div>
  <div *ngIf="recentApplicationData.length==0">
    <p [translate]="'app_history_not_found_text'"></p>
  </div>

</div>
