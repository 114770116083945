import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErpMarketingPreferencesComponent } from './component/erp-marketing-preferences/erp-marketing-preferences.component';
import { ErpCustomLoaderComponent } from './component/erp-custom-loader/erp-custom-loader.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {FormsModule} from "@angular/forms";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {environment} from "../../../environments/root/environment";
import {RouterModule} from "@angular/router";
import {ErpManageJobAlertComponent} from "./component/erp-manage-job-alert/erp-manage-job-alert.component";
import {SharedModule} from "../../jobportal/shared/shared.module";
import { ErpDeleteAllJobAlertsPopupComponent } from './component/erp-delete-all-job-alerts-popup/erp-delete-all-job-alerts-popup.component';
import {AppModule} from "../../app.module";
import {ModelComponent} from "./component/customModals/modal.component";


@NgModule({
  declarations: [ErpMarketingPreferencesComponent, ErpCustomLoaderComponent, ErpManageJobAlertComponent, ErpDeleteAllJobAlertsPopupComponent, ModelComponent],
    imports: [
        CommonModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FormsModule,
        RouterModule,
        SharedModule

    ],
  exports: [
    ErpMarketingPreferencesComponent,
    ErpCustomLoaderComponent,
    ModelComponent
  ]
})
export class ErpSharedModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    environment.translations,
    '.json'
  );
}
