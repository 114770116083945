import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {JobSearchService} from '../../../../../service/job-search.service';
import {RequestService} from '../../../../../../global/services/request/request.service';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {TokenStorage} from '../../../../../../global/services/tokenStorage/token-storage';
import {GoogleJobResponse} from '../../../../../model/response/google-job-response';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../../../../environments/root/environment';
import {NgxXml2jsonService} from 'ngx-xml2json';
import {CookieService} from 'ngx-cookie-service';
import {DOCUMENT, isPlatformServer, PlatformLocation} from '@angular/common';
import {DataSharingService} from '../../../../../service/data-sharing.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Meta, Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-jp-detail',
  templateUrl: './jp-detail.component.html',
  styleUrls: ['./jp-detail.component.scss']
})
export class JpDetailComponent implements OnInit,OnDestroy {
  public googleJobResponse: GoogleJobResponse[];
  public hostName: string;
  private applyIdCookie: string;
  public googleSimilarJobResponse: GoogleJobResponse[];
  public applyClicked: boolean;
  public emailLinkBody: string;
  private userAgent: string;
  private cookieDomain: string;
  public isGo1Enabled:boolean;
  constructor(private activatedRoute:ActivatedRoute,private searchService : JobSearchService,private requestService : RequestService,
              private siteService:SiteService,private tokenStorage:TokenStorage,private router:Router,
              private xmlParserService :NgxXml2jsonService,private cookieService:CookieService,private _renderer2: Renderer2,private dataSharingService :DataSharingService,
              @Inject(DOCUMENT) private _document: Document,private deviceDetector:DeviceDetectorService,
              private titleService: Title ,private metaService: Meta, private translaService:TranslateService,
              private platformLocation: PlatformLocation,@Inject(PLATFORM_ID) private platformId: object) {

    this.userAgent =this.deviceDetector.isMobile()?'-Mobile':this.deviceDetector.isDesktop()?'-Desktop':'-Tablet';
  }

  ngOnInit() {
    let country='';
    let lang='';
    let path=[];
    this.activatedRoute.queryParamMap.subscribe(param=>{
        if(param.get('country'))
          country=param.get('country');
        if(param.get('lang'))
          lang = param.get('lang');
        if(param.get('path')){
          path=param.get('path').split('/');
          country=path[0];
          lang=path[1];
        }

        if (country.toUpperCase() === "UK") {
          country = "GB";
        }
        if(country && lang)
          this.siteService.setSite('/' + country.toUpperCase() + '/' + lang + '/');
        this.cookieDomain =environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.cookieDomain];
    })
    localStorage.removeItem("requstedRedirectURL");
    this.isGo1Enabled = environment[config.theme + this.siteService.getSiteLocale()]['config']['isGo1Enabled'];
    this.dataSharingService.getWidgetListName(keywords.jobDetailWidget);
    this.googleJobResponse=null;
    // this.searchService.setJobDetailResponse(this.googleJobResponse);
    this.hostName=window.location.hostname;
    this.searchService.getJobDeatils(this.activatedRoute, this.requestService, this.siteService, this.tokenStorage, this.xmlParserService,
      'jobDetails', '','',this.cookieService,this.router,this.userAgent,this.cookieDomain, this.dataSharingService,this.translaService,this.titleService,this.metaService,this._document);
    this.searchService.sharedJobDetailData.subscribe(jobDetailData=> {
        this.googleJobResponse = jobDetailData;
      if (this.cookieService.get('applyJobid'))
        this.applyIdCookie = this.cookieService.get('applyJobid');
      if (this.googleJobResponse && this.googleJobResponse.length > 0) {
        this.searchService.getJobDeatils(this.activatedRoute, this.requestService, this.siteService, this.tokenStorage,
          this.xmlParserService, 'similarJob', this.googleJobResponse[0].JobTitle,
          this.googleJobResponse[0].locationDesc, this.cookieService,this.router,this.userAgent,this.cookieDomain, this.dataSharingService,this.translaService,this.titleService,this.metaService,this._document);
        this.searchService.sharedJobResponseList.subscribe(similarJob => {
          this.googleSimilarJobResponse = similarJob;
          if (this.googleSimilarJobResponse && this.googleSimilarJobResponse.length > 0 && this.googleJobResponse && this.googleJobResponse.length > 0) {
           // console.log("similar jobs..",this.googleSimilarJobResponse);
            this.googleSimilarJobResponse.forEach((job, index) => {
              if (job.jobId === this.googleJobResponse[0].jobId) {
                this.googleSimilarJobResponse.splice(index, 1);
              }

            });
          }
        });
        if (this.googleJobResponse && this.googleJobResponse.length>0 && this.googleJobResponse[0].jobId.indexOf(this.applyIdCookie) > -1)
          this.googleJobResponse[0].isJobApplied = true;
    }
    });

  }
  ngOnDestroy(){
   // console.log("details destroyed");
   //  this.searchService.clearJobDetailData();
    this.googleJobResponse=undefined;

  }
  public redirectToApplicationUrl(applicationURL: string) {
    window.location.href=applicationURL;
  }

  public redirectToApply(applyId:string,jobName:string) {
      this.applyClicked=true;
    let applyUrl='';
    if(this.tokenStorage.getLoggedInToken())
      applyUrl=this.getApplyUrl(routingEndpoint.secureJobApply);
    else
      applyUrl=this.getApplyUrl(routingEndpoint.jobApply);
    let keyWord='';
    let jobSource ='';
    let location='';
    let specialismId='';
    let subSpecialismId='';
    let defaultJobSource='';
    this.dataSharingService.sharedClientConfig.subscribe(res => defaultJobSource = res.jobSource );
    this.activatedRoute.queryParamMap.subscribe(param=>{
      if(param.get('q')){
        keyWord = param.get('q');
      }
      jobSource=param.get('jobSource')?param.get('jobSource'):param.get('jobsource')?param.get('jobsource'):param.get('source')?param.get('source'):defaultJobSource;
      if(param.get('location')){
        location = param.get('location');
      }
      if(param.get('specialismId')){
        specialismId = param.get('specialismId');
      }
      if(param.get('subSpecialismId')){
        subSpecialismId = param.get('subSpecialismId');
      }
    });
    let urlFinal='';
    let isExpertClient;
    // let urlFinal=config.baseWebAppUrl+applyUrl+'?q='+keyWord+'&jobName='+decodeURIComponent(jobName)+'&applyId='+applyId+'&jobSource='+jobSource+'&specialismId='+specialismId+'&subSpecialismId='+subSpecialismId;
    if(applyUrl.indexOf(':recordId')>-1)
      urlFinal=window.location.origin+applyUrl.replace(':recordId',applyId)+'?q='+keyWord+'&jobName='+decodeURIComponent(jobName)+'&applyId='+applyId+'&jobSource='+jobSource+'&specialismId='+specialismId+'&subSpecialismId='+subSpecialismId;
    else
      urlFinal=config.baseWebAppUrl+applyUrl+'?q='+keyWord+'&jobName='+decodeURIComponent(jobName)+'&applyId='+applyId+'&jobSource='+jobSource+'&specialismId='+specialismId+'&subSpecialismId='+subSpecialismId;
        config.ExpertClientsName.forEach(a => { if (applyUrl.includes(a)) isExpertClient = true; })
        config.ExpertClientsCode.forEach(a => { if (applyUrl.includes(a)) isExpertClient = true; })
          if (isExpertClient)
          window.location.href = urlFinal + '&showSendCV=N&showApplyForm=true';
        else
          window.location.href = urlFinal;
  }

  getApplyUrl(endpointUrl){
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"]
    let applyurl ='';
    if(orgName){
      let url =environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][endpointUrl];
      applyurl = url.replace(':orgName',orgName);
    }
    else
      applyurl=environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][endpointUrl];
    return applyurl;
  }

  public getEmailLink($event: string) {
    this.emailLinkBody=$event;
  }
}
