import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-hcpatcha',
  templateUrl: './hcpatcha.component.html',
  styleUrls: ['./hcpatcha.component.css']
})
export class HcpatchaComponent implements OnInit {
  public formGroup  :FormGroup;

  public isPlatform : boolean = false;

  constructor(private formBuilder: FormBuilder, @Inject(PLATFORM_ID) private platformId: object) { }

  ngOnInit(): void {
    if(isPlatformBrowser(this.platformId)){
      this.isPlatform = true;
      this.generateForm();
    }
  }

  private generateForm(){
    this.formGroup = this.formBuilder.group({
      captcha : this.formBuilder.control('', { updateOn : 'change'}),
    });
  }

  public submitForm(){
    console.log(this.formGroup.controls.captcha.value);
  }
}
