import {Adapter} from '../../../global/adapter/adapter';
export class LanguageSpokenData{
    constructor(public code_id: number,
        public language: string,
        public prof: profData[]){}
}
export class profData{
    constructor(public code_id: number,
        public level: string){}
}

export class LanguageSpoken{
    constructor(public status: string,
        public message: string,
        public data: LanguageSpokenData[]){}
}

export class LanguageSpokenAdapt implements Adapter<LanguageSpoken> {
    adapt(item:any): LanguageSpoken{
        return new LanguageSpoken(
            item.status,
            item.message,
            item.data
        );
    }
}