export class UnsubscribeRequest {
  constructor(
  public identifier: string,
  public email: string,
  public languageId: string,
  public signedUrl: string
){}

}

