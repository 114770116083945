import {Adapter} from '../../../global/adapter/adapter';

export class UploadCVData{
    constructor(){}
}

export class UploadCV{
    constructor(public status: string,
        private message: string,
        public data: UploadCVData[]){}
}

export class UploadCVAdapt implements Adapter<UploadCV> {
    adapt(item:any): UploadCV{
        return new UploadCV(
            item.status,
            item.message,
            item.data
        );
    }
}
