import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {RequestService} from 'src/app/global/services/request/request.service';
import {map} from 'rxjs/operators';
import {Sector, SectorAdapt, SectorData} from 'src/app/jobportal/model/response/sector';
import {SiteService} from 'src/app/global/services/site/site.service';
import {TranslateService} from '@ngx-translate/core';
import {FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {apiEndPoints, config, environment, keywords} from '../../../../../environments/root/environment';
import {JsonRequest} from '../../../model/response/office-locator';
import {OfficeForSpecialism, OfficeForSpecialismAdapt, OfficeForSpecialismData} from '../../../model/response/office-for-specialism';
import {DataSharingService} from '../../../service/data-sharing.service';

@Component({
  selector: 'app-jp-sector',
  templateUrl: './jp-sector.component.html',
  styleUrls: ['./jp-sector.component.scss'],
  providers: [SectorAdapt,OfficeForSpecialismAdapt]
})
export class JpSectorComponent implements OnInit {
  sectorList: SectorData[] = [];
  siteLocale: any;
  @Input() public parent: FormGroup;
  @Input() public sector: String;
  @Input() public officeSpecialism: string;
  @Input() specialismId : string;
  public sectorName:string;
  public officeList : OfficeForSpecialismData[];
  @ViewChild('sectorId', {static: false}) sectorId :ElementRef;
  @ViewChild('sectorListData', {static: false}) sectorListValues :ElementRef;
  public isMultiSelect: boolean;
  @Input() public isAUSHaysOffice: boolean;
  public selectedSectorValue: string;
  orgName: string;
  public updateYourDetailsUrl: string;

  constructor(private _requestService: RequestService, private sectorAdapt: SectorAdapt,
    public _site: SiteService, private _translate: TranslateService,private officeForSpecialismAdapt:OfficeForSpecialismAdapt ,private dataSharing:DataSharingService, public router:Router) {
    this.siteLocale = this._site.getSiteLocale();
    this._translate.setDefaultLang(this.siteLocale);
  }

  ngOnInit() {
    this.getSectors();
    this.updateYourDetailsUrl = keywords.updateYourDetailsUrl;
  }

  getSectors() {
    this.orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    let requestPayload= new JsonRequest('GetSpecialism','');
    this._requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI, apiEndPoints.getSector, requestPayload).pipe(
      map((response: Sector) => this.sectorAdapt.adapt(response))
    ).subscribe(response => {
      if (response.status === config.SUCCESS) {
        this.sectorList = environment[config.theme + this._site.getSiteLocale()].config['filterSectors'] ? this.filteredSectors(response.data['results']) : response.data['results'];
        if(this.sector){
          if(this.sectorList && this.sectorList.length>0){
            this.sectorList.forEach(sector=>{
              if(sector.id===this.sector) {
                this.sectorName=sector.label;
                this.parent.value.expertise=sector.id;
              }

            })
          }
        }
        if(this.orgName === keywords.orgName) {
          if (this.sectorList && this.sectorList.length > 0) {
            this.sectorList.forEach(sector => {
              if (sector.id === keywords.orgSectorName) {
                this.parent.get('expertise').setValue(sector.id);
                this.getOfficeList('');
              }
            })
          }
        }
      }
    }, (error) => {
    });
  }

  getOfficeList($event) {
    this.selectedSectorValue = this.parent.value.expertise
    let json = new JsonRequest(keywords.GetOfficeForSpecialism, this.parent.value.expertise);
    if (json['specialismType']) {
      this.dataSharing.isSectorClicked(true);
      this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getOfficeForSpecialism, json).pipe(
        map((res: OfficeForSpecialism) => this.officeForSpecialismAdapt.adapt(res)))
        .subscribe((res) => {
          if (res.status === config.SUCCESS && res.data) {
            if (res.data["results"].length > 0) {
              this.officeList = res.data["results"];
              this.officeList.sort((a, b) => a.office_name.localeCompare(b.office_name));
              if (this.isAUSHaysOffice)
                this.officeList.sort((a, b) => a.State.localeCompare(b.State));
              console.log("this.officeList..", this.officeList);
              // if(this.officeSpecialism){
              if (this.officeList && this.officeList.length > 0) {
                this.dataSharing.setOfficeList(this.officeList);
              }
              //  }
            }
          }
        }, (error) => {

        });
    }
    else {
      this.officeList = null;
      this.dataSharing.setOfficeList([]);
      this.parent['controls']['officeForSpecialism'].setValue('');
    }
  }
  /**
   * Called to filter sectors if feature is enabled in config
   * @param collection 
   */
  private filteredSectors(collection : []){
    const filterableSectorsList = environment[config.theme + this._site.getSiteLocale()].config['filterableSectorsList']; 
    return collection.filter((element) => {
      return !filterableSectorsList.includes(element['label']);
    });
  }
}
