import {Adapter} from '../../../global/adapter/adapter';
import {Injectable} from '@angular/core';

export class Employee {
  constructor(public status: string, public message: string, public data:EmployeeData[]) {
  }

}

export class EmployeeData{
  constructor(public lookuptableText1: string, public lookuptableText3:string){
  }
}

@Injectable({
  providedIn:'root'
})
export class EmployeeAdapt implements Adapter<Employee>{
  adapt(item: any): Employee {
    return new Employee(
      item.status,
      item.message,
      item.data
    );
  }

}
