<div [class]="pageConfig.applyOptions.isSeekApply ? 'other-option other-option-seek-acc' : 'other-option'" *ngIf="pageConfig && pageConfig.applyOptions">
  <label *ngIf="azureEnable " [translate]="'or_apply_with_text'" class="apply-text"></label>
  <div class="help-text" [translate]="!hideText ?(azureEnable ? 'applying_text' : 'linkedIn_or_indeed_profile_applying_text' ):'apply_using_text'"></div>
  <form id="gtm_apply_option">
    <div class="hays-account-login" *ngIf="azureEnable && !isLoggedin && !disable_login && !hideText">
      <button id="gtm_login_btn" (click)="redirectToAzure()" class="btn btn-default" [translate]="'hays_account_key'"></button>
    </div>
    <div class="apply-indeed" *ngIf="pageConfig.applyOptions.isIndeedApply">
      <div class="label" *ngIf="!hideText && (!azureEnable && !disable_login)">
        <label [translate]="'indeed_profile_key'"></label>
      </div>
      <div class="indeed-btn-cont" *ngIf="JobResponse && JobResponse.length>0">
        <button id="gtm_indeed_apply_btn" (click)="indeedApply(true)" class="btn btn-secondary"
                tabindex="44" [translate]="'apply_with_indeed_text'">
        </button>
      </div>
    </div>
    <div [hidden]="true" *ngIf="JobResponse && JobResponse.length>0">
        <span class="indeed-apply-widget" style="position: absolute !important; opacity: 0!important;" id="indeed-apply-widget-id"
              [attr.data-indeed-apply-apitoken]="'12a13d402815d3e78b5738b932fc0512fa2203e381f6d72bb16911120514bdc0'"
              [attr.data-indeed-apply-jobid]="JobResponse[0].jobId"
              [attr.data-indeed-apply-joblocation]="JobResponse[0].locationDesc"
              [attr.data-indeed-apply-jobcompanyName]="'Hays'"
              [attr.data-indeed-apply-jobtitle]="JobResponse[0].JobTitle"
              [attr.data-indeed-apply-joburl]=""
              [attr.data-indeed-apply-posturl]="indeedAPIURL"
              [attr.data-indeed-apply-jobmeta]="'right-rail-apply-button'"
              [attr.data-indeed-apply-skipcontinue]="true"
              [attr.data-indeed-apply-continueurl]="thankyouPageURL"></span>
      <jp-script>
        {{indeedJs}}
      </jp-script>
    </div>
    <div class="apply-linkedin" *ngIf="pageConfig.applyOptions.isLinkedinApply && !pageConfig.applyOptions.isSeekApply">
      <div class="label" *ngIf="!hideText && !azureEnable && !disable_login " >
        <label [translate]="'linkedin_profile_key'"></label>
      </div>

      <div [ngClass]="profileData?'linkedin-wrapper linkedin-disabled':'linkedin-wrapper'" name="jwidget-holder">
        <jp-script type="text/javascript"
                   src="https://www.linkedin.com/mjobs/awli/awliWidget" integrity="sha384-R4/ztc4ZlRqWjqIuvf6RX5yb/v90qNGx6fS48N0tRxiGkqveZETq72KgDVJCp2TC">
        </jp-script>
        <jp-script type="IN/AwliWidget"
                   dataCompanyJobCode="{{jobID}}"
                   dataIntegrationContext="urn:li:organization:3486"
                   dataMode="BUTTON_DATA"
                   dataCallbackMethod="onProfileData"
                   dataApiKey="75so5asrp6ofvr"
                   dataAllowSignIn="true"
                   dataSize="small">
        </jp-script>
      </div>
    </div>

    <div class="apply-seek" *ngIf="pageConfig.applyOptions.isSeekApply" >
      <div>
        <div class=""seek-help-text [translate] = "azureEnable ? '' : 'apply_with_your_seek_account'"> </div>
        <!-- <button *ngIf = 'hideText' id = 'gtm_login_btn' class = 'btn btn-secondary seek-hays-login-btn seek-hays-btn' [translate] = "'hays_account_key'"></button> -->
        <button (click)="seekApply(true)" id = 'gtm_seek_apply_btn' class = 'btn btn-secondary seek-hays-btn' [translate] = "'seek_account_btn_text'"></button>

        <div [ngClass]="profileData?'linkedin-wrapper linkedin-disabled linkedInApplyTopMargins':'linkedin-wrapper linkedInApplyTopMargins'" name="jwidget-holder" *ngIf = "pageConfig.applyOptions.isLinkedinApply">
          <jp-script type="text/javascript"
                     src="https://www.linkedin.com/mjobs/awli/awliWidget" integrity="sha384-R4/ztc4ZlRqWjqIuvf6RX5yb/v90qNGx6fS48N0tRxiGkqveZETq72KgDVJCp2TC">
          </jp-script>
          <jp-script type="IN/AwliWidget"
                     dataCompanyJobCode="{{jobID}}"
                     dataIntegrationContext="urn:li:organization:3486"
                     dataMode="BUTTON_DATA"
                     dataCallbackMethod="onProfileData"
                     dataApiKey="75so5asrp6ofvr"
                     dataAllowSignIn="true"
                     dataSize="small">
          </jp-script>
        </div>
      </div>
    </div>
  </form>
  <app-jp-legal-consent-checkbox-popup *ngIf="isconsentPopUpEnabled && !isMarketingConsentEnabled" [legalConsentVisible] = isconsentPopUpEnabled (consentFlag) = "getConsentValue($event)" (close)="closePopup($event)"></app-jp-legal-consent-checkbox-popup>
  <app-jp-market-consent-linked-in-popup *ngIf="isconsentPopUpEnabled && isMarketingConsentEnabled"
                                         [legalConsentVisible] = isconsentPopUpEnabled (consentFlag) = "getConsentValue($event)"
                                         (close)="closePopup($event)">
  </app-jp-market-consent-linked-in-popup>
  <app-jp-marketing-linked-in-alert-popup (crossClicked)="crossClicked($event)"
    *ngIf="(isApplyOptionComponent && dataSharingService.$openMarketContentPopup | async) || marketConsentPopup" [consentPopUp]="marketConsentPopup" (consentFlag)="getConsentValue($event)"></app-jp-marketing-linked-in-alert-popup>
</div>
