import {Component, Inject, Input, OnInit, PLATFORM_ID, Renderer2} from '@angular/core';
import {GoogleJobResponse} from '../../../../../model/response/google-job-response';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {apiEndPoints, config, environment, keywords} from '../../../../../../../environments/root/environment';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DataSharingService} from '../../../../../service/data-sharing.service';
import { DOCUMENT, isPlatformServer, PlatformLocation } from '@angular/common';
import { JobSearchService } from 'src/app/jobportal/service/job-search.service';

@Component({
  selector: 'app-google-for-jobs',
  templateUrl: './google-for-jobs.component.html',
  styleUrls: []
})
export class GoogleForJobsComponent implements OnInit {
  @Input() public jobDetails : GoogleJobResponse[]=[];
  public schema: {};

  constructor(private siteService:SiteService,private activatedRoute:ActivatedRoute,private translate:TranslateService, private dataSharingService : DataSharingService,private platformLocation: PlatformLocation,@Inject(PLATFORM_ID) private platformId: object,private jobSearchService: JobSearchService, private renderer: Renderer2,@Inject(DOCUMENT) private document: Document) {
    this.translate.setDefaultLang(this.siteService.getSiteLocale());
  }

  ngOnInit() {
    let currency = '';
   // const siteBaseURL = environment[config.theme + this.siteService.getSiteLocale()]['siteBaseURL'];
    const siteBaseURL =  this.jobSearchService.siteBaseURL;
    this.jobSearchService.sharedConfigList.subscribe(searchFilter => {
      if(searchFilter && searchFilter.currency){
        currency = searchFilter.currency;
      }
      let country= environment[config.theme+this.siteService.getSiteLocale()].config.country_name;
      let locale ='';
      let recordId='';
      let liferayURL=environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.liferaybaseUrl];
      this.activatedRoute.paramMap.subscribe(params=>{
        if(params.get('country')){
          country=params.get('country');
        }
        locale=params.get('locale');
        recordId=params.get('recordId');
  
      });
      if(this.jobDetails && this.jobDetails.length>0){
        let jobType='';
        let logo = '';
        let sharedImageUrl = '';
        let url = siteBaseURL + this.platformLocation.pathname;
        let orgNameList = ['/it/', '/cognizant/', '/yourrailindustryjob/', '/geniuswithin/'];
        orgNameList.forEach(orgName => {
          console.log("orgName..",orgName);
          console.log("location path....",this.platformLocation.pathname);
          if (this.platformLocation.pathname.indexOf(orgName) > -1) {
            console.log("inside if ");
            url = siteBaseURL + this.platformLocation.pathname.replace(orgName, '/');
            console.log("meta url.. ",url);
          }
        });
        logo= siteBaseURL + '/assets/images/hays-og-optimize-image-new.png';
        /*if(isPlatformServer(this.platformId)){
          url = 'https://'+ this.platformLocation.hostname+this.platformLocation.pathname;
          logo =  'https://'+this.platformLocation.hostname +'/assets/images/hays-og-optimize-image-new.png'
        }else {
          url = window.location.href.split('?q')[0];
          logo = window.location.origin+'/assets/images/hays-og-optimize-image-new.png';
        }*/
        this.translate.get(this.jobDetails[0].jobType).subscribe(val=>jobType=val);
        this.schema = {
          "@context": "http://schema.org",
          "@type": "JobPosting",
          "title": this.jobDetails[0].JobTitle ? this.jobDetails[0].JobTitle : "",
          "url": url,
          "datePosted": this.formatDate(this.jobDetails[0].createDate),
          "validThrough": this.jobDetails[0].endDate?this.formatDate(this.jobDetails[0].endDate):"1970-01-01",
          "description": this.jobDetails[0].description ? this.jobDetails[0].description: "",
          "industry": this.jobDetails[0].industry ? this.jobDetails[0].industry:"",
          "employmentType":jobType ? jobType : "",
          "baseSalary":{
            "@type":"MonetaryAmount",
            "currency": currency,
            "value" : {
              "@type" : "QuantitativeValue",
              "value":this.jobDetails[0].incentives?this.jobDetails[0].incentives:"Competitive",
              "unitText" : "YEAR"
            }
          }, "salaryCurrency": currency,
          "hiringOrganization": {
              "@type": "Organization",
              "name": "Hays",
        "url": liferayURL,
          "logo": logo
      },
        "jobLocation": {
          "@type": "Place",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": this.jobDetails[0].locationDesc ? this.jobDetails[0].locationDesc: "",
            "addressRegion": this.jobDetails[0].locationDesc ? this.jobDetails[0].locationDesc : "",
            "addressCountry": country? country:"" ,
            "postalCode": "NA",
            "streetAddress": "NA"
  
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": this.jobDetails[0].xLatitude ? this.jobDetails[0].xLatitude: "",
            "longitude": this.jobDetails[0].xLongitude ? this.jobDetails[0].xLongitude : ""
          }
        }
  
  
        };
        if(!this.document.getElementById('jobPostingSchema')) {
          this.setJsonLd(this.schema);
        }
      }
    });
  }
  formatDate(formatDate: any) {
    formatDate = formatDate.year+'-'+('0' + (formatDate.month)).slice(-2) + '-' +('0' + formatDate.day).slice(-2);
    return formatDate;
  }
  setJsonLd(data: any): void {
    let script = this.renderer.createElement('script');
    script.id = 'jobPostingSchema';
    script.type = 'application/ld+json';
    script.text = `${JSON.stringify(data)}`;
    this.renderer.appendChild(this.document.head, script);
  }

}
