<section>
  <div class="unsubscribe text-center">
    <h3 [translate]="'unsubscribe_title'"></h3>
  <div class="row">
    <div class="col-12">
      <div class="content" [translate]="'unsubscribe_msg'"></div>
      <div [textContent]="email"> </div>
    </div>

    <div class="col-12 btn-cont">
      <button  id="gtm_unsubscribe_submit_btn" name="submit" [disabled]="unsubscribeBtnClicked" [class]="!unsubscribeBtnClicked?'btn btn-default':'btn btn-default btn-loading'" type="submit" [translate]="'unsubscribe_btn'" (click)="unsubscribe();"></button>
    </div>
  </div>

  <div class="sign-in">
    <span>{{'unsubs_Eshot_msg'|translate}} </span>
    <a id="gtm_unsubs_login_redirect" [href]="loginUrl">{{'success_msg_on_err_page1'|translate}}</a>
    <span *ngIf="showPrivacyPolicy"><span>{{'unsubs_Eshot_msg_text1'|translate}}
      <a id="gtm_unsubs_privacypolicy_redirect" [href]="privacyPolicyLink">{{'here_text'|translate}}</a>  {{'unsubs_Eshot_msgline'|translate}}</span>
    </span>
  </div>
  </div>
</section>
