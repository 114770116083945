import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {RequestService} from '../../../../global/services/request/request.service';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../../global/services/site/site.service';
import {Router} from '@angular/router';
import {apiEndPoints, config} from '../../../../../environments/root/environment';
import {Subscription} from 'rxjs';
import {MarketingConsent} from '../../../model/request/marketing-consent';
import {DataSharingService} from '../../../service/data-sharing.service';

@Component({
  selector: 'app-jp-marketing-consent-popup',
  templateUrl: './jp-marketing-consent-popup.component.html',
  styleUrls: ['./jp-marketing-consent-popup.component.scss']
})
export class JpMarketingConsentPopupComponent implements OnInit, OnDestroy {

  public marketSelectForm: FormGroup;
  public formSubmitted: boolean;
  public siteLocale: string;
  public marketConsentRes: any;
  public dontReciveText: string;
  public emailText: string;
  public smsText: string;
  public whatsAppText: string;
  private dontReciveRef: Subscription = new Subscription();
  private emailRef: Subscription = new Subscription();
  private smsRef: Subscription = new Subscription();
  private whatsappRef: Subscription = new Subscription();
  private marketingConsentData: MarketingConsent;
  private marketingConsentDataRef: Subscription = new Subscription();
  @Output() public crossClicked = new EventEmitter<boolean>();
  @Output() public consentFlag = new EventEmitter<boolean>();
  @Input () public consentPopUp: boolean;



  constructor(private _requestService: RequestService, private _translate: TranslateService,
              private siteService: SiteService, private router: Router,
              public dataShareService: DataSharingService) {
    this.siteLocale = this.siteService.getSiteLocale();
    this._translate.setDefaultLang(this.siteLocale);
  }

  ngOnInit() {
    if (!this.consentPopUp) {
    this.marketingConsentDataRef = this.dataShareService.$isMarketingConsent.subscribe(data => this.marketingConsentData = data);
    let json = {
      type: 'marketing_checkbox'
    };
    this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.marketingConsent, json)
      .subscribe(response => {
        if (response.status === config.SUCCESS && response.data) {
          this.marketConsentRes = response.data;
          for (let res of this.marketConsentRes) {
            if (res['id'] === 'modal_popup_checkbox') {
              this.dontReciveText = res['value'];
            } else if (res['id'] === 'email_checkbox') {
              this.emailText = res['value'];
            } else if (res['id'] === 'sms_checkbox') {
              this.smsText = res['value'];
            } else if (res['id'] === 'wtsapp_checkbox') {
              this.whatsAppText = res['value'];
            }
          }
        }
      });
    this.createFormGroup();
    this.setFormData();
  }
  }

  createFormGroup() {
    this.marketSelectForm = new FormGroup({
      email: new FormControl(this.marketingConsentData.email),
      sms: new FormControl(this.marketingConsentData.sms),
      whatsapp: new FormControl(this.marketingConsentData.whatsApp),
      dontreceive: new FormControl(false)
    });
  }

  setFormData() {
    this.dontReciveRef = this.marketSelectForm.get('dontreceive').valueChanges.subscribe(data => {
      if (data) {
        this.marketSelectForm.get('email').setValue(!data);
        this.marketSelectForm.get('sms').setValue(!data);
        this.marketSelectForm.get('whatsapp').setValue(!data);
      }
    });
    this.emailRef = this.marketSelectForm.get('email').valueChanges.subscribe(data => {
      if (data) {
        this.marketSelectForm.get('dontreceive').setValue(!data);
      }
    });
    this.smsRef = this.marketSelectForm.get('sms').valueChanges.subscribe(data => {
      if (data) {
        this.marketSelectForm.get('dontreceive').setValue(!data);
      }
    });
    this.whatsappRef = this.marketSelectForm.get('whatsapp').valueChanges.subscribe(data => {
      if (data) {
        this.marketSelectForm.get('dontreceive').setValue(!data);
      }
    });
  }

  closePopup() {
    this.crossClicked.emit(true)
    this.dataShareService.setOpenMarketContentPopup(false);
  }

  submit(type:string,event: boolean ) {
    if(type =='consentPopUp'){
      this.consentFlag.emit(event);
    }else {
      let formValue = this.marketSelectForm.value;
      this.dataShareService.$isMarketingConsent.next({
        ...this.marketingConsentData,
        email: formValue.email,
        whatsApp: formValue.whatsapp,
        sms: formValue.sms
      });
      this.dataShareService.setOpenMarketContentPopup(false);
    }
  }

  ngOnDestroy(): void {
    this.dontReciveRef.unsubscribe();
    this.emailRef.unsubscribe();
    this.smsRef.unsubscribe();
    this.whatsappRef.unsubscribe();
    this.marketingConsentDataRef.unsubscribe();
  }



}
