import { Component, OnInit } from '@angular/core';
import { SiteService } from '../../../services/site/site.service';
import {ActivatedRoute, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { config, environment, routingEndpoint, keywords } from '../../../../../environments/root/environment';

@Component({
  selector: 'app-success-pages',
  templateUrl: './success-pages.component.html',
  styleUrls: ['./success-pages.component.scss']
})
export class SuccessPagesComponent implements OnInit {

  public query: any;
  public signInUrl: string;
  public emailData = '';
  public gdprEmail: string = '';
  public mailtoText: string = '';
  public loginUrl: string;
  constructor(private activateRoute: ActivatedRoute, private translate: TranslateService, private _siteService: SiteService, public route:Router) {
    this.translate.setDefaultLang(this._siteService.getSiteLocale());
  }

  ngOnInit() {
    let orgName = this.route.routerState.snapshot.root.firstChild.params["orgName"];
    let loginRoute ='';
    if(orgName)
      loginRoute = (environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.login]).replace(':orgName', orgName);
    else
      loginRoute = environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.login];

    this.loginUrl= window.location.origin + loginRoute;
    this.activateRoute.queryParams.subscribe(params => {
      if (params) {
        this.query = params;
        if (this.query.emailConfirmation === 'Y' || this.query.emailSent === 'Y' ) {
          this.emailData = this.query.userEmailId;
          this.mailtoText = keywords.mailto;
          this.gdprEmail = this.query.customerService;
        }
        if (this.query.submitCV == 'Y' || this.query.emailConfirmation === 'Y' || this.query.emailSent === 'Y') {
          this.signInUrl = loginRoute;
        }
      }
    });
  }



}
