import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiginPopupService {
  public $changeSuccess = new BehaviorSubject(false);
  public $openPopup = new BehaviorSubject(false);

  setChangeSuccess(value:boolean){
    this.$changeSuccess.next(value);
  }
  setOpenPopup(value:boolean){
    this.$openPopup.next(value);
  }
}
