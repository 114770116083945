export const routeConstant = {
  translations_me: '/assets/messages/me/messages_',
  countryDetails: [{
    'United Kingdom': '#/GB/en/me/login',
    'France': '#/FR/fr/me/login'
  }],
  app_route:{
    appmodule_link: ':country/:locale/hla',
  hla_route: {
    shared_module_link: 'ns',
    secure: {
      assess_skill_module_link: 'assessskill/browse',
    assess_skill:{
      landingPage_link: 'jobtitleloc',
      skillPage_link: 'skill',
      skillLevelPage_link: 'skilllevel',
      analysisPage_link: 'analysis',
      dashboardPage_link: 'dashboard'
    }},
    // module_link_secure: 's/me',
    // module_link: 'me',
    // login_link: 'login',
    // home_link: 'home',
    // dashboard_link: 'dashboard/browse/view',
    secure_module_link: 's',
  }
}
};
