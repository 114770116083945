<ul *ngIf="keyWord">
  <li class="item" ></li>
  <li [ngClass]="disableEmailButton?'disabled email email-large':'email email-large'"><i class="icon-mail-alt"></i>
    <input type="text" [ngClass]="emailErrorFlag?'form-control error-input':'form-control'" [(ngModel)]="email" [disabled]="disableEmailButton" *ngIf="!isLoggedIn"
           (keyup)="validateEmail(email)" placeholder="{{'gdpr_email_placeholder_text' | translate}}">
    <button id="gtm_email_alert_save" class="btn btn-default" (click)="saveJobAlert()"
            [disabled]="disableEmailButton" [translate]="savedEmailAlert?'alertSaved_text':personalDetails?'save_loggedIn_user_alert_button_text':'save_alert_button_text'"></button>
  </li>
  <li class="item non-whatsapp">
    <a id="gtm_email_alert_close" class="icon-cancel" (click)="closeAlert()"></a>
  </li>
</ul>
