import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {RequestService} from '../../../../global/services/request/request.service';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../../global/services/site/site.service';
import {Router} from '@angular/router';
import {apiEndPoints, config, environment} from '../../../../../environments/root/environment';
import {MarketingConsent} from '../../../model/request/marketing-consent';
import {FormGroup} from '@angular/forms';
import {DataSharingService} from '../../../service/data-sharing.service';

@Component({
  selector: 'app-jp-marketing-consent',
  templateUrl: './jp-marketing-consent.component.html',
  styleUrls: ['./jp-marketing-consent.component.scss']
})
export class JpMarketingConsentComponent implements OnInit {

  public confirmationText=[];
  public siteLocale: any;
  public consentBox1=[];
  public consentBox2=[];
  public consentBox3=[];

  @Input() public parent: FormGroup;
  @Input() public isChecked: boolean;
  @Input() public type: string;
  @Input() public privacyPolicyErrorMsg: boolean;
  @Output() public allCheckBox=new EventEmitter<any>();
  @Output() public isConsentChecked=new EventEmitter<boolean>();

  @Input() formGroup;
  @Input() validationCheck;
  @Input() formSubmitted;
  @Input() firstPrivacyError;
  public marketConsentRes: any;
  public selectAllText: string;
  public consentText: string;
  public emailText: string;
  public smsText: string;
  public whatsAppText: string;
  public marketingConsentData: MarketingConsent;
  private isSelectAllChecked: undefined;
  private isMarketObsRef: Subscription = new Subscription();

  constructor(private _requestService: RequestService, private _translate: TranslateService,
              private siteService: SiteService, private router: Router,
              public dataShareService: DataSharingService) {
    this.siteLocale = this.siteService.getSiteLocale();
    this._translate.setDefaultLang(this.siteLocale);
  }

  ngOnInit() {
    this.getConfirmation();
    let json = {
      type: ''
    };
    this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.marketingConsent, json)
      .subscribe(response => {
        if (response.status === config.SUCCESS && response.data) {
          this.marketConsentRes = response.data;
          for (let res of this.marketConsentRes) {
            if (res['id'] === 'select_all_checkbox') {
              this.selectAllText = res['value'];
            } else if (res['id'] === 'consent_text') {
              this.consentText = res['value'];
            } else if (res['id'] === 'email_checkbox') {
              this.emailText = res['value'];
            } else if (res['id'] === 'sms_checkbox') {
              this.smsText = res['value'];
            } else if (res['id'] === 'wtsapp_checkbox') {
              this.whatsAppText = res['value'];
            }
          }
        }
      });
    this.isMarketObsRef = this.dataShareService.$isMarketingConsent.subscribe(data => {
      this.marketingConsentData = data;
      if (data.selectAll && this.isSelectAllChecked === true) {
        this.isChecked = data.email = data.sms = data.whatsApp = data.privacy = true;
      } else if (!data.selectAll && this.isSelectAllChecked === false) {
        this.isChecked = data.email = data.sms = data.whatsApp = data.privacy =false;
      }
      if (data.email && data.sms && data.whatsApp && this.isChecked && data.privacy) {
        data.selectAll = true;
      }
      if(!data.email || !data.sms || !data.whatsApp || !this.isChecked || !data.privacy){
        data.selectAll = false;
      }
        this.isConsentChecked.emit(this.isChecked);
        this.parent.controls.privacyconsent1.setValue(data.privacy);
    });
  }

  getConfirmation(){
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"]

    let endpoint="";
    if(this.type==="Go1")
      endpoint=apiEndPoints.go1getTermsAndConditions;
    else endpoint=apiEndPoints.getTermsAndConditions;
    let liferayBaseUrl = environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.liferaybaseUrl];
    this._requestService.getRequest(config.theme, apiEndPoints.gateWayAPI,endpoint).subscribe(response => {
      if(response.status === config.SUCCESS && response.data ){
        this.confirmationText = response.data;
        this.allCheckBox.emit(this.confirmationText);
        this.confirmationText['checkBox1'].forEach((data)=>{
          if(data['link'].indexOf('email')==-1) {
            if (orgName)
              data['link'] = liferayBaseUrl+'/'+orgName + data['link'];
            else
              data['link'] = liferayBaseUrl + data['link'];

          }
          else {
            if (orgName)
              data['link'] = orgName + data['link'];
          }
          data['consentText1']= decodeURI(data['consentText1']);
          data['consentText2']= decodeURI(data['consentText2']);
          this.consentBox1.push(data);
        });

        this.confirmationText['checkBox2'].forEach((data)=>{
          if(data['link'].indexOf('email')==-1)
            data['link']=liferayBaseUrl+data['link'];
          else {
            if (orgName)
              data['link'] = orgName + data['link'];
          }
          data['consentText1']= decodeURI(data['consentText1']);
          data['consentText2']= decodeURI(data['consentText2']);
          this.consentBox2.push(data);
        });

        this.confirmationText['checkBox3'].forEach((data)=>{
          if(data['link'].indexOf('email')==-1)
            data['link']=liferayBaseUrl+data['link'];
          else {
            if (orgName)
              data['link'] = orgName + data['link'];
          }
          data['consentText1']= decodeURI(data['consentText1']);
          data['consentText2']= decodeURI(data['consentText2']);
          this.consentBox3.push(data);
        });
      }
    }, (error) => {
    });
  }

  onChangeCheck(type: string, value) {
    let selectVal = value.target.checked;
    switch (type) {
      case 'selectall':
        this.isSelectAllChecked = selectVal;
        this.dataShareService.$isMarketingConsent.next({...this.marketingConsentData, selectAll: selectVal});
        return;
      case 'email':
        this.isSelectAllChecked = undefined;
        this.dataShareService.$isMarketingConsent.next({...this.marketingConsentData, email: selectVal});
        return;
      case 'sms':
        this.isSelectAllChecked = undefined;
        this.dataShareService.$isMarketingConsent.next({...this.marketingConsentData, sms: selectVal});
        return;
      case 'whatsapp':
        this.isSelectAllChecked = undefined;
        this.dataShareService.$isMarketingConsent.next({...this.marketingConsentData, whatsApp: selectVal});
        return;
      case 'privacy':
        this.isSelectAllChecked = undefined;
        this.dataShareService.$isMarketingConsent.next({...this.marketingConsentData, privacy: selectVal});
        this.isChecked = selectVal;
        this.isConsentChecked.emit(this.isChecked);
        return;
    }
  }

  getCheckBoxData(event) {
    this.allCheckBox.emit(event);
  }

  ngOnDestroy(): void {
    this.isMarketObsRef.unsubscribe();
  }

}
