import {Component, Input, OnInit} from '@angular/core';
import {ExtraFieldsConfig, GoogleJobResponse} from '../../../../../model/response/google-job-response';
import {JobSearchService} from '../../../../../service/job-search.service';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {CookieService} from 'ngx-cookie-service';
import {config, environment, routingEndpoint} from '../../../../../../../environments/root/environment';
import {TokenStorage} from '../../../../../../global/services/tokenStorage/token-storage';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-job-summary',
  templateUrl: './job-summary.component.html',
  styleUrls: ['./job-summary.component.scss']
})
export class JobSummaryComponent implements OnInit {
  @Input() jobSummary:GoogleJobResponse[];
  public config: ExtraFieldsConfig;
  public buttonClicked: boolean;
  /**
   * Checks if legal sentence is enabled or not
   */
  public isLegalSentenceEnabled: boolean = false;


  constructor(private jobSearchService:JobSearchService,private tranlateService:TranslateService,private siteService:SiteService,
              public cookieService:CookieService,private tokenStorage:TokenStorage,private activatedRoute:ActivatedRoute,private router:Router) {
    this.tranlateService.setDefaultLang(this.siteService.getSiteLocale());
  }

  ngOnInit() {
    this.jobSearchService.sharedConfigList.subscribe(config=>this.config=config);
    this.isLegalSentenceEnabled = environment[config.theme + this.siteService.getSiteLocale()]['config']['isLegalSentenceEnabled'];
  }
  redirectToApplicationUrl(applicationURL: string) {
    window.location.href=applicationURL;

  }

  redirectToApply(applyId:string,jobName:string) {
    this.buttonClicked=true;
    let applyUrl='';
    if(this.tokenStorage.getLoggedInToken())
      applyUrl = this.getApplyUrl(routingEndpoint.secureJobApply);
      //applyUrl=environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.secureJobApply];
    else
      applyUrl = this.getApplyUrl(routingEndpoint.jobApply);
      //applyUrl=environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.jobApply];
    let keyWord='';
    let jobSource ='';
    let location='';
    let specialismId='';
    let subSpecialismId='';
    this.activatedRoute.queryParamMap.subscribe(param=>{
      if(param.get('q')){
        keyWord = param.get('q');
      }
      if(param.get('jobSource')){
        jobSource = param.get('jobSource');
      }
      if(param.get('jobsource')){
        jobSource = param.get('jobsource');
      }
      if(param.get('source')){
        jobSource = param.get('source');
      }
      if(param.get('location')){
        location = param.get('location');
      }
      if(param.get('specialismId')){
        specialismId = param.get('specialismId');
      }
      if(param.get('subSpecialismId')){
        subSpecialismId = param.get('subSpecialismId');
      }
    });
    let urlFinal = '';
    let isExpertClient;
    if (applyUrl.indexOf(':recordId') > -1)
      urlFinal = window.location.origin + applyUrl.replace(':recordId', applyId) + '?q=' + keyWord + '&jobName=' + decodeURIComponent(jobName) + '&applyId=' + applyId + '&jobSource=' + jobSource + '&specialismId=' + specialismId + '&subSpecialismId=' + subSpecialismId;
    else
      urlFinal = config.baseWebAppUrl + applyUrl + '?q=' + keyWord + '&jobName=' + decodeURIComponent(jobName) + '&applyId=' + applyId + '&jobSource=' + jobSource + '&specialismId=' + specialismId + '&subSpecialismId=' + subSpecialismId;
    config.ExpertClientsName.forEach(a => { if (applyUrl.includes(a)) isExpertClient = true; })
    config.ExpertClientsCode.forEach(a => { if (applyUrl.includes(a)) isExpertClient = true; })
      if (isExpertClient)
      window.location.href = urlFinal + '&showSendCV=N&showApplyForm=true';
      else
        window.location.href = urlFinal;


  }

  getApplyUrl(endpointUrl){
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"]
    let applyurl ='';
    if(orgName){
      let url =environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][endpointUrl];
      applyurl = url.replace(':orgName',orgName);
    }
    else
      applyurl=environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][endpointUrl];
    return applyurl;
  }

  public formatDate(formatDate: any) {
    formatDate = formatDate.year+'-'+('0' + (formatDate.month)).slice(-2) + '-' +('0' + formatDate.day).slice(-2);
    return formatDate;
  }

}
