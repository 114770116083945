<div class="breadcrumb-cont apply-job">
  <app-jp-breadcrumbs [jobResponseApply]="JobResponse"></app-jp-breadcrumbs>
</div>

<section [ngClass]="showApplyForm?'apply-job send-cv-active':'apply-job'">

  <div class="cv-data-container send-cv-anonymus">

    <app-custom-loader *ngIf="!JobResponse"></app-custom-loader>
      <div *ngIf="this.JobResponse && this.JobResponse.length>0">

          <div class="help-text" [translate]="'user_applying_text'"></div>
          <h2>
            <div [textContent]="JobResponse[0].JobTitle" id="jobTitle_jobApply" class="job-title"></div>
           <div>
              <span *ngIf = "JobResponse[0].incentives"><strong [translate]="'salary'"></strong><strong [textContent]="' '"></strong><span id="salary_jobApply" [textContent]="JobResponse[0].incentives"></span> </span> <span>
              <strong [translate]="'job_type_label'"></strong><strong [textContent]="': '"></strong> <span id="jobType_jobApply" [translate]="JobResponse[0].jobType==='C'?'C':JobResponse[0].jobType==='P'?'P':JobResponse[0].jobType==='T'?'T':''"></span></span>
            </div>
          </h2>

      </div>
      <ul id="tabs" class="tab-menu" *ngIf="!showApplyForm && !loggedInUser">
        <li id="apply_tab" [ngClass]="showApply?'active':''" (click)="showContainer('apply')"><a href="javascript:void(0)" [translate]="'job_apply_label'"></a></li>
        <li id="signin_tab"  [ngClass]="!showApply?'active':''" (click)="showContainer('login')"><a href="javascript:void(0)" [translate]="'signIn'"></a></li>
      </ul>

      <div class="row send-cv device" *ngIf="!showApplyForm && !loggedInUser &&  ! isDesktopDevice && !isTablet">
      <div class="col-sm-12 col-md-6" *ngIf="showApply && showApplyTab ">
        <div class="cv-container">
          <div [translate]="'apply_text1'"></div>
          <div [translate]="'without_signing_text'"></div>
          <button id="gtm_send_cv_btn" class="btn btn-default" (click)="showSendCVCall()" [translate]="'send_CV_text'"></button>
        </div>
        <div [class]="pageConfig.applyOptions.isSeekApply ? 'other-apply-option  other-apply-option-seek-acc' : 'other-apply-option'" *ngIf="pageConfig && (pageConfig.applyOptions.isIndeedApply || pageConfig.applyOptions.isLinkedinApply || pageConfig.applyOptions.isSeekApply)">
          <app-jp-apply-option></app-jp-apply-option>
        </div>

      </div>
      <div  class="col-sm-12 col-md-6" *ngIf="!showApply && !azureEnable && !showApplyTab && !disable_login">
        <app-jp-signin ></app-jp-signin>
      </div>
    </div>

    <div class="row send-cv desktop" *ngIf="!showApplyForm && !loggedInUser &&  (isDesktopDevice || isTablet)">
      <div class="col-sm-12 col-md-6">
        <div class="cv-container">
          <div [translate]="'apply_text1'"></div>
          <div [translate]="'without_signing_text'"></div>
          <button id="gtm_showSendCV_btn" class="btn btn-default" (click)="showSendCVCall()" [translate]="'send_CV_text'"></button>
        </div>
         <div [class]="pageConfig.applyOptions.isSeekApply ? 'other-apply-option  other-apply-option-seek-acc' : 'other-apply-option'" *ngIf="!azureEnable && !disable_login && pageConfig && (pageConfig.applyOptions.isIndeedApply || pageConfig.applyOptions.isLinkedinApply || pageConfig.applyOptions.isSeekApply)">
          <app-jp-apply-option></app-jp-apply-option>
        </div>

      </div>
      <div class="col-sm-12 col-md-6" *ngIf="(azureEnable || disable_login) && pageConfig && pageConfig.applyOptions">
        <app-jp-apply-option></app-jp-apply-option>
      </div>
      <div *ngIf="!azureEnable && !disable_login" class="col-sm-12 col-md-6">
        <app-jp-signin></app-jp-signin>
      </div>
    </div>
      <div [ngClass]="loggedInUser?'apply-without-login apply-logged-in':'apply-without-login'">
        <app-send-cv [showApplyForm]="showApplyForm" [JobResponse]="JobResponse" [currentUserFlow] = 'currentUserFlow'></app-send-cv>
      </div>





  </div>
</section>


