import {Injectable} from '@angular/core';
import {Adapter} from '../../../global/adapter/adapter';

export class PrivacyConsent{
  constructor(public status:string,public message:string,public data:PrivacyConsentData[]){
  }
}

export class PrivacyConsentData{
  constructor(public consentText1:string,public consentText2:string,public link:string){
  }
}

@Injectable({
  providedIn:'root'
})

export class ConsentAdapter implements Adapter<PrivacyConsent>{
  adapt(item: any): PrivacyConsent {
    return new PrivacyConsent(
      item.status,
      item.message,
      item.data
    );
  }


}
