export class RecruitingRequest{

  constructor(public organisation:string,
              public user_name:string,
              public jobtitle: string,
              public from:string,
              public telephone:string,
              public position:string,
              public salaryRange:string,
              public location:string,
              public stateCountry:string,
              public addInfo:string,
              public jobType:string,
              public gcaptchaResponse:string){
  }
}
