<div  class="new-user bg-white">
  <form id="gtm_go1_spec_cv" [formGroup]="specCVFormGroup" (ngSubmit)="register()">
    <h2 class="spec-cv-h2" *ngIf="specRoute" [translate]="'spec_cv_title_text'">
    </h2>
    <div [ngClass]="specRoute?'col  border spec-cv':'col border'">
      <h3 class="new-user" [translate]="'new_to_hays_text'"
          *ngIf="!specRoute">      .
        <p class="help-text" [translate]="'new_to_hays_description'"></p>
      </h3>

      <ng-container>
        <div class="form-group">
          <label [translate]="'enter_first_name'"></label>
          <input type="text" [(ngModel)]="firstName" class="form-control" id="filed1" autocomplete="off" placeholder="{{'enter_first_name_placeholder' | translate}}" formControlName="firstname" required>
          <div
            *ngIf="specCVFormGroup['controls'].firstname.invalid && formSubmitted">
        <span class="error-msg" *ngIf="specCVFormGroup['controls'].firstname.errors?.required || specCVFormGroup['controls'].firstname.errors?.whitespace"
              [translate]="'invalid_first_name_msg'">
        </span>
          </div>
        </div>

        <div class="form-group">
          <label  [translate]="'enter_last_name'"></label>
          <input type="text" [(ngModel)]="lastName" class="form-control" id="filed2" autocomplete="off" placeholder="{{'enter_last_name_placeholder' | translate}}" formControlName="lastname" required>
          <div
            *ngIf="specCVFormGroup['controls'].lastname.invalid && formSubmitted">
        <span class="error-msg" *ngIf="specCVFormGroup['controls'].lastname.errors?.required || specCVFormGroup['controls'].lastname.errors?.whitespace"
              [translate]="'invalid_last_name_msg'">
        </span>
          </div>
        </div>
      </ng-container>

      <div class="form-group">
        <label  [translate]="'enter_email_label'"></label>
        <input type="text" [(ngModel)]="emailAddress"  (keyup)="onEmailChanged($event);" class="form-control" id="filed3" autocomplete="off" placeholder="{{'enter_email_placeholder' | translate}}"
               formControlName="email" required>
        <span class="error-msg" *ngIf="emailError"  [translate]="'invalid_email_msg'">
        </span>

      </div>
      <div class="info-msg" [translate]="'upload_issue_info'"></div>
      <div *ngIf=isGo1UploadCV>
        <app-jp-upload-cv [parent]="specCVFormGroup" [isGo1Page]='true'></app-jp-upload-cv>
        <div
          *ngIf="!specCVFormGroup['controls'].cvData.value && formSubmitted && showFileValidation">
        <span *ngIf="specCVFormGroup['controls'].cvData.errors?.required" class="error-msg"
              [translate]="'invalid_cv_msg'"></span>
        </div>

        <span *ngIf="showPopUp" class="error-msg" [translate]="'password_protected_warning'">
      </span>

      </div>

      <div>
        <app-jp-sector [parent]="specCVFormGroup" [sector]="sector" [officeSpecialism]="officeSpecialism" [specialismId]="specCVFormGroup.value.expertise?specCVFormGroup.value.expertise:sector"></app-jp-sector>
        <div
          *ngIf="specCVFormGroup['controls'].expertise.invalid && formSubmitted">
        <span *ngIf="specCVFormGroup['controls'].expertise.errors?.required" class="error-msg"
              [translate]="'invalid_specialism_msg'"></span>
        </div>
      </div>

      <div>
        <app-jp-office-for-specialism [parent]="specCVFormGroup" [specialismId]="specCVFormGroup.value.expertise?specCVFormGroup.value.expertise:sector" [officeSpecialism]="officeSpecialism"></app-jp-office-for-specialism>
        <div
          *ngIf="specCVFormGroup['controls'].officeForSpecialism.invalid && formSubmitted">
        <span *ngIf="specCVFormGroup['controls'].officeForSpecialism.errors?.required" class="error-msg"
              [translate]="'invalid_office_msg'"></span>
        </div>
      </div>

      <div>
        <app-term-and-privacy [isChecked]="isChecked" [parent]="specCVFormGroup" (isConsentChecked)="getConsentChecked($event)" [type]="'Go1'"></app-term-and-privacy>
        <div
          *ngIf="formSubmitted && !validationCheck && !isChecked">
        <span class="error-msg"
              [translate]="'policy_validation_error'"></span>
        </div>
      </div>

      <ng-container *ngIf="!isCaptchaDisable">
        <app-jp-captcha [parent]="specCVFormGroup">
        </app-jp-captcha>
        <div
          *ngIf="specCVFormGroup['controls'].reCaptchaReactive?.value === '' && formSubmitted">
        <span class="error-msg"
              [translate]="'captcha_validation_text'"></span>
        </div>
        <div
          *ngIf="specCVFormGroup['controls'].reCaptchaReactive?.value === null"><span class="error-msg"
                                                                                      [translate]="'captcha_validation_text'"></span></div>
      </ng-container>

      <div class="action-bar text-center" *ngIf="!specRoute">
        <button id="gtm_submit_and_register_btn" [ngClass]="(disableButton)?'btn btn-default btn-block btn-loading':'btn btn-default btn-block'"
                type="submit" [disabled] = "disableButton" [translate]="'Submit_and_register'"></button>
      </div>
      <div class="action-bar text-center" *ngIf="specRoute">
        <button id="gtm_submit_btn" [ngClass]="disableButton?'btn btn-default btn-block btn-loading':'btn btn-default btn-block'"
                type="submit" [disabled] = "disableButton" [translate]="'submit_button'"></button>
      </div>
    </div>
  </form>
</div>


