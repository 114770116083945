import {Adapter} from '../../../global/adapter/adapter';

export class AllOffice {
  constructor(public status: string,
              public message: string,
              public data: AllOfficeData[]) {

  }
}

export class AllOfficeData {
  constructor(
    public address: string,
    public googleAddressString: string,
    public googleLink: string,
    public officeName: string,
    public phone: string,
    public email: string
  ) {
  }

}



export class AllOfficeAdapt implements Adapter<AllOffice> {
  adapt(item: any): AllOffice {
    return new AllOffice(
      item.status,
      item.message,
      item.data
    );
  }
}
