import { isPlatformBrowser } from '@angular/common';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { TokenStorage } from '../tokenStorage/token-storage';
import * as CryptoJS from 'crypto-js';
import {environment} from '../../../../environments/root/environment';
@Injectable({
  providedIn: 'root'
})
export class BrowserStateInterceptor implements HttpInterceptor {

  constructor(
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: object,
    private tokenStorage:TokenStorage
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let key = req.url;
    const disableCachingForAPIS = [
        'browse/v1/pagemenu',
    ];
    for(let i=0; i < disableCachingForAPIS.length; i++){
        if(key.indexOf(disableCachingForAPIS[i]) > -1 && this.tokenStorage.getLoggedInToken()) {
            return next.handle(req);
        }
    }
    if (req.method === 'POST' && req.body && isPlatformBrowser(this.platformId)) {
      try {
        key = key + btoa(unescape(encodeURIComponent(JSON.stringify(req.body))));
      } catch (err) {

      }
    }

    const storedResponse: string = this.transferState.get(makeStateKey(key), null);

    if (storedResponse) {
      const hmac_key = environment['hmac_key'];
      const storedResponseText = CryptoJS.AES.decrypt(storedResponse,hmac_key);
      const responseData = JSON.parse(storedResponseText.toString(CryptoJS.enc.Utf8));
      const response = new HttpResponse({ body: responseData, status: 200 });
      return of(response);
    }

    return next.handle(req);
  }
}
