import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {JobVerifyAlertRequest, ValidateAlertRequest} from '../../../model/request/job-alert-request';
import {
  apiEndPoints,
  config,
  environment,
  keywords,
  routingEndpoint
} from '../../../../../environments/root/environment';
import {RequestService} from '../../../../global/services/request/request.service';
import {TokenStorage} from '../../../../global/services/tokenStorage/token-storage';
import {SiteService} from '../../../../global/services/site/site.service';
import {map} from "rxjs/operators";
import {CustomerServiceEmail, EmailConfirmation} from "../../../model/request/email-confirmation";

@Component({
  selector: 'app-jp-verify-alert',
  templateUrl: './jp-verify-alert.component.html',
  styleUrls: ['./jp-verify-alert.component.scss']
})
export class JpVerifyAlertComponent implements OnInit {

  private baseroutUrl: string;
  private alertThankYouPage: string;
  private errorRoute: string;

  constructor(private requestService: RequestService, private activatedRoute: ActivatedRoute, private tokenStorage: TokenStorage,
              private siteService: SiteService, private router: Router) {
    this.baseroutUrl = window.location.origin;
  }

  ngOnInit() {
    this.getRouteUrls();
    this.jobVerifyAlert(this.activatedRoute.snapshot.queryParamMap.get('email'), this.activatedRoute.snapshot.queryParamMap.get('workflowinstanceid'), this.activatedRoute.snapshot.queryParamMap.get('identifier'));
  }


  jobVerifyAlert(email, workflowinstanceid, identifier) {
    let locale = this.activatedRoute.snapshot.queryParamMap.get('lang');
    if (identifier) {
     /* let domain = this.siteService.getDomain();
      this.siteService.setSite2(domain, locale);*/
      this.decryptIdentifier(identifier);
    } else {
      let unsubscribe = this.activatedRoute.snapshot.queryParamMap.get('unsubscribe');
      let jsonData: JobVerifyAlertRequest;
      if (unsubscribe) {
        jsonData = new JobVerifyAlertRequest(email, workflowinstanceid, unsubscribe);
      } else {
        jsonData = new JobVerifyAlertRequest(email, workflowinstanceid, null);
      }
      this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.jobAlertVerify, jsonData)
        .subscribe((res) => {
          if (res.status === config.SUCCESS && res.data) {
            if ((res.data.statuscode == config.portalSuccessCode) &&  unsubscribe === 'Y') {
              if(locale) {
                window.location.href=this.baseroutUrl + this.alertThankYouPage
                  + '?lang=' + this.activatedRoute.snapshot.queryParamMap.get('lang')
                  + '&unsubscribe=Y';
              }else {
                window.location.href=this.baseroutUrl + this.alertThankYouPage
                  + '?unsubscribe=Y';
              }
            }
            else if (res.data.statuscode == config.portalSuccessCode) {
              if(locale) {
                window.location.href = this.baseroutUrl + this.alertThankYouPage
                  + '?lang=' + this.activatedRoute.snapshot.queryParamMap.get('lang')
                  + '&activated=Y';
              }else{
                window.location.href = this.baseroutUrl + this.alertThankYouPage
                  + '?activated=Y';
              }
            }
            else if (res.data.statuscode == 'WC009') {
              if(locale) {
                window.location.href = this.baseroutUrl + this.errorRoute
                  + '?lang=' + this.activatedRoute.snapshot.queryParamMap.get('lang')
                  + '&unsubscribe=N&key=invalid';
              }else {
                window.location.href = this.baseroutUrl + this.errorRoute
                  + '?unsubscribe=N&key=invalid';
              }
            }
            else if (res.data.statuscode == config.emailDoesNotExist && unsubscribe === 'Y') {
              if(locale) {
                window.location.href = this.baseroutUrl + this.errorRoute
                  + '?lang=' + this.activatedRoute.snapshot.queryParamMap.get('lang')
                  + '&unsubscribe=N';
              }else{
                window.location.href = this.baseroutUrl + this.errorRoute
                  + '?unsubscribe=N';
              }
            }
            else if (res.data.statuscode == config.emailDoesNotExist) {
              if(locale) {
                window.location.href = this.baseroutUrl + this.errorRoute
                  + '?lang=' + this.activatedRoute.snapshot.queryParamMap.get('lang')
                  + '&activated=E';
              }else{
                window.location.href = this.baseroutUrl + this.errorRoute
                  + '?activated=E';
              }
            }
            else  if (res.data.statuscode == config.emailAlreadyVerified) {
              if(locale) {
                window.location.href = this.baseroutUrl + this.alertThankYouPage
                  + '?lang=' + this.activatedRoute.snapshot.queryParamMap.get('lang')
                  + '&activated=E';
              }else{
                window.location.href = this.baseroutUrl + this.alertThankYouPage
                  + '?activated=E';
              }
            }
          }
        }, (error) => {
          if(locale) {
            window.location.href = this.baseroutUrl + this.errorRoute
              + '?lang=' + this.activatedRoute.snapshot.queryParamMap.get('lang')
              + '&activated=N'
              + '&error=' + error.status;
          }else {
            window.location.href = this.baseroutUrl + this.errorRoute
              + '?activated=N'
              + '&error=' + error.status;
          }
        });
    }

  }

  decryptIdentifier(identifier) {
    let requestType="";
    if(this.router.url.indexOf('VerifyAlertV2')>-1){
      identifier = localStorage.getItem("encryptedIdentifier");
      requestType='verifyV2';
    }
    let source = this.activatedRoute.snapshot.queryParamMap.get('jobSource')?this.activatedRoute.snapshot.queryParamMap.get('jobSource'):
      this.activatedRoute.snapshot.queryParamMap.get('jobsource')?this.activatedRoute.snapshot.queryParamMap.get('jobsource'):
        this.activatedRoute.snapshot.queryParamMap.get('source')?this.activatedRoute.snapshot.queryParamMap.get('source'):'';

          this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.validateAlert, new ValidateAlertRequest(identifier, requestType))
      .subscribe((res) => {
        if ((res.status === config.SUCCESS) && res.data) {
          if(res.data.type && res.data.type==='verifyAlert'){
            switch (res.data.statuscode) {
              case config.portalSuccessCode:
                 source = source?'&jobSource='+source:'&jobSource='+keywords.haysGCJ;
                window.location.href = this.baseroutUrl + this.alertThankYouPage
                  + '?identifier='+identifier+'&activated=Y'
                  + '&lang=' + this.activatedRoute.snapshot.queryParamMap.get('lang')+source+'&workflow=verifyV2';
                break;
              case config.emailDoesNotExist:
                window.location.href = this.baseroutUrl + this.errorRoute
                  + '?lang=' + this.activatedRoute.snapshot.queryParamMap.get('lang')
                  + '&activated=E'+'&workflow=verifyV2';
                break;
              case config.emailAlreadyVerified:
                window.location.href = this.baseroutUrl + this.alertThankYouPage
                  + '?lang=' + this.activatedRoute.snapshot.queryParamMap.get('lang')
                  + '&activated=E'+'&workflow=verifyV2';
                break;
              default:
                window.location.href = this.baseroutUrl + this.alertThankYouPage
                  + '?lang=' + this.activatedRoute.snapshot.queryParamMap.get('lang')
                  + '&activated=E'+'&workflow=verifyV2';
                break;
            }

          }else if(res.data.type && res.data.type==='unsubscribe'){
            switch (res.data.statuscode) {
              case config.portalSuccessCode:
                source = source?'&jobSource='+source:'&jobSource='+keywords.haysGCJ;
                window.location.href = this.baseroutUrl + this.alertThankYouPage
                  + '?identifier='+identifier+'&unsubscribe=Y'
                  + '&lang=' + this.activatedRoute.snapshot.queryParamMap.get('lang')+source+'&workflow=unsubscribeV2';
                break;
              case 'WC009':
                window.location.href = this.baseroutUrl + this.errorRoute
                  + '?lang=' + this.activatedRoute.snapshot.queryParamMap.get('lang')
                  + '&unsubscribe=N&key=invalid&workflow=unsubscribeV2';
                break;
              default:
                window.location.href = this.baseroutUrl + this.alertThankYouPage
                  + '?lang=' + this.activatedRoute.snapshot.queryParamMap.get('lang')
                  + '&unsubscribe=N&workflow=unsubscribeV2';
                break;
            }
          }

          else{
            if (res.data.statuscode == config.portalSuccessCode) {
              window.location.href = this.baseroutUrl + this.alertThankYouPage
                + '?lang=' + this.activatedRoute.snapshot.queryParamMap.get('lang')
                + '&unsubscribe=Y';
            }else if ((res.data.statuscode == config.emailAlreadyVerified) || (res.data.statuscode == 'WC009')) {
              window.location.href = this.baseroutUrl + this.errorRoute
                + '?lang=' + this.activatedRoute.snapshot.queryParamMap.get('lang')
                + '&activated=E';
            }else
            if ((res.data.statuscode == config.emailAlreadyVerified) || (res.data.statuscode == config.emailDoesNotExist)) {
              window.location.href = this.baseroutUrl + this.errorRoute
                + '?lang=' + this.activatedRoute.snapshot.queryParamMap.get('lang')
                + '&unsubscribe=N';
            }
          }

        } else {
          window.location.href = this.baseroutUrl + this.errorRoute
            + '?lang=' + this.activatedRoute.snapshot.queryParamMap.get('lang')
            + '&activated=N';
        }
      }, (error) => {
        window.location.href = this.baseroutUrl + this.errorRoute
          + '?lang=' + this.activatedRoute.snapshot.queryParamMap.get('lang')
          + '&activated=N'
          + '&error=' + error.status;
      });
  }

  getRouteUrls() {
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    let base='';
    if(orgName){
      base= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org];
      this.alertThankYouPage = (base[routingEndpoint.alertThankYouPage]).replace(':orgName',orgName);
      this.errorRoute = (base[routingEndpoint.errorPage]).replace(':orgName',orgName);
    }else{
      base= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
      this.alertThankYouPage = base[routingEndpoint.alertThankYouPage];
      this.errorRoute = base[routingEndpoint.errorPage];
    }
  }

}
