<app-custom-loader *ngIf="!googleJobResponse"></app-custom-loader>


<section class="job-detail-cont bg-light-grey" *ngIf="googleJobResponse && googleJobResponse.length>0">


  <div class="breadcrumb-cont">

    <app-jp-breadcrumbs></app-jp-breadcrumbs>

  </div>
  <div class="row">
    <div class="col-12 col-md-8">
      <app-jp-job-description [googleJobResponse]="googleJobResponse"></app-jp-job-description>
    </div>
    <div class="col-12 col-md-4">
      <app-video-my-job [sharedVideoUrl]="googleJobResponse[0].jobAdvertUrl"></app-video-my-job>
      <app-job-summary [jobSummary]="googleJobResponse"></app-job-summary>
      <app-talk-to-consultant [consultantDetails]="googleJobResponse" *ngIf="googleJobResponse[0].showJobOwner==='True'"></app-talk-to-consultant>
      <app-jp-social-platform [jobToShare]="googleJobResponse" (emailBodyLinkShare)="getEmailLink($event)" ></app-jp-social-platform>
      <app-go1-widget [jobTitle]="googleJobResponse[0].JobTitle" [specialism]="googleJobResponse[0].xSpecialism" [subspecialism]="googleJobResponse[0].xSubSpecialism" *ngIf="isGo1Enabled"></app-go1-widget>
    </div>
    <div class="col-12 mol-md-12" *ngIf="googleSimilarJobResponse && googleSimilarJobResponse.length>0">
      <app-jp-similar-jobs [googleSimilarJobResponse]="googleSimilarJobResponse" [googleJobResponse]="googleJobResponse"></app-jp-similar-jobs>
    </div>
  </div>
  <div id="analytics_fields" >
    <span id="JobId" hidden="hidden" [textContent]="googleJobResponse[0].jobId"></span>
    <span id="JobReference" hidden="hidden" [textContent]="googleJobResponse[0].jobRef"></span>
  </div>

  <div class="device-apply-container">
    <button id="gtm_jobdetail_apply_button" (click)="googleJobResponse[0].applicationURL?
    redirectToApplicationUrl(googleJobResponse[0].applicationURL):redirectToApply(googleJobResponse[0].jobId,googleJobResponse[0].jobName)"
       [ngClass]="applyClicked?'btn btn-primary btn-loading':'btn btn-primary'"
             [translate]="googleJobResponse[0].isJobApplied?'apply_button_text_disabled':'job_apply_label'"
             [disabled]="googleJobResponse[0].isJobApplied || this.applyClicked"></button>
    <a *ngIf="googleJobResponse[0].jobOwner && googleJobResponse[0].jobOwner.JobOwner" [href]="'tel:'+googleJobResponse[0].jobOwner.JobOwner.tel" [title]="'call '+googleJobResponse[0].jobOwner.JobOwner.tel" class="btn btn-secondary"><i class="icon-phone"></i></a>
    <a [href]="'mailto:?subject='+googleJobResponse[0].JobTitle+'| HAYS Recruiting Experts Worldwide&body='+emailLinkBody" class="btn btn-secondary"><i class="icon-mail-alt"></i></a>
  </div>
</section>
<app-google-for-jobs *ngIf="googleJobResponse && googleJobResponse.length>0" [jobDetails]="googleJobResponse"></app-google-for-jobs>
