import {Injectable} from '@angular/core';
import {Adapter} from '../../adapter/adapter';


export class HeaderRequestJson{
  constructor(public sections:string, public resource_uris:string){
  }
}

export class HeaderMenu{
  constructor(public status: string, public message:string, public data:MenuData[]){
  }
}

export class MenuData{
  constructor(public LeftTop:LeftTop[], public RightTop:RightTop[]){
  }
}

export class LeftTop{
  constructor(public iconPath:string, public linkName:string, public resource_name:string, public resource_url:string){
  }
}

export class RightTop{
  constructor(public iconPath:string, public linkName:string, public resource_name:string, public resource_url:string){
  }
}

export class UsefulLinkData{
constructor(public LinkToURL:string, public Label:string){
}
}

export class footerData{
  constructor(public iconPath:string, public linkName:string, public resource_name:string, public resource_url:string){

  }
}

export class clientConfigRequest {
  constructor(
    public orgUrl: string
  ) {  }
}


export class ClientConfigResponse {
  constructor(public status: string,
              public message: string,
              public data: ClientConfigData) {}
}

export class ClientConfigData {
  constructor(public logoRedirectUrl: string,
              public logoImageUrl: string,
              public jobSource: string,
              public orgCode: string,
              public orgAbbr: string,
              public jobMedium: string) {}
}



@Injectable({
  providedIn:'root'
})
export class HeaderAdapter implements Adapter<HeaderMenu>{
  adapt(item: any): HeaderMenu {
    return new HeaderMenu(
      item.status,
      item.message,
      item.data
    );
  }
}
