/**
 * Contains static helper methods to determine dynamic value of following:
 * Page Title
 * Heading under search box
 * Meta tags
 */
export class DynamicPageTitlesUtility {
    /**
     * Generates dynamic HTML contents for:
     * Page title
     * Meta description
     * Header H1 tag
     * @param jobLocation 
     * @param jobKeyword 
     * @param countryName 
     * @param countryLocaleResponse 
     * @param propName 
     * @returns 
     */
    public static getContentBasedOnSearch(jobLocation: string, jobKeyword: string, countryName: string, countryLocaleResponse, propName: string) : string{
        if(jobLocation && jobKeyword){
            return this.getReplacedString(jobKeyword, jobLocation, countryLocaleResponse['search_page_dynamic_content_keyword_location'][propName]);
        }
        else if(jobLocation && !jobKeyword){
            return this.getReplacedString(jobKeyword, jobLocation, countryLocaleResponse['search_page_dynamic_content_location'][propName]);
        }
        else if(!jobLocation && jobKeyword){
            return this.getReplacedString(jobKeyword, countryName, countryLocaleResponse['search_page_dynamic_content_keyword'][propName]);
        }
        else if(!jobLocation && !jobKeyword){
            return this.getReplacedString(jobKeyword, countryName, countryLocaleResponse['search_page_dynamic_content_blank_search'][propName]);
        }
    }
    /**
     * Replaces string constants with dynamic values i.e. {jobTitle}, {location}
     * @param jobTitle 
     * @param jobLocation 
     * @param replacementString 
     */
    private static getReplacedString(jobTitle: string, jobLocation: string, replacementString: string) : string {
        const jobTitleReplacedString = this.replaceStaticTextWithDynamic('{jobTitle}', jobTitle, replacementString);
        const jobLocationReplacedString = this.replaceStaticTextWithDynamic('{location}', jobLocation, jobTitleReplacedString);
        return jobLocationReplacedString;
    }
    /**
     * Replace all occurences of the given keyword in the string
     * @param stringToBeReplaced 
     * @param dynamicValue 
     * @param replacementString 
     */
    private static replaceStaticTextWithDynamic(stringToBeReplaced: string, dynamicValue: string, replacementString: string): string{
        const numberOfOccurences = this.getNumberOfOccurencesOfKeyword(stringToBeReplaced, replacementString);
        let replacedStringWithDynamicValue = replacementString;
        if(numberOfOccurences > 0){
            for(let index = 0; index < numberOfOccurences; index++){
                replacedStringWithDynamicValue = replacedStringWithDynamicValue.replace(stringToBeReplaced, dynamicValue);
            }
            return replacedStringWithDynamicValue;
        }
        return replacementString;
    }
    /**
     * Calculates number of occurences of a keyword in the string
     * @param keyWord 
     * @param inputString 
     * @returns 
     */
    private static getNumberOfOccurencesOfKeyword(keyWord: string, inputString: string): number{
        let keyWordCount = 0;
        const wordsArray = inputString.split(' ');
        for(let index = 0; index < wordsArray.length; index++){
            if(wordsArray[index].indexOf(keyWord) > -1){
                keyWordCount = keyWordCount + 1;
            }
        }
        return keyWordCount;
    }
}