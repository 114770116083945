import {Component, Inject, Input, OnInit} from '@angular/core';
import {JobSearchService} from '../../../service/job-search.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RequestService} from '../../../../global/services/request/request.service';
import {SiteService} from '../../../../global/services/site/site.service';
import {TokenStorage} from '../../../../global/services/tokenStorage/token-storage';
import {NgxXml2jsonService} from 'ngx-xml2json';
import {GoogleJobResponse} from '../../../model/response/google-job-response';
import {apiEndPoints, config, environment, routingEndpoint} from '../../../../../environments/root/environment';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DataSharingService} from '../../../service/data-sharing.service';
import {Meta, Title} from "@angular/platform-browser";
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-jp-similar-jobs',
  templateUrl: './jp-similar-jobs.component.html',
  styleUrls: ['./jp-similar-jobs.component.scss']
})
export class JpSimilarJobsComponent implements OnInit {
  @Input() public googleSimilarJobResponse: GoogleJobResponse[];
  @Input() public googleJobResponse: GoogleJobResponse[];
  private userAgent:string;
  private cookieDomain:string;
  public jobDetailsURL: string;
  private jobTitle: string;
  private locationDec='';


  constructor(private searchService:JobSearchService,private activatedRoute:ActivatedRoute,private requestService:RequestService,private siteService:SiteService,
              private tokenStorage:TokenStorage,private xmlParserService:NgxXml2jsonService,public router:Router,
              private translaterService:TranslateService,private cookieService:CookieService,private deviceDetector:DeviceDetectorService,
              private dataShareService: DataSharingService,private titleService: Title,private metaService: Meta, @Inject(DOCUMENT) private document: Document) {
    this.translaterService.setDefaultLang(this.siteService.getSiteLocale());
    this.userAgent =this.deviceDetector.isMobile()?'-Mobile':this.deviceDetector.isDesktop()?'-Desktop':'-Tablet';
    this.cookieDomain =environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.cookieDomain];
    this.jobDetailsURL =environment[config.theme+this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.jobdetails];
  }

  ngOnInit() {

  }
  public redirectToDetails(crossCountryUrl: string, jobRecordId: string, jobId: string, isSponsored: string, JobTitle: string, locationDesc: string, jobName: string,
                           lang: string,domain: string, micrositeID: string, micrositeUrl: string)
  {
    if(micrositeID==='HAYSITTEST')
      window.location.href=micrositeUrl;
    let jobSource='';
    let specialismId='';
    let subSpecialismId='';
    let defaultJobSource='';
    this.dataShareService.sharedClientConfig.subscribe(res => defaultJobSource = res.jobSource );

    this.activatedRoute.queryParamMap.subscribe(param=>{
      jobSource=param.get('jobSource')?param.get('jobSource'):param.get('jobsource')?param.get('jobsource'):param.get('source')?param.get('source'):defaultJobSource;
      if(param.get('specialismId'))
        specialismId=param.get('specialismId');
      if(param.get('subSpecialismId'))
        subSpecialismId=param.get('subSpecialismId');
      if(param.get('jobTitle'))
        this.jobTitle= param.get('jobTitle');
    });
    if(this.googleJobResponse && this.googleJobResponse.length>0){
      this.jobTitle= this.googleJobResponse[0].JobTitle;
      this.locationDec = this.googleJobResponse[0].locationDesc;
    }
    let url = '';
    if(crossCountryUrl)
      url=crossCountryUrl;

    let localeRecordId="";
    if(jobRecordId){
      let xLocaleRecordId = jobRecordId.split("/");
      localeRecordId=xLocaleRecordId[xLocaleRecordId.length-1];
    }

    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    let routingUrl ='';
    if(orgName){
      let url = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.jobdetails];
      routingUrl = url.replace(':orgName',orgName);
    }
    else
      routingUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.jobdetails];

    if(!url) {
      if(this.router.url.indexOf('Job/Detail')===-1 && this.router.url.indexOf('job/apply/details')===-1){
        this.router.navigate([routingUrl, localeRecordId], {
          queryParams: {
            q: JobTitle,
            location: locationDesc,
            applyId: jobId,
            jobSource: jobSource ? jobSource : defaultJobSource,
            isSponsored: isSponsored,
            specialismId: specialismId,
            subSpecialismId: subSpecialismId,
            jobName: jobName,
            lang: lang
          }
        }).then(r  => {
          this.searchService.setJobResponse(null);
          this.searchService.setJobDetailResponse(null);
          this.searchService.getJobDeatils(this.activatedRoute, this.requestService, this.siteService, this.tokenStorage, this.xmlParserService,
            'jobDetails', '', '', this.cookieService,this.router,this.userAgent,this.cookieDomain, this.dataShareService,this.translaterService,this.titleService,this.metaService,this.document)
        });
      }else{
        let jobDetailRedirectUrl = window.location.origin+routingUrl+'/'+localeRecordId+'?q='+encodeURIComponent(this.jobTitle)+'&location='+encodeURIComponent(this.locationDec)+'&applyId='+jobId+'&jobSource='+jobSource+
          '&isSponsored='+isSponsored+'&specialismId='+specialismId+'&subSpecialismId='+subSpecialismId+'&jobName='+jobName+'&lang='+lang;
         window.location.href=jobDetailRedirectUrl;
      }



    }
    else {
      if (!specialismId)
        specialismId = '';
      if (!subSpecialismId)
        subSpecialismId = '';

      let crossUrl = config.crossCountryUrl;
      crossUrl = '/' + domain + '/' + lang + crossUrl;
      let crossSiteUrl = url + crossUrl + '/' + localeRecordId.replace('(', '%28').replace(')', '%29') +
        '?q=' + encodeURIComponent(this.jobTitle)+'&location='+encodeURIComponent(this.locationDec)+
        + '&applyId=' + jobId + '&jobSource=' + jobSource + '&isSponsored=' + isSponsored + '&specialismId='
        + specialismId + '&subSpecialismId=' + subSpecialismId + '&jobName=' +
        jobName + "&lang=" + lang;
      //console.log("crossSiteUrl..",crossSiteUrl);
      window.location.href = crossSiteUrl;
    }
    window.scroll(0,0);
  }

  removeHex(data: any) {
    data=data.replace(/&#.{2};/g, ' ');
    if(data.indexOf('#')>-1){
      data = data.substring(0, data.lastIndexOf("#"));
    }
    return data;
  }
}
