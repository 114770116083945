import {Component, OnInit} from '@angular/core';
import {SiteService} from '../../../../../global/services/site/site.service';
import {TranslateService} from '@ngx-translate/core';
import {RequestService} from '../../../../../global/services/request/request.service';
import {apiEndPoints, config, environment, routingEndpoint} from '../../../../../../environments/root/environment';
import {Router} from '@angular/router';
import {RecentApplicationData} from '../../../../model/response/my-profile';

@Component({
  selector: 'app-jp-recent-applications',
  templateUrl: './jp-recent-applications.component.html',
  styleUrls: ['./jp-recent-applications.component.scss'],
})
export class JpRecentApplicationsComponent implements OnInit {

  public recentApplicationData:RecentApplicationData[]=[];

  constructor(private router:Router, private siteService:SiteService, private translation:TranslateService, private requestService:RequestService) {
    this.translation.setDefaultLang(this.siteService.getSiteLocale());
  }

  ngOnInit() {
    this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getRecentApplication,null,null).subscribe(
      (res)=>{
        if(res.data && res.status==config.SUCCESS)
          this.recentApplicationData=res.data['jobapplicationlist'];
    },(error)=>{
      });
  }

  jobDetailsPage(recentApplication:RecentApplicationData){
    let routeBaseUrl ='';
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"]
    if(orgName){
      let url = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.jobdetails];
      routeBaseUrl = url.replace(':orgName',orgName);
    }
    else
      routeBaseUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.jobdetails];

    let jobRedirectURL = window.location.origin+routeBaseUrl+'/'+recentApplication.jobid;
    if(recentApplication.languageid && recentApplication.languageid===1){
      jobRedirectURL=window.location.origin+routeBaseUrl+'/'+recentApplication.jobid+'?lang=en';
    }
    return jobRedirectURL;
    //this.router.navigate([routeBaseUrl.recordIdJobDetail+jobId]);
  }



}
