import {Component, Input, OnInit} from '@angular/core';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../../../environments/root/environment';
import {RequestService} from '../../../../../global/services/request/request.service';
import {UpdateWhatsappRequest} from '../../../../model/request/update-whatsapp-request';
import {ActivatedRoute, Router} from '@angular/router';
import {SiteService} from '../../../../../global/services/site/site.service';
import {TranslateService} from '@ngx-translate/core';
import {SetTitleService} from '../../../../service/set-title.service';
import {DataSharingService} from '../../../../service/data-sharing.service';
import {JobSearchService} from '../../../../service/job-search.service';
import {WhatsappAlertResponse} from '../../../../model/response/whatsapp-alert-response';

@Component({
  selector: 'app-jp-update-whatsapp',
  templateUrl: './jp-update-whatsapp.component.html',
  styleUrls: ['./jp-update-whatsapp.component.scss']
})
export class JpUpdateWhatsappComponent implements OnInit {
  public codeError:boolean;
  public isdisable:boolean;
  public WANumberError:boolean;
  public currentNumError:boolean;
  public newNumError:boolean;
  public alert:boolean;
  public showThankyou:boolean;
  public currentCountryCode:string;
  public currentCountryNumber:string;
  public newCountryCode:string;
  public newCountryNumber:string;
  public existingNumber:string;
  public existingCountryCode:string;
  public portalProfileId:string;
  private myProfileRoute:string;
  private errorRoute:string;
  private baseRoute:string;
  @Input() public whatsappResponse:WhatsappAlertResponse;
  constructor(private requestService:RequestService,private dataSharingService : DataSharingService, private _router: Router,
              private siteService: SiteService,private activatedRoute:ActivatedRoute,private _translate:TranslateService,
              private titleService:SetTitleService,private jobSearchService:JobSearchService) {
    this._translate.setDefaultLang(this.siteService.getSiteLocale());
    this._translate.getTranslation(siteService.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(res['update_number_title_text']);
    });
  }

  ngOnInit() {
  this.dataSharingService.getWidgetListName(keywords.whatsappPageName);
    this.alert=false;
    this.showThankyou=false;
    this.getCurrentCountryCode();
    this.portalProfileId=localStorage.getItem('portalProfileID');
    if(this.portalProfileId || (this.whatsappResponse && this.whatsappResponse.portalProfileId)) {
      this.portalProfileId=this.portalProfileId?this.portalProfileId:this.whatsappResponse.portalProfileId;
      this.getWhatsAppNumber(this.portalProfileId);
      this.alert=true;
    }
    else  this.personalDetailsResponse();
    this.activatedRoute.queryParamMap.subscribe(param=>{
      if(param.get('submit') && param.get('submit')==='Y')
      this.showThankyou= true;
    });

    this.isdisable=true;
    this.WANumberError=false;
    this.codeError=false;
    this.currentNumError=false;
    this.newNumError=false;
    this.currentCountryNumber='';
    this.newCountryNumber='';
}
  ngOnDestroy(){
    localStorage.removeItem('portalProfileID');
  }
  getWhatsAppNumber(portalProfileId){
    this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getWhatsappNumber,portalProfileId)
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          this.existingNumber=res.data.wspNumber;
          this.existingCountryCode=this.currentCountryCode;
        }
      }, (error) => {

      });
  }
getCurrentCountryCode(){
  this.jobSearchService.sharedConfigList.subscribe(domainConfig=>{
    if(domainConfig && domainConfig.wspCountryCode) {
      this.currentCountryCode = domainConfig.wspCountryCode;
      this.newCountryCode = domainConfig.wspCountryCode
    }
  });

  /*this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.jobflowconfig, null, null).subscribe(res => {
      if ((res.status == config.SUCCESS)&&res.data) {
        this.currentCountryCode=res.data['wspCountryCode'];
        this.newCountryCode=res.data['wspCountryCode'];
       }
    });*/
  }

  private personalDetailsResponse(){
    this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getpersonaldetails,null,null).subscribe(
      (res)=>{
        if(res.data && res.status==config.SUCCESS)
          this.existingNumber=res.data['whatsappNumber'];
          this.existingCountryCode=res.data['whatsappCountryCode'];

      },(error)=>{
      });
  }
  submit(){
    this.isdisable=true;
    this.getRouteUrls();
    let json =new UpdateWhatsappRequest(this.portalProfileId,this.currentCountryNumber,this.newCountryNumber,null,this.alert,this.currentCountryCode,this.newCountryCode);
    this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.updateWhatsapp,json)
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          if(this.alert) {
            window.location.href=window.location.origin+this.baseRoute+'?submit=Y';
            /*this._router.navigate([baseRoute[routingEndpoint.updateWhatsappPage]],{queryParams:{submit:'Y'}});
            this.showThankyou=true;*/
          }else
            this._router.navigate([this.myProfileRoute]);
        }
      }, (error) => {
        this._router.navigate([this.errorRoute],{queryParams:{submit:'N',error: error.status}});
      });
    localStorage.removeItem('portalProfileID');
  }

  validate(whatsappCountryCode,whatsappNumber,type,event) {
    let regexWACode = /^([+]{1})[0-9]*$/;
    let regexWANumber = /^[0-9]*$/;
    if (event) {
      if ((event.which != 8 && event.which != 0 && event.which < 48) || (event.which > 57 && event.which < 96) || event.which > 105)
        event.preventDefault();
    }
    if (whatsappCountryCode.trim()) {
      if (!regexWACode.test(whatsappCountryCode)) {
        this.codeError = true;
        this.isdisable = true;
        return false;
      } else
        this.codeError = false;
    }

    if (whatsappNumber.trim()) {
      if (!regexWANumber.test(whatsappNumber)) {
        this.WANumberError = true;
        this.isdisable = true;
        return false;
      } else {
        this.WANumberError = false;
        if(type=='currentNumber'){
          if(this.existingNumber==whatsappNumber && this.existingCountryCode==whatsappCountryCode)
            this.currentNumError = false;
          else{
            this.isdisable = true;
            this.currentNumError = true;
            this.newNumError = false;
            this.codeError = false;
          }
        }
        if(type=='newNumber'){
          if(this.existingNumber==whatsappNumber && this.existingCountryCode==whatsappCountryCode){
            this.newNumError = true;
            this.isdisable = true;
            this.currentNumError = false;
            this.codeError = false;
          }else
            this.newNumError = false;
        }
        if(!this.codeError && !this.WANumberError && !this.currentNumError && !this.newNumError && (this.currentCountryNumber && this.newCountryNumber && this.currentCountryCode && this.newCountryCode))
          this.isdisable = false;
      }
    }
  }

  getRouteUrls() {
    let orgName = this._router.routerState.snapshot.root.firstChild.params["orgName"];
    let base='';
    if(orgName){
      base= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org];
      this.myProfileRoute = (base[routingEndpoint.myProfile]).replace(':orgName',orgName);
      this.errorRoute = (base[routingEndpoint.errorPage]).replace(':orgName',orgName);
      this.baseRoute = (base[routingEndpoint.updateWhatsappPage]).replace(':orgName',orgName);
    }else{
      base= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
      this.myProfileRoute = base[routingEndpoint.myProfile];
      this.errorRoute = base[routingEndpoint.errorPage];
      this.baseRoute = base[routingEndpoint.updateWhatsappPage];
    }
  }
}
