import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';

import {Route, Router} from '@angular/router';
import {ModalService} from "../../../service/modal.service";

@Component({
  selector: 'jw-modal',
  template:
    `<div [class]="router.url.indexOf('q=delete')>-1 ?'jw-modal modal-dialog': 'jw-modal modal-dialog modal-lg'">
      <div class="overlay-bg"></div>
            <div class="jw-modal-body modal-content">
                <ng-content></ng-content>
            </div>
        </div>
        <div class="jw-modal-background"></div>`
})
export class ModelComponent implements OnInit, OnDestroy {
  @Input() id: string;
  private element: any;

  constructor(private modalService: ModalService, private el: ElementRef,public router:Router) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    const modal = this;

    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener('click', function (e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
    this.modalService.onOpen();
  }

  // close modal
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
    this.modalService.onClose();
  }
}
