export class GetSavedJobAlertRequest {
  constructor(public identifier: string,
              public jobSource: string) {}
}

export class GetSavedJobAlertResponse {
  constructor(public alertprofileid:string,
              public alertname:string,
              public distance:string,
              public domainid:string,
              public email:string,
              public frequency:string,
              public isonlyjobtitle:string,
              public keywords:string,
              public languageid:string,
              public latitude:string,
              public levelno:string,
              public location:string,
              public locationid:string,
              public locations:string,
              public longitude:string,
              public whatsappnum:string,
              public whatsappnumstatus:string,
              public whatsappcountrycode:string,
              public wspnumber:string,
              public lastediteddate:string,
              public portalprofileid:string,
              public postcode:string,
              public sectorids:string,
              public specialism:string,
              public anonymousalert:string,
              public emailverified:string,
              public workflowinstanceid:string,
              public jobtypes:string,
              public subspecialism:string,
              public overwritealert:string,
              public unsubscribe:string,
              public verifyRequest:string,
              public saveurl:string,
              public alertjobSource:string,
              public compensationMax:string,
              public compensationMin:string,
              public compensationType:string,
              public typeOnlyFilter:string,
              public flexibleWorking:string,
              public fullTime:string,
              public partTime:string,
              public alertprofilesalaryCollection:string[]) {}
}

