import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RequestService } from 'src/app/global/services/request/request.service';
import { SiteService } from 'src/app/global/services/site/site.service';
import {  DecryptIdentifierJsonRequest} from '../../../model/response/decrypt-identifier';
import {apiEndPoints, environment, keywords, routingEndpoint, config} from '../../../../../environments/root/environment';
import {ActivatedRoute, Router} from '@angular/router';
import { ResetPassword } from 'src/app/jobportal/model/request/reset-password';
import {TranslateService} from '@ngx-translate/core';
import {SetTitleService} from '../../../service/set-title.service';

@Component({
  selector: 'app-jp-reset-password',
  templateUrl: './jp-reset-password.component.html',
  styleUrls: ['./jp-reset-password.component.scss']
})
export class JpResetPasswordComponent implements OnInit {

  private forgetPasswordRoute: string;
  private errorRoute: string;
  private login: string;
  private goLoginPage: string;
  public emailAdd: string;
  public identifier: string;
  public trainingId: string="";
  public trainingUser: string="";
  public resetPassForm: FormGroup;
  public formSubmitted: boolean;
  public validationFlag = {
    required: false,
    pattern: false,
    passwordMatch: false
  };
  public disableButton: boolean;
  public isAzureEnabled: boolean;

  constructor(private _requestService:RequestService,private siteService: SiteService, private router: Router,private activatedRoute:ActivatedRoute,private translateService:TranslateService,private titleService:SetTitleService) {
    this.translateService.getTranslation(siteService.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(res['Reset_password_page_title']);
    });
  }

  ngOnInit() {
    this.isAzureEnabled = environment[config.theme + this.siteService.getSiteLocale()]['config']['azure_enable'];
    if(this.isAzureEnabled){
      let azureLoginURL = environment[config.theme + this.siteService.getSiteLocale()]['config']['auzureLoginURL'] ? environment[config.theme + this.siteService.getSiteLocale()]['config']['auzureLoginURL'] : null;
      let orgName= this.siteService.getOrgName();
      if(orgName==='jobportal')
        orgName='hays';
      if(azureLoginURL.indexOf('{orgName}')>-1)
        azureLoginURL=azureLoginURL.replace('{orgName}',orgName);
      window.location.href = azureLoginURL+"?action=reset&workFlow=resetPass";
    }
    this.getRouteUrls();
    this.activatedRoute.queryParams.subscribe((params)=> {
      console.log("param in reset password..",params);
      this.trainingId=params['trainingId'];
      this.trainingUser=params['trainingUser'];
      if(params['identifier']) {
        this.translateService.setDefaultLang(this.siteService.getSiteLocale());
        this.decryptIdentifier(params['identifier']);
      }
    });
    this.createFormGroup();
  }

  protected createFormGroup(): void {
    this.resetPassForm = new FormGroup({
      newPassword: new FormControl('', {validators: [Validators.required, Validators.pattern(config.passwordPattern)]}),
      confirmPassword: new FormControl('', {validators: [Validators.required, Validators.pattern(config.passwordPattern)]})
    });
  }

  submit() {
    this.formSubmitted = true;

    this.validationFlag.passwordMatch = this.resetPassForm['controls'].newPassword.value !== this.resetPassForm['controls'].confirmPassword.value;
    this.validationFlag.required = this.resetPassForm.invalid && ((this.resetPassForm['controls'].newPassword.errors && this.resetPassForm['controls'].newPassword.errors.required) || (this.resetPassForm['controls'].confirmPassword.errors && this.resetPassForm['controls'].confirmPassword.errors.required));
    this.validationFlag.pattern = this.resetPassForm.invalid && (this.resetPassForm['controls'].newPassword.errors && this.resetPassForm['controls'].newPassword.errors.pattern);

    if (!this.validationFlag.passwordMatch && !this.validationFlag.required && !this.validationFlag.pattern) {
      this.disableButton=true;
      this.resetPassword();
    }
  }

  decryptIdentifier(identifier){
    let jsonData= new DecryptIdentifierJsonRequest(identifier,keywords.resetFlag);
    this._requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.decryptIdentifier,jsonData)
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          if (res.data['statusCode']==='200') {
            this.emailAdd=res.data['data'].split(',')[1].split('"')[0];
            this.identifier=res.data['data'].split(',')[0].split('"')[1];
            if(res.data['statusCode'] === config.expireCode && res.data === config.expireMsg){
              this.router.navigate([this.forgetPasswordRoute], {
                queryParams: {trainingUser: this.trainingUser,trainingId: this.trainingId, status: 'expired' }
              });
            }
          }else {
            this.router.navigate([this.forgetPasswordRoute],{queryParams:{trainingUser: this.trainingUser,trainingId: this.trainingId,status:keywords.expired}});
          }
        }
      }, (error) => {
        this.router.navigate([this.errorRoute],{queryParams:{resetFlag:keywords.invalid}});
      });
  }

  resetPassword(){
    let payload = new ResetPassword(0, this.emailAdd, this.identifier, this.resetPassForm.controls.newPassword.value);
    this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI,apiEndPoints.resetPassword,payload ).subscribe(response => {
    if (response.status === config.SUCCESS) {
      this.disableButton=false;
      if(this.trainingUser=='Y'){
       this.router.navigate([this.goLoginPage], {
          queryParams: { trainingId: this.trainingId, status: 'resetPasswordSuccess' }
        });
      }else {
        this.router.navigate([this.login], {
          queryParams: {status: 'resetPasswordSuccess'}
        });
      }
    }
      if(response.data.statuscode === config.usedIdentifierCode && response.data.statusmsg === config.usedIdentifiermsg){
        this.disableButton=false;
        this.router.navigate([this.forgetPasswordRoute], {
          queryParams: {trainingUser: this.trainingUser,trainingId: this.trainingId, status: 'failure' }
        });
      }
}, (error) => {
      this.disableButton=false;
  this.router.navigate([this.errorRoute],{queryParams:{submitCV:'N',error: error.status}});
});
  }

  getRouteUrls(){
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    let base='';
    if(orgName){
      base= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org];
      this.forgetPasswordRoute = (base[routingEndpoint.forgetPassword]).replace(':orgName',orgName);
      this.errorRoute = (base[routingEndpoint.errorPage]).replace(':orgName',orgName);
      this.login = (base[routingEndpoint.login]).replace(':orgName',orgName);
      this.goLoginPage = base[routingEndpoint.goLoginPage];
      if(this.goLoginPage)
        this.goLoginPage = (base[routingEndpoint.goLoginPage]).replace(':orgName',orgName);
    }else{
      base= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
      this.forgetPasswordRoute = base[routingEndpoint.forgetPassword];
      this.errorRoute = base[routingEndpoint.errorPage];
      this.login = base[routingEndpoint.login];
      this.goLoginPage = base[routingEndpoint.goLoginPage];
    }
  }

}
