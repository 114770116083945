import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from 'src/app/global/services/site/site.service';
import {RequestService} from 'src/app/global/services/request/request.service';
import {ActivatedRoute, Router} from '@angular/router';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../../../../environments/root/environment';
import {DataSharingService} from '../../../../../../jobportal/service/data-sharing.service';
import {CookieService} from 'ngx-cookie-service';

import {SetTitleService} from '../../../../../../jobportal/service/set-title.service';
import {Register} from '../../../../../../jobportal/model/request/register';
import{UploadCV,UploadCVAdapt} from "../../../../../../jobportal/model/response/uploadCV";
import {Subscription} from "rxjs";
import {JobSearchService} from "../../../../../../jobportal/service/job-search.service";
import {CvData} from "../../../../../../jobportal/model/request/cv-data";
import {ExtraFieldsConfig} from "../../../../../../jobportal/model/response/google-job-response";

@Component({
  selector: 'app-go1-spec-cv',
  templateUrl: './go1-spec-cv.component.html',
  styleUrls: ['./go1-spec-cv.component.scss'],
  providers: [UploadCVAdapt]
})
export class Go1SpecCvComponent implements OnInit {

  public specCVFormGroup: FormGroup;
  public siteLocale: any;
  public isCaptchaDisable = false;
  public formSubmitted = false;
  public router: any;
  public specRoute = false;
  public firstName: string;
  public lastName: string;
  public emailAddress: string='';
  public isChecked: boolean;
  public officeSpecialism: string;
  public sector: string;
  public errorRoute: string;
  private isFormValid: boolean;
  public disableButton: boolean;
  public validationCheck: boolean;
  public emailError: boolean;
  public showFileValidation = false;
  public showPopUp = false;
  public isGo1UploadCV=false;
  private jobShredConfigRef: Subscription = new Subscription();
  //public domainConfig: ExtraFieldsConfig;



  constructor(private _requestService: RequestService, private _translate: TranslateService,
              public _site: SiteService, private _cdr: ChangeDetectorRef,
              private _router: Router, private activatedRoute: ActivatedRoute, private dataShareService: DataSharingService,
              private cookieService: CookieService, private titleService: SetTitleService ,public uploadCVAdapt: UploadCVAdapt,private jobSearchService: JobSearchService
  ) {
    if (this._router.url.indexOf('speculativeCV') > -1) {
      this.specRoute = true;
    }
    this.siteLocale = this._site.getSiteLocale();
    this._translate.setDefaultLang(this.siteLocale);
    this._translate.getTranslation(this._site.getSiteLocale()).subscribe((res) => {
      if (this.specRoute)
        this.titleService.setTitleForPage(res['spec_cv_page_title']);

    });
  }

  ngOnInit() {
    localStorage.removeItem("training_id");
    this.activatedRoute.queryParamMap.subscribe(param => {
      if (!param.get("path"))
        this.cookieService.delete('applicantDetails', '/');
    });

      this.createFormGroup();
      this.jobShredConfigRef = this.jobSearchService.sharedConfigList.subscribe(domainConfig => {
      if (domainConfig && domainConfig.isGo1UploadCV) {
        this.isGo1UploadCV = domainConfig.isGo1UploadCV;
        //this.domainConfigs = domainConfig;
      }

    });

  }

  protected createFormGroup(): void {
    this.specCVFormGroup = new FormGroup({
      firstname: new FormControl('', {
        validators: [Validators.required, this.whitespaceValidator],
        updateOn: 'change',
      }),
      lastname: new FormControl('', {
        validators: [Validators.required, this.whitespaceValidator],
        updateOn: 'change'
      }),
      email: new FormControl('', {
        validators: [Validators.required,Validators.email],
        updateOn: 'change'
      }),
      cvData: new FormControl('', {
          validators: Validators.nullValidator,
          updateOn: 'change'
        }
      ),
      expertise: new FormControl('', {
          validators: Validators.required,
          updateOn: 'change'
        },
      ),
      officeForSpecialism: new FormControl('', {
          validators: Validators.required,
          updateOn: 'change'
        },
      ),
      privacyconsent1: new FormControl('', {
          updateOn: 'change'
        }
      ),
      reCaptchaReactive: new FormControl('',
        { updateOn: 'change' }
      )
    });
    this.dataShareService.setSignUpForm(this.specCVFormGroup);
    if (this.cookieService.get("applicantDetails")) {
      let applicantCookie: any;
      applicantCookie = decodeURIComponent(this.cookieService.get("applicantDetails"));
      applicantCookie = JSON.parse(applicantCookie);
      this.firstName = applicantCookie.firstName ? applicantCookie.firstName : "";
      this.lastName = applicantCookie.lastName ? applicantCookie.lastName : "";
      this.emailAddress = applicantCookie.email ? applicantCookie.email : "";
      this.officeSpecialism = applicantCookie.officeSpecialism ? applicantCookie.officeSpecialism : "";
      this.sector = applicantCookie.sector ? applicantCookie.sector : "";
      this.isChecked = applicantCookie.marketingConsents;
    }

    if (localStorage.getItem('disableCaptcha') === 'true') {
      this.isCaptchaDisable = true;
    }
    if (!this.isCaptchaDisable && this.specCVFormGroup) {
      this.specCVFormGroup['controls'].reCaptchaReactive.setValidators([Validators.required]);
    }
  }

  public whitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  register() {
    let EMAIL_REGEX=/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    if(!this.emailAddress && !EMAIL_REGEX.test(this.emailAddress))
      this.emailError = true;

    if (this.officeSpecialism && this.sector && this.firstName && this.lastName && this.emailAddress && EMAIL_REGEX.test(this.emailAddress.trim()) && this.isChecked) {
      this.specCVFormGroup.value.officeForSpecialism = this.officeSpecialism;
      this.specCVFormGroup.value.expertise = this.sector;
      this.specCVFormGroup.value.privacyconsent1 = this.isChecked;
      this.specCVFormGroup.patchValue({ expertise: this.sector, officeForSpecialism: this.officeSpecialism, privacyconsent1: this.isChecked});
      this.specCVFormGroup['controls']['officeForSpecialism'].setErrors(null);
      this.specCVFormGroup['controls']['expertise'].setErrors(null);
      this.specCVFormGroup['controls']['privacyconsent1'].setErrors(null);
      this.isFormValid = true;
      this.emailError=false;
    }
    this.formSubmitted = true;
    if (this.checkValidation()) {
      if (this.specCVFormGroup.valid || (this.isFormValid &&  this.specCVFormGroup.value.reCaptchaReactive)) {
        this.disableButton = true;
        this.emailError=false;
        this.initRegister();
      }
    }
  }

  initRegister() {
    let token = '';
    let trainingId="";
    this.activatedRoute.queryParamMap.subscribe(param => {
      token = param.get('accessTokenDB') ? param.get('accessTokenDB') : param.get('accessTokenGD');
      if(param.get('trainingId'))
        trainingId=param.get('trainingId');
    });
    let cvData: CvData;
    cvData = this.specCVFormGroup.controls.cvData.value;

    this.dataTransform();
    let registerJson = new Register(this.specCVFormGroup.controls.cvData.value, cvData.name,'', this.emailAddress,
      this.specCVFormGroup.value.privacyconsent1, this.specCVFormGroup.value.privacyconsent2, this.specCVFormGroup.value.privacyconsent3,
      this.specCVFormGroup.value.eshotconsent, this.specCVFormGroup.value.expertise, this.specCVFormGroup.value.firstname, this.specCVFormGroup.value.reCaptchaReactive,
      this.specCVFormGroup.value.katkanafirstname, this.specCVFormGroup.value.katkanalastname, this.specCVFormGroup.value.lastname, this.specCVFormGroup.value.marketingconsent,
      this.specCVFormGroup.value.officeForSpecialism.split(':')[0], this.specCVFormGroup.value.officeForSpecialism.split(':')[1],
      this.specCVFormGroup.value.smsconsent, token,"Y",keywords.go1TrainingText,trainingId, "","");
    this.submitAndRegister(registerJson);
  }


  submitAndRegister(registerJson) {
    if (this.cookieService.get('applicantDetails'))
      this.cookieService.delete('applicantDetails', '/');
    let baseRoute = environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url];
    let orgName = this._router.routerState.snapshot.root.firstChild.params["orgName"];
    let successPage='';
    let errorRoute='';
    if(orgName){
      let base= environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url_org];
      successPage = (base[routingEndpoint.successPage]).replace(':orgName',orgName);
      errorRoute = (base[routingEndpoint.errorPage]).replace(':orgName',orgName);
    }else{
      let base= environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url];
      successPage = base[routingEndpoint.successPage];
      errorRoute = base[routingEndpoint.errorPage];
    }
    this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.register, registerJson).subscribe(response => {
      if (response.status === config.SUCCESS) {
        //this.ngxUiLoader.stop();
        this._router.navigate([successPage], { queryParams: { trainingRegister: 'Y' } });
      }
      else {
        //this.ngxUiLoader.stop();
        this._router.navigate([errorRoute], { queryParams: { trainingRegister: 'N', error: response.status } });
      }
    }, (error) => {

        this._router.navigate([errorRoute], { queryParams: { submitCV: 'N', error: error.status } });
    });
  }

  checkValidation() {
    this.validationCheck = true;
    if (this.specCVFormGroup['controls'].privacyconsent1.value === true) {
    } else {
      this.validationCheck = false;
    }
    return this.validationCheck
  }

  dataTransform() {
    this.specCVFormGroup.value.privacyconsent1 = 'Y';
  }

  getConsentChecked($event: boolean) {
    this.isChecked=$event;
  }

  onEmailChanged(emailAddress) {
    this.emailError = false;
    this.emailAddress = emailAddress.target.value;
    this.dataShareService.setEmailId(this.emailAddress);
    let EMAIL_REGEXP  = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if(this.emailAddress) {
      if (this.emailAddress.trim() == "" && !EMAIL_REGEXP.test(this.emailAddress.trim())) {
        this.emailError = true;
      }
    }else
      this.emailError=true;
  }
}
