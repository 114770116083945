<div class="form-group" [formGroup]="parent">
  <label  [translate]="'sector_text'"></label>
  <select class="form-control" id="gtm_sector_field" #sectorId (change)="getOfficeList($event);" formControlName="expertise">
    <option *ngIf="!sectorName" value="" selected="selected" [translate]="router.url.indexOf(updateYourDetailsUrl) > -1 ? 'update_your_details_select_sector_text' :'select_sector_text'"></option>
    <option *ngIf="sectorName" [value]="sector" selected="selected" [textContent]="sectorName"></option>
    <option *ngFor="let item of sectorList" [value]="item.id" id="gtm_sector" #sectorListData
            [ngClass]="selectedSectorValue === item.id ? 'selected': ''"
            [textContent]="item.label"></option>
  </select>
</div>
