<div class="thank-you-apply bg-light-grey">
 <div class="row">
  <div class="col-12">
    <app-jp-apply-confirmation [googleJobResponse]="googleJobResponse"></app-jp-apply-confirmation>
  </div>
 </div>
  <div class="row">
    <div [ngClass]="(googleSimilarJobResponse && googleSimilarJobResponse.length>0)?'col-sm-12 col-md-6':'col-sm-12 no-similar-jobs'">
      <app-jp-application-process-details></app-jp-application-process-details>
    </div>
    <div class="col-sm-12 col-md-6" *ngIf="(googleSimilarJobResponse && googleSimilarJobResponse.length>0) && haysCareers.indexOf(orgName) <= -1">
      <app-jp-similar-jobs [googleSimilarJobResponse]="googleSimilarJobResponse"></app-jp-similar-jobs>
    </div>
  </div>
</div>
