<div class="cdk-overlay-dark-backdrop"></div>
<section class="change-password">

  <div class="row cdk-overlay-container">
    <div class="col-12 col-md-4 border mb-5">
      <a href="javascript:void(0)" id="closePolicy" class="close" (click)="closePopup()">+</a>
      <form id="gtm_reset_password_popup" [formGroup]="changePassForm" (ngSubmit)="submit()">

        <h3 class="border-bottom password-icon" [translate] = "'change_password'"></h3>

        <p class="help-text" [translate] = "'password_criteria_text_azure'">
        </p>

        <div class="form-group">
          <label  [translate]="'cp_changed_password_placeholder'"></label>
          <input class="form-control" id="filed2" placeholder = "{{'changed_password_placeholder_azure' | translate}}"
                 formControlName="newPassword" type="password" required >

        </div>

        <div class="form-group">
          <label  [translate]="'cp_confirm_password_placeholder'"></label>
          <input type="password" class="form-control" id="filed3" placeholder= "{{'confirm_password_placeholder_azure' | translate}}"
                 formControlName="confirmPassword" required >

        </div>

        <div class="form-group" *ngIf = "formSubmitted">
          <span class="error-msg" *ngIf="validationFlag.required" [translate] = "'password_blank_text'"></span>
          <span class = "error-msg" *ngIf="validationFlag.pattern && !validationFlag.required" [translate] = "'password_criteria_text_new'"></span>
          <span class="error-msg" *ngIf="validationFlag.oldNewDiffer && !validationFlag.required && !validationFlag.pattern "
                [translate] ="'noSame_changePassword'"></span>
          <span class="error-msg" *ngIf="validationFlag.passwordMatch && !validationFlag.required && !validationFlag.pattern && !validationFlag.oldNewDiffer" [translate]="'password_do_not_match_new'"></span>
          <span class="error-msg" *ngIf="incorrectOldPass && !validationFlag.required && !validationFlag.oldNewDiffer && !validationFlag.passwordMatch && !validationFlag.pattern" [translate]="'incorrect_old_password'"></span>
        </div>

        <div class="action-bar">
          <button id="c_p_azure" type="submit" [ngClass]="buttonChnagePasswordClicked?'btn btn-secondary btn-block btn-loading':'btn btn-secondary btn-block'" [disabled]="buttonChnagePasswordClicked" [translate] = "'submit_button'"></button>
        </div>

      </form>
    </div>
  </div>
</section>
