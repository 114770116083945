import {Adapter} from '../../../global/adapter/adapter';

export class WidgetList {
  constructor(public status: string,
              public message: string,
              public data: WidgetListData[]) {

  }
}

export class WidgetListData {
  constructor(
    public WIDGET_CONTENT: string,
    public WIDGET_LABEL: string,
    public WIDGET_NAME: string,
    public LOCALE: string,
    public WIDGET_ORDER: number,
    public WIDGET_TITLE: string,
    public WIDGET_LINK: string,
    public PAGE_NAME: string,
    public BUTTON_NAME: string
  ) {
  }

}
export class WidgetDataSharing {
  constructor(
    public showWidget: boolean,
    public widgetUrl: string,
  ) {
  }
}
export class WidgetListAdapt implements Adapter<WidgetList> {
  adapt(item: any): WidgetList {
    return new WidgetList(
      item.status,
      item.message,
      item.data
    );
  }
}
