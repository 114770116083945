<div *ngIf="questionArray.length>0" class="question-list">
  <h3 [translate]="'Qualifying_question_heading'"></h3>
  <div>
    <p [translate]="'Qualifying_question_subheading'"></p>
    <span><a id="gtm_Qualifying_question_cleardata"(click)="clearData()" [translate]="'clear_all_Qualifying_Answers'"></a></span>
  </div>
  <ol *ngFor="let questions of questionArray; let i =index">
    <li>
      <span>0<i [textContent]="i+1"></i></span>
      <p *ngIf="questions.id" [textContent]="questions.question"></p>
      <div class="toggle-btn">
        <button id="gtm_Qualifying_question_yes_btn" *ngIf="questions.id" [ngClass]="questions.flag=='Yes'?'selected':''" [translate]="'yes_text'" (click)="setQuestionValue(questions,'Yes')" class="state-on"></button>
        <button id="gtm_Qualifying_question_no_btn" *ngIf="questions.id"  [ngClass]="questions.flag=='No'?'selected':''" [translate]="'no_text'" (click)="setQuestionValue(questions,'No')" class="state-off"></button><br>
      </div>
    </li>
  </ol>
</div>
