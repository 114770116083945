import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../services/site/site.service';
import {
  apiEndPoints,
  config,
  environment,
  keywords,
  routingEndpoint
} from '../../../../../environments/root/environment';
import {DataSharingService} from '../../../../jobportal/service/data-sharing.service';
import {JobSearchService} from '../../../../jobportal/service/job-search.service';
import {map} from "rxjs/operators";
import {CustomerServiceEmail, EmailConfirmation} from "../../../../jobportal/model/request/email-confirmation";
import {RequestService} from "../../../services/request/request.service";

@Component({
  selector: 'app-error-pages',
  templateUrl: './error-pages.component.html',
  styleUrls: ['./error-pages.component.scss']
})
export class ErrorPagesComponent implements OnInit {

  public query:any;
  private baseUrl: string;
  public loginUrl: string;
  public emailConfirmation: string;
  public apacCountry: boolean;
  public erpCustomerCareId: string;
  public mailtoText: string = '';

  constructor(private activateRoute:ActivatedRoute, private translate:TranslateService,
              public jobSearchService: JobSearchService, public _siteService:SiteService,
              private dataSharingService : DataSharingService, private requestService : RequestService, private router:Router) {
    this.translate.setDefaultLang(this._siteService.getSiteLocale());
    if(environment.platform.indexOf('apac')>-1)
      this.apacCountry=true;
    this.dataSharingService.getWidgetListName(keywords.errorPageWidget);
    this.getRouteUrls();
    this.activateRoute.queryParams.subscribe((params)=>{
      if(params)
        this.query=params;
      if(params['lang']) {
        let locale=params['lang'];
        let domain=this._siteService.getDomain();
        this._siteService.setSite2(domain,locale);
        this.translate.setDefaultLang(this._siteService.getSiteLocale());
      }
    });

  }

  ngOnInit() {
    if(this.query.erpStatus === 'FAIL')
      this.getErpCustomerCareId();
  }

  getErpCustomerCareId(){
    this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getCustomerServiceEmail)
      .subscribe(response => {
        if (response.status === config.SUCCESS) {
          this.erpCustomerCareId = response.data.customerServiceEmail;
          this.mailtoText = keywords.mailto;
      };
    }, (error) => {
    });
  }

  getRouteUrls(){
  let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
  this.loginUrl = environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.login];
  this.emailConfirmation = environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.emailConfirmationPage];
  if(orgName){
    this.loginUrl = (environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.login]).replace(':orgName',orgName);
    this.emailConfirmation = (environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.emailConfirmationPage]).replace(':orgName',orgName);
  }
}

}
