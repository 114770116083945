import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SiteLocaleComponent} from '../global/components/shared/site-locale/site-locale.component';
import {routeConstant} from './routes/routes';
//import {HomeComponent} from './assessskill/components/secured/home/home.component';
import {HomeCanDeactivateGuard} from './guards/home-can-deactivate-guard.service';
//import { LandingPageComponent } from './assessskill/components/secured/landing-page/landing-page.component';
import { Page404hlaComponent } from './page404hla/page404hla.component';

const routes: Routes = [

  { path: routeConstant.app_route.hla_route.secure_module_link, loadChildren: () => import('./secure/secure.module').then(m => m.SecureModule) },
  { path: routeConstant.app_route.hla_route.shared_module_link, loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule) },
  { path: '', redirectTo: routeConstant.app_route.hla_route.secure_module_link, pathMatch: 'full' },
  { path: '**', component: Page404hlaComponent }
 
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HlaAppRoutingModule { }
