import {Component, Input, OnInit, ViewChild, Inject, Renderer2} from '@angular/core';
import {AutocompleteService} from '../../../../service/autocomplete.service';
import {JpOfficeComponent} from '../../../../shared/components/jp-office/jp-office.component';
import {SiteService} from '../../../../../global/services/site/site.service';
import {TranslateService} from '@ngx-translate/core';
import {DOCUMENT} from '@angular/common';
import {Router} from '@angular/router';
import {config, environment, keywords, routingEndpoint} from '../../../../../../environments/root/environment';
import {DataSharingService} from '../../../../service/data-sharing.service';
import {SetTitleService} from '../../../../service/set-title.service';
import {ExtraFieldsConfig} from '../../../../model/response/google-job-response';
import {JobSearchService} from '../../../../service/job-search.service';

@Component({
  selector: 'app-jp-office-locator',
  templateUrl: './jp-office-locator.component.html',
  styleUrls: ['./jp-office-locator.component.scss']
})
export class JpOfficeLocatorComponent implements OnInit {

  public showAjxLoader: boolean;
  public nearMeBtn: boolean;
  public geoLoc: string;
  public latitude: string;
  public longitude: string;
  public locationid: number;
  public locationname: string='';
  public address: string;
  public  distUnit: string;
  public  radius: number;
  public specialismname: string='';
  public specialismnid: string='';
  public subspecialismID: string='';
  @ViewChild(JpOfficeComponent, {static: false}) OfficeComponent;
  public domainConfigResult: ExtraFieldsConfig;
  public showOfficeLoader: boolean;
  public findClicked: boolean;
  public showFindNearMeSubSpecialism: boolean;
  public showFindUsSubSpecialism: boolean;
  public isSubspecialism: string;
  private routUrl:string;


  constructor(private autoCompleteService:AutocompleteService,
              private _renderer2: Renderer2,
              @Inject(DOCUMENT) private _document: Document,
              private siteService: SiteService,private router:Router,private dataSharingService : DataSharingService,
              private _translate: TranslateService,private titleService:SetTitleService,private jobSearchService:JobSearchService) {
    this._translate.setDefaultLang(this.siteService.getSiteLocale());
    this._translate.getTranslation(siteService.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(res['office_locator_page_title']);
    });
    this.nearMeBtn=false;
    this.showAjxLoader=true;
    this.showOfficeLoader=true;
    this.showFindNearMeSubSpecialism=false;
    this.showFindUsSubSpecialism=false;
    this.dataSharingService.getWidgetListName(keywords.findUsWidget);
  }
  ngOnInit() {
    localStorage.removeItem("requstedRedirectURL");
    this.getRouteUrl();
    this.getDomainConfig();
  }
  ngAfterViewInit(){
   this.getLatLongValues();
   this.addOptimalWorkshopSnippet();
  }
  addOptimalWorkshopSnippet() {
    let script = this._renderer2.createElement('div');
    script.id = 'owInviteSnippet';
    script.style = 'position:fixed;right:20px;bottom:20px;width:280px;padding:20px;margin:0;border-radius:6px;background:#333333;color:#EEEEEE;text-align:left;z-index:2200000000;opacity:0;transition:opacity 500ms;-webkit-transition:opacity 500ms;display:none;';
    script.innerHTML = `<div id='owInviteMessage' style='padding:0;margin:0 0 20px 0;font-size:16px;'>Got a spare 5 minutes to help us
    improve our website?</div><a id='owInviteOk'
    href='https://fr8vjtph.optimalworkshop.com/treejack/k3c46712?tag=intercept&utm_medium=intercept'
    onclick='this.parentNode.style.display="none";' target='_blank'
    style='color:#7DB6EE;font-size:16px;font-weight:bold;text-decoration:underline;'>I&#x27;ll do it</a><a
    id='owInviteCancel' href='javascript:void(0)' onclick='this.parentNode.style.display="none";'
    style='color:#EEEEEE;font-size:14px;text-decoration:underline;float:right;'>No thanks</a>`;
    let script2 = this._renderer2.createElement('script');
    script2.innerHTML = `var owOnload = function () { if (-1 == document.cookie.indexOf('ow-intercept-treejack-6473bho5')) { var o = new XMLHttpRequest; o.onloadend = function () { try { var o = document.getElementById('owInviteSnippet'); var date = new Date(); date.setMonth(date.getMonth() + 1); this.response && JSON.parse(this.response).active === !0 && (document.cookie = 'ow-intercept-treejack-6473bho5=Done;path=/;expires=' + date.toUTCString() + ';', setTimeout(function () { o.style.display = 'block', o.style.opacity = 1 }, 2e3)) } catch (e) { } }, o.open('POST', 'https://app.optimalworkshop.com/survey_status/treejack/6473bho5/active'), o.send() } }; if (document.readyState === 'complete') { owOnload(); } else if (window.addEventListener) { window.addEventListener('load', owOnload); } else if (window.attachEvent) { window.attachEvent('onload', owOnload); }`;
    this._renderer2.appendChild(this._document.body, script);
    this._renderer2.appendChild(this._document.body, script2);
  }
  getLocationId(event){
    this.locationid=event.location_Id;
    this.locationname=event.location_Name;
  }
  getSubSpecialism(subspecialismId){
    this.subspecialismID=subspecialismId;
  }
  getSpecialism(event){
    this.specialismnid=event.specialism_Id;
    this.specialismname=event.specialism_Name;
    if(this.specialismname==keywords.consAndProptyText) {
      if (event.subspecialism == 'findNearMe')
        this.showFindNearMeSubSpecialism = true;
      if (event.subspecialism == 'findUs')
         this.showFindUsSubSpecialism = true;
    }
  }
  showAjaxLoader(event){
    this.showAjxLoader=event;
  }
  showOfficeLoaderComponent(event){
    this.showOfficeLoader=event;
  }
  findCall(event){
    this.findClicked=true;
    event.preventDefault();
    this._translate.get(keywords.distanceUnit).subscribe(val => this.distUnit = val);
    if(this.locationid){
          this._translate.get(keywords.radiusGeocoder).subscribe(val => this.radius = val);
         //let routUrl=environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.officeLocatorResultPage];
          this.router.navigate([this.routUrl], { queryParams: { distUnit: this.distUnit, radius: this.radius,locId: this.locationid,
              loc:this.locationname,lat: this.latitude, long: this.longitude,geoLoc: this.geoLoc,
              spec:this.specialismname,specId: this.specialismnid,subSpecId:this.subspecialismID } });
    }else if(this.locationid==undefined && this.latitude==undefined && this.longitude==undefined){
      this._translate.get(keywords.radiusGeocoder).subscribe(val => this.radius = val);
      //let routUrl=environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.officeLocatorResultPage];
      this.router.navigate([this.routUrl], { queryParams: { distUnit: this.distUnit, radius: this.radius,locId:environment[config.theme + this.siteService.getSiteLocale()]['config']['locationParentId'] ,
          loc:this.locationname,lat: this.latitude, long: this.longitude,geoLoc: this.geoLoc,
          spec:this.specialismname,specId: this.specialismnid,subSpecId:this.subspecialismID } });
    } else {
      if(this.latitude && this.longitude && this.OfficeComponent.searchElementRef.nativeElement.value) {
        this.geoLoc=this.autoCompleteService.country;
        this._translate.get(keywords.radiusGeocoder).subscribe(val => this.radius = val);
        this.dataSharingService.onClick(false);
        //let routUrl=environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.officeLocatorResultPage];
        this.router.navigate([this.routUrl], { queryParams: { distUnit: this.distUnit, radius: this.radius,locId: this.locationid,loc:this.locationname,lat: this.latitude, long: this.longitude,geoLoc: this.geoLoc,spec:this.specialismname,specId: this.specialismnid,subSpecId:  this.subspecialismID } });
        this.latitude="";
        this.longitude="";
     }else{
        this.dataSharingService.onClick(true);
      }

    }

  }
  findNearMeCall(event){
    this.nearMeBtn=true;
    event.preventDefault();
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.latitude=position.coords.latitude.toString();
          this.longitude=position.coords.longitude.toString();
          this._translate.get(keywords.distanceUnit).subscribe(val => this.distUnit = val);
          this._translate.get(keywords.defaultRadius).subscribe(val => this.radius = val);
          //let routUrl=environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.officeLocatorResultPage];
          this.router.navigate([this.routUrl], { queryParams: { distUnit: this.distUnit, radius: this.radius,locId:'',loc:this.locationname,lat: this.latitude, long: this.longitude,geoLoc: this.geoLoc,spec:this.specialismname,specId: this.specialismnid,subSpecId:  this.subspecialismID } });
          this.latitude="";
          this.longitude="";
        });
      }
  }
  getLatLongValues(){
    this.autoCompleteService.long.subscribe(longitude =>{
      this.longitude=longitude;
    });
    this.autoCompleteService.lat.subscribe(latitude=>{
      this.latitude=latitude;
    });
  }


  private getDomainConfig() {
    /*this.requesService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.jobflowconfig, null, null).subscribe(res => {
      if (res.status == config.SUCCESS) {*/
    this.jobSearchService.sharedConfigList.subscribe(domainConfig=>{
      if(domainConfig) {
        this.domainConfigResult = domainConfig;
        this.showAjxLoader = false;
      }
    });

     /* }
    });*/
  }
  showSubSpecialism(value){
    this.isSubspecialism = value;
  }

  getRouteUrl(){
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    if(orgName){
      let url = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.officeLocatorResultPage];
      this.routUrl = url.replace(':orgName',orgName);
    }
    else
      this.routUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.officeLocatorResultPage];
  }
}
