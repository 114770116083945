import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RequestService} from '../../services/request/request.service';
import {apiEndPoints, config} from '../../../../environments/root/environment';

@Component({
  selector: 'app-dms-verify-alert',
  templateUrl: './dms-verify-alert.component.html',
  styleUrls: ['./dms-verify-alert.component.scss']
})
export class DmsVerifyAlertComponent implements OnInit {

  private identifier:any;

  constructor(private _requestService:RequestService,private activatedRouter:ActivatedRoute,private router:Router) { }

  ngOnInit() {
    this.identifier=this.activatedRouter.snapshot.queryParamMap.get('identifier');
    this.callDycrptAPI(this.identifier);
  }

  callDycrptAPI(identifier) {
    let jsonData= {
      'identifier':identifier
    };
    this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.dmsDecryptIdentifier, jsonData)
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data['url']) {
          window.location.href=res.data['url'];
        }else{
          this.router.navigate(['/DMS/'],{ queryParams: {DocExchange: 'N'}});
        }
      }, (error) => {
        this.router.navigate(['/DMS/'],{ queryParams: {DocExchange: 'N'}});
      });
  }

}
