
  <h3 [translate]="'upcoming_interviews'"></h3>
  <div class="upcoming-interview" *ngIf="upcomingInterview.length>0">

      <div class="interview-cont" >
        <div class="interview-details" *ngFor="let interviewDetails of upcomingInterview">
         <h4 [textContent]="interviewDetails.clientName"></h4>

          <dl>
            <dd [translate]="'interview_date_label'"></dd>
            <dt [textContent]="interviewDetails.interviewDate"></dt>
          </dl>
          <dl>
            <dd [translate]="'interview_job_label'"></dd>
            <dt [textContent]="interviewDetails.jobTitle"></dt>
          </dl>

          <dl>
            <dd [translate]="'interview_time_label'"></dd>
            <dt [textContent]="interviewDetails.interviewTime"></dt>
          </dl>
          <dl>
            <dd class="label" [translate]="'address_label'"></dd>
            <dt><a id="gtm_upcoming_interview_jobTitle" [href]="viewGoogleLink+interviewDetails.address"
                   [target]="'_blank'" [textContent]="interviewDetails.address"></a></dt>
          </dl>
          <dl>
            <dd class="label" [translate]="'consultant_name_label'"></dd>
            <dt [textContent]="interviewDetails.consultantName"></dt>
          </dl>
          <dl>
            <dd class="label" [translate]="'consultant_phone_label'"></dd>
            <dt><a id="gtm_upcoming_interview_consultantPhone" [href]="'tel:'+interviewDetails.consultantPhone" [textContent]="interviewDetails.consultantPhone"></a></dt>
          </dl>
        </div>

      </div>
  </div>
  <div *ngIf="upcomingInterview.length==0">
    <p [translate]="'no_upcoming_interview_text'"></p>
  </div>
