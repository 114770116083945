<section>
  <div class="no-data-found error">
    <i class="icon-warning">
      <img src="assets/images/errors_icon.jpg">
    </i>
    <div *ngIf="query" id="recruiting-now-form">
      <div *ngIf="query.trainingRegister==='N' || query.orgName==='Incorrect'  || query.recruitingNow==='FAIL' || query.submitCV==='N' || query.getSavedAlert==='N' || query.getSavedJob==='N' ||
     query.jobApply==='N' || query.deleteJobAlert==='N' || query.gdprStatus==='FAIL' || query.emailConfirmation==='N' || query.changeEmail==='N' || query.changePassword==='N'">
        <div [translate]="'error_message_internal_error'"></div>
        <div [translate]="'error_page_info'"></div>
        <span [translate]="'error_page_search_jobs'"></span>
      </div>
      <div *ngIf="query.notFound==='404' ">
        <div [translate]="'error_page_sorry_message'"></div>
        <div> {{'error_page_info'|translate}} <a [translate]="'error_page_search_jobs'"></a></div>
      </div>
      <div *ngIf="query.erpExpire==='Y' ">
        <div [translate]="'error_page_erp_expire_message'"></div>
      </div>
      <div *ngIf="query.erpStatus==='FAIL' ">
        <div [translate]="'erp_service_fail_message'"></div>
        <a [href]="mailtoText+erpCustomerCareId" [textContent]="erpCustomerCareId"></a>
      </div>
      <div *ngIf="query.unsubscribe==='N' ">
        <div><span>{{'standardmsg_thankyou'|translate}} {{'alert_msg_unsubscribe'|translate}} {{'success_msg_on_err_page'|translate}} {{'error_page_search_jobs'|translate}} {{'login_form_register_text2'|translate}}
          <a id="gtm_unsubscribealert_login_redirect" [href]="loginUrl"
             [translate]="'success_msg_on_err_page1'"></a> {{'success_msg_on_err_page2' | translate}}</span></div>
      </div>
      <div *ngIf="query.activated==='E' ">
        <div><span>{{'alert_previously_active_text'|translate}}</span>
          <span
          *ngIf="_siteService.getDomain()!=='JP'">{{'alert_already_active_sub_text'|translate}}
          <a id="gtm_unsubscribealert_login_redirect1" [href]="loginUrl"
             [translate]="'success_msg_on_err_page1'"></a> {{'success_msg_on_err_page2' | translate}}</span>
          <span
            *ngIf="_siteService.getDomain()==='JP'">{{'alert_already_active_sub_text'|translate}} {{'login_form_register_text2'|translate}} {{'alert_previously_active_general'|translate}}
            <a id="gtm_unsubscribealert_login_redirect2" [href]="loginUrl" [translate]="'success_msg_on_err_page1'"></a></span>
        </div>
      </div>
      <div *ngIf="query.globalConsent==='N' || query.updateConsent==='N'">
        <span [translate]="'candidate_update_consent_error_text'"></span>
      </div>
    </div>
  </div>

</section>
