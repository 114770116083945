import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../../../global/services/site/site.service';

@Component({
  selector: 'app-jp-gdpr-thankyou',
  templateUrl: './jp-marketing-pref-thankyou.component.html',
  styleUrls: ['./jp-marketing-pref-thankyou.component.scss']
})
export class JpMarketingPrefThankyouComponent implements OnInit {

  constructor(private _translate: TranslateService,private  siteService: SiteService) {
    this._translate.setDefaultLang(this.siteService.getSiteLocale());
  }

  ngOnInit() {
  }

}
