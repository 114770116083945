import { Directive, HostListener, Input } from '@angular/core';

@Directive({ selector: '[socialShareType]' })

export class SocialShareDirective {

  @Input('socialShareType') socialShareType: string;

  @Input('shareUrl') shareUrl: string;
  @Input('title') title: string;


  @HostListener('click') onMouseEnter() {

    let url = encodeURIComponent(window.location.href);
    let title ='';
    if(this.shareUrl){
      url = encodeURIComponent(this.shareUrl);
    }
    if(this.title){
      title = encodeURIComponent(this.title);
    }
    const options = 'toolbar=0,status=0,width=640,height=480';

    switch (this.socialShareType) {
      case 'facebook':
        window.open(`https://www.facebook.com/sharer.php?t=${title}&u=${url}`,'',options);
        break;
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?text=${title}&url=${url}`,'',options);
        break;
      case 'linkedin':
        window.open(`https://www.linkedin.com/shareArticle?title=${title}&url=${url}`,'',options);
        break;
      default:
        break;
    }

  }
}
