<section class="all-office">
  <h2 [translate]="'your_nearest_offices'">
  </h2>
  <h3 *ngIf="officesOperatingHours">{{officesOperatingHours}}</h3>
  <app-custom-loader *ngIf="!dataFound"></app-custom-loader>
  <div class="row">
    <div class="col-12"  *ngIf="allOfficeList && allOfficeList.length>0">
      <ul class="office-address ca-address">
        <li *ngFor="let office of allOfficeList">
          <div class="office-address-cont">

            <div class="address">
              <h4 [textContent]="office.officeName"></h4>
              <div><a id="gtm_allofficepage_officeName" [href]="office.googleLink" target="_blank" [textContent]="office.address"></a></div>
            </div>
          </div>
          <div class="other-info">
            <dl  *ngIf="office.phone">
              <dt [translate]="'phone_text'"></dt>
              <dd><a id="gtm_allofficepage_phone" [href]="telText+office.phone"  [textContent]="office.phone"></a></dd>
            </dl>
            <dl *ngIf="office.email">
              <dt  [translate]="'email_text'"></dt>
              <dd> <a id="gtm_allofficepage_email"[href]="mailtoText+office.email"  [textContent]="office.email"></a></dd>
            </dl>
          </div>
          <div class="action-bar" *ngIf="office.googleLink">
            <a id="gtm_allofficepage_googlelink_officeName" class="find-location" title="view office"
               [href]="office.googleLink" target="_blank" [translate]="'view_on_google'"></a>
          </div>
        </li>

      </ul>
    </div>
  </div>

  <div *ngIf="allOfficeList.length===0 && dataFound">
    <p class="office-no-results-error" [translate]="'no_results_found'"></p>
  </div>
</section>










