import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {JpApplyComponent} from './components/jp-apply/jp-apply.component';
import {JpApplyOptionComponent} from './components/jp-apply-option/jp-apply-option.component';
import {JpThankYouComponent} from './components/jp-thank-you/jp-thank-you.component';
import {SendCvComponent} from './components/send-cv/send-cv.component';
import {SharedModule} from '../../shared.module';
import {SigninModule} from '../../../notsecure/candidate/signin/signin.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpLoaderFactory} from '../../../jobportal.module';
import {HttpClient} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {JpApplyConfirmationComponent} from './components/jp-apply-confirmation/jp-apply-confirmation.component';
import {JpApplicationProcessDetailsComponent} from './components/jp-application-process-details/jp-application-process-details.component';
import {JpScript} from '../../components/jp-scripts/jp-script';
import {JpSpanIndeed} from '../../components/jp-span-indeed/jp-span-indeed';
import {ApplyRoutingModule} from './apply-routing.module';
import {JobdetailModule} from '../jobdetail/jobdetail.module';

@NgModule({
  declarations: [JpApplyComponent,JpApplyOptionComponent,JpThankYouComponent,
    SendCvComponent, JpApplyConfirmationComponent, JpApplicationProcessDetailsComponent, JpScript,JpSpanIndeed],
    imports: [
      ApplyRoutingModule,
      CommonModule,
      SharedModule,
      SigninModule,
      JobdetailModule,
      FormsModule,
      ReactiveFormsModule,
      TranslateModule.forChild({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      })
    ],
  exports:[JpApplyComponent,JpApplyOptionComponent,SendCvComponent,JpThankYouComponent,JpApplyConfirmationComponent,JpApplicationProcessDetailsComponent,JpScript]
})
export class ApplyModule { }
