import {Adapter} from '../../../global/adapter/adapter';


export class CookiesSettings {
  constructor(public status: string,
              public message: string,
              public data: CookiesSettingsData[]) {

  }
}
export class CookiesSettingsData {
  constructor(
    public chromeCookieLink: string,
    public fireFoxCookieLink: string,
    public ieCookieLink: string,
    public isGDPRTrackingEnabled: boolean,
    public safariCookieLink: string
  ) {
  }

}


export class CookiesSettingsAdapt implements Adapter<CookiesSettings> {
  adapt(item: any): CookiesSettings {
    return new CookiesSettings(
      item.status,
      item.message,
      item.data
    );
  }
}
