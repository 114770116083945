import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page404hla',
  templateUrl: './page404hla.component.html',
  styleUrls: ['./page404hla.component.scss']
})
export class Page404hlaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
