import {Adapter} from '../../../global/adapter/adapter';

export class OfficeLocatorResult {
  constructor(public status: string,
              public message: string,
              public data: OfficeLocatorResultData[]) {

  }
}

export class OfficeLocatorResultData {
  constructor(
            public address: string,
            public googleAddressString: string,
            public googleLink: string,
            public phone: string,
            public officename: string,
            public sector: string,
            public distance: number,
            public isGoogleVisible: string,
            public isDistanceBoxVisible: string,
            public email: string

              ) {
  }

}
export class OfficeLocatorResultAdapt implements Adapter<OfficeLocatorResult> {
  adapt(item: any): OfficeLocatorResult {
    return new OfficeLocatorResult(
      item.status,
      item.message,
      item.data
    );
  }
}
