import { Component, OnInit } from '@angular/core';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../../../../environments/root/environment';
import { RequestService } from 'src/app/global/services/request/request.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SiteService } from 'src/app/global/services/site/site.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ForgotpassEmail} from 'src/app/jobportal/model/request/forgotpassEmail';
import { TranslateService } from '@ngx-translate/core';
import {SetTitleService} from '../../../../../service/set-title.service';
import {DataSharingService} from '../../../../../service/data-sharing.service';


@Component({
  selector: 'app-jp-forgot-password',
  templateUrl: './jp-forgot-password.component.html',
  styleUrls: ['./jp-forgot-password.component.scss']
})
export class JpForgotPasswordComponent implements OnInit {

  public emailForm: FormGroup;
  formSubmitted: boolean;
  public siteLocale: any;
  public patternValidation = false;
  query: any;
  expireFlag = false;
  failFlag = false;
  public disableButton: boolean;
  public trainingId: string="";
  public trainingUser: string="";
  private errorRoute: string;
  private loginRoute: string;
  private goLoginRoute: string;

  constructor(private _requestService: RequestService,private dataSharingService: DataSharingService,
     private _router: Router, public _site: SiteService, private _translate: TranslateService, private activateRoute: ActivatedRoute,private titleService:SetTitleService) {
      this.siteLocale = this._site.getSiteLocale();
      this._translate.setDefaultLang(this.siteLocale);
      this._translate.getTranslation(_site.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(res['forgot_password_page_title']);
        this.dataSharingService.getWidgetListName(keywords.forgetPasswordWidget);
    });
  }

  ngOnInit() {
    this.createFormGroup();
    this.activateRoute.queryParams.subscribe(params => {
      if (params) {
        this.query = params;
        this.trainingUser='N';
        if(params['trainingId'])
           this.trainingId=params['trainingId'];
        if(params['trainingUser'])
          this.trainingUser=params['trainingUser'];
        if (this.query.status === 'expired') {
          this.expireFlag = true;
        }
        if (this.query.status === 'failure') {
          this.failFlag = true;
        }
      }
    });
  }

  protected createFormGroup(): void {
    this.emailForm = new FormGroup({
      emailaddress: new FormControl('', {
        validators: [Validators.required, Validators.pattern(config.emailPattern)],
        updateOn: 'change'
      }
      )
    });
  }

  confirm() {
    this.formSubmitted = true;
    this.patternValidation = this.emailForm.invalid && (this.emailForm['controls'].emailaddress.errors.pattern || this.emailForm['controls'].emailaddress.errors.required) ? true : false;
    if (this.emailForm.valid) {
      this.disableButton=true;
      let payload = new ForgotpassEmail(
        this.emailForm.value.emailaddress,this.trainingUser,this.trainingId);
        this.submit(payload);
    }
  }

  submit(payload) {
    this.getRouteUrls();
    this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.submitForgetPassEmail, payload).subscribe(response => {
      if (response.status === config.SUCCESS) {
        this.disableButton=false;
        if(this.trainingUser=='N') {
          this._router.navigate([this.loginRoute], { queryParams: {status: 'forgotSuccess'}});
        }
        else {
          this._router.navigate([this.goLoginRoute], { queryParams: {trainingId: this.trainingId, status: 'forgotSuccess'}});
        }
      }
      else {
        this.disableButton=false;
        this._router.navigate([this.errorRoute], { queryParams: { status: 'N', error: response.data } });
      }
    }, (error) => {
      this.disableButton=false;
      this._router.navigate([this.errorRoute], { queryParams: { status: 'N', error: error.status } });
    });
  }

  getRouteUrls(){
    let orgName = this._router.routerState.snapshot.root.firstChild.params["orgName"];
    let base='';
    if(orgName){
      base= environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url_org];
      this.errorRoute = (base[routingEndpoint.errorPage]).replace(':orgName',orgName);
      this.loginRoute = (base[routingEndpoint.login]).replace(':orgName',orgName);
      this.goLoginRoute = base[routingEndpoint.goLoginPage];
      if(this.goLoginRoute)
      this.goLoginRoute = (base[routingEndpoint.goLoginPage]).replace(':orgName',orgName);
    }else{
      base= environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url];
      this.errorRoute = base[routingEndpoint.errorPage];
      this.loginRoute = base[routingEndpoint.login];
      this.goLoginRoute = base[routingEndpoint.goLoginPage];
    }
  }

}
