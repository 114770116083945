import {Component, Input, OnInit} from '@angular/core';
import {ExtraFieldsConfig, GoogleJobResponse} from '../../../../../model/response/google-job-response';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {TranslateService} from '@ngx-translate/core';
import {JobSearchService} from '../../../../../service/job-search.service';
import {apiEndPoints, config, environment, routingEndpoint} from '../../../../../../../environments/root/environment';
import {CookieService} from 'ngx-cookie-service';
import {TokenStorage} from '../../../../../../global/services/tokenStorage/token-storage';
import {ActivatedRoute, Router} from '@angular/router';
import {RequestService} from 'src/app/global/services/request/request.service';
import {SetTitleService} from '../../../../../service/set-title.service';
import {DataSharingService} from '../../../../../service/data-sharing.service';
import {DeviceDetectorService} from 'ngx-device-detector';


@Component({
  selector: 'app-jp-job-description',
  templateUrl: './jp-job-description.component.html',
  styleUrls: ['./jp-job-description.component.scss']
})
export class JpJobDescriptionComponent implements OnInit {
  @Input() googleJobResponse : GoogleJobResponse[];
  public userAgent: string;
  public config: ExtraFieldsConfig;
  public hostName: string;
  public orgName: string;
  public liferayBaseUrl: string;
  public saveFlag:boolean;
  singleJobData: any;
  public buttonClicked: boolean;
  public saveButtonClicked: boolean;
  /**
   * Determines whether save/saved button need to be displayed in UI
   */
  public saveFunctionalityDisabled : boolean = false;
  /**
   * Collection of org names for which save functionality is disabled
   */
  private saveFunctionalityDisabledOrgsCollection = ['geniuswithin'];

  constructor(private siteService:SiteService,private dataSharingService : DataSharingService, private translaService:TranslateService,private jobSearchService:JobSearchService,
              private cookieService:CookieService,private tokenStorage:TokenStorage,private router:Router,private activatedRoute:ActivatedRoute,
              private _requestService: RequestService, private _router: Router, private titleService:SetTitleService,private deviceDetector:DeviceDetectorService) {
    this.translaService.setDefaultLang(this.siteService.getSiteLocale());
    this.orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    if(this.orgName === 'yourrailindustryjob' || this.orgName === 'geniuswithin'){
      this.liferayBaseUrl=environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.liferayclientBaseUrl];
    }
    else{
      this.liferayBaseUrl=environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.liferaybaseUrl];
    }
  }

  ngOnInit() {
    this.translaService.getTranslation(this.siteService.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(this.googleJobResponse[0].JobTitle+' '+res['page_title']);
    });
    this.hostName=window.location.host;
    this.userAgent =this.deviceDetector.isMobile()?'-Mobile':this.deviceDetector.isDesktop()?'-Desktop':'-Tablet';
    this.jobSearchService.sharedConfigList.subscribe(config=>this.config=config);
       this.getSingleJob();
    this.determineIsSaveFunctionalityDisabled();
  }

  formatDate(formatDate: any) {
    formatDate = formatDate.year+'-'+('0' + (formatDate.month)).slice(-2) + '-' +('0' + formatDate.day).slice(-2);
    return formatDate;
  }

  redirectToApplicationUrl(applicationURL: string) {
    window.location.href=applicationURL;
  }

  redirectToApply(applyId:string,jobName:string) {
    this.buttonClicked=true;
    let applyUrl= '';

    if(this.tokenStorage.getLoggedInToken())
      applyUrl = this.getApplyUrl(routingEndpoint.secureJobApply);
        //applyUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.secureJobApply];
    else
      applyUrl = this.getApplyUrl(routingEndpoint.jobApply);
        //applyUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.jobApply];

    let keyWord='';
    let jobSource ='';
    let locationParam='';
    let specialismId='';
    let subSpecialismId='';
    let defaultJobSource='';
    this.dataSharingService.sharedClientConfig.subscribe(res => defaultJobSource = res.jobSource );

    this.activatedRoute.queryParamMap.subscribe(param=>{
      if(param.get('q')){
        keyWord = param.get('q');
      }
      jobSource=param.get('jobSource')?param.get('jobSource'):param.get('jobsource')?param.get('jobsource'):param.get('source')?param.get('source'):defaultJobSource;
      if(param.get('location')){
        locationParam = param.get('location');
      }
      if(param.get('specialismId')){
        specialismId = param.get('specialismId');
      }
      if(param.get('subSpecialismId')){
        subSpecialismId = param.get('subSpecialismId');
      }
    });
    let urlFinal='';
    let isExpertClient;
    // let urlFinal=config.baseWebAppUrl+applyUrl+'?q='+keyWord+'&jobName='+decodeURIComponent(jobName)+'&applyId='+applyId+'&jobSource='+jobSource+'&specialismId='+specialismId+'&subSpecialismId='+subSpecialismId;
    if(applyUrl.indexOf(':recordId')>-1)
      urlFinal=window.location.origin+applyUrl.replace(':recordId',applyId)+'?q='+keyWord+'&jobName='+decodeURIComponent(jobName)+'&applyId='+applyId+'&jobSource='+jobSource+'&specialismId='+specialismId+'&subSpecialismId='+subSpecialismId;
    else
      urlFinal=config.baseWebAppUrl+applyUrl+'?q='+keyWord+'&jobName='+decodeURIComponent(jobName)+'&applyId='+applyId+'&jobSource='+jobSource+'&specialismId='+specialismId+'&subSpecialismId='+subSpecialismId;
      config.ExpertClientsName.forEach(a => { if (applyUrl.includes(a)) isExpertClient = true; })
      config.ExpertClientsCode.forEach(a => { if (applyUrl.includes(a)) isExpertClient = true; })
        if (isExpertClient)
          window.location.href = urlFinal + '&showSendCV=N&showApplyForm=true';
        else
          window.location.href = urlFinal;

    }

    getApplyUrl(endpointUrl){
      let orgName = this._router.routerState.snapshot.root.firstChild.params["orgName"]
      let applyurl ='';
      if(orgName){
        let url =environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][endpointUrl];
        applyurl = url.replace(':orgName',orgName);
      }
      else
        applyurl=environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][endpointUrl];
      return applyurl;
    }

  saveJob(){
    this.saveButtonClicked=true;
    let payload = {
      jobRef:this.googleJobResponse[0].jobRef,
      jobTitle:this.googleJobResponse[0].JobTitle,
      jobURL: this._router.url.substring(0,this._router.url.indexOf("?")),
      jobId : this.googleJobResponse[0].jobId,
      locations : this.googleJobResponse[0].locationDesc,
      isSponsored : this.googleJobResponse[0].isSponsored,
      clientLogoUrl:this.googleJobResponse[0].clientLogoUrl,
      salaryDescription:this.googleJobResponse[0].incentives,
      expirydate: this.googleJobResponse[0].endDate
    };
    this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI,apiEndPoints.saveJob,payload ).subscribe(response => {
      if (response.status === config.SUCCESS) {
        this.saveFlag = true;
        this.saveButtonClicked=false;
      }
    }, (error) => {
      this.saveButtonClicked=false;
    });
  }

  deleteJob(){
    let payload = {jobRef:this.googleJobResponse[0].jobRef};
    let baseRoute = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
    this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI,apiEndPoints.deleteJob,payload ).subscribe(response => {
      if (response.status === config.SUCCESS) {
        this.saveFlag = false;
      }
    }, (error) => {
    });
  }

  getSingleJob(){
    this._requestService.getRequest(config.theme, apiEndPoints.gateWayAPI,apiEndPoints.getSingleJob,{'jobref':this.googleJobResponse[0].jobRef})
    .subscribe(response => {
      if(response.status === config.SUCCESS) {
        this.singleJobData = response.data;
        if(this.singleJobData.jobRef === this.googleJobResponse[0].jobRef){
          this.saveFlag = true;
        }
      }
    },error => {
      this.saveFlag = false;
     /* this._router.navigate([environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.errorPage]],
        {queryParams:{getSavedJob:'N'}})
  */
    });
  }


  removeHex(data: any) {
    data=data.replace(/&#.{2};/g, ' ');
    if(data.indexOf('#')>-1){
      data = data.substring(0, data.lastIndexOf("#"));
    }
    return data;
  }
  /**
   * Methos to check if the org exists in the collection
   */
  private determineIsSaveFunctionalityDisabled(){
    if(this.saveFunctionalityDisabledOrgsCollection.indexOf(this.orgName) > -1) {
      this.saveFunctionalityDisabled = true;
    }
  }
}
