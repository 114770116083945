import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {JobPortalRouter} from './jobportal-route';
import {ErrorPagesComponent} from '../global/components/shared/error-pages/error-pages.component';
import {SuccessPagesComponent} from '../global/components/shared/success-pages/success-pages.component';


const routes: Routes = [
  {path:'',
  children:[
    { path: JobPortalRouter.secure_route_link, loadChildren: () => import('./secure/secure.module').then(m => m.SecureModule)},
    { path: JobPortalRouter.notsecure_route_link,  loadChildren: () => import('./notsecure/notsecure.module').then(m=>m.NotsecureModule)},

  ]},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JobportalRoutingModule { }
