import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../../../environments/root/environment';
import {ChangeEmailRequest} from '../../../../model/request/changeEmailRequest';
import {RequestService} from '../../../../../global/services/request/request.service';
import {TokenStorage} from '../../../../../global/services/tokenStorage/token-storage';
import {DataSharingService} from '../../../../service/data-sharing.service';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../../../global/services/site/site.service';
import {SetTitleService} from '../../../../service/set-title.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-jp-change-email',
  templateUrl: './jp-change-email.component.html',
  styleUrls: ['./jp-change-email.component.scss']
})
export class JpChangeEmailComponent implements OnInit {

  public changeEmailForm: FormGroup;
  public errorMsgShow=false;
  public emailNotMatchError=false;
  public captchaShow=false;
  public isCaptchaDisable=false;
  public passwordIncorrectError=false;
  public disableBtn=false;
  public existingMsgError=false;
  public invalidParamMsgError=false;
  public loader=false;
  public isNotOnlineUser=false;

  constructor(private requestService:RequestService,private dataSharingService : DataSharingService, private tokenStorage:TokenStorage, private dataSharing:DataSharingService,private translate:TranslateService,private _siteService:SiteService,private titleService:SetTitleService,private _router:Router) {
    this.translate.setDefaultLang(this._siteService.getSiteLocale());
    this.translate.getTranslation(_siteService.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(res['change_email_page_title']);
    });
    this.dataSharingService.getWidgetListName(keywords.changeEmailWidget);
  }

  ngOnInit() {
    this.changeEmailForm=new FormGroup({
      reCaptchaReactive: new FormControl(''),
      newEmail:new FormControl('', [Validators.email,Validators.pattern(config.emailPattern)]),
      reEnterNewEmail:new FormControl('',[Validators.email,Validators.pattern(config.emailPattern)]),
      confirmPassword: new FormControl('', Validators.required)
    })
  }

  ngDoCheck(){
    if(localStorage.getItem('disableCaptcha')=='true')
      this.isCaptchaDisable=true;
  }

  get f() {
    if(this.captchaShow){
      if(this.changeEmailForm.value.newEmail && this.changeEmailForm.value.reEnterNewEmail && this.changeEmailForm.value.confirmPassword && this.changeEmailForm.value.reCaptchaReactive)
        this.errorMsgShow=false;
    }else if(this.changeEmailForm.value.newEmail && this.changeEmailForm.value.reEnterNewEmail && this.changeEmailForm.value.confirmPassword)
      this.errorMsgShow=false;
    return this.changeEmailForm.controls;
  }

  submitChangeEmail(){
    this.existingMsgError=false;
    this.emailNotMatchError=false;
    this.passwordIncorrectError=false;
    this.errorMsgShow=false;
    this.invalidParamMsgError=false;
    this.validations();
    if(this.changeEmailForm.valid && !this.errorMsgShow && !this.emailNotMatchError) {
      this.disableBtn=true;
      this.loader=true;
      let captchaValue='';
      let captchaValidate=false;
      if(this.captchaShow && !this.isCaptchaDisable){
        captchaValue=this.changeEmailForm.value.reCaptchaReactive;
        captchaValidate=true;
      }
      let changeEmailRequest = new ChangeEmailRequest(
        this.changeEmailForm.value.reEnterNewEmail,
        btoa(this.changeEmailForm.value.confirmPassword),
        this.changeEmailForm.value.reCaptchaReactive,
        captchaValidate
      );
      let orgName = this._router.routerState.snapshot.root.firstChild.params["orgName"];
      let baseRoute= '';
      if(orgName)
         baseRoute = (environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.errorPage]).replace(':orgName',orgName);
      else
         baseRoute = environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.errorPage];

      this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.changeEmail, changeEmailRequest)
        .subscribe(res => {
          this.loader=false;
          if(res.status == config.SUCCESS){
            if(res.data['statuscode'] == config.portalSuccessCode) {
              let isAzureEnabled = environment[config.theme + this._siteService.getSiteLocale()]['config']['azure_enable']?environment[config.theme + this._siteService.getSiteLocale()]['config']['azure_enable']:false;
              if (isAzureEnabled) {
                this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.logoutUser, null, null).pipe().subscribe(response => {
                  this.tokenStorage.loggedInSignOut(keywords.changeEmail, 'Y', this.dataSharing, this.requestService, 'loggedin', 0);
                }, error => {
                  this.tokenStorage.loggedInSignOut(keywords.changeEmail, 'Y', this.dataSharing, this.requestService, 'loggedin', 0);
                });
              } else {
                this.tokenStorage.loggedInSignOut(keywords.changeEmail, 'Y', this.dataSharing, this.requestService, 'loggedin', 0);
              }

            }
            if(res.data['statuscode'] == config.existingEmailAddress) {
              this.changeEmailForm.reset();
              this.existingMsgError=true;
              this.disableBtn=false;
            }
            if(res.data['statuscode'] == config.invalidParamCode){
              this.changeEmailForm.reset();
              this.invalidParamMsgError=true;
              this.disableBtn=false;
            }
            if (res.data['statuscode'] == config.passwordIncorrect || res.data['statuscode'] == config.loginCounterCode){
              if(res.data['Counter']==3){
                this.changeEmailForm.reset();
                this.captchaShow=true;
                this.passwordIncorrectError=true;
                this.disableBtn=false;
              }else if(res.data['Counter']===5){
                this.tokenStorage.loggedInSignOut(keywords.changeEmail,'N',this.dataSharing,this.requestService,'loggedin',0);
              }else{
                this.captchaShow=false;
                this.changeEmailForm.reset();
                this.passwordIncorrectError=true;
                this.disableBtn=false;
              }
            }
            if(res.data == config.ALREADY_EXIST){
              this.isNotOnlineUser=true;
              this.disableBtn=false;
              this.changeEmailForm.reset();
            }
          }
          else{
            this._router.navigate([baseRoute],{queryParams:{changeEmail:'N'}});
          }
        },(error) => {
            this._router.navigate([baseRoute],{queryParams:{changeEmail:'N'}});
        });
    }
  }

  validations(){
    if(!this.changeEmailForm.value.newEmail || !this.changeEmailForm.value.reEnterNewEmail || !this.changeEmailForm.value.confirmPassword){
      this.errorMsgShow=true;
      return false;
    }
    if(this.changeEmailForm.value.newEmail!=this.changeEmailForm.value.reEnterNewEmail)
      this.emailNotMatchError=true;

    if(this.captchaShow && !this.changeEmailForm.value.reCaptchaReactive){
      this.errorMsgShow=true;
      return false;
    }
  }

}
