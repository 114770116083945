<div *ngIf = "modalInnerText" class="modal fade terms-conditions-popup" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="card-img-overlay"></div>
    <div class="modal-dialog modal-dialog-centered " role="document">
      <div class="modal-content">
        <div class="modal-body">
          <a href="javascript:void(0)" id="closePolicy" class="close" (click) = "closePopUp()">+</a>
          <p [innerHTML] = "modalInnerText"></p>  
        </div>
        <div class="modal-footer">
          <button id="gtm_remove_commnt_ok_btn" type="button" (click) = "closePopUp()"
                  [ngClass]="'btn btn-primary'"
                  [translate]="'remove_comment_ok'"></button>
        </div>
      </div>
  </div>
  </div>