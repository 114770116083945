<div class="cv-tips">
  <h3 [translate]="'interview_cv_tips'"></h3>
  <ul>
    <li>
      <a id="gtm_interviewtips_liferaylink" [href]="liferayBaseUrl+interviewCvTipsLink['interviewTipsLink']" [translate]="'interview_advice_link_text'"></a>
    </li>
    <li>
      <a id="gtm_cvtips_liferaylink" [href]="liferayBaseUrl+interviewCvTipsLink['cvTipsLink']" [translate]="'view_cv_tips_link_text'"></a>
    </li>
  </ul>
</div>
