export const baseUrl = {
    apiBaseUrl:'https://moatapi.hays.com/jobportalapi'
  };
  export const en_PL = {
    config:{
      countryCode:', Poland',
      country_name: 'Poland',
      gtm_code:"GTM-58F3TQ",
      timesheetUrl:"http://www.hays.pl/en/hays-tempzone",
      salaryGuideUrl:"http://salaryguide.hays.pl/",
      view_google_link:'https://www.google.pl/maps?q=',
      zopim_enabled:true,
      zopim_enabled_device:'-Desktop',
      zopim_key_withURL:"//v2.zopim.com/?2SfCzrC8fQC6u5Mn6KEr6KMcqQoU4nJL",
      CDN_OPTIMIZELY_URL:"",
      maximumSize:2097152,
      appleItunesMetaEnabled: true,
      azure_enable:true,
      showSearch:false,
      auzureLoginURL : 'https://moat.hays.pl/prjsauthapp/authurl/JobPortal/{orgName}/candidate/pl/en',
      dynamicPageTitleMetaTags: true,
      canonicalSearchLinkCompleteURL: true,
      isLegalSentenceEnabled: true
    },
    liferaybaseUrl: 'https://testsg.hays.pl',
    liferayUrl: 'https://testsg.hays.pl/en',
    cookieDomain:'.hays.pl',
    cookieBannerDomain:'.hays.pl',
    siteBaseURL:'https://moat.hays.pl',
    gateWayAPI:{
      location: baseUrl.apiBaseUrl+':domain/:locale/:organisation/s/candidate/submit/location',
      isCaptchaDisabled : baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/partner/browse/v1/captchaenabled',
      locationOnRecruitingNow:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/recruiting/browse/v1/location?type=recruiting-now-locations',
      employeeType:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/recruiting/browse/v1/employment?type=recruiting-now-employment',
      submitRecruitingForm:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/content/submit/v1/recruitingnow',
      getSector: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/content/browse/v1/specilisms', // post call for getting list of sectors
      getLocationHierarchy :  baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/content/browse/v1/hierarchy',
      getStateList : baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/content/browse/v1/states',
      getOfficeForSpecialism: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/content/browse/v1/offices',
      getSubSpecialism: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/content/browse/v1/subspecialism ',
      getAllOffice: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/content/browse/v1/alloffices',
      getOfficeResultForAPAC: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/content/browse/v1/officeresultapac',
      getLatLongOfficeResult: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/content/browse/v1/officeresultlatlong',
      isAutoSugesstionEnabled: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/google/browse/v1/isautosugesstionenabled',
      getTermsAndConditions: baseUrl.apiBaseUrl + '/int/s/pl/en/{orgname}/recruiting/browse/v1/consentcheckbox?type=consent_checkbox',
      getGDriveAndDropbox: baseUrl.apiBaseUrl + '/int/s/pl/en/{orgname}/partner/browse/v1/isdropboxgdrivedisabled',
      getIsKanaEnabled: baseUrl.apiBaseUrl + '/int/s/pl/en/{orgname}/partner/browse/v1/iskanaenabled',
      register: baseUrl.apiBaseUrl +'/int/s/pl/en/{orgname}/candidate/submit/v1/register',
      pagemanagement:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/pagemanagement/browse/v1/pagemenu',
      countryLanguage:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/site/browse/v1/getlanguages?type=change_language',
      getBrowseByExpertise:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/content/browse/v1/browsebyexpertise',
      showOfficeEmail:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/content/browse/v1/showofficeemail',
      connectdropbox:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/partner/browse/v1/connectdropbox',
      connectGdrive:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/partner/browse/v1/connectgdrive',
      getDropBoxFiles:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/partner/browse/v1/dropboxfiles',
      getDriveFiles:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/partner/browse/v1/gdrivefiles',
      getFileLink:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/partner/browse/v1/dropboxfilelink',
      gdriveFileLink:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/partner/browse/v1/gdrivefilelink',
      getAutoSuggetion:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/job/browse/v1/autosuggestion?keyWord=',
      submitEmail:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/gdpr/submit/emailconfirmation',
      getgooglejobs:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/job/browse/v1/jobsv2',
      getgooglejobssecure:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/job/browse/v1/jobsv2',
      jobflowconfig:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/pagemanagement/browse/v1/jobflowconfig',
      getJobFilters:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/job/browse/v1/filters?type=job_filter',
      getCustomerServiceEmail: baseUrl.apiBaseUrl + '/int/s/pl/en/{orgname}/pagemanagement/browse/v1/customeremail',
      pagemanagementSecure:baseUrl.apiBaseUrl +'/int/s/pl/en/{orgname}/pagemanagement/browse/v1/pagemenu',
      createToken:baseUrl.apiBaseUrl+'/ext/ns/pl/en/{orgname}/usermanagement/create/v1/token',
      validatePage:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/usermanagement/validate/v1/page',
      submitForgetPassEmail: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/candidate/submit/v1/forgetpassword',
      getInterviewAndCvTips: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/candidate/browse/v1/interviewandcvtips',
      getRecentApplication: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/candidate/browse/v1/recentapplication',
      getInterviewCount: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/notification/browse/v1/interview',
      getUpcomingInterview: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/candidate/browse/v1/interviewdetails',
      getpersonaldetails: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/candidate/browse/v1/getpersonaldetailsupgrade',
      getGDriveFiles: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/partner/browse/v1/gdrivefiles',
      gcsPublish:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/job/submit/v1/gcsevent',
      saveAlert:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/job/create/v1/alert',
      saveWhatsapp:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/job/submit/v1/whatsapp',
      updateWhatsapp:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/candidate/update/v1/whatsappnumber',
      getWhatsappNumber:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/browse/v1/whatsappnumber',
      getWhatsAppAlerts:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/decrypt/v1/whatsappalert',
      getJobAlerts:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/job/browse/v1/alert',
      getGdprConfig:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/pagemanagement/browse/v1/gdprconfig',
      getMarketingPreferences:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/gdpr/browse/v1/marketingpreferences',
      updateMarketingPreferences:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/gdpr/update/v1/marketingpreferences',
      decryptIdentifier:baseUrl.apiBaseUrl+'/int/ns/pl/en/{orgname}/decrypt/browse/v1/decryptidentifier',
      saveJob: baseUrl.apiBaseUrl + '/int/s/pl/en/{orgname}/job/create/v1/savejob',
      deleteJob: baseUrl.apiBaseUrl + '/int/s/pl/en/{orgname}/job/delete/v1/deletesavedjob',
      getSavedJobs: baseUrl.apiBaseUrl + '/int/s/pl/en/{orgname}/job/browse/v1/getsavedjobs',
      resetPassword:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/candidate/submit/v1/resetpassword',
      deleteJobAlert:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/job/delete/v1/alert',
      editAlert:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/job/update/v1/alert',
      changeEmail:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/candidate/submit/v1/changeemail',
      getCvs:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/candidate/browse/v1/getcvs',
      downloadCV:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/candidate/browse/v1/downloadcv',
      deleteCv:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/candidate/delete/v1/deletecv',
      getSingleJob:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/job/browse/v1/getsavedjob',
      changePassword:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/candidate/submit/v1/changepassword',
      uploadcv:baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/candidate/upload/v1/cv',
      jobApply: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/candidate/submit/v1/jobapply',
      unsubscribe: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/alert/v1/unsubscribe',
      jobAlertVerify: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/alert/v1/verify',
      applyLinkedin: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/job/v1/applylinkedin',
      getWidgetList: baseUrl.apiBaseUrl+'/int/ns/pl/en/{orgname}/content/browse/v1/widgetlist',
      getTimeSheet: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/auth/browse/v1/pagemenu',
      getKillerQuestions: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/content/browse/v1/killerquestions',
      dmsDecryptIdentifier: baseUrl.apiBaseUrl+'/int/ns/pl/en/{orgname}/candidate/v1/dmsDecryptIdentifier',
      submitDMSAlert: baseUrl.apiBaseUrl+'/int/ns/pl/en/{orgname}/candidate/v1/submitDMSAlert',
      validateAlert: baseUrl.apiBaseUrl+'/int/ns/pl/en/{orgname}/alert/v1/validatealert',
      viewEmailLink:baseUrl.apiBaseUrl+'/int/ns/pl/en/{orgname}/alert/v1/viewmaillink',
      marketingConsent: baseUrl.apiBaseUrl+'/int/s/pl/en/{orgname}/consent/browse/v1/marketingconsent',
      getClientConfig:baseUrl.apiBaseUrl+'/int/ns/pl/en/{orgname}/client/browse/v1/clientconfig',
      logoutUser:baseUrl.apiBaseUrl+'/ext/ns/pl/en/{orgname}/auth/delete/v1/session',
    },
    routing_url:{
      errorPage:'/s/error/',
      successPage:'/s/success/',
      officeLocatorResultPage:'/office-locator/result',
      officeLocatorPage:'/all-offices',
      speculativeCV:'/speculativeCV',
      myProfile:'/s/my-profile',
      login:'/login',
      search:'/search',
      secureSearch:'/search',
      secureJobDetails:'/Job/Detail',
      jobdetails:'/Job/Detail',
      forgetPassword:'/login/forgot-password',
      header:'/PL/en/header',
      footer:'/PL/en/footer',
      recordIdJobDetail:'/PL/en/jobportal/s/candidate/job/browse/details/',
      jobApply:'/job/:recordId/apply',
      secureJobApply:'/job/:recordId/apply',
      gdprThankyouPage:'/marketing-preferences/thankyou',
      marketingPrefPage:'/marketing-preferences',
      resetPasswordPage:'/reset',
      manageCV:'/s/manage-cv',
      saveWhatsappPage:'/s/save-whatsapp',
      updateWhatsappPage:'/s/update-whatsapp',
      secureThankYou:'/job/apply/details',
      thankyou:'/job/apply/details',
      unsubscribePage:'/prjs/PL/en/jobportal/as/alert/submit/unsubscribe',
      alertThankYouPage:'/job/VerifyAlert/success',
      emailConfirmationPage:'/prjs/PL/en/jobportal/as/gdpr/submit/email-confirmation',
      recruitingNow:'/recruiting-now',
      secureRecruitingNow:'/recruiting-now',
    },
    routing_url_org:{
      errorPage:'/:orgName/s/error/',
      successPage:'/:orgName/s/success/',
      officeLocatorResultPage:'/:orgName/office-locator/result',
      officeLocatorPage:'/:orgName/all-offices',
      speculativeCV:'/:orgName/speculativeCV',
      myProfile:'/:orgName/s/my-profile',
      login:'/:orgName/login',
      search:'/:orgName/search',
      secureSearch:'/:orgName/search',
      secureJobDetails:'/:orgName/Job/Detail',
      jobdetails:'/:orgName/Job/Detail',
      forgetPassword:'/:orgName/login/forgot-password',
      header:'/:orgName/PL/en/header',
      footer:'/:orgName/PL/en/footer',
      recordIdJobDetail:'/:orgName/PL/en/jobportal/s/candidate/job/browse/details/',
      jobApply:'/:orgName/job/:recordId/apply',
      secureJobApply:'/:orgName/job/:recordId/apply',
      gdprThankyouPage:'/:orgName/marketing-preferences/thankyou',
      marketingPrefPage:'/:orgName/marketing-preferences',
      resetPasswordPage:'/:orgName/reset',
      manageCV:'/:orgName/s/manage-cv',
      saveWhatsappPage:'/:orgName/s/save-whatsapp',
      updateWhatsappPage:'/:orgName/s/update-whatsapp',
      secureThankYou:'/:orgName/job/apply/details',
      thankyou:'/:orgName/job/apply/details',
      unsubscribePage:'/:orgName/prjs/PL/en/jobportal/as/alert/submit/unsubscribe',
      alertThankYouPage:'/:orgName/job/VerifyAlert/success',
      emailConfirmationPage:'/:orgName/prjs/PL/en/jobportal/as/gdpr/submit/email-confirmation',
      recruitingNow:'/:orgName/recruiting-now',
      secureRecruitingNow:'/:orgName/recruiting-now',
    }

  };


