<div class="bg-white job-summary job-box">
  <h3 [translate]="'Summary_text'"></h3>
  <div class="hays-box-content" *ngIf="jobSummary && jobSummary.length>0">
    <dl>
      <dt>{{('job_type_label'|translate)}}</dt>
      <dd id="jd_type"
          [translate]="jobSummary[0].jobType==='C'?'C':jobSummary[0].jobType==='P'?'P':jobSummary[0].jobType==='T'?'T':''"></dd>
      <div  *ngIf="config && config.isWorkingPattern && jobSummary[0].flexibleWorking.toLowerCase()==='true'|| jobSummary[0].partTime.toLowerCase()==='true' || jobSummary[0].fullTime.toLowerCase()==='true'">
        <dt [translate]="'flexible_working_header'"></dt>
        <dd id="jd_flexible">{{((jobSummary[0].flexibleWorking.toLowerCase()==='true'?'flexible_flexible_tab':'') | translate)
                              +(jobSummary[0].flexibleWorking.toLowerCase()==='true'?',':'')
                              +((jobSummary[0].partTime.toLowerCase()==='true'?'flexible_temp_tab':'') | translate) 
                              + (jobSummary[0].partTime.toLowerCase()==='true'?',':'')
                              +((jobSummary[0].fullTime.toLowerCase()==='true'?'flexible_full_tab':'')| translate)}}
        </dd>
      </div>
      <dt [translate]="'job_industry_label'"></dt>
      <dd id="jd_industry" [textContent]="jobSummary[0].industry"></dd>
      <dt [translate]="'job_location_label'"></dt>
      <dd id="jd_location" [textContent]="jobSummary[0].locationDesc"></dd>
      <dt [translate]="'job_specialism_label'"></dt>
      <dd id="jd_specialism" [textContent]="jobSummary[0].specialism"></dd>
      <dt [translate]="'job_pay_label'" *ngIf="jobSummary[0].incentives"></dt>
      <dd id="jd_salary" [textContent]="jobSummary[0].incentives" *ngIf="jobSummary[0].incentives"></dd>
      <dt [translate]="'job_ref_label'"></dt>
      <dd id="jd_reference" [textContent]="jobSummary[0].jobRef"></dd>
      <dt [translate]="'job_closing_date_label'" *ngIf="jobSummary[0].showClosingDate && jobSummary[0].endDate"></dt>
      <dd id="jd_closingdate" *ngIf="jobSummary[0].showClosingDate && jobSummary[0].endDate" [textContent]="formatDate(jobSummary[0].endDate) | date:'d MMM y'"></dd>
    </dl>
  </div>
  <div id = 'legal-sentence-enabled' *ngIf = 'isLegalSentenceEnabled' [translate] = "'job_desc_summary_legal_sentence'">

  </div>
  <div class="action-bar">
    <button id="gtm_jobSummary_apply_button" [ngClass]="buttonClicked?'btn btn-default btn-block btn-loading':'btn btn-default btn-block'" [disabled]="jobSummary[0].isJobApplied || buttonClicked" *ngIf="jobSummary && jobSummary.length>0"
            [translate]="jobSummary[0].isJobApplied?'apply_button_text_disabled':'job_apply_label'"
            (click)="jobSummary[0].applicationURL?redirectToApplicationUrl(jobSummary[0].applicationURL):redirectToApply(jobSummary[0].jobId,jobSummary[0].jobName)"></button>
  </div>
</div>
