import { Component, ElementRef, ViewChild, Input } from '@angular/core';

declare function onProfileData(): void;

@Component({
  selector: 'jp-script',
  template: `<div #script style.display="none">
              <ng-content></ng-content>
            </div>`
})
export class JpScript {

  @Input()
  src: string;

  @Input()
  type: string;

  @Input()
  dataCompanyJobCode: string;

  @Input()
  dataIntegrationContext: string;

  @Input()
  dataMode: string;

  @Input()
  dataCallbackMethod: string;

  @Input()
  dataApiKey: string;

  @Input()
  dataAllowSignIn: string;

  @Input()
  dataSize: string;

  @ViewChild('script', {static: false}) script: ElementRef;

  convertToScript() {
    let element = this.script.nativeElement;
    let script = document.createElement("script");
    script.type = this.type ? this.type : "text/javascript";
    if (this.src)
      script.src = this.src;
    if (element.innerHTML)
      script.innerHTML = element.innerHTML;

    if(this.dataCompanyJobCode)
      script.setAttribute('data-company-job-code', this.dataCompanyJobCode);
    if(this.dataIntegrationContext)
      script.setAttribute('data-integration-context', this.dataIntegrationContext);
    if(this.dataMode)
      script.setAttribute('data-mode', this.dataMode);
    if(this.dataCallbackMethod)
      script.setAttribute('data-callback-method', this.dataCallbackMethod);
    if(this.dataApiKey)
      script.setAttribute('data-api-key', this.dataApiKey);
    if(this.dataAllowSignIn)
      script.setAttribute('data-allow-sign-in', this.dataAllowSignIn);
    if(this.dataSize)
      script.setAttribute('data-size', this.dataSize);

    let parent = element.parentElement;
    parent.parentElement.replaceChild(script, parent);
  }

  ngAfterViewInit() {
    this.convertToScript();
  }
}
