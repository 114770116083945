import {Component, OnInit} from '@angular/core';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../../../environments/root/environment';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../../../global/services/site/site.service';
import {RequestService} from '../../../../../global/services/request/request.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UnsubscribeRequest} from '../../../../model/request/unsubscribe-request';

@Component({
  selector: 'app-jp-unsubscribe',
  templateUrl: './jp-unsubscribe.component.html',
  styleUrls: ['./jp-unsubscribe.component.scss']
})
export class JpUnsubscribeComponent implements OnInit {
private identifier: string=null;
private signedUrl: string=null;
public loginUrl: string;
public email: string;
public showPrivacyPolicy: boolean;
public privacyPolicyLink: string;
  public unsubscribeBtnClicked: boolean;
  constructor(private _translate: TranslateService, private activatedRoute: ActivatedRoute,private siteService: SiteService, private requestService: RequestService, private router: Router) {
    this.activatedRoute.queryParams.subscribe((params)=> {
      if(params['signedurl']) {
        this.signedUrl=params['signedurl'];

      }
    });
    this._translate.setDefaultLang(this.siteService.getSiteLocale());
    this.loginUrl= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.login];
    this.showPrivacyPolicy=false;
  }

  ngOnInit() {
    let liferayBaseUrl = environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.liferaybaseUrl];
    this.activatedRoute.queryParams.subscribe((params)=> {
      if(params['identifier']) {
        this.identifier=params['identifier'];
        let decodedString = atob(params['identifier']);
        //this.email=decodedString.split("email=")[1];

        this.email=this.getParameterByName('email',decodedString);

      }
      });
    this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.jobflowconfig, null, null).subscribe(res => {
      if (res.status == config.SUCCESS && res.data) {
          this.showPrivacyPolicy=res.data['applyOptions']['isSeekApply'];
          this.privacyPolicyLink=liferayBaseUrl+res.data['gdprPolicyLink'];
      }
    });
  }
  unsubscribe(){
    this.unsubscribeBtnClicked=true;
    let baseRoute = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
    let jsonData= new UnsubscribeRequest(this.identifier,this.email,null,this.signedUrl);
    this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.unsubscribe,jsonData)
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          if(res.data.statuscode===config.portalSuccessCode) {
            window.location.href = window.location.origin+baseRoute[routingEndpoint.alertThankYouPage]+'?Submit=Y';
            //this.router.navigate([baseRoute[routingEndpoint.alertThankYouPage]], {queryParams: {Submit: 'Y'}});
          }
        }
      }, (error) => {
        window.location.href = window.location.origin+baseRoute[routingEndpoint.alertThankYouPage]+'?unsubscribe='+keywords.fail;
      });
  }


    getParameterByName(name, url) {
       name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

}
