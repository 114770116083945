import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestService } from 'src/app/global/services/request/request.service';
import { SiteService } from 'src/app/global/services/site/site.service';
import { CvData } from 'src/app/jobportal/model/request/cv-data';
import { MarketingConsent } from 'src/app/jobportal/model/request/marketing-consent';
import { Register } from 'src/app/jobportal/model/request/register';
import { OfficeForSpecialism, OfficeForSpecialismAdapt, OfficeForSpecialismData } from 'src/app/jobportal/model/response/office-for-specialism';
import { UploadCV, UploadCVAdapt } from 'src/app/jobportal/model/response/uploadCV';
import { DataSharingService } from 'src/app/jobportal/service/data-sharing.service';
import { JobSearchService } from 'src/app/jobportal/service/job-search.service';
import { SetTitleService } from 'src/app/jobportal/service/set-title.service';
import {apiEndPoints, config, environment, routingEndpoint} from 'src/environments/root/environment';
import { SpecPsCV } from '../../../Model/request/spec-Ps-CV';
import { SpecPsCvData } from '../../../Model/request/spec-Ps-CV-Data';

@Component({
  selector: 'app-spec-ps-cv',
  templateUrl: './spec-ps-cv.component.html',
  styleUrls: ['./spec-ps-cv.component.scss'],
  providers: [UploadCVAdapt, OfficeForSpecialismAdapt]
})
export class SpecPsCvComponent implements OnInit {

  public signUpFormGroup: FormGroup;
  public siteLocale: any;
  public isCaptchaDisable = false;
  public formSubmitted = false;
  public isShowKana: string;
  public router: any;
  public specRoute = false;
  public showFileValidation = true;
  public showPopUp = false;
  public firstName: string;
  public lastName: string;
  public emailAddress: string = '';
  public isChecked: boolean;
  public officeSpecialism: string;
  public sector: string;
  private isFormValid: boolean;
  public legalConsentVisible = false;
  validationForCA = false;
  validationForItaly = false;
  public disableButton: boolean;
  public validationCheck: boolean;
  public emailError: boolean;
  public isAUSHaysOffice: boolean;
  public katkanalastname: string;
  public katkanafirstname: string;
  private errorPageRoute: string;
  private successPageRoute: string;
  public isMarketingConsentEnabled: boolean;
  public submitClicked: boolean;
  public allowRegister: boolean;
  public marketingConsentData: MarketingConsent;
  private jobShredConfigRef: Subscription = new Subscription();
  private isMarketRef: Subscription = new Subscription();
  private legalConsentRef: Subscription = new Subscription();
  private openMarketPopupRef: Subscription = new Subscription();
  private crossclick: boolean;
  public isRegisterComponent: boolean;
  public isMarketingConsentForHU: boolean=false;
  public getPrivacyConsent2: boolean;
  public marketConsentPopup: boolean=false;
  public isPrivacyConsentEnabled: boolean=false;
  private expressConsent: string;
  public isAzureEnabled: boolean;
  public officeList : OfficeForSpecialismData[];

  constructor(private _requestService: RequestService, private _translate: TranslateService,
    public _site: SiteService, public uploadCVAdapt: UploadCVAdapt, private _cdr: ChangeDetectorRef,
    private _router: Router, private activatedRoute: ActivatedRoute, public dataShareService: DataSharingService,
    private officeForSpecialismAdapt:OfficeForSpecialismAdapt, private cookieService: CookieService,
    private titleService: SetTitleService, private jobSearchService: JobSearchService) {
      if (this._router.url.indexOf('spec-cv') > -1) {
        this.specRoute = true;
      }
      this.siteLocale = this._site.getSiteLocale();
      this._translate.setDefaultLang(this.siteLocale);
      this._translate.getTranslation(this._site.getSiteLocale()).subscribe((res) => {
        if (this.specRoute) {
          this.titleService.setTitleForPage(res['spec_cv_page_title']);
        }

      });
   }

  ngOnInit() {
    this.jobShredConfigRef = this.jobSearchService.sharedConfigList.subscribe(domainConfig => {
      if (domainConfig && domainConfig.isAUSHaysOffice) {
        this.isAUSHaysOffice = domainConfig.isAUSHaysOffice;
      }
      if (domainConfig && domainConfig.isMarketingConsentEnabled) {
        this.isMarketingConsentEnabled = domainConfig.isMarketingConsentEnabled;
      }
      if (domainConfig && domainConfig.isMarketingConsentForHU) {
        this.isMarketingConsentForHU = domainConfig.isMarketingConsentForHU;
      }
      if (domainConfig && domainConfig.isPrivacyConsentEnabled) {
        this.isPrivacyConsentEnabled = domainConfig.isPrivacyConsentEnabled;
      }
    });

    this.activatedRoute.queryParamMap.subscribe(param => {
      if ((!param.get('path') && !param.get('drive'))) {
        this.cookieService.delete('applicantDetails', '/');
      }
    });
    if (localStorage.getItem('showKana') === 'true' || localStorage.getItem('showKana') === 'false') {
      this.isShowKana = localStorage.getItem('showKana');
      this.createFormGroup();
    } else {
      this.isKanaEnabled();
    }
    this.isMarketRef = this.dataShareService.$isMarketingConsent.subscribe(data=>this.marketingConsentData = data)

   // this.getOfficeList();
  }


  protected createFormGroup(): void {
    this.signUpFormGroup = new FormGroup({
      firstname: new FormControl('', {
        validators: [Validators.required, this.whitespaceValidator],
        updateOn: 'change',
      }),
      lastname: new FormControl('', {
        validators: [Validators.required, this.whitespaceValidator],
        updateOn: 'change'
      }),
      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
        updateOn: 'change'
      }),
      cvData: new FormControl('', {
          validators: Validators.required,
          updateOn: 'change'
        }
      ),
      // expertise: new FormControl('', {
      //     validators: Validators.required,
      //     updateOn: 'change'
      //   },
      // ),
      officeForSpecialism: new FormControl('', {
          validators: Validators.required,
          updateOn: 'change'
        },
      ),
      privacyconsent1: new FormControl('', {
          updateOn: 'change'
        }
      ),
      privacyconsent2: new FormControl('', {
          updateOn: 'change'
        }
      ),
      reCaptchaReactive: new FormControl('',
        {updateOn: 'change'}
      )
    });
    this.dataShareService.setSignUpForm(this.signUpFormGroup);
    if (this.cookieService.get('applicantDetails')) {
      let applicantCookie: any;
      applicantCookie = decodeURIComponent(this.cookieService.get('applicantDetails'));
      applicantCookie = JSON.parse(applicantCookie);
      this.firstName = applicantCookie.firstName ? applicantCookie.firstName : '';
      this.lastName = applicantCookie.lastName ? applicantCookie.lastName : '';
      this.emailAddress = applicantCookie.email ? applicantCookie.email : '';
      this.katkanalastname = applicantCookie.katkanalastname ? applicantCookie.katkanalastname : '';
      this.katkanafirstname = applicantCookie.katkanafirstname ? applicantCookie.katkanafirstname : '';
      this.officeSpecialism = applicantCookie.officeSpecialism ? applicantCookie.officeSpecialism : '';
      // this.sector = applicantCookie.sector ? applicantCookie.sector : '';
      this.isChecked = applicantCookie.marketingConsents;
    }

    if (this.isShowKana) {
      this.signUpFormGroup.addControl('katkanalastname', new FormControl('', {updateOn: 'change'}));
      this.signUpFormGroup.addControl('katkanafirstname', new FormControl('', {updateOn: 'change'}));
    }
    if (localStorage.getItem('disableCaptcha') === "true") {
      console.log("inside IF", localStorage.getItem('disableCaptcha'))
      this.isCaptchaDisable = true;
    }
    if (!this.isCaptchaDisable && this.signUpFormGroup) {
      this.signUpFormGroup['controls'].reCaptchaReactive.setValidators([Validators.required]);
    }
    if (localStorage.getItem('domain') === 'CA') {
      this.validationForCA = true;
      this.signUpFormGroup.addControl('privacyconsent2', new FormControl('', {updateOn: 'change'}));
    }
    if (localStorage.getItem('domain') === 'ITL' || localStorage.getItem('domain') === 'IT') {
      this.validationForItaly = true;
      this.signUpFormGroup.addControl('privacyconsent2', new FormControl('', {updateOn: 'change'}));
    }
  }

  getOfficeList() {
    // this.selectedSectorValue = this.parent.value.expertise
    // let json = new JsonRequest(keywords.GetOfficeForSpecialism, this.parent.value.expertise);
    // if (json['specialismType']) {
    //  this.dataShareService.isSectorClicked(true);
      this._requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getOfficeForSpecialism).pipe(
        map((res: OfficeForSpecialism) => this.officeForSpecialismAdapt.adapt(res)))
        .subscribe((res) => {
          if (res.status === config.SUCCESS && res.data) {
            if (res.data["results"].length > 0) {
              this.officeList = res.data["results"];
              this.officeList.sort((a, b) => a.office_name.localeCompare(b.office_name));
              if (this.isAUSHaysOffice)
                this.officeList.sort((a, b) => a.State.localeCompare(b.State));
              console.log("this.officeList..", this.officeList);
              // if(this.officeSpecialism){
              if (this.officeList && this.officeList.length > 0) {
                this.dataShareService.setOfficeList(this.officeList);
              }
              //  }
            }
          }
        }, (error) => {

        });
    //}
    // else {
    //   this.officeList = null;
    //   this.dataSharing.setOfficeList([]);
    //   this.parent['controls']['officeForSpecialism'].setValue('');
    // }
  }

  public whitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {'whitespace': true};
  }

  showValidation(flag) {
    this.showPopUp = false;
    this.disableButton = false;
    this.signUpFormGroup['controls'].reCaptchaReactive.setValue('');
    this.showFileValidation = flag;
  }

  isKanaEnabled() {
    this._requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getIsKanaEnabled).pipe(
      map((response: UploadCV) => this.uploadCVAdapt.adapt(response))
    ).subscribe(response => {
      this.isShowKana = response.status === config.SUCCESS ? 'true' : 'false';
      localStorage.setItem('showKana', this.isShowKana);
      this.createFormGroup();
    }, (error) => {
      this.isShowKana = 'false';
      localStorage.setItem('showKana', this.isShowKana);
    });
  }

  legalConsentPopup() {
    this.legalConsentRef = this.jobSearchService.sharedConfigList.subscribe(domainConfig => {
      if (domainConfig && domainConfig.isLegalConsentPopupEnabled && !domainConfig.isMultiPrivacyPolicyEnabled) {
        this.legalConsentVisible = true;
      } else {
        this.initRegister();
      }
    });
  }

  legalConsentFlag(temp) {
    let flag = temp === true ? 'Y' : 'N';
    this.signUpFormGroup.value.eshotconsent = flag;
    this.signUpFormGroup.value.smsconsent = flag;
    if(!this.isMarketingConsentForHU && !this.isPrivacyConsentEnabled)
      this.signUpFormGroup.value.marketingconsent = flag;
    this.marketConsentPopup=false;
    this.initRegister();
  }

  register() {
    this.submitClicked = true;
    let EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"\+]+(\.[^<>()[\]\\.,;:\s@\"\+]+)*)|(\".\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/igm;
    if (!this.emailAddress && !EMAIL_REGEX.test(this.emailAddress)) {
      this.emailError = true;
    }

    if (this.officeSpecialism && this.firstName && this.lastName && this.emailAddress && EMAIL_REGEX.test(this.emailAddress.trim()) && this.isChecked) {
      this.signUpFormGroup.value.officeForSpecialism = this.officeSpecialism;
      // this.signUpFormGroup.value.expertise = this.sector;
      this.signUpFormGroup.value.privacyconsent1 = this.isChecked;
      this.signUpFormGroup.patchValue({
        // expertise: this.sector,
        officeForSpecialism: this.officeSpecialism,
        privacyconsent1: this.isChecked
      });
      this.signUpFormGroup['controls']['officeForSpecialism'].setErrors(null);
      // this.signUpFormGroup['controls']['expertise'].setErrors(null);
      this.signUpFormGroup['controls']['privacyconsent1'].setErrors(null);
      this.isFormValid = true;
      this.emailError = false;
    }
    this.formSubmitted = true;
    if (this.checkValidation()) {
      if (this.signUpFormGroup.valid || (this.isFormValid && this.signUpFormGroup.value.reCaptchaReactive)) {
        // if (this.isMarketingConsentEnabled && !this.marketingConsentData.email && !this.marketingConsentData.sms && !this.marketingConsentData.whatsApp) {
        //   this.showMarketingPopup();
        // }
        // if (this.isPrivacyConsentEnabled && this.signUpFormGroup['controls'].privacyconsent2.value !== true){
        //   this.showMarketingPopup();
        // }
        // if (this.isMarketingConsentEnabled) {
        //   this.openMarketPopupRef = this.dataShareService.$openMarketContentPopup.subscribe(data => {
        //     if(this.crossclick)
        //       return
        //     if (!data && this.submitClicked) {
        //       this.disableButton = true;
        //       this.emailError = false;
        //       this.legalConsentPopup();
        //       this.submitClicked = false;
        //     }
        //   });
        // } else
        // if(!this.getPrivacyConsent2 && this.isMarketingConsentForHU) {
        //   this.disableButton = true;
        //   this.emailError = false;
        //   this.submitClicked = false;
        //   this.marketConsentPopup = true;
        // }
        // else if(this.getPrivacyConsent2 && this.isMarketingConsentForHU) {
        //   this.disableButton = true;
        //   this.emailError = false;
        //   this.legalConsentFlag(true);
        //   this.submitClicked = false;
        // }
        // else {
          this.disableButton = true;
          this.emailError = false;
          this.legalConsentPopup();
          this.submitClicked = false;
        // }
      }
    }
  }

  initRegister() {
    let token = '';
    this.activatedRoute.queryParamMap.subscribe(param => {
      token = param.get('accessTokenDB') ? param.get('accessTokenDB') : param.get('accessTokenGD');
    });
    let cvData: SpecPsCvData;
    cvData = this.signUpFormGroup.controls.cvData.value;
    this.dataTransform();
    if(this.isMarketingConsentEnabled)
      this.checkMarketingData();
    let registerJson = new SpecPsCV(this.signUpFormGroup.controls.cvData.value, cvData.name, '', this.emailAddress,
      this.signUpFormGroup.value.privacyconsent1, this.signUpFormGroup.value.privacyconsent2, this.signUpFormGroup.value.privacyconsent3,
      this.signUpFormGroup.value.eshotconsent, this.signUpFormGroup.value.firstname, this.signUpFormGroup.value.reCaptchaReactive,
      this.signUpFormGroup.value.katkanafirstname, this.signUpFormGroup.value.katkanalastname, this.signUpFormGroup.value.lastname, this.signUpFormGroup.value.marketingconsent,
      this.signUpFormGroup.value.officeForSpecialism.split(':')[0], this.signUpFormGroup.value.officeForSpecialism.split(':')[1],
      this.signUpFormGroup.value.smsconsent, token, null, null, null, this.signUpFormGroup.value.whatsApp, this.expressConsent);
    this.submitAndRegister(registerJson);
  }


  submitAndRegister(registerJson) {
    if (this.cookieService.get('applicantDetails')) {
      this.cookieService.delete('applicantDetails', '/');
    }
    //let baseRoute = environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url];
    this.getRoutes();
    this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, "", registerJson).subscribe(response => {
      let thankYouUrl = '';
      if (response.status === config.SUCCESS) {
        // window.location.href = window.location.origin + this.successPageRoute + '?submitCV=Y';
        /*this._router.navigate([baseRoute[routingEndpoint.successPage]], { queryParams: { submitCV: 'Y' } });*/
       // thankYouUrl =environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.confirmationPage];
        this._router.navigate([this.successPageRoute], {
          queryParams: {
           specCv: 'Y'
          }
        });
      } else {
        //this.ngxUiLoader.stop();
        // this._router.navigate([this.errorPageRoute], {queryParams: {submitCV: 'N', error: response.status}});
        this._router.navigate([this.successPageRoute], {
          queryParams: {
           specCv: 'N'
          }
        });
      }
    }, (error) => {
      //this.ngxUiLoader.stop();
      if (error.error.data === config.protectedFileUpload) {
        this.showPopUp = true;
      } else {
        this._router.navigate([this.errorPageRoute], {
          queryParams: {
            specCv: 'N', error: error.status
          }
        });
      }
    });
  }

  checkMarketingData(){
    if(this.marketingConsentData.email)
      this.signUpFormGroup.value.eshotconsent = 'Y';
    else if(!this.marketingConsentData.email)
      this.signUpFormGroup.value.eshotconsent = 'N';
    if(this.marketingConsentData.sms)
      this.signUpFormGroup.value.smsconsent = 'Y';
    else if(!this.marketingConsentData.sms)
      this.signUpFormGroup.value.smsconsent = 'N';
    if(this.marketingConsentData.whatsApp)
      this.signUpFormGroup.value.whatsApp = 'Y';
    else if(!this.marketingConsentData.whatsApp)
      this.signUpFormGroup.value.whatsApp = 'N';
  }

  getRoutes() {
    let orgName = this._router.routerState.snapshot.root.firstChild.params['orgName'];
    let base = '';
    /*if (orgName) {
      base = environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url_org];
      this.successPageRoute = (base[routingEndpoint.confirmationPage]).replace(':orgName', orgName);
      this.errorPageRoute = (base[routingEndpoint.confirmationPage]).replace(':orgName', orgName);
    } else {
      base = environment[config.theme + this._site.getSiteLocale()][routingEndpoint.routing_url];
      this.successPageRoute = base[routingEndpoint.confirmationPage];
      this.errorPageRoute = base[routingEndpoint.confirmationPage];
    }*/

  }

  checkValidation() {
    this.validationCheck = true;
    if (this.signUpFormGroup['controls'].privacyconsent1.value === true) {
      if (this.validationForItaly && this.signUpFormGroup['controls'].privacyconsent2.value !== true) {
        this.validationCheck = false;
      }
    } else {
      this.validationCheck = false;
    }
    return this.validationCheck;
  }

  dataTransform() {
    this.signUpFormGroup.value.privacyconsent1 = 'Y';
    if (this.validationForItaly) {
      this.signUpFormGroup.value.privacyconsent2 = 'Y';
    } else if (this.validationForCA) {
      this.signUpFormGroup.value.privacyconsent2 = this.signUpFormGroup.value.privacyconsent2 !== true ? 'N' : 'Y';
      this.signUpFormGroup.value.privacyconsent3 = this.signUpFormGroup.value.privacyconsent3 !== true ? 'N' : 'Y';
    }
  }

  getConsentChecked($event: boolean) {
    this.isChecked = $event;
  }
  getPrivacyConsent2Checked($event: boolean) {
    this.getPrivacyConsent2=$event;
    this.expressConsent = this.isPrivacyConsentEnabled && $event? "Y":"N";
  }

  onEmailChanged(emailAddress) {
    this.emailError = false;
    this.emailAddress = emailAddress.target.value;
    this.dataShareService.setEmailId(this.emailAddress);
    let EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\"\+]+(\.[^<>()[\]\\.,;:\s@\"\+]+)*)|(\".\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/igm;
    if (this.emailAddress) {
      if (!this.emailAddress.trim() || !EMAIL_REGEXP.test(this.emailAddress.trim())) {
        this.emailError = true;
      }
    } else {
      this.emailError = true;
    }
  }

  showMarketingPopup() {
    this.isRegisterComponent = true;
    this.dataShareService.setOpenMarketContentPopup(true);
  }
  crossClicked(event){
    this.crossclick = event;
    this.marketConsentPopup=false;
  }

  ngOnDestroy(): void {
    this.openMarketPopupRef.unsubscribe();
    this.legalConsentRef.unsubscribe();
    this.isMarketRef.unsubscribe();
    this.jobShredConfigRef.unsubscribe();
  }
}
