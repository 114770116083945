import {Injectable, NgZone} from '@angular/core';
import {MapsAPILoader} from '@agm/core';
import {Subject} from 'rxjs';
import {DataSharingService} from './data-sharing.service';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteService {
  public latitude = new Subject<any>();
  lat = this.latitude.asObservable();
  public longitude = new Subject<any>();
  long = this.longitude.asObservable();
   country :string="";
  constructor(private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone, private dataSharingService:DataSharingService) {

  }
  autoCompleteLocationCall(searchElementRef){
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (!place.geometry) {
            return;
          }
          //set latitude and longitude
          this.latitude.next( place.geometry.location.lat());
          this.longitude.next( place.geometry.location.lng());
          this.country=place.name;
          this.dataSharingService.onClick(false);
        });
      });
    });
  }

}
