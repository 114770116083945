import {Adapter} from '../../../global/adapter/adapter';



export class OfficeLocator {
  constructor(public status: string,
              public message: string,
              public data: OfficeLocatorData[]) {

  }
}

export class OfficeLocatorData {
  constructor(public code: string,
              public label: string,
              public LOCATION_DESC: string,
              public id: number,
              public LOCATION_ID: number,
              public uri: string) {
  }
}
export class JsonRequest{
  constructor(public requestType : string,
              public specialismType : string
  ){}
}
export class LatlongJsonRequest{
  constructor(public distUnit : string,
              public distance : number,
              public lat : string,
              public lon : string,
              public specialismId : string,
              public subSpecialismId : string
  ){}
}
export class ApacLocationJsonRequest{
  constructor(public distUnit : string,
              public distance : number,
              public locationId : number,
              public specialismId : string,
              public subSpecialismId : string
  ){}
}
export class SelectedLocationJsonRequest{
  constructor(public location_Id : number,
              public location_Name : string
  ){}
}
export class SelectedSpecialismJsonRequest{
  constructor(public specialism_Id : string,
              public specialism_Name : string,
              public subspecialism : string
  ){}
}

export class OfficeLocatorAdapt implements Adapter<OfficeLocator> {
  adapt(item: any): OfficeLocator {
    return new OfficeLocator(
      item.status,
      item.message,
      item.data
    );
  }
}
