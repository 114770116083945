import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RequestService} from '../../services/request/request.service';
import {apiEndPoints, config} from '../../../../environments/root/environment';

@Component({
  selector: 'app-dms-submit-alert',
  templateUrl: './dms-submit-alert.component.html',
  styleUrls: ['./dms-submit-alert.component.scss']
})
export class DmsSubmitAlertComponent implements OnInit {

  private domainId:any;
  private envelopeId:any;
  private recepientType:any;
  constructor(private activatedRouter:ActivatedRoute, private _requestService:RequestService, private router:Router) { }

  ngOnInit() {
    this.domainId=this.activatedRouter.snapshot.queryParamMap.get('DomainID');
    this.envelopeId=this.activatedRouter.snapshot.queryParamMap.get('envelopeId');
    this.recepientType=this.activatedRouter.snapshot.queryParamMap.get('RecipentType');
    this.submitDMSAlert(this.domainId,this.envelopeId,this.recepientType);
  }

  submitDMSAlert(domain,envelope,type){
    if(domain && envelope && type){
      let jsonData= {
        'domainId':domain,
        'envelope':envelope,
        'type':type
      };
      this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.submitDMSAlert, jsonData)
        .subscribe((res) => {
          if (res.status === config.SUCCESS && (res.data['status']=='Recipient' || res.data['status']=='Consultant')  && res.data['value']=='200' ) {
            this.router.navigate(['/DMS/'],{ queryParams: {DocExchange: 'Y'}});
          }else{
            this.router.navigate(['/DMS/'],{ queryParams: {DocExchange: 'N'}});
          }
        }, (error) => {
          this.router.navigate(['/DMS/'],{ queryParams: {DocExchange: 'N'}});
        });


    }


  }

}
