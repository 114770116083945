import {Component, Input, OnInit, ViewChild, ElementRef, PLATFORM_ID, Inject} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {environment} from '../../../../../environments/root/environment';
import {WidgetInstance} from "friendly-challenge";
import {isPlatformBrowser} from "@angular/common";
import {SiteService} from "../../../../global/services/site/site.service";
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-jp-captcha',
  templateUrl: './jp-captcha.component.html',
  styleUrls: ['./jp-captcha.component.scss']
})
export class JpCaptchaComponent implements OnInit {
  @ViewChild('frccaptcha', { static: false })
  friendlyCaptcha: ElementRef<HTMLElement>;

  @Input() parent: FormGroup;
  public secretKey="";
  public friendlyCaptchaEndpoint ;
  public isBrowser: boolean;
  friendly_captcha_text_ready: any;
  friendly_captcha_button_start: any;
  friendly_captcha_text_solving: any;
  friendly_captcha_text_completed: any;

  constructor(@Inject(PLATFORM_ID) private platformId: object,private siteService: SiteService,
              private translation:TranslateService) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.secretKey = environment["friendly_captcha_secret_key"];
    this.friendlyCaptchaEndpoint = environment["friendly_captcha_endpoint"];
    const translationGetTranslationSubscription = this.translation.getTranslation(this.siteService.getSiteLocale()).subscribe(response => {
      this.friendly_captcha_text_ready = response['friendly_captcha_text_ready'];
      this.friendly_captcha_button_start = response['friendly_captcha_button_start'];
      console.log('message value button_start',this.friendly_captcha_button_start);
      console.log('message value response ',response['friendly_captcha_button_start']);
      this.friendly_captcha_text_solving = response['friendly_captcha_text_solving'];
      console.log('message value text_solving ',this.friendly_captcha_text_solving);
      this.friendly_captcha_text_completed = response['friendly_captcha_text_completed'];

    });
  }

  ngOnInit() {

    if(this.isBrowser) {
      setTimeout(() => {
        let widget = new WidgetInstance(this.friendlyCaptcha.nativeElement, {
          readyCallback: () => {

            this.friendlyCaptcha.nativeElement['friendlyChallengeWidget'].lang.button_start = 'Verify you are not a Robot';
            this.friendlyCaptcha.nativeElement['friendlyChallengeWidget'].lang.text_ready = '';
          },
          doneCallback: (captchaValue) => {
            this.formControl.reCaptchaReactive.setValue(captchaValue);
            console.log('reCaptchaReactiveValue: ', this.formControl.reCaptchaReactive);
          },
        })
      }, 500)
    }
    //this.secretKey = environment["google_captcha_client_key"];
  }

  get formControl() {
    return this.parent.controls;
  }

  ngAfterViewInit() {
    if(this.isBrowser) {
      const widget = new WidgetInstance(this.friendlyCaptcha.nativeElement, {
        readyCallback: () => {
          this.friendlyCaptcha.nativeElement['friendlyChallengeWidget'].lang.text_ready ='';
          this.friendlyCaptcha.nativeElement['friendlyChallengeWidget'].lang.button_start ='Verify you are not a Robot';
          this.friendlyCaptcha.nativeElement['friendlyChallengeWidget'].lang.text_solving = 'Verifying...';
          this.friendlyCaptcha.nativeElement['friendlyChallengeWidget'].lang.text_completed = 'Verified';
        },
        doneCallback: (captchaValue) => {
          // console.log('DONE: ', captchaValue);
          this.formControl.reCaptchaReactive.setValue(captchaValue);
          console.log('reCaptchaReactiveValue: ', this.formControl.reCaptchaReactive);
        },
        errorCallback: (captchaValueFail) => {
          console.log('FAILED', captchaValueFail);
        },
      })
    }
  }

}
