<app-custom-loader *ngIf="loader"></app-custom-loader>
<section class="my-profile" *ngIf="!loader && !this.isRedirectURL">
  <div class="profile-header">
    <div class="item"><i class="icon-user"></i> </div>
    <div class="item user-details">
      <h1>
        <div [textContent]="personalDetails.name"></div>
        <span [textContent]="personalDetails.email"></span>
      </h1>
      <ul>
        <li *ngFor="let myProfileMenu of myProfileData; let  i = index">
          <a [id]="'gtm_profilemenu_'+i" (click)="clearCacheCookie(myProfileMenu.resource_url)"
             [href]="myProfileMenu.resource_url" [textContent]="myProfileMenu.linkName"></a>
        </li>
      </ul>
    </div>
<!--
    <div class="switch-profile-btn-group-container" *ngIf="(this.hostName.indexOf('hays.fr')>-1 || this.hostName.indexOf('hays.pl')>-1)">
      <app-jp-timesheet></app-jp-timesheet>
    </div>
-->
  </div>

  <div class="row">
    <div class="col-12">
      <app-jp-upcoming-interviews></app-jp-upcoming-interviews>
    </div>
    <div class="col-12">
      <app-jp-interview-and-cv-tips></app-jp-interview-and-cv-tips>
    </div>
    <div class="col-12">
      <app-jp-recent-applications></app-jp-recent-applications>
    </div>
  </div>
  <div>

  </div>
</section>
