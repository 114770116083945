<div class="filter-right">
  <ul>
    <li>
      <ul>
        <li *ngIf="paytypeData && (paytypeData | json)!=='{}'" (clickOutside)="this.showPayType=false;">
          <a *ngIf ="!isOrgName" href="javascript:void(0)" (click)="showJobFilter('pay');getPayTypeData('',null)"  [translate]="'job_pay_label'"></a>
          <a *ngIf ="isOrgName" href="javascript:void(0)" (click)="showJobFilter('pay');getPayTypeData('',null)"  [translate]="'careers_salary_text'"></a>
          <ul class="pay-type" *ngIf="showPayType">
            <li>
              <label *ngIf ="!isOrgName" [translate]="'google_search_pay_type_text'"></label>
              <label *ngIf ="isOrgName" [translate]="'careers_salary_type_text'"></label>
              <select #payType class="form-control" (change)="getPayTypeData(payType.value,payType)">
                <option *ngFor="let payType of paytypeData | keyvalue;index as i" [textContent]="payType.key" id="gtm_pay_type"
                        [value]="{'key':payType.key,'id':i} | json"
                        [selected]="i === this.payTypeId">
                </option>
              </select>
            </li>
            <li>
              <label *ngIf ="!isOrgName" class="ng-binding" [translate]="'google_search_pay_range_text'"></label>
              <label *ngIf ="isOrgName" class="ng-binding" [translate]="'careers_salary_range_text'"></label>
              <span class="multi-input">
                      <select #salaryMin class="form-control"  (change)="getSalaryRange('minSal',salaryMin.value)">
                        <ng-container *ngIf="annualDataMin">
                          <option *ngFor="let payTypeMin of annualDataMin.MinPayRate;index as i" [textContent]="payTypeMin._key" id="gtm_pay_min"
                                  [value]="{'value':payTypeMin._value,'key':payTypeMin._key,'id':i} | json"
                                  [selected]="i === this.minPayIndex"></option>
                        </ng-container>
                      </select>
                      <select #salaryMax class="form-control" (change)="getSalaryRange('maxSal',salaryMax.value);">
                        <ng-container *ngIf="annualDataMin">
                          <option *ngFor="let payTypeMax of annualDataMin.MaxPayRate; index as i" [textContent]="payTypeMax._key" id="gtm_pay_max"
                                  [value]="{'value':payTypeMax._value,'key':payTypeMax._key,'id':i}|json"
                                  [selected]="i === this.maxPayIndex"></option>

                                  <!--[selected]="annualDataMin.MaxPayRate[annualDataMin.MaxPayRate.length-1]._key"-->
                        </ng-container>
                      </select>
                       </span>
            </li>
            <li>
              <button class="btn btn-block btn-primary" (click)="getSalaryRange('','')" [translate]="'update_salary_filter_text'">
              </button>
            </li>
          </ul>
        </li>
        <li *ngIf="jobTypeFilter.length>0" (clickOutside)="this.showJobType=false;">
          <a id="gtm_showjobtype_filter" href="javascript:void(0)" (click)="showJobFilter('jobType')" [translate]="'google_jobType_text'"></a>
          <ul *ngIf="showJobType">
            <li *ngFor="let jobType of jobTypeFilter;index as i">
              <div  (click)="getFilterType('jobType',jobType.jobtype,i,jobType.jobTypeValue)">
              <span id="gtm_job_type" [textContent]="jobType.jobtype"></span> <span
              class="count" [textContent]="jobType.jobCount"></span>
              </div>
            </li>
          </ul>
        </li>
        <li *ngIf="workingPatternFilter.length>0" (clickOutside)="this.showWorkingPattern=false;">
          <a id="gtm_showworkingpattern_filter" href="javascript:void(0)" (click)="showJobFilter('workingPattern')" [translate]="'flexible_working_header'"></a>
          <ul *ngIf="showWorkingPattern">
            <li *ngFor="let workingPatter of workingPatternFilter;index as i">
              <div (click)="getFilterType('workingType',workingPatter.type,i,'')">
                <span id="gtm_work_pattrn"  [textContent]="workingPatter.type"></span>
                <span [textContent]="workingPatter.count" class="count"></span></div></li>
          </ul>
        </li>
      </ul>

    </li>
    <li class="sort" (clickOutside)="this.showSortType=false;">
      <ul>
        <li>
          <a  href="javascript:void(0)" class="sort-relevance" (click)="showJobFilter('sortType')" *ngIf="!sortTypeAppliedText || isSortTypeNotVisible" [translate]="'google_sort_relevence'"></a>
          <a href="javascript:void(0)" class="sort-relevance" (click)="showJobFilter('sortType')" *ngIf="sortTypeAppliedText" [textContent]="sortTypeAppliedText"></a>
          <ul *ngIf="sortTypeFilter.length>0 && showSortType">
            <li *ngFor="let sortType of sortTypeFilter;index as i">
              <span id="'gtm_sort_type" [textContent]="sortType.type" (click)="getFilterType('sortType',sortType.type,i,sortType.value)"></span>
            </li>
          </ul>
        </li>
      </ul>

    </li>
  </ul>
</div>
