<div class="filter-cont">
  <button id="gtm_histogram_clear_all_filter" class="btn btn-secondary btn-block" (click)="clearAllFilter()" [translate]="'google_search_clear_all_filter_text'"></button>
  <app-jobtype-filter></app-jobtype-filter>
  <div class="filter-left">
    <ul>
      <li [ngClass]="showLocation?'active':''">
        <h5 (click)="showLocation=!showLocation" [translate]="'job_location_label'"></h5>
        <div *ngIf="showLocation">
          <div *ngIf="locationList.length!==1">
            <input class="form-control" [(ngModel)]="location" (keyup)="searchHistogram('location')"
                   [ngModelOptions]="{standalone: true}" placeholder="{{'google_placeholder_facetLocation' | translate}}"></div>
          <div *ngIf="slicedLocation.length>0">
            <ul>
              <li *ngFor="let location of slicedLocation">
                <a id="gtm_location"
                  [textContent]="(extraConfig && extraConfig.isTitleCase && !extraConfig.isSentenceCase)?((location.locationName| titlecase).indexOf(',')>-1?
                  ((location.locationName.split(',')[1] | titlecase).indexOf('Cbd')>-1?
                  (location.locationName).split(',')[0]+','+(location.locationName.split(',')[1]| titlecase).replace('Cbd','CBD'):
                  (location.locationName).split(',')[0]+','+(location.locationName.split(',')[1]| titlecase)):
                  (location.locationName| titlecase).indexOf('Cbd')>-1?(location.locationName| titlecase).replace('Cbd','CBD'):(location.locationName| titlecase)):
                  (extraConfig && !extraConfig.isTitleCase && extraConfig.isSentenceCase) ?(location.locationName| sentenceCase):
                  (location.locationName)"
                  (click)="getHistogrameFilter('location',location.locationName)"></a>
                <span
                class="count" [textContent]="location.count"></span>
              </li>
            </ul>

            <a class="show_more_less" id="gtm_histogram_location_show_more_less"  href="javascript:void(0);"
               (click)="getMoreLocation(slicedLocation && locationList &&(slicedLocation.length==locationList.length)?'show_less':'Show_more')"
               [translate]="slicedLocation && locationList &&(slicedLocation.length==locationList.length)?'google_search_show_less_text':slicedLocation && slicedLocation.length>9?'google_search_show_more_text':''" *ngIf="locationList.length>10"></a>
          </div>
        </div>
      </li>
      <li *ngIf = "!isHTSOrg" [ngClass]="showIndustry?'active':''">
        <h5 (click)="showIndustry=!showIndustry" [translate]="'job_industry_label'"></h5>
        <div *ngIf="showIndustry">
          <div *ngIf="industryList.length!==1">
            <input class="form-control" [(ngModel)]="industry" (keyup)="searchHistogram('industry')"
                   [ngModelOptions]="{standalone: true}" placeholder="{{'google_placeholder_facetIndustry' | translate}}"></div>
          <div *ngIf="slicedIndustries.length>0">
            <ul>
              <li *ngFor="let industries of slicedIndustries;index as i">
                <a id="gtm_industry"
                  [textContent]="(extraConfig && extraConfig.isTitleCase)?(industries.industryName | titlecase):(industries.industryName)"
                  (click)="getHistogrameFilter('industry',industries.industryName)">
                </a><span class="count" [textContent]="industries.count"></span>
              </li>
            </ul>
            <a id="gtm_histogram_industry_show_more_less" class="show_more_less" href="javascript:void(0);"
               (click)="getMoreIndustries(slicedIndustries && industryList &&(slicedIndustries.length==industryList.length)?'show_less':'Show_more')"
               [translate]="(slicedIndustries && industryList &&(slicedIndustries.length==industryList.length))?'google_search_show_less_text':(slicedIndustries && slicedIndustries.length>9)?'google_search_show_more_text':''" *ngIf="slicedIndustries.length>9"></a>
          </div>
        </div>
      </li>

    </ul>
  </div>
</div>
