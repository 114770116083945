import {Component, OnInit} from '@angular/core';
import {
  apiEndPoints,
  config,
  environment,
  keywords,
  routingEndpoint
} from '../../../../../../environments/root/environment';
import {RequestService} from '../../../../../global/services/request/request.service';
import {SiteService} from '../../../../../global/services/site/site.service';
import {SaveWhatsappRequest} from '../../../../model/request/save-whatsapp-request';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DataSharingService} from "../../../../service/data-sharing.service";
import {JobSearchService} from '../../../../service/job-search.service';

@Component({
  selector: 'app-jp-save-whatsapp',
  templateUrl: './jp-save-whatsapp.component.html',
  styleUrls: ['./jp-save-whatsapp.component.scss']
})
export class JpSaveWhatsappComponent implements OnInit {
public showThankyou: boolean;
public codeError: boolean;
public isdisable: boolean;
public WANumberError: boolean;
public wappcountryCode: string;
public wappNumber: string;
public emailId: string;

  constructor(private requestService: RequestService, private  siteService: SiteService, private _router: Router, private _translate: TranslateService,
              private dataSharingService: DataSharingService,private jobSearchService:JobSearchService,private activatedRoute:ActivatedRoute ) {
    this._translate.setDefaultLang(this.siteService.getSiteLocale());
  }

  ngOnInit() {
    this.dataSharingService.getWidgetListName(keywords.whatsappPageName);
    this.emailId=localStorage.getItem('emailID');
    this.showThankyou = false;
    this.WANumberError = false;
    this.isdisable = true;
    this.codeError = false;
    this.wappcountryCode='';
    this.wappNumber='';
    this.activatedRoute.queryParamMap.subscribe(param=>{
      if(param.get('submit') && param.get('submit')==='Y')
        this.showThankyou= true;
    });
    this.getCurrentCountryCode();

  }
  ngOnDestroy(){
    localStorage.removeItem('emailID');
  }
  getCurrentCountryCode(){
    this.jobSearchService.sharedConfigList.subscribe(domainConfig=>{
      if(domainConfig && domainConfig.wspCountryCode)
        this.wappcountryCode=domainConfig.wspCountryCode;
    });

  }
  submit(){
    this.isdisable=true;
    let baseRoute = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
    let saveWhatsappJson = new SaveWhatsappRequest(this.wappNumber, this.wappcountryCode,this.emailId);
    this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.saveWhatsapp,saveWhatsappJson)
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          window.location.href=window.location.origin+baseRoute[routingEndpoint.saveWhatsappPage]+'?submit=Y';
          /*this._router.navigate([baseRoute[routingEndpoint.saveWhatsappPage]],{queryParams:{submit:'Y'}});
          this.showThankyou=true;*/
        }
      }, (error) => {
        this._router.navigate([baseRoute[routingEndpoint.errorPage]],{queryParams:{submit:'N',error: error.status}});
      });
    localStorage.removeItem('emailID');
  }
  validate(whatsappCountryCode,whatsappNumber,event) {
    let regexWACode = /^([+]{1})[0-9]*$/;
    let regexWANumber = /^[0-9]*$/;
    if (event) {
      if ((event.which != 8 && event.which != 0 && event.which < 48) || (event.which > 57 && event.which < 96) || event.which > 105)
        event.preventDefault();
    }
    if (whatsappCountryCode.trim()) {
      if (!regexWACode.test(whatsappCountryCode)) {
        this.codeError = true;
        this.isdisable = true;
        return false;
      } else
        this.codeError = false;
    }

    if (whatsappNumber.trim()) {
      if (!regexWANumber.test(whatsappNumber)) {
        this.WANumberError = true;
        this.isdisable = true;
        return false;
      } else {
        this.WANumberError = false;

        if(!this.codeError && !this.WANumberError && (this.wappcountryCode && this.wappNumber))
          this.isdisable = false;
      }
    }
  }
}
