import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RecruitingRequest} from '../../../../../model/request/recruiting.request';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {RequestService} from '../../../../../../global/services/request/request.service';
import {map} from 'rxjs/operators';
import {ConsentAdapter, PrivacyConsent, PrivacyConsentData} from '../../../../../model/response/privacy-consent';
import {Router} from '@angular/router';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../../../../environments/root/environment';
import {SetTitleService} from '../../../../../service/set-title.service';
import {DataSharingService} from '../../../../../service/data-sharing.service';
import {ExtraFieldsConfig} from '../../../../../model/response/google-job-response';
import {JobSearchService} from '../../../../../service/job-search.service';


@Component({
  selector: 'app-jp-recruiting-now',
  templateUrl: './jp-recruiting-now.component.html',
  styleUrls: ['./jp-recruiting-now.component.scss'],
  providers:[ConsentAdapter]
})
export class JpRecruitingNowComponent implements OnInit {

  public recruitingForm:FormGroup;
  public errorMsgShow=false;
  public isCaptchaDisable=false;
  public captchaErrorMsg=false;
  public privacyPolicyErrorMsg=false;
  public privacyPolicyData:PrivacyConsentData[];
  public domain:string;
  public liferaybaseUrl: string;
  public emailmsgError=false;
  public whitespace:boolean;
  public btnDisabled=false;
  public customLoader=false;
  public showLoader: boolean;
  public domainConfigs: ExtraFieldsConfig;
  isChecked: boolean;
  public currentUserFlow: string = 'user_register';
  public getPrivacyConsent2: boolean;
  private expressConsent: string;
  public isPrivacyConsentEnabled: boolean=false;
  formSubmitted: boolean;
  /**
   * Captures whether privacy pop up is displayed or not for CHN
   */
  public isPrivacyPopUpDisplayed : boolean = false;
  /**
   * Captures privacy pop up inner text depending upon the route
   */
  public privacyPopUpContent : string;

  constructor(private router:Router,private requestService:RequestService,private translate:TranslateService,private _siteService:SiteService,
              private consentAdapter:ConsentAdapter,private titleService:SetTitleService,private dataSharingService: DataSharingService,private jobSearchService:JobSearchService) {
    this.translate.setDefaultLang(this._siteService.getSiteLocale());
    this.translate.getTranslation(_siteService.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(res['recruiting_now_page_title']);
    });
    this.domain=this._siteService.getDomain();
    this.getDomainConfig();
    //this.getPrivacyConsent();
    this.dataSharingService.getWidgetListName(keywords.recruitingNowPageName);
  }
  ngOnInit(){
    localStorage.removeItem("requstedRedirectURL");
    this.recruitingForm=new FormGroup({
      reCaptchaReactive: new FormControl(''),
      organization:new FormControl('',[Validators.required,this.noWhitespaceValidator]),
      fullName:new FormControl('',[Validators.required,this.noWhitespaceValidator]),
      jobTitle:new FormControl(''),
      emailAddress: new FormControl('', {validators: [Validators.required,Validators.email], updateOn: 'change'}),
      phoneNumber:new FormControl('',[Validators.pattern(config.mobNumberPattern),Validators.required]),
      basicInfo:new FormControl('',[Validators.required,this.noWhitespaceValidator]),
      salaryRange:new FormControl('',[Validators.required,this.noWhitespaceValidator]),
      jobLocation:new FormControl('',Validators.required),
      stateCountry:new FormControl('',[Validators.required,this.noWhitespaceValidator]),
      employeeTypes:new FormControl('',Validators.required),
      furtherInfo:new FormControl(''),
      privacyPolicyConsent:new FormControl(''),
      privacyconsent1:new FormControl('',Validators.required)

    });
    this.liferaybaseUrl=environment[config.theme+this._siteService.getSiteLocale()][apiEndPoints.liferaybaseUrl];

  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  ngDoCheck(){
    if(localStorage.getItem('disableCaptcha')=='true')
      this.isCaptchaDisable=true;
  }

  get f() {
    if(this.recruitingForm.value.reCaptchaReactive)
      this.captchaErrorMsg=false;
    if(this.recruitingForm.value.privacyconsent1)
      this.privacyPolicyErrorMsg=false;
    return this.recruitingForm.controls;
  }

  onEmailChanged(emailAddress) {
    this.emailmsgError = false;
    this.recruitingForm.value.emailAddress = emailAddress.target.value;
    this.dataSharingService.setEmailId(this.recruitingForm.value.emailAddress);
    let EMAIL_REGEXP  = /^(([^<>()[\]\\.,;:\s@\"\+]+(\.[^<>()[\]\\.,;:\s@\"\+]+)*)|(\".\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/igm;
    if(this.recruitingForm.value.emailAddress) {
      if (!this.recruitingForm.value.emailAddress.trim()|| !EMAIL_REGEXP.test(this.recruitingForm.value.emailAddress.trim())) {
        this.emailmsgError = true;
      }
    }else
      this.emailmsgError=true;
  }




  submitRecruitingNowForm() {
    let EMAIL_REGEX=/^(([^<>()[\]\\.,;:\s@\"\+]+(\.[^<>()[\]\\.,;:\s@\"\+]+)*)|(\".\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/igm;
    this.formSubmitted = true;
    if(!this.recruitingForm.value.emailAddress && !EMAIL_REGEX.test(this.recruitingForm.value.emailAddress))
      this.emailmsgError = true;
    if(localStorage.getItem('domain') === 'CHN' && !this.isChecked){
      this.checkValidityAndDisplayAgreementModal();
    }
    /* Don't change the occurrence of this method */
    this.errorMsgShow = this.recruitingForm.invalid;
    this.customValidation();
    if(!this.errorMsgShow && this.recruitingForm.valid && !this.emailmsgError && !this.captchaErrorMsg){
      this.btnDisabled=true;
      this.customLoader=true;
      let captchaResponse="";
      if(!this.isCaptchaDisable)
        captchaResponse=this.recruitingForm.value.reCaptchaReactive;

      let recruitingFormJson=new RecruitingRequest(
        this.recruitingForm.value.organization,
        this.recruitingForm.value.fullName,
        this.recruitingForm.value.jobTitle,
        this.recruitingForm.value.emailAddress,
        this.recruitingForm.value.phoneNumber,
        this.recruitingForm.value.basicInfo,
        this.recruitingForm.value.salaryRange,
        this.recruitingForm.value.jobLocation,
        this.recruitingForm.value.stateCountry,
        this.recruitingForm.value.furtherInfo,
        this.recruitingForm.value.employeeTypes,
        captchaResponse,
      );
      let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
      let successPage='';
      let errorRoute='';
      if(orgName){
        let base= environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url_org];
        successPage = (base[routingEndpoint.successPage]).replace(':orgName',orgName);
        errorRoute = (base[routingEndpoint.errorPage]).replace(':orgName',orgName);
      }else{
        let base= environment[config.theme + this._siteService.getSiteLocale()][routingEndpoint.routing_url];
        successPage = base[routingEndpoint.successPage];
        errorRoute = base[routingEndpoint.errorPage];
      }
      this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.submitRecruitingForm,recruitingFormJson)
        .subscribe(res=>{
          this.customLoader=false;
          if(res.status==config.SUCCESS && res.data['statusCode']==config.recruitingNowSuccess)
            window.location.href=window.location.origin+successPage+'?recruitingNow='+keywords.success;
        },(error)=>{
          this.router.navigate([errorRoute],{queryParams: {'recruitingNow':keywords.fail}});
        });
    }
  }
  private getPrivacyConsent(){
    this.showLoader=true;
    this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getTermsAndConditions,null,null).pipe(
      map((res:PrivacyConsent)=> this.consentAdapter.adapt(res)))
      .subscribe((res)=>{
        this.showLoader=false;
        if(res.status==config.SUCCESS && res.data)
          this.privacyPolicyData=res.data["checkBox1"];
      },(error)=>{
        this.showLoader=false;
      })
  }
  private customValidation(){
    this.captchaErrorMsg=false;
    this.privacyPolicyErrorMsg=false;
    if((this.domainConfigs && this.domainConfigs.isRecruitingTerm && this.privacyPolicyData) && (!this.recruitingForm.value.privacyconsent1) ){
      this.privacyPolicyErrorMsg=true;
      this.errorMsgShow=true;
      if(!this.isCaptchaDisable && !this.recruitingForm.value.reCaptchaReactive){
        this.captchaErrorMsg=true;
        return false;
      }
    }else if(!this.isCaptchaDisable && !this.recruitingForm.value.reCaptchaReactive) {
      this.captchaErrorMsg=true;
      return false;
    }
  }

  private getDomainConfig() {
    this.jobSearchService.sharedConfigList.subscribe(domainConfig=>{
      if(domainConfig && domainConfig.isRecruitingTerm) {
        this.getPrivacyConsent();
        this.domainConfigs = domainConfig;
      }
    });
    /*this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.jobflowconfig, null, null).subscribe(res => {
      if (res.status == config.SUCCESS) {
        this.domainConfigs = res.data;
        if(this.domainConfigs && this.domainConfigs.isRecruitingTerm)
          this.getPrivacyConsent();
      }
    });*/
  }

  getConsentChecked($event: boolean) {
    this.isChecked = $event;
  }
  getPrivacyConsent2Checked($event: boolean) {
    this.getPrivacyConsent2=$event;
    this.expressConsent = this.isPrivacyConsentEnabled && $event? "Y":"N";
  }

  /**
   * Listens to the pop up close event
   * @param eventPayload
   */
  public onPopUpClose(eventPayload : boolean){
    this.isPrivacyPopUpDisplayed = eventPayload
  }
  /**
   * Sets the privacy popup content
   */
  public getFlowSpecificPopUpMessages(){
    this.translate.getTranslation(this._siteService.getSiteLocale()).subscribe(response => {
        this.privacyPopUpContent = response['privacy-consent-submit-contents'];
    });
  }

  /**
   * Called when user clicks submit button for specified domains and sets the following properties:
   * Sets validation check to false
   * Displayd the privacy pop up
   * Calls the flow specific messages
   */
  private checkValidityAndDisplayAgreementModal(){
    this.isPrivacyPopUpDisplayed = true;
    this.getFlowSpecificPopUpMessages();
  }

}
