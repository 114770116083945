<section class="thank-you-msg">
  <div class="success">
    <div class="success-msg-cont" *ngIf="query">
      <i></i>
      <div *ngIf="query.recruitingNow==='SUCCESS'">
        <p [translate]="'recruiting_now_Thank_you_text'"></p>
      </div>
      <div *ngIf="query.submitCV==='Y'">
        <span [translate]="'spec_cv_thank_you_text1'"></span>
        <span [translate]="'spec_cv_thank_you_text2'"></span>
        <span>{{'spec_cv_thank_you_text4'|translate}} <a id="gtm_success_speccv_signin_redirect"
                                                          [routerLink]="signInUrl">{{'signIn' | translate}}</a></span>
        <span [translate]="'spec_cv_thank_you_text5'"></span>
      </div>
      <div *ngIf="query.trainingRegister==='Y'">
        <h3 [translate]="'goOne_training_thankyou_heading_text'"></h3>
        <span [translate]="'goOne_training_thankyou_text'"></span>
      </div>
      <div *ngIf="query.emailConfirmation==='Y' || query.emailSent==='Y'">
        <span [translate]="'gdpr_email_link_text1'"></span>
        <span>({{emailData}}){{'gdpr_email_link_text2'|translate}}</span>
      </div>
      <div *ngIf="query.emailConfirmation==='Y' || query.emailSent==='Y'" class="change-preferences">
        <div class="new-user">{{'gdpr_signin_text' | translate}}
          <a id="gtm_success_email_confrm_signin_redirect" [routerLink]="signInUrl"
             [translate]="'gdpr_click_here_text'"></a>
        </div>
        <div class="unsubscribe"><span [translate]="'gdpr_email_link_not_received_text'"></span>
          <a id="gtm_email_redirect" [href]="mailtoText+gdprEmail" [textContent]="gdprEmail"></a></div>
      </div>
      <div *ngIf="query.unsubscribe==='Y' ">
        <div><span>{{'standardmsg_thankyou'|translate}} {{'alert_unsubscribe_text'|translate}} {{'success_msg_on_err_page'|translate}} {{'error_page_search_jobs'|translate}} {{'login_form_register_text2'|translate}} {{'login_form_register_text2'|translate}}
          <a id="gtm_unsubscribealert_login_redirect"  [href]="loginUrl" [translate]="'success_msg_on_err_page1'"></a> {{'success_msg_on_err_page2' | translate}}</span></div>
      </div>
      <div *ngIf="query.globalConsent==='Y'">
        <span [translate]="'candidate_consent_thank_you_text'"></span>
      </div>
      <div *ngIf="query.updateConsent==='Y'">
        <span [translate]="'candidate_update_consent_thank_you_text'"></span>
      </div>
      <div *ngIf="query.updatePrivacy==='Y'">
        <span [innerHTML]="'update_privacy_acceptance_thank_you_text' | translate"></span>
        <span><img class="we-chat-qr-code" src="../../assets/images/qr-code-we-chat.png"></span>
      </div>

      <div *ngIf="query.ThailandPrivacyPolicy==='Y'">
       <span [innerHTML]="'update_privacy_policy_thailand_thank_you_text' | translate"></span>
      </div>

    </div>

  </div>

</section>


