<div class="new-user bg-white">
    <form [formGroup]="signUpFormGroup" (ngSubmit)="register()" id="gtm_register_new">
      <h2 class="spec-cv-h2" *ngIf="specRoute" [translate]="'spec_cv_title_text'">
      </h2>     
      <div [ngClass]="specRoute?'col  border spec-cv':'col border'">
  
        <ng-container *ngIf="isShowKana==='true'">
          <app-jp-register-new-kana [parent]="signUpFormGroup" [formSubmitted]="formSubmitted"
                                    [katkanalastname]="katkanalastname"
                                    [katkanafirstname]="katkanafirstname" [firstName]="firstName"
                                    [lastName]="lastName"></app-jp-register-new-kana>
        </ng-container>
        <ng-container *ngIf="!isShowKana || isShowKana==='false'">
          <div class="form-group">
            <label [translate]="'enter_first_name'"></label>
            <input type="text" [(ngModel)]="firstName" class="form-control" id="filed1" autocomplete="off"
                   placeholder="{{'enter_first_name_placeholder' | translate}}" formControlName="firstname" required>
            <div
              *ngIf="signUpFormGroup['controls'].firstname.invalid && formSubmitted">
          <span class="error-msg"
                *ngIf="signUpFormGroup['controls'].firstname.errors?.required || signUpFormGroup['controls'].firstname.errors?.whitespace"
                [translate]="'invalid_first_name_msg'">
          </span>
            </div>
          </div>
  
          <div class="form-group">
            <label [translate]="'enter_last_name'"></label>
            <input type="text" [(ngModel)]="lastName" class="form-control" id="filed2" autocomplete="off"
                   placeholder="{{'enter_last_name_placeholder' | translate}}" formControlName="lastname" required>
            <div
              *ngIf="signUpFormGroup['controls'].lastname.invalid && formSubmitted">
          <span class="error-msg"
                *ngIf="signUpFormGroup['controls'].lastname.errors?.required || signUpFormGroup['controls'].lastname.errors?.whitespace"
                [translate]="'invalid_last_name_msg'">
          </span>
            </div>
          </div>
        </ng-container>
  
        <div class="form-group">
          <label [translate]="'enter_email_label'"></label>
          <input type="text" [(ngModel)]="emailAddress" (keyup)="onEmailChanged($event);" class="form-control" id="filed3"
                 autocomplete="off" placeholder="{{'enter_email_placeholder' | translate}}"
                 formControlName="email" required>
          <span class="error-msg" *ngIf="emailError" [translate]="'invalid_email_msg'">
          </span>
    
        </div>
  
        <div class="info-msg" [translate]="'upload_issue_info'"></div>
        <div>
          <app-jp-upload-cv [parent]="signUpFormGroup"
                            (showValidationMessage)="showValidation($event)"></app-jp-upload-cv>
          <div
            *ngIf="!signUpFormGroup['controls'].cvData.value && formSubmitted && showFileValidation">
          <span *ngIf="signUpFormGroup['controls'].cvData.errors?.required" class="error-msg"
                [translate]="'invalid_cv_msg'"></span>
          </div>
  
          <span *ngIf="showPopUp" class="error-msg" [translate]="'password_protected_warning'">
        </span>
  
        </div>
  
        <!-- <div>
          <app-jp-sector [isAUSHaysOffice]="isAUSHaysOffice" [parent]="signUpFormGroup" [sector]="sector"
                         [officeSpecialism]="officeSpecialism"
                         [specialismId]="signUpFormGroup.value.expertise?signUpFormGroup.value.expertise:sector"></app-jp-sector>
          <div
            *ngIf="signUpFormGroup['controls'].expertise.invalid && formSubmitted">
          <span *ngIf="signUpFormGroup['controls'].expertise.errors?.required" class="error-msg"
                [translate]="'invalid_specialism_msg'"></span>
          </div>
        </div> -->
  
        <div>
          <app-jp-office-for-specialism [isAUSHaysOffice]="isAUSHaysOffice" [parent]="signUpFormGroup"
                                        [officeSpecialism]="officeSpecialism"></app-jp-office-for-specialism>
          <div
            *ngIf="signUpFormGroup['controls'].officeForSpecialism.invalid && formSubmitted">
          <span *ngIf="signUpFormGroup['controls'].officeForSpecialism.errors?.required" class="error-msg"
                [translate]="'invalid_office_msg'"></span>
          </div>
        </div>
    
        <div>
          <app-term-and-privacy [isChecked]="isChecked" [parent]="signUpFormGroup"
                                (isConsentChecked)="getConsentChecked($event)" (privacyconsent2)="getPrivacyConsent2Checked($event)" [type]="'specCV'">
          </app-term-and-privacy>
          <div
            *ngIf="formSubmitted && !validationCheck && !isChecked && !isMarketingConsentEnabled">
          <span class="error-msg"
                [translate]="'policy_validation_error'"></span>
          </div>
        </div>
        Captcha Value : {{isCaptchaDisable}}
        <ng-container *ngIf="!isCaptchaDisable">
          
          <app-jp-captcha [parent]="signUpFormGroup">
          </app-jp-captcha>
          <div
            *ngIf="signUpFormGroup['controls'].reCaptchaReactive?.value === '' && formSubmitted">
          <span class="error-msg"
                [translate]="'captcha_validation_text'"></span>
          </div>
          <div
            *ngIf="signUpFormGroup['controls'].reCaptchaReactive?.value === null"><span class="error-msg"
                                                                                        [translate]="'captcha_validation_text'"></span>
          </div>
        </ng-container>
        <!-- <div class="action-bar text-center" *ngIf="!specRoute">
          <button id="gtm_submit_and_register_btn"
                  [ngClass]="(showPopUp||disableButton)?'btn btn-default btn-block btn-loading':'btn btn-default btn-block'"
                  type="submit" [disabled]="showPopUp || disableButton" [translate]="'Submit_and_register'"></button>
        </div> -->
        <div class="action-bar text-center" *ngIf="specRoute">
          <button id="gtm_submit_btn"
                  [ngClass]="disableButton?'btn btn-default btn-block btn-loading':'btn btn-default btn-block'"
                  type="submit" [disabled]="showPopUp || disableButton" [translate]="'submit_button'"></button>
        </div>
      </div>
    </form>
    <!-- <app-jp-marketing-consent-popup
      *ngIf="((isRegisterComponent && dataShareService.$openMarketContentPopup | async) || marketConsentPopup ) && !isPrivacyConsentEnabled" (consentFlag)="legalConsentFlag($event)" [consentPopUp]="marketConsentPopup"(crossClicked)="crossClicked($event)">
    </app-jp-marketing-consent-popup> -->
  </div>
  
  
  