import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {JpLoginComponent} from './components/jp-login/jp-login.component';
import {JpForgotPasswordComponent} from './components/jp-forgot-password/jp-forgot-password.component';


const routes: Routes = [
  { path: '',component: JpLoginComponent},
  { path: 'azureLogout',component: JpLoginComponent},
  { path: 'forgot-password',component: JpForgotPasswordComponent},


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SigninRoutingModule { }
