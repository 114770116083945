<app-jp-alert *ngIf="(orgName | lowercase) != ('careers' | lowercase) && keyWord && googleJobResponseList.length>0 && showPopup"  [keyWord]="keyWord"></app-jp-alert>
  <section [ngClass]="showFilters?'job-search-result bg-light-grey facet-open':'job-search-result bg-light-grey'">

    <div class="row">

      <div class="col-md-3 position-static facet">
        <app-histogram [locationList]="locationFilterList" [industryList]="industriesList" [tempIndustry]="slicedIndustries" [slicedIndustries]="slicedIndustries" [extraConfig]="domainConfig"
                       [slicedLocation]="locationList" [tempLocationList]="locationList"></app-histogram>
      </div>
      <div class="col-md-9 job-cont" infiniteScroll
           [infiniteScrollThrottle]="50"
           (scrolled)="onScroll()"
           [scrollWindow]="true">
        <app-job-card [jobList]="googleJobResponseList" (openSearchFilter)="openFilters($event)" [extraConfig]="domainConfig" [noResultFound]="noResultFound" [keyWord]="keyWord" [locationQuery]="locationf"></app-job-card>
        <div *ngIf="noResultFound">
          <app-jp-no-result-found [jobConfig]="domainConfig"></app-jp-no-result-found>

        </div>

      </div>
    </div>
  </section>

