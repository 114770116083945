<div class="new-user bg-white">
  <form [formGroup]="signUpFormGroup" (ngSubmit)="updatePrivacyAcceptance()" id="update_your_privacy_form">
    <div class="registration_title">
      <h2 class="spec-cv-h2" [translate]="'update_your_privacy_title_text'"></h2>
    </div>
    <div [ngClass]="'col border spec-cv'">
      <div class="form-group">
        <label [translate]="'enter_first_name'"></label>
        <input type="text" class="form-control" id="firstName" autocomplete="off"
          placeholder="{{'enter_first_name_placeholder' | translate}}" formControlName="firstname" required>
        <div *ngIf="signUpFormGroup['controls'].firstname.invalid && formSubmitted">
          <span class="error-msg"
            *ngIf="signUpFormGroup['controls'].firstname.errors?.required || signUpFormGroup['controls'].firstname.errors?.whitespace"
            [translate]="'invalid_first_name_msg'">
          </span>
        </div>
      </div>

      <div class="form-group">
        <label [translate]="'enter_last_name'"></label>
        <input type="text" class="form-control" id="lastName" autocomplete="off"
          placeholder="{{'enter_last_name_placeholder' | translate}}" formControlName="lastname" required>
        <div *ngIf="signUpFormGroup['controls'].lastname.invalid && formSubmitted">
          <span class="error-msg"
            *ngIf="signUpFormGroup['controls'].lastname.errors?.required || signUpFormGroup['controls'].lastname.errors?.whitespace"
            [translate]="'invalid_last_name_msg'">
          </span>
        </div>
      </div>
      <div class="form-group">
        <label [translate]="'enter_email_label'"></label>
        <input type="text" class="form-control" id="emailAddress" autocomplete="off"
          placeholder="{{ 'enter_email_placeholder' | translate}}" formControlName="email" required>
        <div *ngIf="signUpFormGroup['controls'].email.invalid && formSubmitted">
          <span class="error-msg"
            *ngIf="signUpFormGroup['controls'].email.errors?.required || signUpFormGroup['controls'].email.errors?.email"
            [translate]="'invalid_email_msg'"></span>
        </div>
      </div>
      <div class="form-group">
        <label [translate]="'enter_mobile_label'"></label>
        <input type="text" onKeyPress="if(this.value.length==14) return false;"
          class="form-control d-inline-block mobile-no" id="filed4" autocomplete="off"
          placeholder="{{'enter_mobile_placeholder' | translate}}" formControlName="mobileNumber">
        <div *ngIf="signUpFormGroup['controls'].mobileNumber.invalid && formSubmitted">
          <span class="error-msg" *ngIf="signUpFormGroup['controls'].mobileNumber.errors?.invalid"
            [translate]="'invalid_mobile_msg'"></span>
        </div>
      </div>
      <div>
        <app-jp-privacy-acceptance-checkbox [parent]="signUpFormGroup" (checkBoxValidatorEvent)="checkBoxAcceptEventHander($event)"></app-jp-privacy-acceptance-checkbox>
        <span *ngIf="!isCheckBoxChecked && formSubmitted" class="error-msg" [translate]="'policy_validation_error'"></span>
      </div>
      <ng-container *ngIf="!isCaptchaDisable">
        <app-jp-captcha [parent]="signUpFormGroup">
        </app-jp-captcha>
        <div *ngIf="signUpFormGroup['controls'].reCaptchaReactive?.value === '' && formSubmitted">
          <span class="error-msg" [translate]="'captcha_validation_text'"></span>
        </div>
        <div *ngIf="signUpFormGroup['controls'].reCaptchaReactive?.value === null"><span class="error-msg"
            [translate]="'captcha_validation_text'"></span>
        </div>
      </ng-container>



      <div class="action-bar text-center">
        <button id="gtm_submit_btn"
          [ngClass]="disableButton?'btn btn-default btn-block btn-loading':'btn btn-default btn-block'" type="submit"
          [disabled]="disableButton" [translate]="'submit_button'"></button>
      </div>
    </div>
  </form>

  <!-- Modal -->
<div *ngIf="displayPopUp" class="modal fade terms-conditions-popup" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="card-img-overlay"></div>
  <div class="modal-dialog modal-dialog-centered " role="document">
    <div class="modal-content">
      <div class="modal-body">
        <a href="javascript:void(0)" id="closePolicy" class="close" (click)="closePopUp()">+</a>
        <p [innerHTML] = "'privacy_acceptance_popup_text' | translate"></p>  
      </div>
      <div class="modal-footer">
        <button id="gtm_remove_commnt_ok_btn" type="button" (click)="closePopUp()" 
                [ngClass]="'btn btn-primary'"
                [translate]="'remove_comment_ok'"></button>
      </div>
    </div>
</div>
</div>

</div>

