import {Component, EventEmitter, Inject, Input, OnInit, Optional, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RequestService} from '../../../../../../global/services/request/request.service';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TokenStorage} from '../../../../../../global/services/tokenStorage/token-storage';
import {DataSharingService} from '../../../../../service/data-sharing.service';
import {TranslateService} from '@ngx-translate/core';
import {SetTitleService} from '../../../../../service/set-title.service';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../../../../environments/root/environment';
import {ChangePasswordV2} from '../../../../../model/request/change-password-v2';
import {SiginPopupService} from '../../service/sigin-popup.service';


@Component({
  selector: 'app-reset-pass-popup',
  templateUrl: './reset-pass-popup.component.html',
  styleUrls: ['./reset-pass-popup.component.scss']
})
export class ResetPassPopupComponent implements OnInit{

  public changePassForm: FormGroup;
  public baseRoute: string;
  public formSubmitted: boolean;
  public validationFlag = {
    required: false,
    pattern: false,
    passwordMatch: false,
    oldNewDiffer: false
  }
  public incorrectOldPass = false;
  @Input() public identifier: string;
  @Output() public close = new EventEmitter<boolean>();

  public buttonChnagePasswordClicked: boolean;

  constructor(private _requestService:RequestService,private siteService: SiteService, private router: Router,private activatedRoute:ActivatedRoute,
              private tokenStorage:TokenStorage, private dataSharing:DataSharingService,private translate:TranslateService,private titleService:SetTitleService,
              private signinPopupService: SiginPopupService) {
    this.translate.getTranslation(siteService.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(res['change_password_page_title']);
    });
    this.dataSharing.getWidgetListName(keywords.changePasswordWidget);
  }


  ngOnInit() {
    this.createFormGroup();
  }

  protected createFormGroup(): void {
    this.changePassForm = new FormGroup({
      newPassword: new FormControl('', {
          validators: [Validators.required, Validators.pattern(config.passwordPattern)]
        }
      ),
      confirmPassword: new FormControl('', {
          validators: [Validators.required]
        }
      )
    });
  }

  submit(){
    this.buttonChnagePasswordClicked=true;
    this.formSubmitted = true;

    this.validationFlag.passwordMatch = this.changePassForm['controls'].newPassword.value !== this.changePassForm['controls'].confirmPassword.value;

    this.validationFlag.required = (this.changePassForm.invalid && ((this.changePassForm['controls'].newPassword.errors && this.changePassForm['controls'].newPassword.errors.required)  || (this.changePassForm['controls'].confirmPassword.errors && this.changePassForm['controls'].confirmPassword.errors.required))) ;

    this.validationFlag.pattern = this.changePassForm.invalid && ((this.changePassForm['controls'].newPassword.errors && this.changePassForm['controls'].newPassword.errors.pattern) );

    if (!this.validationFlag.passwordMatch && !this.validationFlag.required && !this.validationFlag.pattern && !this.validationFlag.oldNewDiffer) {
      this.changePassword();
    }else{
      this.buttonChnagePasswordClicked=false;
    }
  }

  changePassword(){
    let payload = new ChangePasswordV2(btoa(this.changePassForm['controls'].newPassword.value), this.identifier);
    this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI,apiEndPoints.changePasswordV2,payload).subscribe(response => {
      if (response.status === config.SUCCESS){
        this.signinPopupService.setOpenPopup(false);
        this.signinPopupService.setChangeSuccess(true);
        this.incorrectOldPass = false;
        this.close.emit(false);
      }
    }, (error) => {
      this.signinPopupService.setChangeSuccess(false);
      this.signinPopupService.setOpenPopup(false);
      // this.router.navigate([environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.login]],
      //   {queryParams: {'ChangePasswordV2' : 'N'}})
      this.buttonChnagePasswordClicked=false;
      this.close.emit(false);
    });
  }

  closePopup(){
    this.signinPopupService.setOpenPopup(false);
    this.signinPopupService.setChangeSuccess(false);
    this.close.emit(false);
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    let loginUrl =environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.login];
    if(orgName)
      loginUrl= (environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.login]).replace(':orgName',orgName);

    this.router.navigate([loginUrl],
      {queryParams: {'ChangePasswordV2': 'N'}})
  }

}
