<section class="change-preferences">
  <form id="gtm_unsubscribe_email_confirm" [formGroup]="emailConfirmationForm" (ngSubmit)="submit()" >
    <h2 [translate] = "'unsubscribe_email_confirmation_title'">
    </h2>
    <div class="row">
      <div class="col-12 col-md-4 offset-md-4 border mb-5">

        <h3 class="border-bottom" >
          <div [translate] = "'unsubscribe_email_confirmation_text'"></div>
          <p class="help-text" [translate] = "'unsubscribe_email_confirmation_msg'">
          </p>
        </h3>

        <div class="form-group">
          <input type="text" class="form-control" id="filed3" autocomplete="off" placeholder="{{'whats_your_emailId' | translate}}"
                 formControlName="emailaddress" required >
        </div>

        <div class="form-group" *ngIf = "formSubmitted">
          <span class = "error-msg" *ngIf="patternValidation" [translate] = "'invalid_email_msg'"></span>
        </div>

        <ng-container *ngIf="!isCaptchaDisable">
          <app-jp-captcha [parent]="emailConfirmationForm">
          </app-jp-captcha>
          <div
            *ngIf="emailConfirmationForm['controls'].reCaptchaReactive?.value === '' && formSubmitted">
              <span class="error-msg"
                    [translate]="'captcha_validation_text'"></span>
          </div>
        </ng-container>

        <div class="action-bar">
          <button id ="gtm_email_confm_submit_btn" type="submit" [ngClass]="disableBtn?'btn btn-secondary btn-block btn-loading':'btn btn-secondary btn-block'" [disabled]="disableBtn" [translate] = "'gdpr_confirm_text'"></button>
        </div>


      </div>
    </div>
  </form>
  <div class="col-12 col-md-4 offset-md-4 text-center">
    <div class="new-user">
      {{'gdpr_signin_text' | translate}}  <a id="gtm_email_confm_login_redirect" [routerLink]="signInUrl" [translate]="'gdpr_click_here_text'"></a></div>
  </div>
</section>

