<div *ngIf="marketConsentRes" class="form-group">
  <div class="check-box-div">
    <input type="checkbox" [checked]="(dataShareService.$isMarketingConsent | async).selectAll"
           (change)="onChangeCheck('selectall', $event)">
    <label [textContent]="selectAllText"></label>
  </div>
  <div>
    <div class="form-group ml-0" [formGroup]="parent" *ngIf="confirmationText">
      <label *ngIf="consentBox1.length>0" class="checkbox-container">
        <input name="Policy" formControlName="privacyconsent1"
               [checked]="(dataShareService.$isMarketingConsent | async).privacy"
               (change)="onChangeCheck('privacy', $event)" type="checkbox" value="">
        <ng-container *ngFor="let item of consentBox1">
      <span> {{item.consentText1}} <a *ngIf="item.link.indexOf('email-confirmation')>-1"
                                      id="gtm_privacy_policy_consentText2"
                                      [href]="item.link">{{item.consentText2}}</a><a
        *ngIf="item.link.indexOf('email-confirmation')==-1" [href]="item.link" target="_blank">{{item.consentText2}}</a></span>
        </ng-container>
      </label>
      <span class="error-msg" *ngIf="privacyPolicyErrorMsg" [translate]="'policy_validation_error1'"></span>
      <label *ngIf="consentBox2.length>0" class="checkbox-container">
        <input name="Policy" formControlName="privacyconsent2" type="checkbox" value="">
        <ng-container *ngFor="let item of consentBox2">
      <span> {{item.consentText1}} <a *ngIf="item.link.indexOf('email-confirmation')>-1"
                                      id="'gtm_privacy_policy_consentText2"
                                      [href]="item.link">{{item.consentText2}}</a><a
        *ngIf="item.link.indexOf('email-confirmation')==-1" [href]="item.link" target="_blank">{{item.consentText2}}</a></span>
        </ng-container>
      </label>
      <label *ngIf="consentBox3.length>0" class="checkbox-container">
        <input name="Policy" formControlName="privacyconsent3" type="checkbox" value="">
        <ng-container *ngFor="let item of consentBox3">
          &nbsp;<span [textContent]="item.consentText1"></span>&nbsp;<a id="gtm_privacy_policy_consentText_2"
                                                                        *ngIf="item.link.indexOf('email-confirmation')>-1"
                                                                        [routerLink]="item.link"
                                                                        [textContent]="item.consentText2"></a>
          <a id="gtm_privacy_policy_consentText" *ngIf="item.link.indexOf('email-confirmation')==-1" [href]="item.link"
             target="_blank"
             [textContent]="item.consentText2"></a>
        </ng-container>
      </label>
    </div>
    <div
      *ngIf="formSubmitted && !validationCheck && !isChecked">
        <span class="error-msg"
              [translate]="'policy_validation_error'"></span>
    </div>
    <span class="error-msg" *ngIf="firstPrivacyError" [translate]="'policy_validation_error'"></span>
  </div>
  <div class="check-box-div">
    <p class="checkbox-container" [textContent]="consentText"></p>
  </div>
  <div class="check-box-div">
    <input type="checkbox" [checked]="(dataShareService.$isMarketingConsent | async).email"
           (change)="onChangeCheck('email', $event)">
    <label [textContent]="emailText"></label>
  </div>
  <div class="check-box-div">
    <input type="checkbox" [checked]="(dataShareService.$isMarketingConsent | async).sms"
           (change)="onChangeCheck('sms', $event)">
    <label [textContent]="smsText"></label>
  </div>
  <div class="check-box-div">
    <input type="checkbox" [checked]="(dataShareService.$isMarketingConsent | async).whatsApp"
           (change)="onChangeCheck('whatsapp', $event)">
    <label [textContent]="whatsAppText"></label>
  </div>
</div>
