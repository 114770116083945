export class Site {
  domain: string;
  locale: string;
  siteLocale: string;
  constructor(domain: string, locale: string) {
    this.domain = domain;
    this.locale = locale;
    this.siteLocale = locale + '_' + domain;
  }

}
