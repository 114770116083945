import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {SiteService} from '../../services/site/site.service';

@Component({
  selector: 'app-dms-thank-you-page',
  templateUrl: './dms-thank-you-page.component.html',
  styleUrls: ['./dms-thank-you-page.component.scss']
})
export class DMSThankYouPageComponent implements OnInit {

  public query:any;
  public message:any;

  constructor(private activateRoute: ActivatedRoute, private translate: TranslateService, private _siteService: SiteService) {
    this.translate.setDefaultLang(this._siteService.getSiteLocale());
  }

  ngOnInit() {

    this.activateRoute.queryParams.subscribe(params => {
      if (params) {
        this.query = params;
        if (this.query.DocExchange === 'Y') {
          this.message="SUCCESS";
        }
        if(this.query.DocExchange === 'N'){
          this.message="FAIL";
        }
      }
    });

  }

}
