import {RequestService} from '../../../services/request/request.service';
import {
  APP_ID,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  SecurityContext,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {map} from 'rxjs/operators';
import {ChangeLanguage, ChangeLanguageAdapt, Language} from '../../../models/header/change-language';
import {SiteService} from '../../../services/site/site.service';
import {
  clientConfigRequest,
  HeaderAdapter,
  HeaderMenu,
  HeaderRequestJson,
  LeftTop,
  RightTop,
  UsefulLinkData
} from '../../../models/header/header-menu';
import {apiEndPoints, config, environment, routingEndpoint} from '../../../../../environments/root/environment';
import {TranslateService} from '@ngx-translate/core';
import {TokenStorage} from '../../../services/tokenStorage/token-storage';
import {DOCUMENT, isPlatformServer, PlatformLocation} from '@angular/common';
import {DataSharingService} from '../../../../jobportal/service/data-sharing.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ExtraFieldsConfig, GoogleJobResponse} from '../../../../jobportal/model/response/google-job-response';
import {JobSearchService} from '../../../../jobportal/service/job-search.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {PersonalDetails} from '../../../../jobportal/model/response/my-profile';
import {CookieService} from 'ngx-cookie-service';
import { ModalService } from 'src/app/erp-integration/service/modal.service';
import {DomSanitizer, Meta, Title, TransferState} from "@angular/platform-browser";
import { DynamicPageTitlesUtility } from 'src/app/jobportal/shared/utilitiy/dynamicPageTitles';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers:[ChangeLanguageAdapt,HeaderAdapter],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  public languages:Language[];
  public liferaybaseUrl:string;
  public imageRedirectUrl:string;
  public imageUrl:string;
  public leftTopHeader:LeftTop[]=[];
  public rightTopHeader:RightTop[]=[];
  public usefullinks:UsefulLinkData[]=[];
  private mobileSearchClicked: boolean;
  private mobileMenuClicked: boolean;
  public alertCount:any;
  public myProfileRouterLink:any;
  public addThisData:string[];
  public loggedInUser=false;
  private extraFieldConfig: ExtraFieldsConfig;
  private callbackFunction=false;
  public hostName: string;
  private lastScroll: number;
  public stickyClass: boolean;
  public gtmmScript: string;
  public gtmCode: string;
  public baseUrl: string;
  public location: string="";
  public keyWord: string="";
  public jobCount: string;
  public emailAddress :string;
  @ViewChild('iframe', {static: false}) iframe :ElementRef;
  private personalDetails: PersonalDetails;
  public orgName: string;
  public cdnScript1: any;
  isAcceptedTnC: boolean;
  isOpenModal: boolean;
  isChecked: boolean = false;
  showSearch: boolean = false;
  /**
   * Determines dynamic tags are enabled or not
   */
  public dynamicPageTitleMetaTags: boolean = false;
  /**
   * Captures dynamic header text shown underneath search boxes in search page based upon user search criteria
   */
  public dynamicSearchText: string = '';

  private countrySpecificLocale: any;
  htsBaseUrl: any;
  /**
   * If usefulprivacy links navigation item to be displayed or not in the page header
   */
  public isUsefulPrivacyEnabled: boolean = false;
  /**
   * List of orgs for which useful privacy links is disabled
   */
  private usefulPrivacyDisabledOrgsCollection = ['yourrailindustryjob', 'geniuswithin'];
  /**
   * If home navigation link item to be displayed or not in the page header
   */
  public isHomeNavigationLinkEnabled: boolean = false;
  /**
   * List of orgs for which home navigation link is disabled
   */
  private homeNavigationDisabledOrgsCollection = ['yourrailindustryjob'];
  /**
   * If worldwide navigation link item to be displayed or not in the page header
   */
  public isWorldwideNavigationEnabled: boolean = false;
  /**
   * List of orgs for which worldwide navigation link is disabled
   */
  private worldwideNavigationDisabledOrgsCollection = ['yourrailindustryjob'];

  constructor(private requestService: RequestService,private languageAdapt:ChangeLanguageAdapt,private siteservice:SiteService,
              private headerAdapter:HeaderAdapter, private translation:TranslateService,private tokenStorage:TokenStorage,private dataSharing:DataSharingService,
              private activateRoute:ActivatedRoute,private cookieService:CookieService, private modalService: ModalService,
              private renderer2:Renderer2,@Inject(DOCUMENT) private document: Document, public route:Router,private jobSearchService:JobSearchService, private deviceDetector:DeviceDetectorService, private sanitized: DomSanitizer,
              private state: TransferState, @Inject(PLATFORM_ID) private platformId: object,
              @Inject(APP_ID) private appId: string, private platformLocation: PlatformLocation, private titleService: Title, private metaService: Meta,private _siteService: SiteService) {
    if(isPlatformServer(this.platformId)){
      this.hostName = this.platformLocation.hostname;
    }else {
        this.hostName = location.hostname;
    }
    this.orgName = this.route.routerState.snapshot.root.firstChild.params["orgName"];
    this.baseUrl=config.baseWebAppUrl+'/prjs';
    this.liferaybaseUrl=environment[config.theme+this.siteservice.getSiteLocale()][apiEndPoints.liferaybaseUrl];
    this.htsBaseUrl = environment[config.theme+this.siteservice.getSiteLocale()][apiEndPoints.htsBaseUrl];
    this.getClientConfig();
    this.translation.setDefaultLang(this.siteservice.getSiteLocale());
    this.translation.getTranslation(this.siteservice.getSiteLocale()).subscribe(response => {
      this.countrySpecificLocale = response;
    });

    this.jobSearchService.sharedCount.subscribe(count => this.jobCount = count);
    this.jobSearchService.sharedKeyWord.subscribe((keyWord) => {
      this.keyWord = keyWord;
      if(this.dynamicPageTitleMetaTags)
        this.generateUserSearchSpecificHeader();
    });
    this.jobSearchService.sharedLocation.subscribe((location) => {
      this.location = location;
      if(this.dynamicPageTitleMetaTags)
        this.generateUserSearchSpecificHeader();
    });

    this.gtmCode = environment[config.theme + this.siteservice.getSiteLocale()]['config']['gtm_code'];
    this.showSearch=environment[config.theme+this.siteservice.getSiteLocale()]['config']['showSearch'];
    this.dynamicPageTitleMetaTags = environment[config.theme+this.siteservice.getSiteLocale()]['config']['dynamicPageTitleMetaTags'];
    this.myProfileRouterLink=environment[config.theme + this.siteservice.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.myProfile];
    if(this.orgName)
      this.myProfileRouterLink = (environment[config.theme + this.siteservice.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.myProfile]).replace(':orgName',this.orgName);
    if(this.tokenStorage.getLoggedInToken()) {
      this.loggedInUser = true;
      this.personalDetailsResponse();
    }
    // Haystech redirection one locale to another locale for SE
    if (this.siteservice.getDomain() === 'SE' && this.siteservice.getOrgName() === 'it'  && this.siteservice.getLocale() === 'sv' ) {
      let lang ='';
        const queryParams = { lang: 'en' };

        this.route.navigate(
          [],
          {
            relativeTo: this.activateRoute,
            queryParams: queryParams,
            queryParamsHandling: 'merge',
          }).then(() => {
         // window.location.reload();

         localStorage.setItem('locale', 'en');
         localStorage.setItem('siteLocale', 'en_SE');
         window.location.href =  window.location.href;
        });
    }
    if(this.siteservice.getDomain() && this.siteservice.getDomain() === 'TH' &&  window.location.href.indexOf('recruiting-now') > -1){
      this.route.navigate(["/"]).then(result=>{window.location.href = `${this.liferaybaseUrl}/register-a-vacancy`});
    }
  }
  private personalDetailsResponse(){
    this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getpersonaldetails,null,null).subscribe(
      (res)=>{
        if(res.data && res.status==config.SUCCESS)
          this.personalDetails=res.data;
        this.emailAddress=this.personalDetails.email;
        this.dataSharing.setPersonalDetails(this.personalDetails);
        let cookieDomainName = environment[config.theme + this.siteservice.getSiteLocale()][apiEndPoints.cookieDomain];
        if(!this.cookieService.get('usrname')){
          this.cookieService.set('usrname', this.personalDetails.name, 0, '/', cookieDomainName);
        }
        if(!this.cookieService.get('PortalId')){
          this.cookieService.set('PortalId',this.personalDetails.portalProfileId,0,'/',cookieDomainName);
        }
      },(error)=>{
      });
  }

  getClientConfig(){
      let payload = new clientConfigRequest(this.orgName? location.origin+'/'+this.orgName+'/' : location.origin+'/');
      if(isPlatformServer(this.platformId))
       payload = new clientConfigRequest(this.orgName? 'https://'+this.hostName+'/'+this.orgName+'/' : 'https://'+this.hostName+'/');
       if(this.hostName == 'localhost')
        payload = new clientConfigRequest(this.orgName? this.hostName+'/'+this.siteservice.getDomain().toUpperCase()+'/'+this.orgName+'/'  : this.hostName+'/'+this.siteservice.getDomain().toUpperCase()+'/');
      this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getClientConfig, payload)
        .subscribe(response => {
          if (response.status === config.SUCCESS && response.data) {
            this.dataSharing.sendClientConfig(response.data);
            this.imageRedirectUrl=response.data.logoRedirectUrl;
            //this.imageUrl = response.data.logoImageUrl;
            //console.log(this.deviceDetector.isMobile());
            //console.log(this.deviceDetector.isDesktop());
            this.imageUrl = this.deviceDetector.isMobile()? response.data.logoImageMobileUrl: response.data.logoImageUrl;
            if(this.orgName === 'yourrailindustryjob'){
              this.liferaybaseUrl = `${environment[config.theme + this.siteservice.getSiteLocale()][apiEndPoints.liferayclientBaseUrl]}/yourrailindustryjob`;
            }
            else{
              this.liferaybaseUrl =  response.data.logoRedirectUrl;
            }

          }
        }, error => {
        });
  }

  ngOnInit() {
    if(isPlatformServer(this.platformId)){
      if(this.gtmCode) {
      this.gtmmScript="(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});" +
        "var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';" +
        "j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})" +
        "(window,document,'script','dataLayer','"+this.gtmCode+"');";
        let script = this.renderer2.createElement('script');
        script.text = `${this.gtmmScript}`;
        this.renderer2.insertBefore(this.document.head, script,this.document.head.firstChild)
        let noscript = this.renderer2.createElement('noscript');
        let iframe = this.renderer2.createElement('iframe');
        iframe.src = "//www.googletagmanager.com/ns.html?id=" + this.gtmCode;
        iframe.width = "0";
        iframe.height = "0";
        iframe.style = "display:none;visibility:hidden";
        noscript.appendChild(iframe);
        this.renderer2.insertBefore(this.document.body, noscript, this.document.body.firstChild);
        this.addAppleItunesMeta();
      }
    }
    this.isUsefulPrivacyEnabled = this.determineEnabledDisabledLinks(this.usefulPrivacyDisabledOrgsCollection);
    this.isHomeNavigationLinkEnabled = this.determineEnabledDisabledLinks(this.homeNavigationDisabledOrgsCollection);
    this.isWorldwideNavigationEnabled = this.determineEnabledDisabledLinks(this.worldwideNavigationDisabledOrgsCollection);
      //this.checkCaptchaDisabled();
      this.getHeaderMenu();
      this.getAlertCount();
      if(this.dynamicPageTitleMetaTags)
        this.getInitialHeaderTagContent();
      this.setPageMetaTag();
  }
  /**
   * Method to check if the orgname exists in the useful privacy disabled links collection
   * @param orgsCollection
   * @returns
   */
  private determineEnabledDisabledLinks(orgsCollection){
    if(orgsCollection.indexOf(this.orgName) > -1){
      return false;
    }
    return true;
  }

  /*private checkCaptchaDisabled() {
    const isCaptchaValue = environment[config.theme + this.siteservice.getSiteLocale()]['config']['isCaptchaValueEnabled'];
    localStorage.setItem("disableCaptcha",isCaptchaValue);
  }*/

  showDropDown={
    showData: false
  };

  redirectTo(url){
    window.location.href=url;
  }

  openRoutingUrl(url) {
    let isClientUrl;
    config.ExpertClientsName.forEach(a => { if (url == (a.concat('/'))) isClientUrl = true; })
    if (isClientUrl) {
      this.isOpenModal = true;
      this.modalService.open('send_cv_confirmation');
    } else {
      if (url.indexOf('jobportal/') > -1) {
        url = this.baseUrl + url;
        this.sendClassToMainContainer('');
        if (url.indexOf('/s/signout/submit/logout') > -1) {
          url = url.replace('/s/signout/submit/logout', '/as/user/submit/login');
          this.loggedInUser = false;
          url = url + "?logout=success";
        }
      } else if (url.indexOf('/logout') > -1 && url.indexOf('onmicrosoft.com') === -1) {
        let param = '/logout';
        if (this.orgName)
          param = '/' + this.orgName + param;
        url = window.location.origin + param;
      }
      if(url.indexOf("prjsauthapp")>-1 && this.route.url.indexOf("apply")==-1 && this.route.url.indexOf("/partner/go1/")==-1 && this.route.url.indexOf("Job/Details")==-1 && url.indexOf("logout")==-1) {
        debugger;
        localStorage.setItem("requstedRedirectURL", window.location.href);
        url=url+"?redirectBackURL="+window.location.pathname;
      }
      if(url.indexOf('action=logout')>-1){
        this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.logoutUser,null,null).pipe().
        subscribe(response => {
          this.tokenStorage.clearCacheAndCookie();
          window.location.href = url;
        },error => {
          this.tokenStorage.clearCacheAndCookie();
          window.location.href = url;
        });

      }
      window.location.href = url;
    }
  }

  public sendClassToMainContainer(data){
    if(data==='search-active') {
      this.mobileSearchClicked = !this.mobileSearchClicked;
      this.dataSharing.sendMainComponentData(this.mobileSearchClicked ? data : '');
    }else{
      this.mobileMenuClicked = !this.mobileMenuClicked;
      if(!this.mobileMenuClicked)
        this.mobileSearchClicked=false;
      this.dataSharing.sendMainComponentData(this.mobileMenuClicked ? data : '');
    }
  }

  private getLanguages(){
    this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.countryLanguage).pipe(
      map((res:ChangeLanguage)=> this.languageAdapt.adapt(res)))
      .subscribe((res)=>{
        if(res.status==config.SUCCESS && res.data)
          this.languages=res.data;
      },(error)=>{
      });
  }

  private getAlertCount(){
    this.dataSharing.sharedUpcomingInterviewData.subscribe((res)=>{
      this.alertCount=res;
    })
  }

  private getHeaderMenu(){
    this.activateRoute.queryParams.subscribe((params)=>{
      if(params['ChangeEmail']=='Y' || params['ChangeEmail']=='N' || params['ChangePassword'] == 'Y' || params['ChangePassword'] == 'N' || params['logout'] == 'success' ||
        params['q'] == 'unauthorised' || params['signed']==='Y') {
        this.loggedInUser=false;
        this.dataSharing.sharedHeaderData.subscribe((res) => {
          this.usefullinks = [];
          this.rightTopHeader = [];
          this.leftTopHeader = [];
          if (res){
            this.callbackFunction=true;
            if(res.data['PageData']['Menu']['RightTop']) {
              res.data['PageData']['Menu']['RightTop'].forEach((item) => {
                if (this.orgName)
                  item.resource_url = this.orgName + item.resource_url;
                this.rightTopHeader.push(item);
              });
            }
            if(res.data['UsefulLinkData'])
                this.usefullinks = res.data['UsefulLinkData']['results'];
                if( res.data['PageData']['Menu']['LeftTop']) {
          res.data['PageData']['Menu']['LeftTop'].forEach((item) => {
            if ((item['resource_url'].indexOf('online-learning') > -1) || (item['resource_url'].indexOf('salary-checker') > -1) || (item['resource_url'].indexOf('hays-connect') > -1) || (item['resource_url'].indexOf('customer-service') > -1)
              || (item['resource_url'].indexOf('about-hays') > -1) || (item['resource_url'].indexOf('what-we-offer') > -1)|| (item['resource_url'].indexOf('life-at-hays') > -1)) {
             /* if(this.orgName)
                item.resource_url = this.liferaybaseUrl +'/'+this.orgName+ item.resource_url;
              else*/
                item.resource_url = this.liferaybaseUrl + item.resource_url;
            }
            else{
              if(this.orgName)
                item.resource_url = this.orgName+item.resource_url;
            }
            this.leftTopHeader.push(item);
          });
        }
        }
        })

      }
    });

    let headerJson= new HeaderRequestJson(
      '',
      environment[config.theme + this.siteservice.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.header]
    );
    let endPoint=apiEndPoints.pagemanagement;
    if(!this.callbackFunction) {
      this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, endPoint, headerJson).pipe(
        map((res: HeaderMenu) => this.headerAdapter.adapt(res)))
        .subscribe((res) => {
          this.usefullinks = [];
          this.rightTopHeader = [];
          this.leftTopHeader = [];
          if (res.status == config.SUCCESS && res.data) {
           // this.dataSharing.sendHeaderData(res);
            if(res.data['PageData']['Menu']['RightTop']) {
              res.data['PageData']['Menu']['RightTop'].forEach((item) => {
                if (this.orgName && item.resource_url.indexOf('http')===-1)
                  item.resource_url = this.orgName + item.resource_url;
                if(this.orgName && item.resource_url.indexOf('http')>-1) {
                  item.resource_url = item.resource_url.indexOf('/hays/')>-1 ? item.resource_url.replace('/hays/','/'+this.orgName+'/'):item.resource_url;
                }
                this.rightTopHeader.push(item);
              });
            }
            if (res.data['UsefulLinkData'])
              this.usefullinks = res.data['UsefulLinkData']['results'];
              if(res.data['PageData']['Menu']['LeftTop']) {
            res.data['PageData']['Menu']['LeftTop'].forEach((item) => {
              if(this.orgName && this.orgName === 'yourrailindustryjob' && !(item['resource_url'].indexOf('salary-checker') > -1) && !(item['resource_url'].indexOf('shortlisted-jobs')>-1) && !(item['resource_url'].indexOf('/search') > -1)){
                item.resource_url = item.resource_url;
              }
              else if(this.orgName && this.orgName === 'geniuswithin' && !(item['resource_url'].indexOf('/search') > -1)){
                item.resource_url = item.resource_url;
              }
              else if ((item['resource_url'].indexOf('online-learning') > -1) || (item['resource_url'].indexOf('salary-checker') > -1) || (item['resource_url'].indexOf('hays-connect') > -1) || (item['resource_url'].indexOf('customer-service') > -1)
                || item['resource_url'].indexOf('about-hays')>-1 || item['resource_url'].indexOf('what-we-offer')>-1 || item['resource_url'].indexOf('life-at-hays')>-1) {

                  if(this.orgName == config.HaysHTS)
                item.resource_url = this.htsBaseUrl + item.resource_url;
              else
                item.resource_url = this.liferaybaseUrl + item.resource_url;
              }
              else{
                if(this.orgName)
                item.resource_url = (this.orgName == config.HaysHTS && item['resource_url'].indexOf('shortlisted-jobs')>-1) ? this.orgName + item.resource_url
                                :this.orgName == config.HaysHTS ? this.htsBaseUrl + item.resource_url
                                :this.orgName + item.resource_url;
              }
              this.leftTopHeader.push(item);
            });
            this.liferaybaseUrl=environment[config.theme+this.siteservice.getSiteLocale()][apiEndPoints.liferaybaseUrl];
          }
          }
        })
    }
  }
  @HostListener('window:scroll')
  onWindowScroll() {
    var scroll = window.pageYOffset;
    this.stickyClass = scroll < this.lastScroll;
    if(scroll < 100){
      this.stickyClass= false;
    }
    this.lastScroll = scroll;
  }

  ngAfterViewInit(){
    this.addScripts();
  }

  private addScripts() {
   // this.iframe.nativeElement.setAttribute('src','//www.googletagmanager.com/ns.html?id='+this.gtmCode);
    let opt_url=environment[config.theme + this.siteservice.getSiteLocale()]['config']['CDN_OPTIMIZELY_URL'];
    if(opt_url) {
      let cdnScript = this.renderer2.createElement('script');
      cdnScript.src = environment[config.theme + this.siteservice.getSiteLocale()]['config']['CDN_OPTIMIZELY_URL'];
     // cdnScript.integrity = "sha256-QszWsoWrLHbvyq0SCBggSb7nJ3Fobkfy6hIWnfsY3Xg=";
      cdnScript.crossorigin = "anonymous";
      if (cdnScript)
        this.renderer2.appendChild(this.document.head, cdnScript);
    }

    let isZoopimEnabled =environment[config.theme + this.siteservice.getSiteLocale()]['config']['zopim_enabled'];
    if(isZoopimEnabled){
      let zoopimEnabledDevices = environment[config.theme + this.siteservice.getSiteLocale()]['config']['zopim_enabled_device'];
      let zoopimEnabledDevicesList :string [];
      zoopimEnabledDevicesList = zoopimEnabledDevices.split(',');
      let isDesktopDevice = this.deviceDetector.isDesktop(window.navigator.userAgent);
      let isMobileEnabled =this.deviceDetector.isMobile(window.navigator.userAgent);
      let isTablet =this.deviceDetector.isTablet(window.navigator.userAgent);
      let userAgent = this.jobSearchService.getUserAgent(window.navigator.userAgent);
      for(let i=0;i<zoopimEnabledDevicesList.length;i++){
        if((zoopimEnabledDevicesList[i]==='-Desktop' && isDesktopDevice) || (zoopimEnabledDevicesList[i]==='-Mobile' && isMobileEnabled) ||
          (zoopimEnabledDevicesList[i]==='-Tablet' && isTablet)){
          let zopimScript = this.renderer2.createElement('script');
          zopimScript.src=environment[config.theme + this.siteservice.getSiteLocale()]['config']['zopim_key_withURL'];
          zopimScript.async=`async`;
          this.renderer2.appendChild(this.document.head, zopimScript);
          break;
        }
      }
    }
    this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.jobflowconfig, null, null).subscribe(res => {
      if (res.status == config.SUCCESS) {
        this.extraFieldConfig = res.data;
        this.jobSearchService.setExtraConfigField(this.extraFieldConfig);
        let trustarc_hostname=environment[config.theme+this.siteservice.getSiteLocale()][apiEndPoints.cookieBannerDomain];
        if(trustarc_hostname)
          trustarc_hostname=trustarc_hostname.substr(1);
        let lang = this.siteservice.getLocale();
        if(lang === 'br'){
          lang = 'pt';
        }
        if(trustarc_hostname) {
          let trustarc_script = this.renderer2.createElement('script');
          trustarc_script.src = `//consent.trustarc.com/notice?domain=` + trustarc_hostname + `&c=teconsent&js=nj&noticeType=bb&gtm=1&text=true&privacypolicylink=`
            + this.liferaybaseUrl + this.extraFieldConfig.gdprPolicyLink + `&language=` + lang;
          trustarc_script.async = `async`;
          trustarc_script.crossorigin = `crossorigin`;
          this.renderer2.appendChild(this.document.head, trustarc_script);
        }
        this.checkChangeLanguageDisable(this.extraFieldConfig.disableChangeLangDropDown);
      }
    });


  }

  changeLanguage(event)
  {
    this.liferaybaseUrl=environment[config.theme+this.siteservice.getSiteLocale()][apiEndPoints.liferaybaseUrl];
    if(event.target.value && event.target.value.indexOf('update_language') > -1) {
      const pageUrl = this.route.routerState.snapshot.url;
      if(pageUrl.indexOf('update-your-privacy') > -1){
        this.handelUpdateYourPrivacyPageChangeLang(event);
      }else {
        let url = this.liferaybaseUrl + event.target.value ;
        window.location.href= url ;
      }
    }
    else {
      const queryParams = { lang: event.target.value  };
      this.route.navigate(
        [],
        {
          relativeTo: this.activateRoute,
          queryParams: queryParams,
          queryParamsHandling: 'merge',
        }).then(() => {
          window.location.reload();
        });
    }
  }

  handelUpdateYourPrivacyPageChangeLang(event){
    const locale = event.target.value.indexOf('en_CN') > -1 ? 'en':'cn'
    const queryParams = { lang: locale  };
    this.route.navigate(
      [],
      {
        relativeTo: this.activateRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      }).then(() => {
        //window.location.reload();
        window.location.href =  window.location.href;
      });
  }
  getConsentChecked($event: boolean) {
    this.isChecked = $event;
  }
  closeModal(id: string)
  {
    this.modalService.close(id);
    this.isChecked = false;
  }
  sendCV(id) {
    config.ExpertClientsName.forEach(a => {
      if (this.orgName == a) {
      this.isChecked = false;
      this.modalService.close(id);
      window.location.href = this.orgName == 'careers'? "mailto:UKI.careers@hays.com?subject=Hays UK Careers CV Submission" : "mailto:HTSUK.Careers@hays.com?subject=HTS HAYS UK CAREERS CV SUBMISSION";
    }
  })

  }

  private checkChangeLanguageDisable(isDisable: boolean){
    if(isDisable) {
      const pageUrl = this.route.routerState.snapshot.url;
      if(pageUrl.indexOf('Job/Detail') === -1 && pageUrl.indexOf('job/detail') === -1 && (pageUrl.indexOf('job/') === -1 && pageUrl.indexOf('/apply') === -1)){
        this.getLanguages();
      }
    }else {
      this.getLanguages();
    }
  }
  /**
   * Method to retrieve user specific search header
   */
  private generateUserSearchSpecificHeader(){
    if(this.countrySpecificLocale){
      this.dynamicSearchText = DynamicPageTitlesUtility.getContentBasedOnSearch(this.location, this.keyWord, environment[config.theme+this.siteservice.getSiteLocale()]['config']['country_name'], this.countrySpecificLocale, 'headerH1Tag');
    }
  }
  /**
   * Extracts location and job title from URL and sets the header
   */
  private getInitialHeaderTagContent(){
    if(this.platformLocation.href.indexOf('/search') > -1){
      const routeURL = this.platformLocation.search;
      const urlParams = new URLSearchParams(routeURL);
      if(urlParams){
        if(urlParams.get('location')) {
          this.location = urlParams.get('location');
        }
        if(urlParams.get('q')){
          this.keyWord = urlParams.get('q');
        }
        this.generateUserSearchSpecificHeader();
      }
    }
  }
  setPageMetaTag() {

    const routeURL = this.platformLocation.href;
    const siteBaseURL = environment[config.theme + this.siteservice.getSiteLocale()]['siteBaseURL'];
    if (routeURL && routeURL.indexOf('/search') > -1) {
      this.jobSearchService.sharedConfigList.subscribe(domainConfig => {
        if (domainConfig) {
          this.setSearchPageMetaTags(siteBaseURL);
        }
      });
    }
  }

  /*setPageMetaTags(){
    const routeURL =  this.platformLocation.href;
    if (routeURL && routeURL.indexOf('/search') > -1) {
      this.jobSearchService.sharedConfigList.subscribe(domainConfig => {
    if(domainConfig){
      this.setSearchPageMetaTags();
    }
    });
    }else if(routeURL && (routeURL.indexOf('/job/browse/details') > -1 || routeURL.indexOf('/Job/Detail') > -1 || routeURL.indexOf('/SeekAuth') > -1)){
    this.jobSearchService.sharedJobDetailData.subscribe(jobDetailData=> {
      if(jobDetailData){
        this.setDetailsPageMetaTags(jobDetailData);
      }
    });
    }else {
      const siteBaseURL = environment[config.theme + this.siteservice.getSiteLocale()]['siteBaseURL'];
      const metaURL =  siteBaseURL + this.platformLocation.pathname;
      this.updateCanonicalUrl(metaURL);
    }
  }
  setDetailsPageMetaTags(googleJobResponse:GoogleJobResponse[]){
    if(googleJobResponse && googleJobResponse.length > 0) {
      this.translation.getTranslation(this.siteservice.getSiteLocale()).subscribe((res)=>{
      const title = googleJobResponse[0].JobTitle+' '+res['page_title'];
      const jobDescription =  googleJobResponse[0].SearchTextSnippet;
      const author = res['page_title'];
      const siteBaseURL = environment[config.theme + this.siteservice.getSiteLocale()]['siteBaseURL'];
      let sharedImageUrl = '';
      const metaURL =  siteBaseURL + this.platformLocation.pathname;
      sharedImageUrl = siteBaseURL +'/assets/images/hays-og-optimize-image-new.png';
      const type = res['hays_publication_events_text'];
      this.titleService.setTitle(title);
      const tags = [
        {property: 'og:title', content: title},
        {name: 'description', content: jobDescription},
        {property: 'og:description', content: jobDescription},
        {name: 'author', content: author},
        {property: 'og:url', content: metaURL},
        {property: 'og:type', content: type},
        {property: 'og:image', content: sharedImageUrl}
      ];
      if(siteBaseURL && siteBaseURL.indexOf('response') > -1) {
        tags.push({property: 'og:image:width', content: '200'});
        tags.push({property: 'og:image:width', content: '200'});
      }else {
        tags.push({property: 'og:image:width', content: '382'});
        tags.push({property: 'og:image:width', content: '200'});
      }
      this.metaService.addTags(tags);
      this.updateCanonicalUrl(metaURL);
      });
    }
  }*/
  updateCanonicalUrl(url:string){
    const head = this.document.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.document.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',url)
  }

  setSearchPageMetaTags(siteBaseURL){
    this.activateRoute.queryParams
    .subscribe(urlParams => {
      this.translation.getTranslation(this.siteservice.getSiteLocale()).subscribe((res)=>{
        const author = res['page_title'];
        const type = res['hays_publication_events_text'];
        let metaTitle: string='';
        let metaLoc: string='';
        let metaDescription: string;
        let jobTitle: string = '';
        if (urlParams['searchPageTitle']) {
          metaTitle = urlParams['searchPageTitle'];
        }
        if (urlParams['location']) {
          metaLoc = urlParams['location'];
        }
        metaTitle = metaTitle ? decodeURI(metaTitle) : '';
        if (urlParams['searchPageDesc']) {
          metaDescription = urlParams['searchPageDesc'];
        }
        if(urlParams['q']){
          jobTitle = urlParams['q'];
        }
        if(environment[config.theme + this.siteservice.getSiteLocale()]['config']['dynamicPageTitleMetaTags']){
          this.updatePageTitle(metaTitle, jobTitle, metaLoc, res, environment[config.theme + this.siteservice.getSiteLocale()]['config']['country_name']);
        }
        metaDescription = metaDescription ? decodeURI(metaDescription) : '';
        let sharedImageUrl = '';
        let metaURL =  siteBaseURL + this.platformLocation.pathname;
        let orgNameList = ['/it/','/cognizant/', '/yourrailindustryjob/', '/geniuswithin/'];
        orgNameList.forEach(orgName => {
          console.log("orgName..",orgName);
          console.log("location path....",this.platformLocation.pathname);
          if (this.platformLocation.pathname.indexOf(orgName) > -1) {
            console.log("inside if ");
            metaURL = siteBaseURL + this.platformLocation.pathname.replace(orgName, '/');
            console.log("meta url.. ",metaURL);
          }
        });
        if(siteBaseURL && siteBaseURL.indexOf('response') > -1) {
          sharedImageUrl = siteBaseURL +'/assets/images/Hays_R_RGB_OG_Sharing.jpg';
        }else {
          sharedImageUrl = siteBaseURL +'/assets/images/hays-og-optimize-image-new.png';
        }
        const tags = [
          {property: 'og:title', content: metaTitle},
          {name: 'description', content: metaDescription},
          {property: 'og:description', content: metaDescription},
          {name: 'author', content: author},
          {property: 'og:url', content: metaURL},
          {property: 'og:type', content: type},
          {property: 'og:image', content: sharedImageUrl}
        ];
        if(siteBaseURL && siteBaseURL.indexOf('response') > -1) {
          tags.push({property: 'og:image:width', content: '200'});
          tags.push({property: 'og:image:width', content: '200'});
        }else {
          tags.push({property: 'og:image:width', content: '382'});
          tags.push({property: 'og:image:width', content: '200'});
        }
        this.metaService.addTags(tags);
        if(environment[config.theme + this.siteservice.getSiteLocale()]['config']['canonicalSearchLinkCompleteURL']){
          metaURL = siteBaseURL + this.platformLocation.pathname + this.platformLocation.search;
        }
        this.updateCanonicalUrl(metaURL);
      });
    }
  );
  }
  /**
   * Method to be called on server side and sets page title
   * @param metaTitle
   * @param jobTitle
   * @param location
   * @param countrySpecificLocale
   * @param countryName
   */
  private updatePageTitle(metaTitle, jobTitle, location, countrySpecificLocale, countryName){
    if(metaTitle){
      this.titleService.setTitle(metaTitle);
    }
    else{
      this.titleService.setTitle(DynamicPageTitlesUtility.getContentBasedOnSearch(location, jobTitle, countryName, countrySpecificLocale, 'title'));
    }
  }
  /**
   * Method to add apple itunes meta
   */
  private addAppleItunesMeta(){
    this.jobSearchService.sharedConfigList.subscribe((domainConfig) => {
      if(domainConfig && domainConfig['appleItunesMeta']) {
        const element : HTMLMetaElement = this.renderer2.createElement('meta');
        element.setAttribute('name', 'apple-itunes-app');
        element.setAttribute('content', 'app-id=1577939990');
        this.renderer2.appendChild(this.document.head, element);
      }
    });
  }
}
