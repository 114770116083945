import {Component, OnInit} from '@angular/core';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import { Router} from '@angular/router';
import {DataSharingService} from '../../../service/data-sharing.service';
import {SiteService} from '../../../../global/services/site/site.service';
import {RequestService} from '../../../../global/services/request/request.service';
import {apiEndPoints, config, keywords} from '../../../../../environments/root/environment';
import {SetTitleService} from '../../../service/set-title.service';
import {AllOfficeAdapt, AllOfficeData} from '../../../model/response/all-offices';

@Component({
  selector: 'app-jp-all-offices',
  templateUrl: './jp-all-offices.component.html',
  styleUrls: ['./jp-all-offices.component.scss'],
  providers:[AllOfficeAdapt]
})
export class JpAllOfficesComponent implements OnInit {
  public allOfficeList : AllOfficeData[]=[];
  public viewGoogleLink:string="";
  public mailtoText:string="";
  public telText:string="";
  public dataFound: boolean;
  public officesOperatingHours: string="";


  constructor(private dataSharingService: DataSharingService, private _translate:TranslateService, private siteService:SiteService,
              private router:Router, private allOfficeAdapt:AllOfficeAdapt,
              private requestService:RequestService,private titleService:SetTitleService)
  {
    this._translate.setDefaultLang(this.siteService.getSiteLocale());
    this._translate.get('view_google_link').subscribe(val => this.viewGoogleLink = val);
    this._translate.get('offices_operating_hours').subscribe(val => this.officesOperatingHours = val);
    this._translate.getTranslation(siteService.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(res['office_locator_page_title']);
    });
    this.mailtoText=keywords.mailto;
    this.telText=keywords.tel;
    this.getAllOfficesList();
    this.dataSharingService.getWidgetListName(keywords.allOfficesWidget);
  }

  ngOnInit() {
    localStorage.removeItem("requstedRedirectURL");
  }
  getAllOfficesList(){
    this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getAllOffice,null,null).pipe(
      map((res: AllOfficeData) => this.allOfficeAdapt.adapt(res)))
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          this.allOfficeList=res.data;
          this.dataFound=true;
        }else{
          this.dataFound=true;
        }
      }, (error) => {
            this.dataFound=true;
      });
  }
}
