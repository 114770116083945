import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SiteService } from 'src/app/global/services/site/site.service';

@Component({
  selector: 'app-confirmation-msg',
  templateUrl: './confirmation-msg.component.html',
  styleUrls: ['./confirmation-msg.component.scss']
})
export class ConfirmationMsgComponent implements OnInit {
  confirmationStatus: any;

  constructor(private activatedRoute : ActivatedRoute, private translate: TranslateService, private _siteService: SiteService, ) {
    this.translate.setDefaultLang(this._siteService.getSiteLocale());
   }

  ngOnInit() {
      this.activatedRoute.queryParams.subscribe(params => {
        this.confirmationStatus = params['specCv'];
    })
  }

}
