import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {ExtraFieldsConfig, GoogleJobResponse} from '../../../../../model/response/google-job-response';
import {JobSearchService} from '../../../../../service/job-search.service';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {TranslateService} from '@ngx-translate/core';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-talk-to-consultant',
  templateUrl: './talk-to-consultant.component.html',
  styleUrls: ['./talk-to-consultant.component.scss']
})
export class TalkToConsultantComponent implements OnInit {
@Input()  consultantDetails:GoogleJobResponse[];
  public configData: ExtraFieldsConfig;
  public jobOwnerLength: number;
  constructor(private jobSearchService:JobSearchService,private siteService:SiteService,private translator:TranslateService,@Inject(PLATFORM_ID) private platformId: object) {
    this.translator.setDefaultLang(this.siteService.getSiteLocale());
  }

  ngOnInit() {
    if(this.consultantDetails && this.consultantDetails.length>0 && this.consultantDetails[0].jobOwner.JobOwner){
      this.jobOwnerLength=Object.keys(this.consultantDetails[0].jobOwner.JobOwner.address).length;
      //console.log("job owner..",this.consultantDetails[0].jobOwner.JobOwner.address.split(','));
    }
    this.jobSearchService.sharedConfigList.subscribe(config=>this.configData=config);
  }
  getAddress(address:string){
    if(isPlatformServer(this.platformId)){
      return address;
    }else {
      if(address){
        return address.split(',');
      }
      return '';
    }
  }

}
