// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environmentLocal.local.tsal.ts` with `environmentLocal.prod.ts`.
// The list of file replacements can be found in `angular.json`.


import {conf} from '../apiconfig/UK/hla/preprod/conf';
import {fr_conf} from '../apiconfig/UK/hla/preprod/fr';
import {gb_conf} from '../apiconfig/UK/hla/preprod/gb';
import {en_GB} from '../apiconfig/UK/jobportal/preprod/en-GB';
import {en_CA} from '../apiconfig/UK/jobportal/preprod/en-CA';
import {cf_CA} from '../apiconfig/UK/jobportal/preprod/cf-CA';
import { ihr_IT } from '../apiconfig/UK/jobportal/preprod/ihr-IT';
import { it_ITL } from '../apiconfig/UK/jobportal/preprod/it-ITL';
import { fr_FR } from '../apiconfig/UK/jobportal/preprod/fr-FR';
import { en_NL } from '../apiconfig/UK/jobportal/preprod/en-NL';
import { nl_NL } from '../apiconfig/UK/jobportal/preprod/nl-NL';
import { pt_PT } from '../apiconfig/UK/jobportal/preprod/pt-PT';
import { hrpt_HRPT } from '../apiconfig/UK/jobportal/preprod/hrpt-HRPT';
import { en_SE } from '../apiconfig/UK/jobportal/preprod/en-SE';
import { en_DK } from '../apiconfig/UK/jobportal/preprod/en-DK';
import { bf_BEL } from '../apiconfig/UK/jobportal/preprod/bf-BEL';
import { bn_BEL } from '../apiconfig/UK/jobportal/preprod/bn-BEL';
import { eb_BEL } from '../apiconfig/UK/jobportal/preprod/eb-BEL';
import { en_LU } from '../apiconfig/UK/jobportal/preprod/en-LU';
import { fr_LU } from '../apiconfig/UK/jobportal/preprod/fr-LU';
import { da_DK } from '../apiconfig/UK/jobportal/preprod/da-DK';
import { sv_SE } from '../apiconfig/UK/jobportal/preprod/sv-SE';
import { en_PL } from '../apiconfig/UK/jobportal/preprod/en-PL';
import { pl_PL } from '../apiconfig/UK/jobportal/preprod/pl-PL';
import {hrpl_HRPL} from '../apiconfig/UK/jobportal/preprod/hrpl-HRPL';
import {es_ES} from '../apiconfig/UK/jobportal/preprod/es-ES';
import {ehr_ES} from '../apiconfig/UK/jobportal/preprod/ehr-ES';
import {en_HU} from '../apiconfig/UK/jobportal/preprod/en-HU';
import {hu_HU} from '../apiconfig/UK/jobportal/preprod/hu-HU';
import {hrhu_HRHU} from '../apiconfig/UK/jobportal/preprod/hrhu-HRHU';
import {hren_HRHU} from '../apiconfig/UK/jobportal/preprod/hren-HRHU';
import {cs_CZ} from '../apiconfig/UK/jobportal/preprod/cs-CZ';
import {hrcz_HRCZ} from '../apiconfig/UK/jobportal/preprod/hrcz-HRCZ';
import {en_CZ} from '../apiconfig/UK/jobportal/preprod/en-CZ';
import {hren_HRCZ} from '../apiconfig/UK/jobportal/preprod/hren-HRCZ';
import {en_RO} from '../apiconfig/UK/jobportal/preprod/en-RO';
import {ro_RO} from '../apiconfig/UK/jobportal/preprod/ro-RO';
import {br_BR} from '../apiconfig/UK/jobportal/preprod/br-BR';
import {mx_MX} from '../apiconfig/UK/jobportal/preprod/mx-MX';
import {co_CO} from '../apiconfig/UK/jobportal/preprod/co-CO';
import {cl_CL} from '../apiconfig/UK/jobportal/preprod/cl-CL';
import {en_VUS} from '../apiconfig/UK/jobportal/preprod/en-VUS';
import {en_IE} from '../apiconfig/UK/jobportal/preprod/en-IE';
import {en_US} from '../apiconfig/UK/jobportal/preprod/en-US';
import {en_AE} from '../apiconfig/UK/jobportal/preprod/en-AE';

export const environment = {
  azure_enable:false,
  platform: 'preprod-uk',
  translations: '/assets/messages/jobportal/UK/messages_',
  production: false,
  apiUrl: 'http://10.53.96.3:8555/hla/ext/',
  hla_conf: conf,
  hla_GB: gb_conf,
  hla_FR: fr_conf,
  jobportal_en_GB:en_GB,
  jobportal_cf_CA: cf_CA,
  jobportal_en_CA: en_CA,
  jobportal_fr_FR: fr_FR,
  jobportal_it_ITL: it_ITL,
  jobportal_ihr_IT: ihr_IT,
  jobportal_en_NL: en_NL,
  jobportal_nl_NL: nl_NL,
  jobportal_pt_PT: pt_PT,
  jobportal_hrpt_HRPT: hrpt_HRPT,
  jobportal_en_SE: en_SE,
  jobportal_sv_SE: sv_SE,
  jobportal_en_DK: en_DK,
  jobportal_bf_BEL: bf_BEL,
  jobportal_bn_BEL: bn_BEL,
  jobportal_eb_BEL: eb_BEL,
  jobportal_en_LU: en_LU,
  jobportal_fr_LU: fr_LU,
  jobportal_da_DK: da_DK,
  jobportal_en_PL: en_PL,
  jobportal_pl_PL: pl_PL,
  jobportal_hrpl_HRPL: hrpl_HRPL,
  jobportal_es_ES: es_ES,
  jobportal_ehr_ES: ehr_ES,
  jobportal_en_HU: en_HU,
  jobportal_hu_HU: hu_HU,
  jobportal_hrhu_HRHU: hrhu_HRHU,
  jobportal_hren_HRHU: hren_HRHU,
  jobportal_cs_CZ: cs_CZ,
  jobportal_hrcz_HRCZ: hrcz_HRCZ,
  jobportal_en_CZ: en_CZ,
  jobportal_hren_HRCZ: hren_HRCZ,
  jobportal_en_RO: en_RO,
  jobportal_ro_RO: ro_RO,
  jobportal_br_BR: br_BR,
  jobportal_mx_MX: mx_MX,
  jobportal_co_CO: co_CO,
  jobportal_cl_CL: cl_CL,
  jobportal_en_AE: en_AE,
  jobportal_en_IE: en_IE,
  jobportal_en_VUS: en_VUS,
  jobportal_en_US: en_US,
  hmac_key: 'GBQ4jfXEzVmpgVW5',
  hmac_secret: 'LBunN4ACXg32jy8g',
  google_captcha_client_key:'6Leb5hUTAAAAAFzQ2ThiPeTqaWujG5kVnsK2QLaZ',
  google_api_key:'AIzaSyDQvGyIJJLhVBRctZOAICL13NR9Wo1Q-uE',
  captchaJs:'https://www.recaptcha.net/recaptcha/api.js',
  getDefaultLang:'/user/browse/v1/getdefaultlang',
  baseAPIURL:'https://moatapi.hays.com/jobportalapi/int/ns/',
  indeedAPIURL:'https://cpubprofile1test.hays.com/portalservice/resources/registration/applyindeed/',
  indeedV2APIURL:'https://cpubprofile1test.hays.com/portalservice/resources/registration/applyindeedv2/',
  indeedExpressConsentAPIURL:'https://cpubprofile1test.hays.com/portalservice/resources/registration/applyindeeddk/',
  friendly_captcha_secret_key:'FCMKMO7GHQBDN53P',
  friendly_captcha_endpoint:'https://api.friendlycaptcha.com/api/v1/puzzle'
};

export const config={
  SUCCESS:'HAYS_200',
  mobNumberPattern:'^((\\+91-?)|0)?[0-9]*$',
  headerUri:'/header',
  notFoundStatus :'HAYS_204',
  fileUnit:['Bytes', 'KB', 'MB', 'GB', 'TB'],
  theme:'jobportal_',
  recruitingNowSuccess:'UC001',
  portalSuccessCode: 'WC000',
  invalidParamCode: 'WC001',
  emailAlreadyVerified: 'WC008',
  emailDoesNotExist:'WC010',
  existingEmailAddress: 'WC017',
  passwordIncorrect: 'WC025',
  loginCounterCode: 'WC002',
  portalFailureCode: 'WC002',
  emailPattern: '^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$',
  youtubePattern:'http(?:s)?:\\/\\/(?:m.)?(?:www\\.)?youtu(?:\\.be\\/|be\\.com\\/(?:watch\\?(?:feature=youtu.be\\&)?v=|v\\/|embed\\/|user\\/(?:[\\w#]+\\/)+))([^&#?\\n]+)',
  youtubeEmbedUrl:'https://www.youtube.com/embed/',
  youtubeEmbedProperties:'?enablejsapi=1&autoplay=1&mute=1',
  passwordPattern: '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$',
  protectedFileUpload: 'Protected file found',
  infectedFileUpload: 'Infected file found',
  usedIdentifiermsg: 'Identifier/Email does not exist',
  usedIdentifierCode : 'WC026',
  expireCode: '997',
  expireMsg: 'Data has been expired no decryption will be performed',
  mobileDevice: 'HaysGCJ-Mobile',
  desktopDevice:'HaysGCJ-Desktop',
  crossCountryUrl:'/jobportal/as/job/browse/details',
  gdprEmailNotExist: 'WC005',
  followHaysUrl:"https://www.linkedin.com/company/hays",
  baseWebAppUrl : typeof window !== "undefined" ? window.location.origin:'',
  haysTechOrgCode:'HAYSTECH003',
  ALREADY_EXIST:'HAYS_409',
  HaysCareerJob: 'HCP005',
  HaysCareer: 'careers',
  HaysHTSJob: 'HTS007',
  HaysHTS: 'hts',
  ExpertClientsName: ['careers','hts'],
  ExpertClientsCode: ['HCP005','HTS007']
};
export const apiEndPoints={
  getDropBoxFiles:'getDropBoxFiles',
  connectdropbox:'connectdropbox',
  go1getTermsAndConditions:'go1getTermsAndConditions',
  getFileLink: 'getFileLink',
  getSector:'getSector',
  getStateList:'getStateList',
  getLocationHierarchy:'getLocationHierarchy',
  getOfficeForSpecialism: 'getOfficeForSpecialism',
  getAllOffice:'getAllOffice',
  getSubSpecialism:'getSubSpecialism',
  getOfficeResultForAPAC:'getOfficeResultForAPAC',
  getLatLongOfficeResult:'getLatLongOfficeResult' ,
  isAutoSugesstionEnabled:'isAutoSugesstionEnabled',
  countryLanguage:'countryLanguage',
  isCaptchaDisabled:'isCaptchaDisabled',
  pagemanagement:'pagemanagement',
  pagemanagementSecure:'pagemanagementSecure',
  locationOnRecruitingNow:'locationOnRecruitingNow',
  employeeType:'employeeType',
  getTermsAndConditions:'getTermsAndConditions',
  submitRecruitingForm:'submitRecruitingForm',
  getBrowseByExpertise:'getBrowseByExpertise',
  showOfficeEmail:'showOfficeEmail',
  getPagemenu:'getPagemenu',
  connectGdrive:'connectGdrive',
  gdriveFileLink:'gdriveFileLink',
  getDriveFiles:'getDriveFiles',
  liferaybaseUrl:'liferaybaseUrl',
  liferayclientBaseUrl:'liferayclientBaseUrl',
  liferayUrl:'liferayUrl',
  liferayCognizantUrl:'liferayCognizantUrl',
  htsBaseUrl: 'htsBaseUrl',
  getIsKanaEnabled:'getIsKanaEnabled',
  getGDriveAndDropbox:'getGDriveAndDropbox',
  register:'register',
  getAutoSuggetion:'getAutoSuggetion',
  gateWayAPI:'gateWayAPI',
  submitEmail: 'submitEmail',
  getgooglejobs:'getgooglejobs',
  jobflowconfig:'jobflowconfig',
  getJobFilters:'getJobFilters',
  decryptIdentifier:'decryptIdentifier',
  validateAlert:'validateAlert',
  getGdprConfig:'getGdprConfig',
  getMarketingPreferences:'getMarketingPreferences',
  updateMarketingPreferences: 'updateMarketingPreferences',
  getCustomerServiceEmail: 'getCustomerServiceEmail',
  createToken:'createToken',
  cookieDomain:'cookieDomain',
  cookieBannerDomain:'cookieBannerDomain',
  validatePage: 'validatePage',
  submitForgetPassEmail: 'submitForgetPassEmail',
  interviewCvTips: 'getInterviewAndCvTips',
  getRecentApplication:'getRecentApplication',
  getUpcomingInterview:'getUpcomingInterview',
  getgooglejobssecure:'getgooglejobssecure',
  getpersonaldetails:'getpersonaldetails',
  getGDriveFiles: 'getGDriveFiles',
  gcsPublish: 'gcsPublish',
  saveAlert: 'saveAlert',
  saveWhatsapp: 'saveWhatsapp',
  updateWhatsapp: 'updateWhatsapp',
  getWhatsappNumber:'getWhatsappNumber',
  getWhatsAppAlerts: 'getWhatsAppAlerts',
  getJobAlerts: 'getJobAlerts',
  saveJob:'saveJob',
  deleteJob: 'deleteJob',
  getSavedJobs: 'getSavedJobs',
  resetPassword:'resetPassword',
  deleteJobAlert: 'deleteJobAlert',
  editAlert: 'editAlert',
  changePassword:'changePassword',
  changeEmail:'changeEmail',
  getCvs:'getCvs',
  downloadCV:'downloadCV',
  deleteCv:'deleteCv',
  getSingleJob: 'getSingleJob',
  uploadcv: 'uploadcv',
  jobApply:'jobApply',
  unsubscribe:'unsubscribe',
  jobAlertVerify:'jobAlertVerify',
  getWidgetList: 'getWidgetList',
  applyLinkedin: 'applyLinkedin',
  getKillerQuestions: 'getKillerQuestions',
  getTimeSheet: 'getTimeSheet',
  getGo1Trainings:'getGo1Trainings',
  seekAuthUrl:'seekAuthUrl',
  seekApply:'seekApply',
  dmsDecryptIdentifier:'dmsDecryptIdentifier',
  submitDMSAlert:'submitDMSAlert',
  viewEmailLink:'viewEmailLink',
  go1generateLink:'go1generateLink',
  signedURL:'signedURL',
  getErpMarketingPref:'getErpMarketingPref',
  updateErpMarketingPref:'updateErpMarketingPref',
  submitGlobalConsent:'submitGlobalConsent',
  erpValidateEmail:'erpValidateEmail',
  changePasswordV2:'changePasswordV2',
  marketingConsent: 'marketingConsent',
  getClientConfig:'getClientConfig',
  deleteAllAlerts: 'deleteAllAlerts',
  updateConsent:'updateConsent',
  sendUnsubscribeEmail:'sendUnsubscribeEmail',
  getMarketingPreferencesDK:'getMarketingPreferencesDK',
  updateMarketingPreferencesDK:'updateMarketingPreferencesDK',
  getLanguageSpoken : 'getLanguageSpoken',
  logoutUser:'logoutUser',
};
export const routingEndpoint={
  routing_url:'routing_url',
  routing_url_org:'routing_url_org',
  errorPage:'errorPage',
  successPage:'successPage',
  officeLocatorResultPage:'officeLocatorResultPage',
  officeLocatorPage:'officeLocatorPage',
  speculativeCV:'speculativeCV',
  forgetPassword:'forgetPassword',
  secureSearch:'secureSearch',
  secureJobDetails:'secureJobDetails',
  login:'login',
  search:'search',
  jobdetails:'jobdetails',
  header:'header',
  footer:'footer',
  myProfile:'myProfile',
  jobApply:'jobApply',
  secureJobApply:'secureJobApply',
  gdprThankyouPage: 'gdprThankyouPage',
  marketingPrefPage: 'marketingPrefPage',
  resetPasswordPage:'resetPasswordPage',
  manageCV: 'manageCV',
  saveWhatsappPage:'saveWhatsappPage',
  updateWhatsappPage:'updateWhatsappPage',
  secureThankYou: 'secureThankYou',
  thankyou: 'thankyou',
  unsubscribePage: 'unsubscribePage',
  alertThankYouPage:'alertThankYouPage',
  emailConfirmationPage:'emailConfirmationPage',
  recruitingNow:'recruitingNow',
  secureRecruitingNow:'secureRecruitingNow',
  viewEmailLinkPage:'viewEmailLinkPage',
  goLoginPage: 'goLoginPage',
  erpMarketingPref:'erpMarketingPref',
  secureErpMarketingPref:'secureErpMarketingPref',
  erpEmailConfirmation: 'erpEmailConfirmation',
  erpManageJobAlert:'erpManageJobAlert',
  secureErpManageJobAlert: 'secureErpManageJobAlert',
  erpThankyouPage: 'erpThankyouPage'
};

export const keywords={
  radiusGeocoder:'radius_geocoder',
  defaultRadius:'default_radius',
  distanceUnit:'distance_unit',
  GetSpecialism:'GetSpecialism',
  GetOfficeForSpecialism:'GetOfficeForSpecialism',
  officeLocPlaceholder:"Enter a city or postal code",
  success:'SUCCESS',
  fail:'FAIL',
  invalid:'INVALID',
  expired:'expired',
  mailto:'mailto:',
  tel:'tel:',
  gdprFlag:'gdprFlag',
  email:'email',
  sms:'sms',
  whatapp:'whatapp',
  survey:'survey',
  resetFlag:'resetFlag',
  changeEmail:'ChangeEmail',
  changePassword: 'ChangePassword',
  findUsWidget: 'find us',
  changeEmailWidget: 'Change email',
  changePasswordWidget: 'change password',
  forgetPasswordWidget: 'forgot password',
  savedSearchesWidget: 'saved searches',
  jobApplyWidget: 'job apply',
  jobDetailWidget: 'job detail',
  specCvWidget: 'Speculative CV',
  allOfficesWidget: 'all offices',
  errorPageWidget: 'error page',
  savedJobsWidget: 'shortlisted jobs',
  jobAppliedWidget: 'job applied',
  myProfileWidget: 'my profile',
  manageCVPageName: 'manageCV',
  loginPageWidget: 'login',
  recruitingNowPageName: 'recruiting-now',
  whatsappPageName:'whatsAppNumber',
  marketingPrefPageName:'marketingPref',
  searchPageName: 'search',
  followHaysText:'follow hays',
  officeLocatorText:'office_locator',
  recentSearchText: 'recent_search',
  recruitingNowText :'recruiting now',
  salaryGuideText: 'salary guide',
  specCvText: 'speculative cv',
  timesheetText: 'timesheet',
  go1TrainingText: 'Go1Training',
  consAndProptyText:'Construction and Property',
  hays:'hays',
  haysGCJ:'HaysGCJ',
  seekApplyUrl:'/seekApply/',
  orgName:'it',
  orgSectorName:'hays:Technology',
  updateYourDetailsUrl:'update-your-details'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
