import {Adapter} from '../../../global/adapter/adapter';

export class MarketingPref {
  constructor(public status: string,
              public message: string,
              public data: MarketingPrefData) {

  }
}

export class MarketingPrefData {
  constructor(
  public  email_address:string,
  public  source_description :string,
  public  channel :string,
  public  country:number,
  public  person_id:number,
  public  locale:string,
  public  portal_profile_id:number,
  public  consent_email:boolean,
  public  consent_publications_art_event:boolean,
  public  consent_roles_of_interest:boolean,
  public  consent_social_and_media:boolean,
  public  consent_survey:boolean,
  public  mrktng_sms:boolean,
  public  whats_mrk:boolean,
  public  gdprTrackEnable:boolean,
  public  emailAdd:string,
  public  candidateID:string,
  public  portalProfileId:string,
) {
  }

}
export class GDPRTrackingEnabled {
  constructor(
    public isGDPRTrackingEnabled: boolean
  ) {
  }

}
export class MarketingPrefJsonRequest {
  constructor(
    public candidateID: string,
    public emailAdd: string,
    public gdprtrackingEnabled: boolean,
    public portalProfileId: string,
  ) {

  }
}
  export class MarketingPrefAdapt implements Adapter<MarketingPref> {
  adapt(item: any): MarketingPref {
    return new MarketingPref(
      item.status,
      item.message,
      item.data
    );
  }
}
