import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {JpLoginComponent} from './components/jp-login/jp-login.component';
import {JpRegisterNewComponent} from '../../../shared/components/jp-register-new/jp-register-new.component';
import {JpSigninComponent} from './components/jp-signin/jp-signin.component';
import {SigninRoutingModule} from './signin-routing.module';
import {SharedModule} from '../../../shared/shared.module';
import {JpForgotPasswordComponent} from './components/jp-forgot-password/jp-forgot-password.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {ResetPassPopupComponent} from './components/reset-pass-popup/reset-pass-popup.component';
import { TransferState } from '@angular/platform-browser';
import { translateBrowserLoaderFactory } from 'src/app/global/services/loaders/translate-browser.loader';


@NgModule({
  declarations: [JpLoginComponent, JpSigninComponent, JpForgotPasswordComponent, ResetPassPopupComponent],
  imports: [
    CommonModule,
    SigninRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
        loader: {
          provide: TranslateLoader,
          useFactory: translateBrowserLoaderFactory,
          deps: [HttpClient, TransferState]
        }
      })

  ],exports:[JpSigninComponent,JpRegisterNewComponent]
})
export class SigninModule { }
