<div class="bg-white similar-jobs" *ngIf="googleSimilarJobResponse && googleSimilarJobResponse.length>0">
  <h3 *ngIf="router.url.indexOf(jobDetailsURL)>-1">{{'similar_jobs_to'| translate}} {{(googleJobResponse && googleJobResponse.length>0) ? googleJobResponse[0].JobTitle:''}}</h3>
  <h3 *ngIf="router.url.indexOf(jobDetailsURL)==-1 && googleSimilarJobResponse.length!==1">{{'similar_jobs_txt'| translate | uppercase}}</h3>
  <ul>
    <li *ngFor="let similarJob of router.url.indexOf(jobDetailsURL)>-1?(googleSimilarJobResponse.length>6?googleSimilarJobResponse.slice(1,6):googleSimilarJobResponse)
    :(googleSimilarJobResponse.length>3?googleSimilarJobResponse.slice(1,4):googleSimilarJobResponse); index as i">
      <div>
        <span (click)="redirectToDetails(similarJob.crossCountryUrl,similarJob.jobRecordId,similarJob.jobId,
        similarJob.isSponsored.toUpperCase(),similarJob.JobTitle,similarJob.locationDesc,
        similarJob.jobName,
        similarJob.siteLocale.split('-')[0],similarJob.siteLocale.split('-')[1],similarJob.micrositeID,similarJob.micrositeUrl)">
        <h4  id="gtm_similarjob"  [textContent]="similarJob.JobTitle" >
        </h4>
          <input type="hidden" [value]="similarJob.jobId">
        </span>
        <div [textContent]="removeHex(similarJob.SearchTextSnippet)"></div>
        <div class="more-info">
          <span class="icon-location" [textContent]="similarJob.locationDesc">

          </span>
          <span [textContent]="similarJob.incentives"></span>
        </div>
      </div>
    </li>
  </ul>
</div>
