<div *ngIf="route.url.indexOf('apply')>-1 && (consentBox1.length>0 || consentBox3.length>0 || consentBox3.length>0 || consentBox2.length>0)" class="modal fade terms-conditions-popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="card-img-overlay"></div>
  <div class="modal-dialog modal-dialog-centered " role="document">
    <div class="modal-content">
      <div class="modal-body">
        <a href="javascript:void(0)" id="closePolicy" class="close" (click)="closePopup()">+</a>
        <h1 [translate]="'social_site_policy_headline'"></h1>
        <p class="instruction" [translate]="'social_site_policy_msg'"></p>
        <label *ngIf="consentBox1.length>0">
          <input name="Policy" [(ngModel)]="checked1" type="checkbox" value="">
          <ng-container *ngFor="let item of consentBox1">
            <span> {{item.consentText1}} <a id="gtm_legal_consent_ec" class="legal-consent-anchor-tag" *ngIf="(item.link.indexOf('email-confirmation')>-1 && item.consentText2)"
                                            [href]="baseUrl+'/'+item.link">{{item.consentText2}}</a>
              <a  id="gtm_privacy_policy" *ngIf="(item.link.indexOf('email-confirmation')==-1 && item.consentText2)" [href]="item.link" target="_blank">
                {{item.consentText2}}</a>
            </span>
          </ng-container>
        </label>
        <span class="error-msg" *ngIf="privacyPolicyErrorMsg1" [translate]="'policy_validation_error'"></span>
        <label *ngIf="consentBox2.length>0">
          <input name="Policy" [(ngModel)]="checked2" type="checkbox" value="">
          <ng-container *ngFor="let item of consentBox2">
            &nbsp;<span [textContent]="item.consentText1"></span>&nbsp;<a *ngIf="item.link.indexOf('email-confirmation')>-1" [routerLink]="item.link" [textContent]="item.consentText2"></a>
            <a id="gtm_legal_consent_consentText2" *ngIf="item.link.indexOf('email-confirmation')==-1"
               [href]="item.link" target="_blank" [textContent]="item.consentText2"></a>
          </ng-container>
        </label>
        <span class="error-msg" *ngIf="privacyPolicyErrorMsg2" [translate]="'policy_validation_error'"></span>
        <label *ngIf="consentBox3.length>0">
          <input name="Policy" [(ngModel)]="checked3" type="checkbox" value="">
          <ng-container *ngFor="let item of consentBox3">
            &nbsp;<span [textContent]="item.consentText1"></span>&nbsp;
            <a *ngIf="item.link.indexOf('email-confirmation')>-1" [routerLink]="item.link" [textContent]="item.consentText2"></a>
            <a id="gtm_legal_consent_consentText_2" *ngIf="item.link.indexOf('email-confirmation')==-1"
               [href]="item.link" target="_blank"
               [textContent]="item.consentText2"></a>
          </ng-container>
        </label>
        <span class="error-msg" *ngIf="privacyPolicyErrorMsg3" [translate]="'policy_validation_error1'"></span>
        <div class="modal-footer">
          <button id="gtm_privacyconsent_submit_btn" type="button" [ngClass]="buttonClicked?'btn-loading':''"
                  [disabled]="buttonClicked" (click)="submitPrivacyConsent()" class="btn btn-default btn-block btn-continue" [translate]="'continue_text'"></button>
        </div>
      </div>
    </div>
  </div>

</div>

