<div class="form-group" [formGroup]="parent">
  <div class="form-group">
    <label  [translate] = "'enter_last_name'"></label>
    <input type="text" class="form-control"  placeholder="{{'enter_last_name_placeholder' | translate}}" formControlName="lastname" required [(ngModel)]="firstName">
    <div *ngIf="parent['controls'].lastname.invalid && (formSubmitted || parent['controls'].lastname.dirty || parent['controls'].lastname.touched)"
  >
    <span class="error-msg" *ngIf="parent['controls'].lastname.errors?.required || parent['controls'].lastname.errors?.whitespace" [translate] = "'last_name_required'">
    </span>
  </div></div>

  <div class="form-group">
    <label [translate] = "'enter_kana_last_name'"></label>
    <input type="text" class="form-control"  placeholder="{{'enter_kana_last_name_placeholder' | translate}}" formControlName="katkanalastname" [(ngModel)]="lastName">
   </div>

  <div class="form-group" >
    <label [translate] = "'enter_first_name'"></label>
    <input type="text" class="form-control"  placeholder="{{'enter_first_name_placeholder' | translate}}" formControlName="firstname" required [(ngModel)]="katkanafirstname">
    <div *ngIf="parent['controls'].firstname.invalid && ( formSubmitted || parent['controls'].firstname.dirty || parent['controls'].firstname.touched)">
<span class="error-msg" *ngIf="parent['controls'].firstname.errors?.required || parent['controls'].firstname.errors?.whitespace"
[translate] = "'first_name_required'">
  </span>
</div></div>

  <div class="form-group">
    <label  [translate] = "'enter_kana_first_name'"></label>
    <input type="text" class="form-control"  placeholder="{{'enter_kana_first_name_placeholder' | translate}}" formControlName="katkanafirstname" [(ngModel)]="katkanalastname">
    </div>
</div>


