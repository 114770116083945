export class GoogleJobResponse {

  constructor(public JobTitle:string,public SearchTextSnippet:string,public locationDesc:string,public incentives:string,
              public description:string,public jobType:string,public industry:string,public specialism:string,public jobRef:string,
              public jobOwner:any,public showJobOwner:string,public jobRecordId: string,public jobId:string,public isSponsored:string
              ,public jobName:string,public siteLocale:string,public clientLogoUrl:string,public createDate:string,public crossCountryUrl:string,
              public sponsoredJob:string,public flexibleWorking:string,public fullTime:string,public partTime:string,public jobAdvertUrl:string,
              public isJobExists:boolean,public micrositeUrl:string,public endDate:string,public xLatitude:string,public xLongitude:string,
              public applicationURL:string,public isJobApplied:boolean,public showClosingDate:boolean,public isLoading:boolean,public micrositeID:string,
              public jobSource:string, public orgCode:string, public xSpecialism:string, public xSubSpecialism: string){}

}
export class JobType {
  constructor(public P:number,public C:number,public T:number){}
}
export class JobTypeFilter {
  constructor(public jobtype:string,public jobCount:number,public jobTypeValue:string){}
}
export class WorkingPattern {
  constructor(public fullTime:number,public partTime:number,public flexibleWorking:number){}
}
export class WorkingPatternFilter {
  constructor(public type:string,public count:number){}
}
export class Industries {
  constructor(public industryName:string,public count:number){}
}
export class SortBy {
  constructor(public type:string,public value:string){}
}
export class PayTypeDataList {
  constructor(public payType:string,public MaxPayRate:MaxPayRate,public MinPayRate:MinPayRate){}
}
export class MaxPayRate {
  constructor(public _key:string,public _value:string){}
}
export class MinPayRate {
  constructor(public _key:string,public _value:string){}
}

export class LocationFilter {
  constructor(public locationName:string,public count:number){}
}
export class JobSearchFilter{
  constructor(public locationf:string,public industryf:string,public payTypeIndex:string,public minSal:string,public minSalValue:string,
              public maxSal:string,public maxSalValue:string,public jobType:string
              ,public flexibleworking:string,public fullTime:string,public partTime:string,public sortType:string,public workingPattern:string,public jobTypeSelected:string,
              public workingTypeIndex:string,public jobtypeIndex:string,public minPayIndex:string,public maxPayIndex:string,public sortTypeIndex:string,public payTypeValue:string){}
}
export class ExtraFieldsConfig {
  constructor(public isEmailAlert:boolean,public isWhatsappAlert:boolean,public responseSiteLocale:string,public isCapatalize:boolean,public isTitleCase:boolean,
              public isCrossCountry:boolean,
              public isResponseJob:boolean,public currency:string,public crossCountryUrl:string,public radius:number,public isWorkingPattern:boolean,public isRecordMF:boolean,
              public isVideoMyJobEnabled:boolean,public isEmployerLogoEnabled:boolean,public showExpiryDatePattern:boolean,public showConsultantDelimeter:boolean
              ,public showConsultantFullAddress:boolean,public isGDPRPrivacyPolicyEnabled:boolean,public gdprPolicyLink:string,public wspCountryCode:string,
              public isHandicap:boolean,public shareOptions:ShareOptions,public applyOptions:ApplyOptions,public findUsLink:string,public isMultiPrivacyPolicyEnabled:boolean,
              public isLegalConsentPopupEnabled:boolean,public isKillerQuestionsEnabled:boolean,public isDualPrivacyEnabled:boolean,public isLegalNameLicence:boolean,
              public licenceLink:string,public isCustomCaptchaEnabled:boolean,public isRecruitingTerm:boolean,public isLocationDisabled:boolean,public findNearMe:boolean,
              public isSentenceCase:boolean,public isAUSHaysOffice:boolean,public isDistanceinKM:boolean, public isMarketingConsentEnabled: boolean,
              public isMarketingConsentForHU: boolean, public isPrivacyConsentEnabled: boolean, public disableChangeLangDropDown: boolean,  public isLanguageProficiencyEnable: boolean,public isGo1UploadCV: boolean, public isLinkedinPrivacy: boolean,
              public appleBadgeFlag: boolean, public gplayBadgeFlag: boolean, public appleItunesMeta: boolean,
              public appleBannerURL: string, public gplayBannerURL: string){}
}
export class ShareOptions {
  constructor(public isTwitter:boolean,public isFacebook:boolean,public isGooglePlus:boolean,public isLinkedin:boolean,public isWhatsapp:boolean,public isEmail:boolean,public isSinaweibo:boolean,
              public isKaixin:boolean,public isBaidu:boolean){}
}
export class ApplyOptions {
  constructor(public isLinkedinApply:boolean,public isIndeedApply:boolean,public isSeekApply:boolean){}
}

export class HistoGram {
  constructor(public locationFilter:LocationFilter,public industries:Industries){}
}
