import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {formatDate, isPlatformServer, PlatformLocation} from '@angular/common';
import * as CryptoJS from 'crypto-js';
import {environment} from '../../../../environments/root/environment';
import {TokenStorage} from '../tokenStorage/token-storage';
import {HttpRequest} from '@angular/common/http';
import {LogsService} from '../logger/logs.service';
import {ActivatedRoute} from '@angular/router';


@Injectable()
export class HmacSecurity {
  hmac_key: string;
  hmac_secret: string;

  constructor(private  _logger: LogsService, private tokenStorage: TokenStorage, private route: ActivatedRoute,@Inject(PLATFORM_ID) private platformId: object, 
   private location: PlatformLocation) {
    if (this.route.snapshot.queryParamMap.has('logLevel') && this.route.snapshot.queryParamMap.get('logLevel') != null
      && this.tokenStorage.getDebugMode() === 'true') {
      this._logger.level = <any> this.route.snapshot.queryParamMap.get('logLevel');
    }
    this.hmac_key = environment['hmac_key'];
    this.hmac_secret = environment['hmac_secret'];
  }

  public getRequestTime(): string {
    const todayDate = new Date();
    return formatDate(todayDate, 'yyyy-MM-dd\'T\'HH:mm:ssZ', 'en-US');
  }

  public generateHash(httpRequest: HttpRequest<any>, reqTimestamp: string): string {
    // this._logger.debug("content type: ", httpRequest.detectContentTypeHeader());
    if (httpRequest && httpRequest.url && httpRequest.url.startsWith('http')) {
      const toSign = this.getToString(httpRequest.method, httpRequest.detectContentTypeHeader(), reqTimestamp, httpRequest.urlWithParams, httpRequest.body);
      let hmacSecret = this.hmac_secret;
      hmacSecret = this.tokenStorage.getLoggedInToken() ? this.tokenStorage.getLoggedInToken() : this.tokenStorage.getAnonymousToken() ? this.tokenStorage.getAnonymousToken() : hmacSecret;
      const server_hash = this.getHash(hmacSecret, toSign);
      return this.hmac_key + ':' + server_hash;
    } else {
      return null;
    }
  }

  public getToString(httpMethod: string, contentType: string, timestamp: string, uri: string, body: string): string {
    // uri = uri.split('\\?')[0];
    // this._logger.debug('uri', uri);

    let queryString = null;
    if (uri.split('?').length > 0) {
      queryString = uri.split('?')[1];
    }

    const url = this.urlUtil(uri);
    let referrer ='';
    if(isPlatformServer(this.platformId)){
      referrer = this.getRefererUrl();
    }else {
      referrer = window.location.origin;
    }
    // this._logger.debug('referrer', referrer);
    referrer = this.getDomainName(referrer);
    if (referrer && referrer.endsWith('/')) {
      referrer = referrer.substring(0, referrer.length - 1);
    }
    // this._logger.debug('referrer', referrer);
    // this._logger.debug('url.pathname', url.pathname);
    // uri = referrer + '/' + url.pathname;
    if (url.pathname && url.pathname.startsWith('/')) {
      uri = referrer + url.pathname;
    } else {
      uri = referrer + '/' + url.pathname;
    }
    this._logger.debug('referrer uri', uri);
    if (queryString) {
      uri = uri + '?' + queryString.trim();
    }
    let toSign = '';
    toSign += httpMethod + '\n';
    toSign += contentType + '\n';
    toSign += timestamp + '\n';
    toSign += uri;
    if (body != null) {
      // this._logger.debug('body', body);
      const type = typeof body;
      this._logger.debug('body type', type);
      if (type === 'object') {
        body = JSON.stringify(body);
      } else {
        this._logger.debug('this is string object');
      }
      // body = btoa(body);
      toSign += '\n' + body;
    }
    this._logger.debug('toSign ', toSign);
    return toSign.toString();

  }

  public getHash(secret1: string, data: string): string {
    const hash = CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(data, secret1));
    return hash;
  }

  private getDomainName(url: string) {
    // this._logger.debug('url', url);
    if (url && url.split('/').length > 2) {
      const urlArry = url.split('/');
      url = urlArry[0] + '//' + urlArry[1] + urlArry[2];
    }
    return url;
  }

  private urlUtil(href) {
    const l = document.createElement('a');
    l.href = href;
    return l;
  }
  public getRefererUrl(){
    let referrer ='';
    if(isPlatformServer(this.platformId)){
      const url = new URL(this.location.href);
      url.port = '';
      referrer = url.toString();
      if(this.location.hostname==='localhost') {
        referrer = this.location.href;
      }
    }
    return referrer;
  }

}
