import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-jp-marketing-setting',
  templateUrl: './jp-marketing-setting.component.html',
  styleUrls: ['./jp-marketing-setting.component.scss']
})
export class JpMarketingSettingComponent implements OnInit {
  /**
   * Captures marketing consent response as Y or N depending upon the user selection
   */
  public marketingConsentResponse: string = '';
  /**
   * Determines whether error message should be displayed
   */
  public displayValidationMessage: boolean = false;
  /**
   * Pop up close notify event
   */
  @Output() marketingConsentCloseEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
  /**
   * Called when user selects Yes or No option
   * @param userSelection 
   */
  public updateMarketingResponse(userSelection : string) {
    this.marketingConsentResponse = userSelection;
    this.displayValidationMessage = false;
  }
  /**
   * Called when user clicks on Ok button, it does following:
   * 1. If user has selected an option then fire the event with the user response
   * 2. If user has not selected any option then display error message
   */
  public closeMarketingConsentPopUp(){
    if(this.marketingConsentResponse) {
      this.marketingConsentCloseEvent.emit(this.marketingConsentResponse);
    }
    else{
      this.displayValidationMessage = true;
    }
  }
}
