import {Component, Input, OnInit} from '@angular/core';
import {SiteService} from '../../../../global/services/site/site.service';
import {config, environment, routingEndpoint} from '../../../../../environments/root/environment';
import {TokenStorage} from '../../../../global/services/tokenStorage/token-storage';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GoogleJobResponse} from '../../../model/response/google-job-response';
import {JobSearchService} from '../../../service/job-search.service';
import {DataSharingService} from '../../../service/data-sharing.service';

@Component({
  selector: 'app-jp-breadcrumbs',
  templateUrl: './jp-breadcrumbs.component.html',
  styleUrls: ['./jp-breadcrumbs.component.scss']
})
export class JpBreadcrumbsComponent implements OnInit {
  public sharedSearchUrl: string;
  @Input() public  jobResponseApply :GoogleJobResponse[];
  constructor(private tokenStorage:TokenStorage,private siteService:SiteService,private translateService:TranslateService,
              private activatedRoute:ActivatedRoute,public router:Router,private jobSearchService:JobSearchService, private dataShare:DataSharingService) {
    this.translateService.setDefaultLang(this.siteService.getSiteLocale());
  }

  ngOnInit() {
      this.getSearchUrl();
  }

  getSearchUrl(){
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    if(orgName){
      let url = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.search];
      this.sharedSearchUrl = url.replace(':orgName',orgName);
    }
    else
      this.sharedSearchUrl = environment[config.theme+this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.search];
  }

  redirectToSearch() {
    let keyword='';
    let location='';
    let searchPageTitle='';
    let searchPageDesc='';
    this.translateService.getTranslation(this.siteService.getSiteLocale()).subscribe((res) => {
      if (res['search_result_title'])
        searchPageTitle = res['search_result_title'];
      if (res['search_page_description_text'])
        searchPageDesc = res['search_page_description_text'];
      if(this.activatedRoute.snapshot.queryParamMap.get('q'))
        keyword=this.activatedRoute.snapshot.queryParamMap.get('q');
      if(this.activatedRoute.snapshot.queryParamMap.get('location'))
        location=this.activatedRoute.snapshot.queryParamMap.get('location');
      let jobTitle=keyword?keyword+" ":" ";
      let loc=location?location+" ":environment[config.theme+this.siteService.getSiteLocale()]['config']['country_name']+" ";
      if (res['search_result_title'])
        searchPageTitle = res['search_result_title'];
      if (res['search_page_description_text'])
        searchPageDesc = res['search_page_description_text'];
      if (res['search_result_title1'])
        searchPageTitle = jobTitle+res['search_result_title1']+loc+res['search_result_title2'];
      if (res['meta_desc_text1'])
        searchPageDesc = res['meta_desc_text1']+jobTitle+res['meta_desc_text2']+loc+res['meta_desc_text3']+jobTitle+res['meta_desc_text4'];
      let defaultJobSource='';
      this.dataShare.sharedClientConfig.subscribe(res => {
        if(res) {
          defaultJobSource = res.jobSource;
          if(this.router.url.indexOf('/details')>-1) {
            this.router.navigate([this.sharedSearchUrl], {
              queryParams: {
                q: keyword,
                location: location,
                specialismId: '',
                subSpecialismId: '',
                locationf: '',
                industryf: '',
                sortType: '0',
                jobType: '-1',
                flexiWorkType: '-1',
                payTypefacet: '-1',
                minPay: '-1',
                maxPay: '-1',
                jobSource: defaultJobSource,
                searchPageTitle: searchPageTitle,
                searchPageDesc: searchPageDesc
              }
            });
          }else{
            let searchRedirectURL = window.location.origin+this.sharedSearchUrl+'?q='+ encodeURIComponent(keyword)+'&location='+encodeURIComponent(location)+'&specialismId='+'&subSpecialismId='+'&locationf='+
              '&industryf='+'&sortType=0&jobType=-1&flexiWorkType=-1&payTypefacet=-1&minPay=-1&maxPay=-1&jobSource='+defaultJobSource+'&searchPageTitle='+encodeURIComponent(searchPageTitle)+'&searchPageDesc='+encodeURIComponent(searchPageDesc);
            window.location.href=searchRedirectURL;

          }
        }
      });

    });


  }

  redirectToDetails() {
    if(this.jobResponseApply && this.jobResponseApply.length>0) {
      let routingUrl = '';
      let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"]
      if(orgName){
        let url = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.jobdetails];
        routingUrl = url.replace(':orgName',orgName);
      }
      else
        routingUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.jobdetails];
     // }
      let localeRecordId='';
      let jobSource='';
      jobSource =this.activatedRoute.snapshot.queryParamMap.get('jobSource')?this.activatedRoute.snapshot.queryParamMap.get('jobSource'):
        this.activatedRoute.snapshot.queryParamMap.get('jobsource')?this.activatedRoute.snapshot.queryParamMap.get('jobsource'):
          this.activatedRoute.snapshot.queryParamMap.get('source');

      if (this.jobResponseApply[0].jobRecordId) {
        let xLocaleRecordId = this.jobResponseApply[0].jobRecordId.split('/');
        localeRecordId = xLocaleRecordId[xLocaleRecordId.length - 1];
      }
      let q=this.activatedRoute.snapshot.queryParamMap.get('q')?this.activatedRoute.snapshot.queryParamMap.get('q'):'';
      let location=this.activatedRoute.snapshot.queryParamMap.get('location') ? this.activatedRoute.snapshot.queryParamMap.get('location') : '';
      let defaultJobSource='';
      this.dataShare.sharedClientConfig.subscribe(res => defaultJobSource = res.jobSource );
      jobSource=jobSource?jobSource:defaultJobSource;
      let subSpecilism=this.activatedRoute.snapshot.queryParamMap.get('subspecialism')?this.activatedRoute.snapshot.queryParamMap.get('subspecialism'):'';
      if(routingUrl.indexOf('Job/Detail')>-1){
        let detailsURL = window.location.origin+routingUrl+'/'+localeRecordId+'?q='+q+'&location='+location+'&applyId='+this.jobResponseApply[0].jobId+'&jobSource='+jobSource+
            '&isSponsored='+this.jobResponseApply[0].isSponsored+'&specialismId='+this.activatedRoute.snapshot.queryParamMap.get('specialismId')+'&subSpecialismId='+subSpecilism+
            '&jobName='+this.jobResponseApply[0].jobName;
        window.location.href=detailsURL;
      }
      else{
        this.router.navigate([routingUrl, localeRecordId], {
          queryParams: {
            q: this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : '',
            location: this.activatedRoute.snapshot.queryParamMap.get('location') ? this.activatedRoute.snapshot.queryParamMap.get('location') : '',
            applyId: this.jobResponseApply[0].jobId,
            jobSource: jobSource?jobSource:defaultJobSource,
            isSponsored: this.jobResponseApply[0].isSponsored,
            specialismId: this.activatedRoute.snapshot.queryParamMap.get('specialismId'),
            subSpecialismId: this.activatedRoute.snapshot.queryParamMap.get('subspecialism'),
            jobName: this.jobResponseApply[0].jobName,
            lang: this.jobResponseApply[0].siteLocale.split('-')[0]
          }
        }).then(r => {
          //console.log('redirected to details');
          this.jobSearchService.setJobDetailResponse(null);
          this.jobSearchService.setJobResponse(null);
        });
      }

    }

  }
}
