
    <section class="change-password">
      <div class="row">
        <div class="col-12 col-md-4 offset-md-4 border mb-5">
    <form id="gtm_change_pass" [formGroup]="changePassForm" (ngSubmit)="submit()">

      <h3 class="password-icon border-bottom" [translate] = "'change_password'"></h3>

          <p class="help-text" [translate] = "'password_criteria_text_new'">
          </p>

          <div class="form-group">
            <label  [translate]="'cp_current_password_placeholder'"></label>
            <input class="form-control" id="filed3"  placeholder = "{{'current_password_placeholder' | translate}}"
              formControlName="currentPassword" type="password" required >
          </div>

          <div class="form-group">
            <label  [translate]="'cp_changed_password_placeholder'"></label>
            <input class="form-control" id="filed3" placeholder = "{{'changed_password_placeholder' | translate}}"
              formControlName="newPassword" type="password" required >

          </div>

          <div class="form-group">
            <label  [translate]="'cp_confirm_password_placeholder'"></label>
            <input type="password" class="form-control" id="filed3" placeholder= "{{'confirm_password_placeholder' | translate}}"
              formControlName="confirmPassword" required >

          </div>

          <ng-container *ngIf="captchaVisible">
            <app-jp-captcha [parent]="changePassForm">
            </app-jp-captcha>
          </ng-container>

          <div class="form-group" *ngIf = "formSubmitted">
            <span class="error-msg" *ngIf="validationFlag.required" [translate] = "'password_blank_text'"></span>
            <span class = "error-msg" *ngIf="validationFlag.pattern && !validationFlag.required" [translate] = "'password_criteria_text_new'"></span>
            <span class="error-msg" *ngIf="validationFlag.oldNewDiffer && !validationFlag.required && !validationFlag.pattern "
            [translate] ="'noSame_changePassword'"></span>
            <span class="error-msg" *ngIf="validationFlag.passwordMatch && !validationFlag.required && !validationFlag.pattern && !validationFlag.oldNewDiffer" [translate]="'password_do_not_match_new'"></span>
            <span class="error-msg" *ngIf="incorrectOldPass && !validationFlag.required && !validationFlag.oldNewDiffer && !validationFlag.passwordMatch && !validationFlag.pattern" [translate]="'incorrect_old_password'"></span>
          </div>

          <div class="action-bar">
            <button id="gtm_chng_pwd_submit_btn" type="submit" [ngClass]="buttonChnagePasswordClicked?'btn btn-secondary btn-block btn-loading':'btn btn-secondary btn-block'" [disabled]="buttonChnagePasswordClicked" [translate] = "'update_password_button_label'"></button>
          </div>



  </form>
  </div>
  </div>
  </section>


