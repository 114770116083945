<app-custom-loader *ngIf="showLoader"></app-custom-loader>
<section class="recruit-now">
  <h2 translate>recruiting_now<p class="help-text" [translate]="'recruiting_now_icon_text'"></p></h2>
  <div class="row">
    <div class="col-12 col-md-6 offset-md-1">
      <div class="col border">

        <form id="recruiting-now" class="form" [formGroup]="recruitingForm" (ngSubmit)="submitRecruitingNowForm()">
          <div class="form-group">
            <label [translate]="'user_organization_label'"></label>
            <input type="text" id="organization" formControlName="organization" class="form-control" autocomplete="off" placeholder="{{'user_organization_place_holder' | translate}}" name="organization">
            <span class="error-msg" *ngIf="f.organization.errors && errorMsgShow && !whitespace" [translate]="'invalid_organization_name_msg'"></span>
          </div>
          <div class="form-group">
            <label [translate]="'user_name_label'"></label>
            <input type="text" id="name" formControlName="fullName" class="form-control" autocomplete="off" placeholder="{{'user_name_place_holder' | translate}}"  name="your name">
            <span class="error-msg" *ngIf="f.fullName.errors && errorMsgShow && !whitespace" [translate]="'invalid_name_msg'"></span>
          </div>
          <div class="form-group">
            <label [translate]="'job_title_place_holder'"></label>
            <input type="text" id="jobtitle" formControlName="jobTitle" class="form-control" autocomplete="off" placeholder="{{'job_title_place_holder' | translate}}"  name="Job Title">
          </div>
          <div class="form-group">
            <label [translate]="'user_email_address'"></label>
            <input type="text" id="email" formControlName="emailAddress" class="form-control" (keyup)="onEmailChanged($event);" autocomplete="off" placeholder="{{'user_email_address_place_holder' | translate}}"  name="Email address">
            <span class="error-msg" *ngIf="emailmsgError" [translate]="'invalid_email_address_msg'"></span>
          </div>
          <div class="form-group">
            <label [translate]="'user_phone_label'"></label>
            <input type="text" id="contact-number" formControlName="phoneNumber" class="form-control" autocomplete="off" placeholder="{{'user_phone_place_holder' | translate}}"  name="contact number">
            <span class="error-msg" *ngIf="f.phoneNumber.errors && errorMsgShow" [translate]="'invalid_phone_number_msg'"></span>
          </div>
          <div class="form-group">
            <label [translate]="'user_basic_information_label'"></label>
            <textarea type="text" id="job-description" formControlName="basicInfo" class="form-control" autocomplete="off" placeholder="{{'user_basic_information_place_holder' | translate}}"  name="Job Description">
                 </textarea>
            <span class="error-msg" *ngIf="f.basicInfo.errors && errorMsgShow && !whitespace" [translate]="'invalid_basic_info_msg'"></span>
          </div>
          <div class="form-group">
            <label [translate]="'salary_range_place_holder'"></label>
            <input type="text" id="salary" formControlName="salaryRange" class="form-control" autocomplete="off" placeholder="{{'salary_range_place_holder' | translate}}"  name="Salary range">
            <span class="error-msg" *ngIf="f.salaryRange.errors && errorMsgShow && !whitespace" [translate]="'invalid_salary_range_msg'"></span>
          </div>
          <app-rec-locations [parent]="recruitingForm"></app-rec-locations>
          <span class="error-msg" *ngIf="f.jobLocation.errors && errorMsgShow && !whitespace" [translate]="'invalid_job_location_msg'"></span>
          <div class="form-group">
            <label [translate]="'state_country_place_holder'"></label>
            <input type="text" id="State-Country" formControlName="stateCountry" class="form-control" autocomplete="off" placeholder="{{'state_country_place_holder' | translate}}"  name="Salary range">
            <span class="error-msg" *ngIf="f.stateCountry.errors && errorMsgShow && !whitespace" [translate]="'invalid_state_country_msg'"></span>
          </div>
          <app-employment [parent]="recruitingForm"></app-employment>
          <span class="error-msg" *ngIf="f.employeeTypes.errors && errorMsgShow" [translate]="'invalid_job_type_msg'"></span>
          <div class="form-group">
            <label [translate]="'more_role_information_place_holder'"></label>
            <textarea id="more-role-information" formControlName="furtherInfo" name="more_role_information" class="form-control" autocomplete="off" placeholder="{{'more_role_information_place_holder' | translate}}"></textarea>
          </div>
          <!--<div class="form-group" *ngIf="this.domainConfigs && this.domainConfigs.isRecruitingTerm && privacyPolicyData">
            <label class="checkbox-container">
              <input type="checkbox" value="false" formControlName="privacyPolicyConsent">
              <ng-container *ngFor="let privacyData of privacyPolicyData">
                <span> {{privacyData.consentText1}} <a id="gtm_recruiting_privacypolicy_redirect"[href]="liferaybaseUrl+privacyData.link">{{privacyData.consentText2}}</a></span>
              </ng-container>
            </label>
            <span class="error-msg" *ngIf="privacyPolicyErrorMsg" [translate]="'policy_validation_error'"></span>
          </div>-->

          <div class="form-group" *ngIf="this.domainConfigs && this.domainConfigs.isRecruitingTerm && privacyPolicyData">
            <app-term-and-privacy [parent]="recruitingForm"
                                  (isConsentChecked)="getConsentChecked($event)" (privacyconsent2)="getPrivacyConsent2Checked($event)" [type]="'specCV'"
                                  [currentUserFlow] = 'currentUserFlow'>
            </app-term-and-privacy>
            <div
              *ngIf="formSubmitted && !isChecked">
        <span class="error-msg"
              [translate]="'policy_validation_error'"></span>
            </div>
          </div>

          <app-jp-privacy-modal *ngIf = "isPrivacyPopUpDisplayed" (popUpCloseEvent) = 'onPopUpClose($event)' [modalInnerText] = "privacyPopUpContent">

          </app-jp-privacy-modal>

          <div *ngIf="!isCaptchaDisable">
            <app-jp-captcha [parent]="recruitingForm"></app-jp-captcha>
            <span class="error-msg" *ngIf="captchaErrorMsg" [translate]="'captcha_validation_text'"></span>
          </div>
          <div class="action-bar text-center">
            <button id="gtm_recruiting_submit_btn" [ngClass]="btnDisabled?'btn btn-default btn-block btn-loading':'btn btn-default btn-block'" [disabled]="btnDisabled" type="submit" [translate]="'recruiting_submit_button_text'"></button>
          </div>
        </form>
      </div>

    </div>
    <div class="col-12 col-md-4">
      <app-right-widget></app-right-widget>
    </div>

  </div>
</section>



