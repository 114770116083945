import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {RequestService} from '../../../../../global/services/request/request.service';
import {apiEndPoints, config, environment, keywords} from '../../../../../../environments/root/environment';
import {SiteService} from '../../../../../global/services/site/site.service';
import {JsonRequest, SelectedSpecialismJsonRequest} from '../../../../model/response/office-locator';
import {SectorAdapt, SectorData} from '../../../../model/response/sector';

@Component({
  selector: 'app-jp-sector-for-office-locator',
  templateUrl: './jp-sector-for-office-locator.component.html',
  styleUrls: ['./jp-sector-for-office-locator.component.scss'],
  providers:[SectorAdapt]
})
export class JpSectorForOfficeLocatorComponent implements OnInit {
  public specialismList : SectorData[];
  public selectedSpecialismJson : SelectedSpecialismJsonRequest;
  @Output() selectedSpecialism = new EventEmitter<any>();
  @Output() showLoader = new EventEmitter<any>();
  @Input() isSubspecialism: string;
  constructor(private sectorAdapt:SectorAdapt,private  requestService:RequestService, private _translate : TranslateService,
              private siteService:SiteService) {
    this._translate.setDefaultLang(this.siteService.getSiteLocale());
  }
  
  ngOnInit() {
    this.getSpecialismList();
  }

  getSpecialismList(){
    let json= new JsonRequest(keywords.GetSpecialism,"");
    this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getSector,json).pipe(
      map((res: SectorData) => this.sectorAdapt.adapt(res)))
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          if(res.data["results"].length >0) {
            this.specialismList = environment[config.theme + this.siteService.getSiteLocale()].config['filterSectors'] ? this.filteredSectors(res.data["results"]) : res.data["results"];
            this.selectedSpecialismJson=new SelectedSpecialismJsonRequest(this.specialismList[0].id,this.specialismList[0].label,this.isSubspecialism);
            this.selectedSpecialism.emit(this.selectedSpecialismJson);
            this.showLoader.emit(false);
          }else{
            this.showLoader.emit(false);
          }
        }else{
          this.showLoader.emit(false);
        }
      }, (error) => {
        this.showLoader.emit(false);
      });
  }
  selectedspecialism(event){
    let specialism=event.currentTarget.value;
    let specialismArray=specialism.split("_");
    this.selectedSpecialismJson=new SelectedSpecialismJsonRequest(specialismArray[0],specialismArray[1],this.isSubspecialism);
    this.selectedSpecialism.emit(this.selectedSpecialismJson);
  }
  /**
   * Called to filter sectors if feature is enabled in config
   * @param collection 
   */
  private filteredSectors(collection : []){
    const filterableSectorsList = environment[config.theme + this.siteService.getSiteLocale()].config['filterableSectorsList']; 
    return collection.filter((element) => {
      return !filterableSectorsList.includes(element['label']);
    });
  }
}
