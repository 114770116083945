export class SignIn{
  constructor(public password: string,
              public rememberMe: boolean,
              public token: string,
              public username: string,
              public counter: number,
              public captchaResponse :string,
              public isLoggedInViaGo1 :string
  ) {
  }
}
