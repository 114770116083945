<section class="no-result-found">
  <div class="sorry-msg">
    <h3 [translate]="'no_job_results_no_match_result_text'"></h3>
    <ul>
      <li><span [translate]="'no_job_results_search_criteria_text'"></span></li>
      <li *ngIf = "!isExpertOrg"><span [translate]="'no_job_results_job_alerts_text'"></span></li>
      <li><span [translate]="'no_job_results_connecting_Hays_text'"></span></li>
      <!--<li *ngIf="jobConfig && jobConfig.isResponseJob"><span>{{'clicking_here_text' | translate}} <a [href]="liferayUrl">{{'here_text'|translate}}</a> {{'visit_response_site_text'|translate}}</span></li>-->


    </ul>
  </div>
  <div class="connect-hays">
    <h3 [translate]="'no_job_results_connecting_Hays_label'"></h3>
    <ul class="connect-options">
      <li [ngClass]="!isExpertOrg ? 'send-cv' : 'careers-send-cv'">
        <img class="" src="../assets/images/sendCv-blue.png" alt="Send us your CV">
        <p class="" [translate]="'no_job_results_sendCV_text'"></p>
        <span id="gtm_no_job_results_sendCV_url" class="btn btn-secondary" (click)="redirectToResouceURL('spec')" [translate]="'no_job_results_sendCV_label'"></span>
      </li>
      <li *ngIf = "!isExpertOrg" class="ofc-locator">
        <img class="" src="../assets/images/officeLocator-blue.png" alt="Office locator">
        <p class="" [translate]="'no_job_results_officeLocator_text'"></p>
        <span id="gtm_no_job_results_findus_url" class="btn btn-secondary" (click)="redirectToResouceURL('officeLocator')"
              [translate]="'no_job_results_officeLocator_label'"></span>
      </li>
    </ul>
  </div>
  <div *ngIf = "!isExpertOrg" class="browse-by-expertise">
    <h3 [translate]="'no_job_results_browse_by_expertise_label'"></h3>
    <ul>
      <li *ngFor="let expertise of browseByExpertiseRes">
        <a [href]="liferayUrl+'/'+expertise.href" [textContent]="expertise.label"></a>
      </li>
    </ul>
  </div>

</section>

<jw-modal *ngIf ="isOpenModal" id="send_cv_confirmation_no_result" class="modal-popup">
  <div class="modal-header border-0">
   <button type="button" class="close" (click)="closeModal('send_cv_confirmation_no_result')" data-dismiss="modal">&times;</button>
  </div>
  <div class="modal-body">
    <app-term-and-privacy [isChecked]="isChecked" (isConsentChecked)="getConsentChecked($event)" [type]="'specCV'">
    </app-term-and-privacy>
  </div>
  <div class="modal-footer">
      <button id="send_cv_button" class="btn btn-secondary" [translate]="'send_CV_text'" [disabled] = !isChecked (click)="openMailClient('send_cv_confirmation_no_result')"></button>
  </div>
</jw-modal>
