import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {JobTypeFilter, SortBy, WorkingPatternFilter} from '../model/response/google-job-response';
import {FormGroup} from '@angular/forms';
import {PersonalDetails} from '../model/response/my-profile';
import {OfficeForSpecialismData} from '../model/response/office-for-specialism';
import {MarketingConsent} from '../model/request/marketing-consent';
import {ClientConfigData} from '../../global/models/header/header-menu';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  private show = new BehaviorSubject(false);
  validation = this.show.asObservable();
  private payTypeList: any = '';
  private payTypeListData = new BehaviorSubject(this.payTypeList);
  sharedpayTypeList = this.payTypeListData.asObservable();
  private signUpForm:FormGroup = undefined;
  private signUpFormToBeShared=new BehaviorSubject(this.signUpForm);
  public sharedSignUpForm = this.signUpFormToBeShared.asObservable();
  private isCVSelected=new BehaviorSubject(false);
  public sharedCVSelectedInfo=this.isCVSelected.asObservable();
  private personalDetails:PersonalDetails = undefined;
  private setPersonalDetailsData=new BehaviorSubject(this.personalDetails);
  public sharedPersonalDetails=this.setPersonalDetailsData.asObservable();
  private addThis:string[]=[];
  private addthisData=new BehaviorSubject(this.addThis);
  public sharedAddThisData=this.addthisData.asObservable();
  private footerData :any = '';
  private footer=new BehaviorSubject(this.footerData);
  public footerShare = this.footer.asObservable();
  private officeListResponse : OfficeForSpecialismData[] = undefined;
  private officeList = new BehaviorSubject(this.officeListResponse);
  public sharedOfficeList=this.officeList.asObservable();
  private sectorClicked:boolean = false;
  private isSectorClick=new BehaviorSubject(this.sectorClicked);
  public sharedIsSectorClicked = this.isSectorClick.asObservable();
  private emailAddress:string = '';
  private setEmailAddress = new BehaviorSubject(this.emailAddress);
  public sharedEmailAddress = this.setEmailAddress.asObservable();
  constructor() { }
  private subject = new Subject<any>();
  private location = new BehaviorSubject('');
  private industry = new BehaviorSubject('');
  private workingPattern :WorkingPatternFilter[]=[];
  private jobType :JobTypeFilter[]=[];
  private sortType :SortBy[]=[];
  private jobTypefilter = new BehaviorSubject(this.jobType);
  private sortTypeFilter = new BehaviorSubject(this.sortType);
  private workingPatternFilter = new BehaviorSubject(this.workingPattern);
  sharedLocation = this.location.asObservable();
  sharedIndustry = this.industry.asObservable();
  sharedWorkingPattern = this.workingPatternFilter.asObservable();
  sharedSortTypeFilter = this.sortTypeFilter.asObservable();
  sharedJobTypeFilter = this.jobTypefilter.asObservable();
  private PayTypeData:any = '';
  private payTypeDateList = new BehaviorSubject(this.PayTypeData);
  sharedpayTypeDateList = this.payTypeDateList.asObservable();
  private headerData:any = '';
  private headerDataList = new BehaviorSubject(this.headerData);
  public sharedHeaderData = this.headerDataList.asObservable();
  private dataSentToMainComponent:any = '';
  private mainComponentDataList=new BehaviorSubject(this.dataSentToMainComponent);
  public sharedMainComponentData=this.mainComponentDataList.asObservable();
  private upcomingInterview:any = '';
  private upcomingInterviewDataList=new BehaviorSubject(this.upcomingInterview);
  public sharedUpcomingInterviewData=this.upcomingInterviewDataList.asObservable();
  private clearCVName:any = '';
  private clearCVData=new BehaviorSubject(this.clearCVName);
  public sharedCVData=this.clearCVData.asObservable();
  private pageName:any = '';
  private widgetListData=new BehaviorSubject(this.pageName);
  public getWidgetListPageName=this.widgetListData.asObservable();
  public $openMarketContentPopup = new BehaviorSubject(false);
  public $isMarketingConsent = new BehaviorSubject<MarketingConsent>({
    selectAll: false,
    email: false,
    sms: false,
    whatsApp: false,
    dontReceive: false,
    privacy: false
  });
  public $isMarketingConsentLinkedIn = new BehaviorSubject<MarketingConsent>({
    selectAll: false,
    email: false,
    sms: false,
    whatsApp: false,
    dontReceive: false,
    privacy: false
  });
  private clientConfig:ClientConfigData = undefined;
  private clientConfigList = new BehaviorSubject(this.clientConfig);
  public sharedClientConfig = this.clientConfigList.asObservable();

  setOpenMarketContentPopup(value: boolean) {
    this.$openMarketContentPopup.next(value);
  }

  clearSearchData() {
    this.industry.next('');
    this.location.next('');
    this.workingPattern=[];
    this.workingPatternFilter.next(this.workingPattern);
    this.jobType=[];
    this.jobTypefilter.next(this.jobType);
    this.sortType=[];
    this.sortTypeFilter.next(this.sortType);
    this.payTypeList=undefined;
    this.payTypeListData.next(this.payTypeList);
  }
  public clearHeader(){
    this.headerDataList.next(null);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  onClick(show) {
    this.show.next(show);
  }
  getLocation(): Observable<any> {
    return this.location.asObservable();
  }

  sendLocation(filterText: string) {
    this.location.next(filterText);
  }
  sendIndustry(filterText: string) {
    this.industry.next(filterText);
  }

  sendWorkingPatternFilter(workingPatternFilterList: WorkingPatternFilter[]) {
    this.workingPatternFilter.next(workingPatternFilterList);
  }

  sendSortTypeFilter(sortTypeFilter: SortBy[]) {
    this.sortTypeFilter.next(sortTypeFilter);
  }

  sendJobTypeFilterList(JobTypeFilterList: JobTypeFilter[]) {
    this.jobTypefilter.next(JobTypeFilterList);
  }

  sendPayTypeList(payType: string[]) {
    this.payTypeListData.next(payType);
  }

  sendPayTypeDataList(payTypeFilter: any) {
    this.payTypeDateList.next(payTypeFilter);
  }

  setSignUpForm(signUpFormGroup: FormGroup) {
    this.signUpFormToBeShared.next(signUpFormGroup)
  }

  sendHeaderData(headerData: any) {
    this.headerDataList.next(headerData);
  }

  sendMainComponentData(data:any){
    this.mainComponentDataList.next(data);
  }

  setCVSelectedValue(isCVSelected: boolean) {
    this.isCVSelected.next(isCVSelected);
  }

  setPersonalDetails(personalDetails: PersonalDetails) {
    this.setPersonalDetailsData.next(personalDetails);
  }
  sendUpcomingInterviewData(data:any){
    this.upcomingInterviewDataList.next(data);
  }

  setSharingJob(addThisData: string[]) {
    this.addthisData.next(addThisData);
  }
  getWidgetListName(pageName){
    this.widgetListData.next(pageName);
  }

  sendClearCvData(data:any){
    this.clearCVData.next(data);
  }

  setFooterData(data: any) {
    this.footer.next(data);
  }

  setOfficeList(officeList: OfficeForSpecialismData[]) {
    this.officeList.next(officeList);
  }

  isSectorClicked(isSectorClick: boolean) {
    this.isSectorClick.next(isSectorClick);
  }

  setEmailId(emailAddress: string) {
    this.setEmailAddress.next(emailAddress);
  }

  sendClientConfig(clientConfig: any) {
    this.clientConfigList.next(clientConfig);
  }
}
