import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {ExtraFieldsConfig} from '../../../model/response/google-job-response';

@Component({
  selector: 'app-jp-legal-consent-popup',
  templateUrl: './jp-legal-consent-popup.component.html',
  styleUrls: ['./jp-legal-consent-popup.component.scss']
})
export class JpLegalConsentPopupComponent implements OnInit {
  @Input() public domainConfig:ExtraFieldsConfig;
  @Input() public legalConsentVisible:boolean;
  @Output() public consentFlag = new EventEmitter<boolean>();
  @Output() public isConsentValid = new EventEmitter<boolean>();
  @Output() public close = new EventEmitter<boolean>();
  public selectedValue: boolean;
  public validFlag = false;
  public showValidationError: boolean;
  constructor(public route:Router) { }

  ngOnInit() {
  }

  onSubmit() {
    if(this.selectedValue!==undefined) {
      this.validFlag = true;
      this.consentFlag.emit(this.selectedValue);
    }else{
      this.showValidationError=true;
    }
  }

  onClick(value) {
    this.selectedValue = value;
    this.showValidationError=false;
  }

}
