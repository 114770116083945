import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-jp-privacy-modal',
  templateUrl: './jp-privacy-modal.component.html',
  styleUrls: ['./jp-privacy-modal.component.scss']
})
export class JpPrivacyModalComponent implements OnInit {
  /**
   * Captures text to be rendered as per the parent component
   */
  @Input() modalInnerText : string;
  /**
   * Pop up close notifier event
   */
  @Output() popUpCloseEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    
  }
  /**
   * Method to be called on click of OK or close in modal and notify parent of pop up close
   */
  public closePopUp(){
    this.popUpCloseEvent.emit(false);
  }
}
