import { Component, OnInit } from '@angular/core';
import { ExtraFieldsConfig } from 'src/app/jobportal/model/response/google-job-response';
import { JobSearchService } from 'src/app/jobportal/service/job-search.service';

@Component({
  selector: 'app-play-store',
  templateUrl: './play-store.component.html',
  styleUrls: ['./play-store.component.css']
})
export class PlayStoreComponent implements OnInit {
  /**
   * Captures the domain config information
   */
  public domainConfig: ExtraFieldsConfig;

  constructor(private jobSearchService: JobSearchService) { }

  ngOnInit(): void {
    this.jobSearchService.sharedConfigList.subscribe((domainConfig) => {
      this.domainConfig = domainConfig;
    });
  }

}
