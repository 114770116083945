import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppRouter} from '../app-route';
import {JobPortalRouter} from '../jobportal/jobportal-route';
import {ErpIntegrationRouter} from '../erp-integration/erp-integration-route';


const routes: Routes = [
  {
    path: AppRouter.job_detail_route_link,
    loadChildren: () => import('../jobportal/shared/job/jobdetail/jobdetail.module').then(m => m.JobdetailModule)
  },
  {
    path: AppRouter.search_link,
    loadChildren: () => import('../jobportal/shared/job/search/search.module').then(m => m.SearchModule)
  },
  {
    path: AppRouter.spec_cv_link,
    loadChildren: () => import('../jobportal/notsecure/candidate/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: AppRouter.register_link,
    loadChildren: () => import('../jobportal/notsecure/candidate/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: AppRouter.job_route,
    loadChildren: () => import('../jobportal/shared/job/apply/apply.module').then(m => m.ApplyModule)
  },
  {
    path: AppRouter.job_apply_route,
    loadChildren: () => import('../jobportal/shared/job/apply/apply.module').then(m => m.ApplyModule)
  },
  {
    path: AppRouter.reset_link,
    loadChildren: () => import('../jobportal/notsecure/candidate/reset/reset.module').then(m => m.ResetModule)
  },
  {
    path: AppRouter.my_profile_link,
    redirectTo: AppRouter.my_profile_redirect_link,
    pathMatch:'full'
  },
  {
    path: AppRouter.secure_link,
    loadChildren: () => import('../jobportal/secure/secure-candidate/secure-candidate.module').then(m => m.SecureCandidateModule)
  },
  {
    path: AppRouter.login_link,
    loadChildren: () => import('../jobportal/notsecure/candidate/signin/signin.module').then(m => m.SigninModule)
  },
  {
    path: AppRouter.go1_link,
    loadChildren: () => import('../partner/go1-app/go1-notsecure/components/go1-signin/go1-signin.module').then(m => m.Go1SigninModule)
  },
  {
    path: AppRouter.all_office_link,
    loadChildren: () => import('../jobportal/office/office.module').then(m => m.OfficeModule)
  },
  {
    path: AppRouter.office_locator_link,
    loadChildren: () => import('../jobportal/office/office-locator/office-locator.module').then(m => m.OfficeLocatorModule)
  },
  {
    path: AppRouter.saved_jobs_link,
    loadChildren: () => import('../jobportal/shared/savedjob/savedjob.module').then(m => m.SavedjobModule)
  },
  {
    path: AppRouter.recruiting_now_link,
    loadChildren: () => import('../jobportal/notsecure/candidate/recruiting-now/recruiting-now.module').then(m => m.RecruitingNowModule)
  },
  {
    path: AppRouter.gdpr_marketing_pref_link,
    loadChildren: () => import('../jobportal/shared/gdpr/gdpr.module').then(m => m.GdprModule)
  },
  {
    path: AppRouter.whatsapp_alert_link,
    loadChildren: () => import('../jobportal/shared/whatsapp/whatsapp.module').then(m => m.WhatsappModule)
  },
  {
    path: AppRouter.unsubscribe_link,
    loadChildren: () => import('../jobportal/notsecure/candidate/alerts/alert.module').then(m => m.AlertModule)
  },
  {
    path: AppRouter.gdpr_email_confirm_link,
    loadChildren: () => import('../jobportal/notsecure/candidate/gdpr/email-confirmation/email-confirmation.module').then(m => m.EmailConfirmationModule)
  },
  {
    path: JobPortalRouter.appmodule_link,
    loadChildren: () => import('../jobportal/jobportal.module').then(m => m.JobportalModule)
  },
  {
    path: ErpIntegrationRouter.appmodule_link,
    loadChildren: () => import('../erp-integration/erp-integration.module').then(m => m.ErpIntegrationModule)
  },
  {
    path: AppRouter.hcp_link,
    loadChildren: () => import('../partner/hays-career/people-soft/people-soft.module').then(m => m.PeopleSoftModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrganizationRoutingModule { }
