import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ExtraFieldsConfig} from '../../../model/response/google-job-response';
import {Router} from '@angular/router';
import {RequestService} from '../../../../global/services/request/request.service';
import {SiteService} from '../../../../global/services/site/site.service';
import {apiEndPoints, config, environment} from '../../../../../environments/root/environment';
import {Subscription} from 'rxjs';
import {DataSharingService} from '../../../service/data-sharing.service';
import {MarketingConsent} from '../../../model/request/marketing-consent';

@Component({
  selector: 'app-jp-market-consent-linked-in-popup',
  templateUrl: './jp-market-consent-linked-in-popup.component.html',
  styleUrls: ['./jp-market-consent-linked-in-popup.component.scss']
})
export class JpMarketConsentLinkedInPopupComponent implements OnInit, OnDestroy{

  @Input() public domainConfig:ExtraFieldsConfig;
  @Input() public legalConsentVisible:boolean;
  @Output() public consentFlag = new EventEmitter<any>();
  @Output() public isConsentValid = new EventEmitter<boolean>();
  @Output() public close = new EventEmitter<boolean>();
  public consentBox1=[];
  public consentBox2=[];
  public consentBox3=[];
  public confirmationText=[];
  public checked1: boolean;
  public checked2: boolean;
  public checked3: boolean;
  public privacyPolicyErrorMsg1: boolean;
  public privacyPolicyErrorMsg2: boolean;
  public privacyPolicyErrorMsg3: boolean;
  public buttonClicked: boolean;
  public showValidationError: boolean;
  public baseUrl:string;
  public emailText: string;
  public smsText: string;
  public whatsAppText: string;
  public marketConsentRes: string;
  public selectAllText: string;
  public consentText: string;
  private isMarketObsRef: Subscription;
  private marketingConsentData: MarketingConsent;
  private isSelectAllChecked: undefined;

  constructor(public route:Router,private requestService:RequestService,private siteService:SiteService,
              public dataSharingService: DataSharingService, private _requestService:RequestService) { }

  ngOnInit() {
    this.baseUrl = window.location.origin;
    if(this.route.url.indexOf('apply')>-1)
      this.getConfirmation();
    let json = {
      type: ''
    };
    this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.marketingConsent, json)
      .subscribe(response => {
        if (response.status === config.SUCCESS && response.data) {
          this.marketConsentRes = response.data;
          for (let res of this.marketConsentRes) {
            if (res['id'] === 'select_all_checkbox') {
              this.selectAllText = res['value'];
            } else if (res['id'] === 'consent_text') {
              this.consentText = res['value'];
            } else if (res['id'] === 'email_checkbox') {
              this.emailText = res['value'];
            } else if (res['id'] === 'sms_checkbox') {
              this.smsText = res['value'];
            } else if (res['id'] === 'wtsapp_checkbox') {
              this.whatsAppText = res['value'];
            }
          }
        }
      });
    this.isMarketObsRef = this.dataSharingService.$isMarketingConsentLinkedIn.subscribe(data=>{
      this.marketingConsentData = data;
      if (data.selectAll && this.isSelectAllChecked === true) {
        this.checked1 = data.email = data.sms = data.whatsApp = data.privacy = true;
      } else if (!data.selectAll && this.isSelectAllChecked === false) {
        this.checked1 = data.email = data.sms = data.whatsApp = data.privacy =false;
      }
      if (data.email && data.sms && data.whatsApp && this.checked1 && data.privacy) {
        data.selectAll = true;
      }
      if(!data.email || !data.sms || !data.whatsApp || !data.privacy){
        data.selectAll = false;
      }
    })
  }

  getConfirmation(){
    let liferayBaseUrl = environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.liferaybaseUrl];
    let orgName = this.route.routerState.snapshot.root.firstChild.params["orgName"];
    this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI,apiEndPoints.getTermsAndConditions).subscribe(response => {
      if(response.status === config.SUCCESS && response.data ){
        this.confirmationText = response.data;
        this.confirmationText['checkBox1'].forEach((data)=>{
          if(data['link'].indexOf('email')==-1) {
            if (orgName)
              data['link'] = liferayBaseUrl+'/'+orgName + data['link'];
            else
              data['link'] = liferayBaseUrl + data['link'];

          }
          else {
            if (orgName)
              data['link'] = orgName + data['link'];
          }

          /*if(data['link'].indexOf('email')==-1)
            data['link']=liferayBaseUrl+data['link'];*/
          data['consentText1']= decodeURI(data['consentText1']);
          data['consentText2']= decodeURI(data['consentText2']);
          this.consentBox1.push(data);
        });

        this.confirmationText['checkBox2'].forEach((data)=>{
          if(data['link'].indexOf('email')==-1) {
            if (orgName)
              data['link'] = liferayBaseUrl+'/'+orgName + data['link'];
            else
              data['link'] = liferayBaseUrl + data['link'];

          }
          else {
            if (orgName)
              data['link'] = orgName + data['link'];
          }
          data['consentText1']= decodeURI(data['consentText1']);
          data['consentText2']= decodeURI(data['consentText2']);
          this.consentBox2.push(data);
        });

        this.confirmationText['checkBox3'].forEach((data)=>{
          if(data['link'].indexOf('email')==-1) {
            if (orgName)
              data['link'] = liferayBaseUrl+'/'+orgName + data['link'];
            else
              data['link'] = liferayBaseUrl + data['link'];

          }
          else {
            if (orgName)
              data['link'] = orgName + data['link'];
          }
          data['consentText1']= decodeURI(data['consentText1']);
          data['consentText2']= decodeURI(data['consentText2']);
          this.consentBox3.push(data);
        });
      }
    }, (error) => {
    });
  }


  submitPrivacyConsent() {
    this.buttonClicked = true;
    if(this.consentBox1 && this.consentBox1.length>0  && this.consentBox2.length===0 && this.consentBox3.length===0){
      if(this.checked1) {
        let flag={type:'',event:true};
        this.consentFlag.emit(flag);
      }
      else {
        this.privacyPolicyErrorMsg1 = true;
        this.buttonClicked=false;
      }
    }
    else if(this.consentBox1 && this.consentBox1.length>0  && this.consentBox2.length>0 && this.checked2 && this.consentBox3.length===0){
      if(this.checked1  && this.checked2) {
        let flag={type:'',event:true};
        this.consentFlag.emit(flag);
      }else if(this.checked1 && !this.checked2) {
        this.privacyPolicyErrorMsg2 = true;
        this.buttonClicked = false;
      }
      else if(!this.checked1 && this.consentBox2) {
        this.privacyPolicyErrorMsg1 = true;
        this.buttonClicked = false;
      }
      else {
        this.buttonClicked = false;
        this.privacyPolicyErrorMsg1=true;
        this.privacyPolicyErrorMsg2=true;
      }

    }else if(this.consentBox1 && this.consentBox1.length>0  && this.consentBox2 && this.consentBox2.length>0 && this.consentBox3.length>0){
      if(this.checked1) {
        let flag={type:'',event:true};
        this.consentFlag.emit(flag);
      }else {
        this.privacyPolicyErrorMsg1=true;
        this.buttonClicked = false;
      }
    }
  }

  onChangeCheck(type: string, value) {
    let selectVal = value.target.checked;
    switch (type) {
      case 'selectall':
        this.isSelectAllChecked = selectVal;
        this.dataSharingService.$isMarketingConsentLinkedIn.next({...this.marketingConsentData, selectAll: selectVal});
        return;
      case 'email':
        this.isSelectAllChecked = undefined;
        this.dataSharingService.$isMarketingConsentLinkedIn.next({...this.marketingConsentData, email: selectVal});
        return;
      case 'sms':
        this.isSelectAllChecked = undefined;
        this.dataSharingService.$isMarketingConsentLinkedIn.next({...this.marketingConsentData, sms: selectVal});
        return;
      case 'whatsapp':
        this.isSelectAllChecked = undefined;
        this.dataSharingService.$isMarketingConsentLinkedIn.next({...this.marketingConsentData, whatsApp: selectVal});
        return;
      case 'term1':
        this.isSelectAllChecked = undefined;
        this.dataSharingService.$isMarketingConsentLinkedIn.next({...this.marketingConsentData, privacy: selectVal});
        return;
      case 'term2':
        this.isSelectAllChecked = undefined;
        this.dataSharingService.$isMarketingConsentLinkedIn.next({...this.marketingConsentData, privacy: selectVal});
        this.checked2 = selectVal
        return;
      case 'term3':
        this.isSelectAllChecked = undefined;
        this.dataSharingService.$isMarketingConsentLinkedIn.next({...this.marketingConsentData, privacy: selectVal});
        this.checked3 = selectVal
        return;
    }
  }

  closePopup() {
    this.close.emit(false);
  }

  ngOnDestroy(): void {
    this.isMarketObsRef.unsubscribe();
  }

}
