<section class="marketing-preferences">
  <h2 [translate]="'marketing_preference_heading_text'"></h2>
  <p [translate]="'marketing_preferences_header'"></p>
  <app-custom-loader *ngIf="!isDataLoaded"></app-custom-loader>
  <div class="row">
    <div class="col-sm-12 col-md-6" *ngIf="isDataLoaded">

      <h3 [translate]="'email_text'"></h3>
      <p [translate]="'email_subheading_text_new'"></p>
      <div class="border panel-box">
        <p [translate]="'like_to_recieve_email_text_new'"></p>
        <div class="switch-field">
          <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" >
            <div class="toggle-btn">
              <button class="btn" [ngClass]="marketingPrefData?.consent_email ? 'active' : ''" (click)="setService($event,'email')" [translate]="'yes_text'" >
              </button>
              <button class="btn" [ngClass]="!marketingPrefData?.consent_email ? 'active' : ''" (click)="setService($event,'email')" [translate]="'no_text'">
              </button>
            </div>
          </div>
        </div>
        <p class="email-second-head" [translate]="'gdpr_email_share_interest_text_new'"></p>
        <hr>
        <div class="job-alert">
          <p [translate]="'gdpr_job_alert_head_text'"></p>
          <div>
            <p class="email-second-head"[translate]="'gdpr_job_alert_subhead_text'"></p>
          </div>
        </div>
        <div class="border panel-box mt-3 save-info">
          <span class="save-icon"  [translate]="'save_your_changes_text'"></span>
          <span class="save-btn text-right"><button type="submit" [ngClass]="updateClicked?'btn btn-default btn-block btn-loading':'btn btn-default btn-block'" id="saveButton" [disabled]="buttonDisable" (click)="updateMarketingPref()" [translate]="'save_text'"></button>
       </span>
        </div>
        <p class="other-info" [translate]="'gdpr_sms_notification_text'"></p>
      </div>
    </div>
      <div class="col-12">
        <app-jp-cookies-settings ></app-jp-cookies-settings>
      </div>
    </div>
</section>

