<app-jp-widgetlist *ngIf="router.url.indexOf(searchPage)===-1 || router.url.indexOf(jobDetails)===-1"></app-jp-widgetlist>
<div *ngIf="showFooter" class="footer">
  <div class = 'footer-elements-wrapper' id = 'footerElementsContainer'>
    <nav class="navbar" >
      <img *ngIf="footerLogoUrl" [src]="footerLogoUrl" alt="Hays">
      <a *ngFor="let footer of footerData" [id]="'gtm_footer_'+footer.linkName" [href]="isHtsRedirect ? footer.resource_url : liferaybaseUrl+footer.resource_url"
          class="nav-item nav-link"
          [textContent]="footer.linkName ? footer.linkName: ''"></a>
    </nav>
    <app-play-store></app-play-store>
  </div>
  <p *ngIf = "isHtsRedirect" class="custom-footer copyright-text" [textContent] = "plc"></p>
  <div *ngIf="showlicence" class="custom-footer">
    <label [translate]="'new_licence_name'"></label>
    <a *ngIf="licenceLink" id="gtm_footer_licencelink" [href]="licenceLink" target="_blank" [translate]="'new_licence_number'"> </a>
    <p *ngIf="!licenceLink" [translate]="'new_licence_number'"> </p>
  </div>
</div>

