<section class="change-email">
    <div class="row">
      <div class="col-12 col-md-4 offset-md-4 mb-5">

        <form class="form" id="change-email" [formGroup]="changeEmailForm" (ngSubmit)="submitChangeEmail()">
          <div class="col border">
          <h3 class="email-icon border-bottom" [translate]="'change_email'">
          </h3>
          <span class="error-msg" *ngIf="passwordIncorrectError" [translate]="'user_pass_incorrect_text'"></span>
          <div class="form-group">
            <label [translate]="'ce_current_email_placeholder'"></label>
            <input type="text" autocomplete="off" id="Current-password" [ngClass]="{'is-invalid':f.newEmail.errors}"  formControlName="newEmail" class="form-control" placeholder="{{'ce_current_email_placeholder' | translate}}"  name="password">
          </div>
          <div class="form-group">
            <label [translate]="'ce_changed_email_placeholder'"></label>
            <input type="text" autocomplete="off" id="new-password" [ngClass]="{'is-invalid':f.reEnterNewEmail.errors}" class="form-control" formControlName="reEnterNewEmail" placeholder="{{'ce_changed_email_placeholder' | translate}}"  name="password">
           </div>
          <div class="form-group">
            <label [translate]="'ce_confirm_password_placeholder'"></label>
            <input type="password" autocomplete="off" id="confirm-password" class="form-control" formControlName="confirmPassword" placeholder="{{'ce_confirm_password_placeholder' | translate}}"  name="password">
            <div *ngIf="captchaShow">
              <app-jp-captcha [parent]="changeEmailForm"></app-jp-captcha>
            </div>
            <span class="error-msg" *ngIf="errorMsgShow" [translate]="'email_blank_text'"></span>
            <span class="error-msg" *ngIf="emailNotMatchError" [translate]="'email_do_not_match'"></span>
            <span class="error-msg" *ngIf="existingMsgError" [translate]="'existing_old_email'"></span>
            <span class="error-msg" *ngIf="invalidParamMsgError" [translate]="'try_again_text'"></span>
            <span class="error-msg" *ngIf="isNotOnlineUser" [translate]="'not_online_user_text'"></span>
          </div>
            <!--<app-custom-loader *ngIf="loader"></app-custom-loader>-->
          <div class="action-bar">
            <button id="gtm_chng_email_submit_btn" [ngClass]="disableBtn?'btn btn-secondary btn-block btn-loading':'btn btn-secondary btn-block'"
                    type="submit" [disabled]="disableBtn" [translate]="'submit_button'"></button>
          </div>
          </div>
        </form>
      </div>
    </div>
  </section>
