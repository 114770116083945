import {Component, Inject, OnInit} from '@angular/core';
import {JobSearchService} from '../../../../../service/job-search.service';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {RequestService} from '../../../../../../global/services/request/request.service';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {TokenStorage} from '../../../../../../global/services/tokenStorage/token-storage';
import {CookieService} from 'ngx-cookie-service';
import {NgxXml2jsonService} from 'ngx-xml2json';
import {ExtraFieldsConfig, GoogleJobResponse} from '../../../../../model/response/google-job-response';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {apiEndPoints, config, environment} from '../../../../../../../environments/root/environment';
import {DataSharingService} from '../../../../../service/data-sharing.service';
import {SetTitleService} from "../../../../../service/set-title.service";
import {Meta, Title} from "@angular/platform-browser";
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-jp-apply',
  templateUrl: './jp-apply.component.html',
  styleUrls: ['./jp-apply.component.scss']
})
export class JpApplyComponent implements OnInit {

  public JobResponse: GoogleJobResponse[];
  public showApplyForm: boolean;
  public loggedInUser = false;
  public showSendCV: string;
  private applyIdCookie: string;
  public showApply: boolean=true;
  public pageConfig: ExtraFieldsConfig;
  public isDesktopDevice: boolean;
  public isTablet: boolean=false;
  public azureEnable: boolean;
  public disable_login: boolean;
  private userAgent: string;
  private cookieDomain:string;
  public showApplyTab: boolean=false;
  public currentUserFlow: string = 'job_apply';

  constructor(private searchService: JobSearchService, private activatedRoute: ActivatedRoute, private requestService: RequestService, private siteService: SiteService,
              private tokenStorage: TokenStorage, private cookieService: CookieService, private deviceDetector:DeviceDetectorService,private titleService: SetTitleService,
              private xmlParserService: NgxXml2jsonService, private translateService: TranslateService,private router:Router,
              private dataShareService: DataSharingService,private titleServiceT: Title,private metaService: Meta, @Inject(DOCUMENT) private document: Document) {
    this.translateService.setDefaultLang(this.siteService.getSiteLocale());

    this.azureEnable=environment[config.theme + this.siteService.getSiteLocale()]['config']['azure_enable'];
    this.disable_login=environment[config.theme + this.siteService.getSiteLocale()]['config']['disable_login'];
    router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          //console.log("back pressed..");
          this.searchService.sharedJobDetailData.subscribe(jobDetailData => {
            this.JobResponse = jobDetailData;
            if(this.cookieService.get('applyJobid'))
              this.applyIdCookie=this.cookieService.get('applyJobid');
            if(this.JobResponse && this.JobResponse.length>0 && this.JobResponse[0].jobId.indexOf(this.applyIdCookie)>-1)
              this.JobResponse[0].isJobApplied=true;

          });
        }
      });

  }

  ngOnInit() {
    if (this.tokenStorage.getLoggedInToken()) {
      this.loggedInUser = true;
    }
    this.isDesktopDevice = this.deviceDetector.isDesktop(window.navigator.userAgent);
    this.userAgent =this.deviceDetector.isMobile()?'-Mobile':this.deviceDetector.isDesktop()?'-Desktop':'-Tablet';
    if(this.userAgent==='-Tablet')
      this.isTablet=true;
    this.cookieDomain =environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.cookieDomain];
    this.searchService.getJobDeatils(this.activatedRoute, this.requestService, this.siteService, this.tokenStorage,this.xmlParserService,
      'jobApply', '', '', this.cookieService,this.router,this.userAgent,this.cookieDomain, this.dataShareService,this.translateService,this.titleServiceT,this.metaService,this.document);
    this.searchService.sharedJobDetailData.subscribe(jobDetailData => {
      this.JobResponse = jobDetailData;
      if(this.cookieService.get('applyJobid'))
        this.applyIdCookie=this.cookieService.get('applyJobid');
      if(this.JobResponse && this.JobResponse.length>0 && this.JobResponse[0].jobId.indexOf(this.applyIdCookie)>-1)
        this.JobResponse[0].isJobApplied=true;
      this.translateService.getTranslation(this.siteService.getSiteLocale()).subscribe((res) => {
        let title='';
        let jobTitle='';
        let loc='';
        jobTitle = this.JobResponse && this.JobResponse.length && this.JobResponse[0].JobTitle ? this.JobResponse[0].JobTitle+ " " : "";
        loc = this.JobResponse && this.JobResponse.length && this.JobResponse[0].locationDesc ? this.JobResponse[0].locationDesc + " " : "";
        if(res['job_apply_page_title'])
          title=res['job_apply_page_title'];
        if(res['job_apply_page_title1'])
          title=res['job_apply_page_title1']+jobTitle+res['job_apply_page_title2']+loc;
        this.titleService.setTitleForPage(title);
      });
    });
    this.searchService.sharedConfigList.subscribe(config=>{
      this.pageConfig=config;
    });
    this.activatedRoute.queryParamMap.subscribe(param => {
      if (param.get('path')) {
          this.showApplyForm = true;
      }
      if(param.get('showSendCV') && param.get('showSendCV')==='N') {
        this.showApplyForm = param.get('showApplyForm').indexOf('true')>-1;
      }
    });
    if(!this.showApplyForm && !this.loggedInUser && this.userAgent==='-Mobile' )
      this.showApplyTab=true;

  }

  showSendCVCall() {
    this.activatedRoute.queryParamMap.subscribe(param => {
      if (!param.get('showApplyForm') && !param.get('showSendCV') && this.router.url.indexOf('/totalJobs')===-1) {
        window.location.href =window.location.href+'&showSendCV=N&showApplyForm=true'
      }else if(!param.get('showApplyForm') && !param.get('showSendCV') && this.router.url.indexOf('/totalJobs')>-1){
        this.activatedRoute.paramMap.subscribe(param=>{
          if(param.get('recordId'))
            window.location.href =window.location.href+'?applyId='+param.get('recordId')+'&showSendCV=N&showApplyForm=true'
        })

      }
    });


  }
  showContainer(type: string) {
    this.showApply = type === 'apply';
    if(this.showApply)
      this.showApplyTab=true;
    else this.showApplyTab=false;
  }
}
