export class GoogleJob{
  constructor(public facetLocation: string,
              public flexibleWorking: string,
              public fullTime: string,
              public industry: Array<string>,
              public isSponsored: boolean,
              public jobType:Array<string>,
              public partTime:string,
              public query:string,
              public locations:string,
              public salMax:string,
              public salMin:string,
              public sortType:string,
              public specialismId:string,
              public subSpecialismId:string,
              public typeOnlyFilter:string,
              public userAgent:string,
              public radius:number,
              public isCrossCountry:boolean,
              public isResponseCountry:boolean,
              public responseSiteLocale:string,
              public pageToken:string,
              public jobId:string,
              public jobRefrence:string,
              public crossCountryUrl:string,
              public payType:string,
              public type:string,
              public cookieDomain:string


              ) {
  }

}
