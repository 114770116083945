<!--show this div only for uk/cerow on the basis of  api calling-->
<div *ngIf="showGeocoderTextBox" class="form-group">
    <label [translate]="'job_location_label'"></label>
    <input #search type="text" class="form-control"  (keydown.enter)="$event.preventDefault()" (keyup)="keyUp();"  placeholder="{{'office_location_placeholder' | translate}}"
           autocorrect="off" autocapitalize="off" spellcheck="off" type="text" >
  <span *ngIf="validationMessage" class="error-msg" [translate]="'invalid_office_msg'"></span>
</div>

<!--show this div only for APAC on the basis of  api calling-->
<div *ngIf="!showGeocoderTextBox" class="form-group">
  <label [translate]="'job_location_label'" *ngIf="showLocationList || showStatelist"></label>
  <select *ngIf="showLocationList" (change)="selectedLocation($event)"  class="form-control"  >
    <option  *ngFor='let loc of locationList' [value]="loc.LOCATION_ID+'_'+loc.LOCATION_DESC"
             [textContent]="loc.LOCATION_DESC"></option>
  </select>
  <select *ngIf="showStatelist" (change)="selectedLocation($event)" class="form-control" >
    <option *ngFor='let state of auStateList'
       [value]="state.State_id+'_'+state.state_description"   [textContent]="state.state_description" ></option>
  </select>
</div>
