import { Injectable } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SetTitleService {

  constructor(private title:Title) {}

  setTitleForPage(titleValue){
    this.title.setTitle(titleValue);
  }

}
