import {Component, ElementRef, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteService } from 'src/app/global/services/site/site.service';
import {apiEndPoints, environment, keywords, routingEndpoint, config} from '../../../../../../../environments/root/environment';
import {TranslateService} from '@ngx-translate/core';
import {SetTitleService} from '../../../../../../jobportal/service/set-title.service';
import {DataSharingService} from '../../../../../../jobportal/service/data-sharing.service';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-go1-signin-register',
  templateUrl: './go1-signin-register.component.html',
  styleUrls: ['./go1-signin-register.component.scss']
})
export class Go1SigninRegisterComponent implements OnInit {
  public query: any;
  public forgetFlag = false;
  public resetFlag = false;
  public changePassSuccFlag = false;
  public changePassFailFlag = false;
  public changeEmail: boolean;
  public showRegister: boolean;
  public showLogin: boolean;

  constructor(public activateRoute: ActivatedRoute,private dataSharingService: DataSharingService,
              public router: Router, private siteService: SiteService, private translate:TranslateService,
              private titleService:SetTitleService,@Inject(DOCUMENT) private document: Document) {
    this.translate.getTranslation(this.siteService.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(res['logIn']+' - '+res['page_title']);
    });

    this.dataSharingService.getWidgetListName(keywords.loginPageWidget);
  }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe(params => {
      if (params) {
        this.query = params;
        if (this.query.status === 'forgotSuccess') {
          this.forgetFlag = true;
        }
        if (this.query.status === 'resetPasswordSuccess') {
          this.resetFlag = true;
        }
        if(this.query.ChangePassword === 'Y') {
          this.changePassSuccFlag = true;
        }
        if(this.query.ChangeEmail==='Y'){
          this.changeEmail =true;
        }
      }
    });

  }
  scrollToBottom(e:HTMLElement,type:string): void {
    try {
      type==='register'?this.showRegister =true:this.showLogin=true;
      setTimeout(()=>{
        e.scrollIntoView();
      },200);

    } catch(err) { }
  }


  cancel(){
    this.forgetFlag = false;
    this.resetFlag = false;
    this.changePassSuccFlag = false;
    this.changePassFailFlag = false;
    this.changeEmail=false;
    let baseRoute = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
    this.router.navigate([baseRoute[routingEndpoint.login]])
  }

}
