import {Adapter} from '../../../global/adapter/adapter';

export class EmailConfirmation {
    constructor(public emailID: string,
        public captchaResponse: string,
        public isGDPRTracking: boolean) { }
}

export class CustomerServiceData{
    constructor(public customerServiceEmail: string,
        public isGDPRTrackingEnabled: boolean,
                public erpMailConfirm: boolean){}
}

export class CustomerServiceEmail{
    constructor(public status: string,
        private message: string,
        public data: CustomerServiceData){}
}

export class CustomerServiceAdapt implements Adapter<CustomerServiceEmail> {
    adapt(item:any): CustomerServiceEmail{
        return new CustomerServiceEmail(
            item.status,
            item.message,
            item.data
        );
    }
}
