<div [ngClass]="stickyClass?'sticky-header':''">
<header>

  <!-- <noscript>
    <iframe #iframe  height="0" width="0" style="display:none;visibility:hidden">
    </iframe>
  </noscript> -->
  <!-- <jp-custom-script>
    {{gtmmScript}}
  </jp-custom-script> -->
  <div class="row">
    <div class="col-6 col-md-8"><a id="gtm_hayslogo" [href]=" imageRedirectUrl | safeUrl" [ngClass]="hostName.indexOf('response.')>-1?'custom-logo logo': orgName=='it' || orgName === 'yourrailindustryjob' || orgName === 'geniuswithin' ?'tech-logo':'logo'">
      <img [src]="this.imageUrl" alt="Hays"></a></div>
    <div class="col-6 col-md-4 text-right" >
      <a class="search-mobile" (click)="sendClassToMainContainer('search-active')" *ngIf="this.route.url.indexOf('search')===-1"><i class="icon-search"></i></a>
      <a class="menu-mobile" (click)="sendClassToMainContainer('menu-active')"><em [translate]="'right_Toggle_menu_Label'"></em><i class="menu-icon"><span></span><span></span><span></span></i></a>
    </div>
  </div>
  <nav class="navbar" *ngIf="this.route.url.indexOf('secure-gateway')===-1">
    <ul>
      <li class="text-right my-account-link" *ngIf="loggedInUser && emailAddress">
        <a href="javascript:void(0)" class="nav-item nav-link icon-my-account" [textContent]="emailAddress"></a>
      </li>
      <li class="text-right log-in-out"  >
        <a *ngFor="let rightHeader of rightTopHeader; index as i" [class]="rightHeader.resource_url.indexOf('speculativeCV')>-1 ?'nav-item nav-link text-right log-in-out' : 'nav-item nav-link text-right log-in-out register'" id="gtm_header_rightHeader"[ngClass]="rightHeader.iconPath"  [textContent]="rightHeader.linkName" (click)="openRoutingUrl(rightHeader.resource_url)"></a>
      </li>
      <li class="home-mobile" *ngIf = 'isHomeNavigationLinkEnabled'>
        <a d="gtm_header_home" class="nav-item nav-link active icon-home" [href]="liferaybaseUrl" [translate]="'home'"></a>
      </li>
      <li class="text-left" *ngFor="let leftHeader of leftTopHeader">
        <a [ngClass]="leftHeader.iconPath" *ngIf="leftHeader.resource_url.indexOf('online-learning')>-1 || leftHeader.resource_url.indexOf('salary-checker')>-1 || leftHeader.resource_url.indexOf('hays-connect')>-1 || leftHeader.resource_url.indexOf('customer-service')>-1 || leftHeader.resource_url.indexOf('about-hays')>-1|| leftHeader.resource_url.indexOf('what-we-offer')>-1|| leftHeader.resource_url.indexOf('life-at-hays')>-1" class="nav-item nav-link active"
           id="gtm_header_leftHeader" [textContent]="leftHeader.linkName" [href]="leftHeader.resource_url"></a>
        <a [ngClass]="leftHeader.iconPath"  (click)="openRoutingUrl(leftHeader.resource_url)" *ngIf="!(leftHeader.resource_url.indexOf('online-learning')>-1 || leftHeader.resource_url.indexOf('salary-checker')>-1 || leftHeader.resource_url.indexOf('hays-connect')>-1 || leftHeader.resource_url.indexOf('customer-service')>-1 || leftHeader.resource_url.indexOf('about-hays')>-1|| leftHeader.resource_url.indexOf('what-we-offer')>-1|| leftHeader.resource_url.indexOf('life-at-hays')>-1)"
           id="gtm_header_leftHeader" class="nav-item nav-link active"  [textContent]="leftHeader.linkName" ></a>
      </li>
      <li class="text-left" *ngIf="usefullinks.length!=0 && isUsefulPrivacyEnabled" (click)="showDropDown.showData=!showDropDown.showData">
        <a [translate]="'useful_link_text'" class="nav-item nav-link active useful-link"></a>
      <ul class="sub-nav" *ngIf="showDropDown.showData">
        <li *ngFor="let usefullink of usefullinks">
          <a id="gtm_header_usefullink" *ngIf="showDropDown.showData" (click)="redirectTo(liferaybaseUrl+usefullink.LinkToURL)" [textContent]="usefullink.Label"></a>
        </li>
      </ul>
      </li>

      <li [class]="!isWorldwideNavigationEnabled ? 'worldwide-yrij' : 'worldwide-mobile'">
        <a class="nav-item nav-link activen icon-worldwide" [translate]="'worldwide_Note'"></a>
      </li>
      <li *ngIf="languages" class="change-languages">
        <select (change)="changeLanguage($event)" class="form-control">
          <option *ngFor="let language of languages" [disabled]="language.language===''"
                  id="language" [value]="language.url" [textContent]="language.language"></option>

        </select>
      </li>
    </ul>


  </nav>
  <div class="alert-count" *ngIf="alertCount && loggedInUser">
    <a id="gtm_alertcount_redirecttoprofile" class="nav-item nav-link active" [routerLink]="myProfileRouterLink">

      <span class="text-count" [textContent]="alertCount"></span>
      <span class="text-alert" *ngIf="alertCount==1" [translate]="'alert_text'"></span>
      <span class="text-alert" *ngIf="alertCount!=1" [translate]="'alerts_text'"></span>
    </a>
  </div>
</header>
</div>
<div>
  <app-search-box *ngIf="this.route.url.indexOf('secure-gateway')===-1" ></app-search-box>
  <!-- <h1 class="header-text-pl" *ngIf="keyWord && showSearch">{{jobCount+' '+keyWord+' '}}{{'jobs_in_text'| translate}}{{' '+this.location}}</h1> -->
  <h1 class="header-text-pl" *ngIf="dynamicPageTitleMetaTags && dynamicSearchText">{{dynamicSearchText}}</h1>
</div>

<jw-modal *ngIf ="isOpenModal" id="send_cv_confirmation" class="modal-popup">
  <div class="modal-header border-0">
   <!-- <a href="javascript:void(0)" class="close" (click)="closeModal('send_cv_confirmation')">+</a> -->
   <button type="button" class="close" (click)="closeModal('send_cv_confirmation')" data-dismiss="modal">&times;</button>
  </div>
  <div class="modal-body">
    <app-term-and-privacy [isChecked]="isChecked" (isConsentChecked)="getConsentChecked($event)" [type]="'specCV'">
    </app-term-and-privacy>
  </div>
  <div class="modal-footer">
      <button id="send_cv_button" class="btn btn-secondary" [translate]="'send_CV_text'" [disabled] = !isChecked (click)="sendCV('send_cv_confirmation')"></button>
  </div>
</jw-modal>

