<div class="thank-you-header">
<div *ngIf="!showErrorMesasges">
  <div  *ngIf = "!isExpertOrg">
    <h2>  {{'thank_you_text'|translate}} {{jobTitle?jobTitle.toUpperCase():''}} ({{'job_ref_label'| translate}} {{jobRef?jobRef:''}}) </h2>
          <!--<span [textContent]="jobTitle?jobTitle.toUpperCase():''"></span>-->
    <div>{{'applied_for_text' | translate}}</div>
</div>
<div class="career-thankyoumsg" *ngIf = "(isExpertOrg && isOrgName) || isExpertOrg">
  <h2> {{'careers_thank_you_text1'|translate}} </h2>
  <div> {{'careers_thank_you_text2'|translate}} </div>
</div>
</div>
<div *ngIf="showErrorMesasges">
  <h2 [translate]="'thank_you_text1'"></h2>
  <span> {{'contact_administrator_text'|translate}}</span>
</div>
</div>
