import { Injectable } from '@angular/core';
import { CanDeactivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import {HomeComponent} from '../../hla-app/secure/assessskill/components/home/home.component';
import {SiteService} from '../../global/services/site/site.service';

@Injectable()
export class HomeCanDeactivateGuard implements CanDeactivate<HomeComponent> {
  constructor(private _siteService: SiteService, private _translate: TranslateService,
              private readonly router: Router, private readonly location: Location) {
    //this._translate.setDefaultLang(this._siteService.getSiteLocale());
  }
  canDeactivate(target: HomeComponent, currentRoute: ActivatedRouteSnapshot) {
      if (target.hasChanges()) {
          const currentUrlTree = this.router.createUrlTree([], currentRoute);
          const currentUrl = currentUrlTree.toString();
          this.location.go(currentUrl);
          let message = '';
          this._translate.get('constants.pageReloadMessage').subscribe((res: string) => {
              message = res;
              window.scrollTo(0, 0);
            });
          return window.confirm(message);
      }
      return true;
  }
}
