<div class="job-box bg-white" *ngIf="training.length>0">
  <div >
    <div class="trainings">
      <div class="recommended-courses-widget">
        <h2 [translate]="'recommended_courses_text'"></h2>
        <div *ngFor="let trainings of training" class="go1_div">

            <img [src]="trainings.thumbnail" />
            <span>
              <a (click)="generateLinkUrl(trainings.trainingId)" [title]="trainings.title" [textContent]="trainings.title"></a>
              <i [textContent]="trainings.duration"></i> {{'minutes_text' | translate}}
            </span>

        </div>
        <input type="button" (click)="generateLinkUrl('');" id="gtm_go1_training"
               class="submit btn btn-primary" value="{{'explore_more_training_text' | translate}}" />
      </div>
    </div>
  </div>
</div>
<app-custom-loader *ngIf="router.url.indexOf('go1/training')>-1"></app-custom-loader>
