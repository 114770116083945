import {Injectable} from '@angular/core';
import {Adapter} from '../../../global/adapter/adapter';

export class WidgetJson{
  constructor(public sections:string, public resource_uris:string){
  }
}
export class WidgetData{
  constructor(public status:string,public message:string,public data:string){
  }
}
export class RightWidget{
  constructor(public linkName:string, public resource_name:string, public resource_url:string){
  }
}

@Injectable({
  providedIn:'root'
})
export class RightWidgetAdapter implements Adapter<WidgetData> {
  adapt(item: any): WidgetData {
    return new WidgetData(
      item.status,
      item.message,
      item.data
    );
  }
}
