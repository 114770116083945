export class Getcv{
  constructor(public extraParam: string,
              public redirectUrl: string,
              public state: string,
              public code: string,
              public drive: string,
              public path:string,
              public accessToken:string,
              public medium:string,
              public fileId:string) {
  }

}


