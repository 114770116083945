import {Component, Input, OnInit} from '@angular/core';
import {apiEndPoints, config, environment, routingEndpoint} from '../../../../../../../environments/root/environment';
import {RequestService} from '../../../../../../global/services/request/request.service';
import {BrowseByExpertiesResponse} from '../../../../../model/response/browse-by-experties-response';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {TranslateService} from '@ngx-translate/core';
import {TokenStorage} from '../../../../../../global/services/tokenStorage/token-storage';
import {ExtraFieldsConfig} from '../../../../../model/response/google-job-response';
import {Router} from '@angular/router';
import { ModalService } from 'src/app/erp-integration/service/modal.service';

@Component({
  selector: 'app-jp-no-result-found',
  templateUrl: './jp-no-result-found.component.html',
  styleUrls: ['./jp-no-result-found.component.scss']
})
export class JpNoResultFoundComponent implements OnInit {
  public browseByExpertiseRes : BrowseByExpertiesResponse[];
  public liferayUrl: string;
  public specCVURL: string;
  @Input()public jobConfig : ExtraFieldsConfig;
  public baseUrl :string;
  private officeLocatorURL: string;
  orgName: string;
  isOpenModal: boolean;
  isChecked: boolean;
  isExpertOrg: boolean;

  constructor(private requestService:RequestService,public siteService:SiteService,private translateService:TranslateService,
    private tokenStorage:TokenStorage, private route:Router, private modalService: ModalService) {
    this.translateService.setDefaultLang(this.siteService.getSiteLocale());
  }

  ngOnInit() {

    this.getBrowseByExpertise();
    this.baseUrl = window.location.origin;
    this.liferayUrl=environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.liferayUrl];
    this.getRouteUrls();
  }

  getRouteUrls(){
    let orgName = this.route.routerState.snapshot.root.firstChild.params["orgName"];
    config.ExpertClientsName.forEach(expertOg => {
      if (orgName == expertOg)
      this.isExpertOrg = true;
    })
    this.orgName = orgName;
    let base='';
    if(orgName){
      base= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org];
      this.specCVURL = (base[routingEndpoint.speculativeCV]).replace(':orgName',orgName);
      this.officeLocatorURL = (base[routingEndpoint.officeLocatorPage]).replace(':orgName',orgName);
    }else{
      base= environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
      this.specCVURL = base[routingEndpoint.speculativeCV];
      this.officeLocatorURL = base[routingEndpoint.officeLocatorPage];
    }
  }

  private getBrowseByExpertise() {
    let jsonForBrowseByExpertise = {"requestType": "BrowseByExpertise", "specialismType": ""};
    this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getBrowseByExpertise, jsonForBrowseByExpertise).subscribe(res => {
      if (res.status === config.SUCCESS)
          this.browseByExpertiseRes= res.data.results;
    });
  }

  redirectToResouceURL(type: string) {
    config.ExpertClientsName.forEach(a => {
      if (this.isExpertOrg) {
        this.isOpenModal = true;
        this.modalService.open('send_cv_confirmation_no_result');
      } else {
        if(type==='spec')
          window.location.href=this.specCVURL;
        else if(type==='officeLocator')
          window.location.href=this.officeLocatorURL;
        }
    })
    // if (this.orgName == config.HaysCareer || this.orgName == config.HaysHTS) {
    //   this.isOpenModal = true;
    //   this.modalService.open('send_cv_confirmation');
    //   // window.location.href = "mailto:UKI.careers@hays.com?subject=Hays UK Careers CV Submission";
    // } else {
    // if(type==='spec')
    //   window.location.href=this.specCVURL;
    // else if(type==='officeLocator')
    //   window.location.href=this.officeLocatorURL;
    // }
  }

  getConsentChecked($event: boolean) {
    this.isChecked = $event;
  }

  closeModal(id: string)
  {
    this.modalService.close(id);
    this.isChecked = false;
  }

  openMailClient(id) {
    this.isChecked = false;
    this.modalService.close(id);
    window.location.href = this.orgName == 'careers'? "mailto:UKI.careers@hays.com?subject=Hays UK Careers CV Submission" : "mailto:HTSUK.Careers@hays.com?subject=HTS HAYS UK CAREERS CV SUBMISSION";

  }
}
