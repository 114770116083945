import {Component, OnInit, Input} from '@angular/core';
import { RequestService } from 'src/app/global/services/request/request.service';
import { SiteService } from 'src/app/global/services/site/site.service';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../../../environments/root/environment';
import { Router } from '@angular/router';
import {SaveJobResponse} from "../../../../model/response/save-job-response";
import {SetTitleService} from '../../../../service/set-title.service';
import {TranslateService} from '@ngx-translate/core';
import {DataSharingService} from '../../../../service/data-sharing.service';


@Component({
  selector: 'app-jp-my-saved-job',
  templateUrl: './jp-my-saved-job.component.html',
  styleUrls: ['./jp-my-saved-job.component.scss']
})
export class JpMySavedJobComponent implements OnInit {
  public savedJobData : SaveJobResponse[];
  visible = false;
  saveFlag: boolean;
  jobId: any;
  empty = false;
  url: string;
  confirmMsg: any;
  public jobDeletionCalled: boolean;
  private errorRoute: string;

  constructor(private _requestService: RequestService,private dataSharingService : DataSharingService,
     private siteService:SiteService, private _router: Router,private titleService:SetTitleService,private translate:TranslateService) {
    this.translate.getTranslation(this.siteService.getSiteLocale()).subscribe((res)=>{
      this.titleService.setTitleForPage(res['shortlist_page_title']);
      this.confirmMsg = (res['bookmark_delete_confirmation']);
    });
    this.dataSharingService.getWidgetListName(keywords.savedJobsWidget);
    this.errorRoute = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.errorPage];
  }

  ngOnInit() {
    localStorage.removeItem("requstedRedirectURL");
    this.getSavedJobs();
  }


getSavedJobs(){
  let orgName = this._router.routerState.snapshot.root.firstChild.params["orgName"];
  if(orgName)
      this.errorRoute = (environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.errorPage]).replace(':orgName', orgName);
  this._requestService.getRequest(config.theme, apiEndPoints.gateWayAPI,apiEndPoints.getSavedJobs)
  .subscribe(response => {
    if(response.status === config.SUCCESS) {
      this.savedJobData = response.data;
      if(this.savedJobData && this.savedJobData.length>0){
        this.savedJobData.forEach(job=>{
          job.jobUrl = window.location.origin+job.jobUrl;
        })
      }
    }if(response.status === config.notFoundStatus && response.data.length == 0) {
        this.empty = true;
    }
  },error => {
    this._router.navigate([this.errorRoute], {queryParams:{getSavedJob:'N'}})

  });
}

deleteRow(jobId){
  this.jobId = jobId;
  if(confirm(this.confirmMsg)){
    this.jobDeletionCalled=true;
  this.deleteJob(this.jobId);
  }else{
    this.getSavedJobs();
  }
}

deleteJob(jobId){
  let payload = {jobRef:jobId}
  let baseRoute = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
  this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI,apiEndPoints.deleteJob,payload ).subscribe(response => {
  if (response.status === config.SUCCESS) {
    this.saveFlag = false;
    this.getSavedJobs();
    this.jobDeletionCalled=false;
}
else{
    this.jobDeletionCalled=false;
    this._router.navigate([this.errorRoute],{queryParams:{submitCV:'N',error:response.status}});
}
}, (error) => {
    this.jobDeletionCalled=false;
    this._router.navigate([this.errorRoute],{queryParams:{submitCV:'N',error: error.status}});
});
}



}
