import {Injectable} from '@angular/core';
import {Adapter} from '../../adapter/adapter';

export class ChangeLanguage{
  constructor(public status:string, public message:string, public data:Language[]){
  }
}

export class Language{
  constructor(public language:string, public url:string){
  }
}

@Injectable({
  providedIn:'root'
})
export class ChangeLanguageAdapt implements Adapter<ChangeLanguage>{
  adapt(item: any): ChangeLanguage {
    return new ChangeLanguage(
      item.status,
      item.message,
      item.data
    );
  }
}
