<section class="update-whatsapp-number">
    <div *ngIf="!showThankyou" >

        <div class="border WhatsApp-widget">
          <h1 [translate]="'whatsApp_job_alert_text'"></h1>
          <div class="form-group">
            <div class="col phone-number">
              <div class="isd-code">
              <i class="icon-phone"></i>
              <input type="text" id="currentNumberCode" [(ngModel)]="currentCountryCode" (keyup)="validate(currentCountryCode,'','',$event)" maxlength="5">
              </div>
              <input type="text" id="currentNumber"  placeholder="Current whatsapp number" [(ngModel)]="currentCountryNumber" (keyup)="validate(currentCountryCode,currentCountryNumber,'currentNumber',$event)">
            </div>
            <div class="col phone-number">
            <div class="isd-code">
              <i class="icon-phone"></i>
              <input type="text" id="newNumberCode"  [(ngModel)]="newCountryCode" (keyup)="validate(newCountryCode,'','',$event)" maxlength="5">
            </div>
              <input type="text" id="newNumber"  placeholder="New whatsapp number" [(ngModel)]="newCountryNumber" (keyup)="validate(newCountryCode,newCountryNumber,'newNumber',$event)">
            </div>

          </div>
          <label id="codeError" *ngIf="codeError" [translate]="'whatsapp_country_code_error_text'"></label>
          <label id="WANumberError" *ngIf="WANumberError" [translate]="'whatsApp_contact_validation_text'"></label>
          <span  id="currentNumError"  *ngIf="currentNumError" [translate]="'whatsApp_no_doesnt_exists_text'"></span>
          <span  id="newNumError"  *ngIf="newNumError" [translate]="'whatsApp_new_contact_validation_text'"></span>
          <button id="gtm_update_whtsapp_number_btn" class="btn btn-secondary btn-block" type="submit" (click)="submit()"
                  [disabled]="isdisable" [translate]="'whatsApp_update_text'"></button>
        </div>

    </div>
  <div class="whatsapp-container" *ngIf="showThankyou">
    <i class="tick"></i>
    <div class="thankyou" [translate]="'whatsApp_thankyou_text'"></div>
    <div [translate]="'whatsApp_contact_update_text'"></div>
  </div>

</section>
