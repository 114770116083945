<div class="widget-parent" *ngIf="widgetList.length > 0">
  <ng-container *ngFor="let widget of widgetList">
  <div class="widget widget-text footer-link" *ngIf="widget && ((widget.WIDGET_LINK && widget.WIDGET_NAME.includes('(c)') || !(widget.WIDGET_NAME.includes('(c)') && !widget.WIDGET_LINK)))">
        <div  *ngIf="widget.WIDGET_LABEL.includes('(c)') ">
        <h3 class="widget-text-title widget-text-title-person" [textContent]=" widget.WIDGET_TITLE "></h3>
          <p [textContent]="widget.WIDGET_CONTENT"></p>
          <button id="gtm_widget_BUTTON" class="widget-text-call-to-action btn btn-secondary">
            <a  [href]="widget.WIDGET_LINK | safeUrl"  [textContent]="widget.BUTTON_NAME"></a>
          </button>
        </div>
        <div *ngIf="!widget.WIDGET_LABEL.includes('(c)')">
            <div *ngIf=" widget.WIDGET_NAME.toLowerCase() === officeLocatorText">
              <h3 class="widget-text-title widget-text-title-person" [translate]="'find_near_me_title_text'"></h3>
            <!--  <app-jp-sector-for-office-locator *ngIf="buttonUrl===officeLocatorKeyword" (showLoader)="showAjaxLoader($event)" (selectedSpecialism)="getSpecialism($event)" ></app-jp-sector-for-office-locator>-->
              <button id="gtm_widget_find_near_me_btn" class="widget-text-call-to-action btn btn-secondary" >
                <a  [href]="buttonUrl===officeLocatorText ? findNearMeCall($event) : buttonUrl "  [translate]="'find_near_me'" ></a>
              </button>
             </div>
            <div *ngIf=" widget.WIDGET_NAME.toLowerCase() === timesheetText">
            <h3 class="widget-text-title widget-text-title-person" [translate]="'timesheet_title_text'"></h3>
            <p [translate]="'timesheet_body_text'"></p>
            <button id="gtm_widget_timesheet_btn" class="widget-text-call-to-action btn btn-secondary">
              <a  [href]="timesheetTextUrl"  [translate]="'timesheet_button_text'" ></a>
            </button>
          </div>
            <div *ngIf=" widget.WIDGET_NAME.toLowerCase() === specCvText">
              <h3 class="widget-text-title widget-text-title-person" [translate]="'spec_cv_title_text'"></h3>
              <p [translate]="'spec_cv_body_text'"></p>
              <button id="gtm_widget_spec_cv_btn" class="widget-text-call-to-action btn btn-secondary">
                <a  [href]="specCvTextUrl"  [translate]="'spec_cv_button_text'" ></a>
              </button>
            </div>
            <div *ngIf=" widget.WIDGET_NAME.toLowerCase() === recruitingNowText">
              <h3 class="widget-text-title widget-text-title-person" [translate]="'recruiting_title_text'"></h3>
              <p [translate]="'recruiting_body_text'"></p>
              <button id="gtm_widget_recruiting_btn" class="widget-text-call-to-action btn btn-secondary">
                <a  [href]="recruitingTextUrl"  [translate]="'recruiting_button_text'" ></a>
              </button>
            </div>
            <div *ngIf=" widget.WIDGET_NAME.toLowerCase() === salaryGuideText">
              <h3 class="widget-text-title widget-text-title-person" [translate]="'salary_guide_title_text'"></h3>
              <p [translate]="'salary_guide_body_text'"></p>
              <button id="gtm_widget_salary_guide_btn" class="widget-text-call-to-action btn btn-secondary">
                <a  [href]="salaryGuideTextUrl"  [translate]="'salary_guide_button_text'" ></a>
              </button>
            </div>
            <div *ngIf=" widget.WIDGET_NAME.toLowerCase() === followHaysText">
              <h3 class="widget-text-title widget-text-title-person">
                <a [href]="followHaysUrl" target="_blank"  [translate]="'follow_hays_widget_title'"></a>
              </h3>
            </div>
            <!--<div *ngIf="widget.WIDGET_NAME.toLowerCase() === recentSearchText">
              <h3 class="widget-text-title widget-text-title-person" [translate]="'most_recent_heading'"></h3>
              <div class="recent-widget">
                <ul>
                  <li id="viewRecentSearchList" class="active"><a href="" id="viewRecentSearch" [translate]="'searches'"></a>
                  </li>
                  <li id="viewRecentjobsList"><a href="" id="viewRecentjobs" [translate]="'viewed_jobs_text'"></a>
                  </li>
                </ul>
              </div>
              <div class="recent-widget-container" id="recent-searches-container">
                <ul id="recentSearchesList">
                  <li [translate]="'no_recent_searches'"></li>
                </ul>
              </div>
              <div class="recent-widget-container" id="viewed-jobs-container" >
                <ul id="recentViewedJobsList">
                  <li [translate]="'no_recent_viewed_jobs'">
                  </li>
                </ul>
              </div>
              <button class="widget-text-call-to-action btn btn-secondary">
                <a [href]="" id="clearAllRecent" [translate]="'clear_all_text'"></a>
              </button>
            </div>-->
        </div>
    </div>
  </ng-container>
</div>

