<section class="thank-you-msg">
    <div *ngIf="confirmationStatus == 'Y'" class="success">
        <div class="success-msg-cont">
            <i></i>
            <div>
                <p [translate]="'ps-confirmation-msg'"></p>
            </div>
        </div>
    </div>
    <div *ngIf="confirmationStatus == 'N'" class="no-data-found error">
        <i class="icon-warning">
            <img src="assets/images/errors_icon.jpg">
        </i>
        <div>
            <p [translate]="'error_message_internal_error'"></p>
        </div>
    </div>
</section>
