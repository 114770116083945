import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {
  ExtraFieldsConfig,
  GoogleJobResponse,
  Industries,
  JobSearchFilter,
  JobType,
  JobTypeFilter,
  LocationFilter,
  SortBy,
  WorkingPattern,
  WorkingPatternFilter
} from '../model/response/google-job-response';
import {BehaviorSubject} from 'rxjs';
import {GoogleJob} from '../model/request/google-job';
import {ActivatedRoute, Router} from '@angular/router';
import {apiEndPoints, config, environment, routingEndpoint} from '../../../environments/root/environment';
import {RequestService} from '../../global/services/request/request.service';
import {SiteService} from '../../global/services/site/site.service';
import {TokenStorage} from '../../global/services/tokenStorage/token-storage';
import {NgxXml2jsonService} from 'ngx-xml2json';
import {GCSPublishRequest} from '../model/request/gcspublish-request';
import {CookieService} from 'ngx-cookie-service';
import {DataSharingService} from './data-sharing.service';
import {isPlatformBrowser, isPlatformServer, PlatformLocation} from '@angular/common';
import {parseString} from 'xml2js';
import {TranslateService} from "@ngx-translate/core";
import {Meta, Title} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class JobSearchService {
  public googleJobRequest: GoogleJob;


  private pageNoSponsored: any;
  private googleJobResponse: GoogleJobResponse;
  private sponsoredGoogleJobResponseList: GoogleJobResponse[] = [];
  public pageNo: number = 0;
  public totalPage: number = 1;
  private sponsoredJobName: string;
  public googleJobResponseList: GoogleJobResponse[] = [];
  public JobType: JobType;
  private workingPattern = new WorkingPattern(0, 0, 0);
  private industries: Industries;
  public industriesList: Industries[] = [];
  private locationFilter: LocationFilter;
  public locationFilterList: LocationFilter[] = [];
  public slicedIndustries: Industries[] = [];
  public locationList: LocationFilter[] = [];
  public JobTypeFilter: JobTypeFilter;
  public JobTypeFilterList: JobTypeFilter[] = [];
  public sortTypeFilter: SortBy[] = [];
  private workingPatternFilter: WorkingPatternFilter;
  public workingPatternFilterList: WorkingPatternFilter[] = [];
  private specialismId: string;
  private subSpecialismId: string;
  private location: string;
  private locationf: string;
  private industryf: string;
  private sortType: string;
  private jobType: string;
  private flexiWorkType: string = '';
  private payTypefacet: string;
  private minPay: string;
  private maxPay: string;
  private jobSource: string;
  private keyWord: string;
  private searchPerformed: boolean = false;
  private searchPerformedValue = new BehaviorSubject(this.searchPerformed);
  sharedSearchPerformedValue = this.searchPerformedValue.asObservable();
  private jobNames: string = '';
  private keyWordUpdated = new BehaviorSubject('');
  sharedKeyWord = this.keyWordUpdated.asObservable();
  private countUpdated = new BehaviorSubject('');
  sharedCount = this.countUpdated.asObservable();
  private locationUpdated = new BehaviorSubject('');
  sharedLocation = this.locationUpdated.asObservable();
  private flexiWorkValue = new BehaviorSubject('');
  private jobTypeFilterValue = new BehaviorSubject('');
  sharedJobTypeFilterupdated = this.jobTypeFilterValue.asObservable();
  private sortTypeValue = new BehaviorSubject('');
  public payType: string[];
  public payTypeFilter: any;
  private jobSearchFilter: JobSearchFilter = undefined;
  private jobSearchFilterData = new BehaviorSubject(this.jobSearchFilter);
  sharedJobSearchFilterData = this.jobSearchFilterData.asObservable();
  private sponsoredHistogram: any;
  private sortBy: SortBy;
  private userAgent: string = '';
  private userAgentValue = new BehaviorSubject(this.userAgent);
  public sharedUserAgent = this.userAgentValue.asObservable();
  private applyId: string;
  private isSponsored: string;
  private recordId: string;
  private isSponsoredValue: boolean;
  private extraFieldConfig: ExtraFieldsConfig = undefined;
  private extraConfigDataValue = new BehaviorSubject(this.extraFieldConfig);
  public sharedConfigList = this.extraConfigDataValue.asObservable();
  private googleJobDetailResponseList: GoogleJobResponse[] = undefined;
  private jobDetailsData = new BehaviorSubject(this.googleJobDetailResponseList);
  public sharedJobDetailData = this.jobDetailsData.asObservable();
  private jobResult = new BehaviorSubject(this.googleJobResponseList);
  public sharedJobResponseList = this.jobResult.asObservable();
  private recentSearches = new BehaviorSubject('');
  public sharedRecentSearches = this.recentSearches.asObservable();
  private isWorkingPattern = new BehaviorSubject(false);
  public sharedIsWorkingPattern = this.isWorkingPattern.asObservable();
  private isScrolled = new BehaviorSubject(false);
  public sharedIsScrolled = this.isScrolled.asObservable();
  private sharedSortType = new BehaviorSubject('');
  public sharedSortTypeValue = this.sharedSortType.asObservable();
  jobMedium: string;
  public siteBaseURL: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string, private platformLocation: PlatformLocation) {
  }

  clearFilter() {
    this.jobSearchFilter = undefined;
    this.jobSearchFilterData.next(this.jobSearchFilter);
    this.jobTypeFilterValue.next('');
  }

  clearJobDetailData() {
    this.googleJobDetailResponseList = [];
    this.jobDetailsData.next(this.googleJobDetailResponseList);
    this.googleJobResponseList = [];
    this.jobResult.next(this.googleJobResponseList);

  }

  setJsonRequest(locationQuery, flexiWorkType, fullTime, industryf, isSponsored, jobtype, partTime, keyWord, locationFilter, sortTypeFilter, specialismId, subSpecialismId,
                 typeOnlyFilter, userAgent, radius, isCrossCountry, isResponseJob, responseSiteLocale, pageToken, jobId,
                 jobRef, crossCountryUrl, minSal, maxSal, payType, type, cookieDomain) {
    this.keyWord = keyWord;
    if (keyWord == 'eshotO' || keyWord == 'eshotC') {
      this.keyWord = '';
      this.googleJobResponseList = [];
    } else {
      this.googleJobRequest = new GoogleJob(locationQuery ? locationQuery : '', flexiWorkType,
        fullTime,
        industryf ? [industryf] : [''], isSponsored, [jobtype], partTime, this.keyWord ? this.keyWord : '',
        locationFilter ? locationFilter : '', maxSal, minSal,
        sortTypeFilter,
        specialismId ? specialismId : '', subSpecialismId ? subSpecialismId : '', typeOnlyFilter,
        userAgent ? userAgent : '', radius, isCrossCountry, isResponseJob,
        isResponseJob ? responseSiteLocale : '', pageToken, jobId ? jobId : '', jobRef ? jobRef : '', crossCountryUrl, payType, type, cookieDomain);
    }
    return this.googleJobRequest;
  }

  processSponsoredJob(data: any, crossCountryUrlList: string, histoGram: any, type, xmlParser: NgxXml2jsonService) {
    this.sponsoredGoogleJobResponseList = [];
    this.locationFilterList = [];
    this.industriesList = [];
    this.slicedIndustries = [];
    this.locationList = [];
    if (this.pageNo % 10 === 0) {
      let deskTopUrl: string[] = [];
      if (crossCountryUrlList && crossCountryUrlList !== ',') {
        deskTopUrl = crossCountryUrlList.split(',');
      }
      let crossCountryUrl = '';
      this.sponsoredHistogram = histoGram;
      if (data) {
        data.forEach(job => {
          if (job.nonFilterableCustomFields.xlocale && job.nonFilterableCustomFields.xlocale.values[0]) {
            let responseLocale = job.nonFilterableCustomFields.xlocale.values[0];
            if (responseLocale === 'ehr-ES') {
              responseLocale = 'ehr-ESR';
            }
            for (let i = 0; i < deskTopUrl.length; i++) {
              if (deskTopUrl[i]) {
                if (responseLocale && responseLocale.toLowerCase().indexOf('hr') > -1 && deskTopUrl[i].split('_')[1] && deskTopUrl[i].split('_')[1].toLowerCase().indexOf('hr') > -1) {
                  crossCountryUrl = deskTopUrl[i].split('_')[0];
                  break;
                } else if (deskTopUrl[i].split('_')[1] === responseLocale.split('-')[1]) {
                  crossCountryUrl = deskTopUrl[i].split('_')[0];
                  break;
                } else {
                  crossCountryUrl = '';
                }
              }
            }
            let itcEnabled: boolean;
            itcEnabled = environment[config.theme + localStorage.getItem('siteLocale')]['config']['ITC_job_visible'];
            this.googleJobResponse = new GoogleJobResponse(job.nonFilterableCustomFields.JobTitle ? job.nonFilterableCustomFields.JobTitle.values[0] : '', job.nonFilterableCustomFields.SearchTextSnippet ? job.nonFilterableCustomFields.SearchTextSnippet.values[0] : '',
              job.nonFilterableCustomFields.xLocationDescription ? job.nonFilterableCustomFields.xLocationDescription.values[0] : '', job.incentives, job.description, job.nonFilterableCustomFields.xjobType ? job.nonFilterableCustomFields.xjobType.values[0] : '',
              job.nonFilterableCustomFields.xIndustry ? job.nonFilterableCustomFields.xIndustry.values[0] : '', job.nonFilterableCustomFields.xCategory ? job.nonFilterableCustomFields.xCategory.values[0] : '', job.nonFilterableCustomFields.JobRef ? job.nonFilterableCustomFields.JobRef.values[0] : '',
              job.nonFilterableCustomFields.JobOwner ? job.nonFilterableCustomFields.JobOwner.values[0] : '', job.nonFilterableCustomFields.ShowJobOwner ? job.nonFilterableCustomFields.ShowJobOwner.values[0] : '', job.nonFilterableCustomFields.xLocaleRecordID ? job.nonFilterableCustomFields.xLocaleRecordID.values[0] : '',
              job.nonFilterableCustomFields.JobId ? job.nonFilterableCustomFields.JobId.values[0] : '', job.nonFilterableCustomFields.IsSponsored ? job.nonFilterableCustomFields.IsSponsored.values[0] : '', job.name,
              job.nonFilterableCustomFields.xlocale ? job.nonFilterableCustomFields.xlocale.values[0] : '',
              job.nonFilterableCustomFields.ClientLogoUrl ? job.nonFilterableCustomFields.ClientLogoUrl.values[0] : '',
              job.createDate, crossCountryUrl, 'sponsoredJob', job.nonFilterableCustomFields.FlexibleWorking ? job.nonFilterableCustomFields.FlexibleWorking.values[0] : '',
              job.nonFilterableCustomFields.FullTime ? job.nonFilterableCustomFields.FullTime.values[0] : '',
              job.nonFilterableCustomFields.PartTime ? job.nonFilterableCustomFields.PartTime.values[0] : '', job.nonFilterableCustomFields.JobAdvertVideo ?
                job.nonFilterableCustomFields.JobAdvertVideo.values[0] : '', !!job.description, job.nonFilterableCustomFields.MicrositeURL ?
                job.nonFilterableCustomFields.MicrositeURL.values[0] : '', job.endDate, job.nonFilterableCustomFields.xLatitude ? job.nonFilterableCustomFields.xLatitude.values[0] : '',
              job.nonFilterableCustomFields.xLongitude ? job.nonFilterableCustomFields.xLongitude.values[0] : '',
              job.nonFilterableCustomFields.ApplicationUrl ? job.nonFilterableCustomFields.ApplicationUrl.values[0] : '',
              false, job.nonFilterableCustomFields.DisplayClosingDate ? (job.nonFilterableCustomFields.DisplayClosingDate.values[0] === 'True') : false,
              false, itcEnabled ? job.nonFilterableCustomFields.MicrositeID ? job.nonFilterableCustomFields.MicrositeID.values[0] : '' : '', job.nonFilterableCustomFields.JobSource ? job.nonFilterableCustomFields.JobSource.values[0] : '',
              job.nonFilterableCustomFields.OrgCode ? job.nonFilterableCustomFields.OrgCode.values[0] : '',
              job.nonFilterableCustomFields.xSpecialism ? job.nonFilterableCustomFields.xSpecialism.values[0] : '',
              job.nonFilterableCustomFields.xSubSpecialism ? job.nonFilterableCustomFields.xSubSpecialism.values[0] : '');
            this.sponsoredGoogleJobResponseList.push(this.googleJobResponse);
          }
        });
      }
    }
    return this.sponsoredGoogleJobResponseList;

  }

  processNonSponsoredJob(result: any, crossCountryList: string, type: string, xmlParser: NgxXml2jsonService,
                         requestService: RequestService, siteService: SiteService, cookieService: CookieService, router: Router) {
    let requestId = result.header ? result.header.requestId : '';
    const setSponsoredJob = router.routerState.snapshot.root.firstChild.params['orgName'] && (router.routerState.snapshot.root.firstChild.params['orgName'] === 'yourrailindustryjob' || router.routerState.snapshot.root.firstChild.params['orgName'] === 'geniuswithin') ? 'sponsoredJob' : '';
    this.locationFilterList = [];
    this.industriesList = [];
    this.slicedIndustries = [];
    this.locationList = [];
    this.workingPatternFilterList = [];
    this.JobTypeFilterList = [];
    this.sortTypeFilter = [];
    this.JobType = new JobType(0, 0, 0);
    this.workingPattern = new WorkingPattern(0, 0, 0);

    if (type !== 'jobDetails' && type !== 'similarJob' && type !== 'jobApply' && type !== 'thankYou') {
      if (this.sponsoredGoogleJobResponseList[this.pageNo]) {
        this.sponsoredJobName = this.sponsoredGoogleJobResponseList[this.pageNo].jobName;
        this.googleJobResponseList.push(this.sponsoredGoogleJobResponseList[this.pageNo]);
      }

      if (result.histograms) {
        this.processHistoGrams(result.histograms);
      } else {
        this.processHistoGrams(this.sponsoredHistogram);
      }
      this.countUpdated.next(result.resultCount);
    } else {
      this.googleJobResponseList = [];
      this.sponsoredJobName = '';
      this.googleJobResponse = null;
    }

    let deskTopUrl: string[] = [];
    if (crossCountryList && crossCountryList != ',') {
      deskTopUrl = crossCountryList.split(',');
    }
    let crossCountryUrl = '';
    if (result.jobs) {
      result.jobs.forEach(job => {
        this.jobNames = this.jobNames + ',' + job.name;
        if (this.sponsoredJobName != job.name) {
          if (job.nonFilterableCustomFields.xlocale && job.nonFilterableCustomFields.xlocale.values[0]) {
            let responseLocale = job.nonFilterableCustomFields.xlocale.values[0];
            if (responseLocale === 'ehr-ES') {
              responseLocale = 'ehr-ESR';
            }
            if (deskTopUrl.length > 0) {
              for (let i = 0; i < deskTopUrl.length; i++) {
                if (deskTopUrl[i]) {
                  if (responseLocale && responseLocale.toLowerCase().indexOf('hr') > -1 && deskTopUrl[i].split('_')[1].toLowerCase().indexOf('hr') > -1) {
                    crossCountryUrl = deskTopUrl[i].split('_')[0];
                    break;
                  } else if (deskTopUrl[i].split('_')[1] === responseLocale.split('-')[1]) {
                    crossCountryUrl = deskTopUrl[i].split('_')[0];
                    break;
                  } else {
                    crossCountryUrl = '';
                  }
                }
              }
            }
            let itcEnabled: boolean;
            itcEnabled = environment[config.theme + localStorage.getItem('siteLocale')]['config']['ITC_job_visible'];
            this.googleJobResponse = new GoogleJobResponse(job.nonFilterableCustomFields.JobTitle ? job.nonFilterableCustomFields.JobTitle.values[0] : '', job.nonFilterableCustomFields.SearchTextSnippet ? job.nonFilterableCustomFields.SearchTextSnippet.values[0] : '',
              job.nonFilterableCustomFields.xLocationDescription ? job.nonFilterableCustomFields.xLocationDescription.values[0] : '',
              job.incentives != "0.00" ? job.incentives : null, job.description, job.nonFilterableCustomFields.xjobType ? job.nonFilterableCustomFields.xjobType.values[0] : '',
              job.nonFilterableCustomFields.xIndustry ? job.nonFilterableCustomFields.xIndustry.values[0] : '', job.nonFilterableCustomFields.xCategory ? job.nonFilterableCustomFields.xCategory.values[0] : '', job.nonFilterableCustomFields.JobRef ? job.nonFilterableCustomFields.JobRef.values[0] : '',
              this.getJobOwner(job.nonFilterableCustomFields.JobOwner ? job.nonFilterableCustomFields.JobOwner.values[0] : '', xmlParser), job.nonFilterableCustomFields.ShowJobOwner ? job.nonFilterableCustomFields.ShowJobOwner.values[0] : '', job.nonFilterableCustomFields.xLocaleRecordID ? job.nonFilterableCustomFields.xLocaleRecordID.values[0] : '',
              job.nonFilterableCustomFields.JobId ? job.nonFilterableCustomFields.JobId.values[0] : '', job.nonFilterableCustomFields.IsSponsored ? job.nonFilterableCustomFields.IsSponsored.values[0] : '', job.name,
              job.nonFilterableCustomFields.xlocale ? job.nonFilterableCustomFields.xlocale.values[0] : '',
              job.nonFilterableCustomFields.ClientLogoUrl ? job.nonFilterableCustomFields.ClientLogoUrl.values[0] : '',
              job.createDate, crossCountryUrl, setSponsoredJob, job.nonFilterableCustomFields.FlexibleWorking ? job.nonFilterableCustomFields.FlexibleWorking.values[0] : '',
              job.nonFilterableCustomFields.FullTime ? job.nonFilterableCustomFields.FullTime.values[0] : '',
              job.nonFilterableCustomFields.PartTime ? job.nonFilterableCustomFields.PartTime.values[0] : '', job.nonFilterableCustomFields.JobAdvertVideo ?
                job.nonFilterableCustomFields.JobAdvertVideo.values[0] : '', !!job.description, job.nonFilterableCustomFields.MicrositeURL ?
                job.nonFilterableCustomFields.MicrositeURL.values[0] : '', job.endDate, job.nonFilterableCustomFields.xLatitude ? job.nonFilterableCustomFields.xLatitude.values[0] : '',
              job.nonFilterableCustomFields.xLongitude ? job.nonFilterableCustomFields.xLongitude.values[0] : '',
              job.nonFilterableCustomFields.ApplicationUrl ? job.nonFilterableCustomFields.ApplicationUrl.values[0] : '',
              false, job.nonFilterableCustomFields.DisplayClosingDate ? (job.nonFilterableCustomFields.DisplayClosingDate.values[0] === 'True') : false,
              false, itcEnabled ? job.nonFilterableCustomFields.MicrositeID ? job.nonFilterableCustomFields.MicrositeID.values[0] : '' : '', job.nonFilterableCustomFields.JobSource ? job.nonFilterableCustomFields.JobSource.values[0] : '',
              job.nonFilterableCustomFields.OrgCode ? job.nonFilterableCustomFields.OrgCode.values[0] : '',
              job.nonFilterableCustomFields.xSpecialism ? job.nonFilterableCustomFields.xSpecialism.values[0] : '',
              job.nonFilterableCustomFields.xSubSpecialism ? job.nonFilterableCustomFields.xSubSpecialism.values[0] : '');
            this.googleJobResponseList.push(this.googleJobResponse);
          }
        } else {
          //console.log('job name are same ...', job.name);
        }

      });
    } else {
      if (type === 'jobDetails' || type === 'jobApply' || type === 'thankYou') {
        let endPoint = environment[config.theme + siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.search];
        let orgName = router.routerState.snapshot.root.firstChild.params['orgName'];
        if (orgName) {
          endPoint = (environment[config.theme + siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.search]).replace(':orgName', orgName);
        }

        /* if (localStorage.getItem('haysToken'))
           endPoint = routeBaseUrl[routingEndpoint.secureSearch];
         else*/
        //endPoint = routeBaseUrl[routingEndpoint.search];
        if (isPlatformBrowser(this.platformId)) {
          router.navigate([endPoint], {
            queryParams: {
              q: '',
              stat: 'e',
              location: '',
              jobType: '-1'
            }
          });
        }


      }
    }
    /*setTimeout(()=>{
      this.gcsPublishEvent(requestService,this.googleJobResponseList,type,requestId)
    },200);*/

    if (this.keyWord && isPlatformBrowser(this.platformId)) {
      this.setRecentSearch(this.keyWord, cookieService);
    }
    return this.googleJobResponseList;
  }

  procesJobFilter(data: any) {
    if (data.JobType) {
      data.JobType.forEach((data, index) => {
        switch (data._key) {
          case 'Perm':
            this.JobTypeFilter = new JobTypeFilter(data.type, (this.JobType && this.JobType.P) ? this.JobType.P : 0, 'FULL_TIME');
            this.JobTypeFilterList.push(this.JobTypeFilter);
            break;
          case 'Temp':
            this.JobTypeFilter = new JobTypeFilter(data.type, (this.JobType && this.JobType.T) ? this.JobType.T : 0, 'TEMPORARY');
            this.JobTypeFilterList.push(this.JobTypeFilter);
            break;
          case 'Contract':
            this.JobTypeFilter = new JobTypeFilter(data.type, (this.JobType && this.JobType.C) ? this.JobType.C : 0, 'CONTRACTOR');
            this.JobTypeFilterList.push(this.JobTypeFilter);
            break;
        }
      });
    }
    if (data.SortByRelevance) {
      data.SortByRelevance.forEach(sort => {
        switch (sort._key) {
          case 'Sort by Relevance':
            this.sortBy = new SortBy(sort.type, 'RELEVANCE_DESC');
            break;
          case 'Sort by Date':
            this.sortBy = new SortBy(sort.type, 'PUBLISHED_DATE_DESC');
            break;
          case 'Sort by Title':
            this.sortBy = new SortBy(sort.type, 'TITLE');
            break;
        }
        this.sortTypeFilter.push(this.sortBy);

      });
    }
    if (data.WorkingPattern) {
      data.WorkingPattern.forEach(pattern => {
        switch (pattern._key) {
          case 'Flexible Working':
            this.workingPatternFilter = new WorkingPatternFilter(pattern.type, this.workingPattern.flexibleWorking ? this.workingPattern.flexibleWorking : 0);
            this.workingPatternFilterList.push(this.workingPatternFilter);
            break;
          case 'Full-time':
            this.workingPatternFilter = new WorkingPatternFilter(pattern.type, this.workingPattern.fullTime ? this.workingPattern.fullTime : 0);
            this.workingPatternFilterList.push(this.workingPatternFilter);
            break;
          case 'Part-time':
            this.workingPatternFilter = new WorkingPatternFilter(pattern.type, this.workingPattern.partTime ? this.workingPattern.partTime : 0);
            this.workingPatternFilterList.push(this.workingPatternFilter);
            break;
        }
      });
    }
    if (data.Pay) {
      this.payTypeFilter = data.Pay;
    }
  }

  routeNavigate(router: Router, activatedRoute: ActivatedRoute, siteLocale: string, jobType: string, sortType: string, workingType: string, location: string,
                industry: string, payTypeIndex: string, minPayIndex: string, maxPayIndex: string, type: string, searchTitleParam: string, searchDescParam: string, dataShareService: DataSharingService) {
    let searchTitle = '';
    let searchDesc = '';
    activatedRoute.queryParamMap.subscribe(param => {
      this.pageNo = 0;
      this.specialismId = '';
      this.subSpecialismId = '';
      this.sponsoredGoogleJobResponseList = [];
      this.googleJobRequest = null;
      if (type === 'filtered') {
        if (param.get('q')) {
          this.keyWord = param.get('q');
        }
        if (param.get('locationf') && location === null) {
          this.locationf = param.get('locationf');
        } else {
          this.locationf = location;
        }
        if (param.get('specialismId')) {
          this.specialismId = param.get('specialismId');
        }
        if (param.get('subSpecialismId')) {
          this.subSpecialismId = param.get('subSpecialismId');
        }
        if (param.get('location')) {
          this.location = param.get('location');
        } else {
          this.location = '';
        }
        if (param.get('industryf') && industry == null) {
          this.industryf = param.get('industryf');
        } else {
          this.industryf = industry;
        }
        if (param.get('sortType') && !sortType) {
          this.sortType = param.get('sortType');
        } else {
          this.sortType = sortType;
        }
        if (param.get('jobType') && !jobType) {
          this.jobType = param.get('jobType');
        } else {
          this.jobType = jobType;
        }
        if (param.get('flexiWorkType') && !workingType) {
          this.flexiWorkType = param.get('flexiWorkType');
        } else {
          this.flexiWorkType = workingType;
        }
        if (param.get('payTypefacet') && !payTypeIndex) {
          this.payTypefacet = param.get('payTypefacet');
        } else {
          this.payTypefacet = payTypeIndex;
        }
        if (param.get('minPay') && !minPayIndex) {
          this.minPay = param.get('minPay');
        } else {
          this.minPay = minPayIndex;
        }
        if (param.get('maxPay') && !maxPayIndex) {
          this.maxPay = param.get('maxPay');
        } else {
          this.maxPay = maxPayIndex;
        }
        if (param.get('jobSource')) {
          this.jobSource = param.get('jobSource');
        }
        if (param.get('jobsource')) {
          this.jobSource = param.get('jobsource');
        }
        if (param.get('source')) {
          this.jobSource = param.get('source');
        }
        if (param.get('searchPageTitle')) {
          searchTitle = param.get('searchPageTitle');
        }
        if (param.get('searchPageDesc')) {
          searchDesc = param.get('searchPageDesc');
        }

      }
    });
    /*'&searchPageTitle=' + encodeURI(this.searchPageTitle) + '&searchPageDesc=' + encodeURI(this.searchPageDesc);*/
    let routingUrl = '';
    /*if(localStorage.getItem("haysToken"))
      routingUrl = environment[config.theme + siteLocale][routingEndpoint.routing_url][routingEndpoint.secureSearch];
    else*/
    //add orgname
    let orgName = router.routerState.snapshot.root.firstChild.params['orgName'];
    if (orgName) {
      let url = environment[config.theme + siteLocale][routingEndpoint.routing_url_org][routingEndpoint.search];
      routingUrl = url.replace(':orgName', orgName);
    } else {
      routingUrl = environment[config.theme + siteLocale][routingEndpoint.routing_url][routingEndpoint.search];
    }
    let defaultJobSource = '';
    dataShareService.sharedClientConfig.subscribe(res => defaultJobSource = res.jobSource);
    router.navigate([routingUrl], {
      queryParams: {
        q: this.keyWord ? this.keyWord : '',
        location: this.location ? this.location : '',
        specialismId: this.specialismId ? this.specialismId : '',
        subSpecialismId: this.subSpecialismId ? this.subSpecialismId : '',
        locationf: this.locationf ? this.locationf : '',
        industryf: this.industryf ? this.industryf : '',
        sortType: this.sortType,
        jobType: this.jobType,
        flexiWorkType: this.flexiWorkType,
        payTypefacet: this.payTypefacet,
        minPay: this.minPay,
        maxPay: this.maxPay,
        jobSource: this.jobSource ? this.jobSource : defaultJobSource,
        searchPageTitle: searchTitle,
        searchPageDesc: searchDesc
      }
    });
  }

  private setRecentSearch(keyWord: string, cookieService: CookieService) {
    let keyWordUpdated = '';
    keyWord = encodeURIComponent(keyWord);
    let recentSearchTerm = '';
    let name = keyWord + '|';
    if (!cookieService.get('recentSearches')) {
      cookieService.set('recentSearches', name, 15, '/');
    } else {
      let recentArr = cookieService.get('recentSearches').split('|');
      let duplicateRemovestr = '';
      keyWordUpdated = keyWord;
      if (recentArr.length > 0) {
        recentArr.forEach(arr => {
          if (arr && keyWordUpdated && (arr.toLowerCase() !== keyWordUpdated.toLowerCase())) {
            if (duplicateRemovestr) {
              duplicateRemovestr = duplicateRemovestr + '|' + arr;
            } else {
              duplicateRemovestr = arr;
            }
          }
        });
        recentSearchTerm = duplicateRemovestr;
        if (recentSearchTerm.split('|').length >= 5) {
          recentSearchTerm = recentSearchTerm.substring(0, recentSearchTerm.lastIndexOf('|'));
        }
      }
      if (recentSearchTerm != null && recentSearchTerm != '') {
        keyWord = keyWord + '|' + recentSearchTerm;
      }
    }
    cookieService.set('recentSearches', keyWord, 15, '/');
    this.getRecentSearchCookie(cookieService.get('recentSearches'));
  }

  private getRecentSearchCookie(recentViewedJobs: string) {
    this.recentSearches.next(recentViewedJobs);

  };

  setSerchPerfomedValue(searchPerformed: boolean) {
    this.searchPerformedValue.next(searchPerformed);
  }

  setKeyWords(keyWord: string, location: string, count: string) {
    this.keyWordUpdated.next(keyWord);
    this.locationUpdated.next(location);
  }

  setJobFilterValue(flexiWorkType: string, jobType: string, sortType: string) {
    this.flexiWorkValue.next(flexiWorkType);
    this.jobTypeFilterValue.next(jobType);
    this.sortTypeValue.next(sortType);
  }

  setJobSearchFilter(jobSearchFilter: JobSearchFilter) {
    this.jobSearchFilterData.next(jobSearchFilter);

  }

  private processHistoGrams(histograms: any) {
    if (histograms) {
      histograms.forEach(histogram => {
        switch (histogram.field) {
          case 'JOB_CUSTOM_FIELD_4':
            this.JobType = histogram.values;
            break;
          case 'FullTime':
            this.workingPattern.fullTime = histogram.values.TRUE;
            break;
          case 'PartTime':
            this.workingPattern.partTime = histogram.values.TRUE;
            break;
          case 'FlexibleWorking':
            this.workingPattern.flexibleWorking = histogram.values.TRUE;
            break;
          case 'JOB_CUSTOM_FIELD_2':
            for (let key in histogram.values) {
              this.industries = new Industries(key, histogram.values[key]);
              this.industriesList.push(this.industries);
            }
            break;
          case 'JOB_CUSTOM_FIELD_3':
            for (let key in histogram.values) {
              this.locationFilter = new LocationFilter(key, histogram.values[key]);
              this.locationFilterList.push(this.locationFilter);
            }
            break;

        }

      });
      this.industriesList = this.industriesList.sort((a, b) => a.industryName.localeCompare(b.industryName));
      this.locationFilterList = this.locationFilterList.sort((a, b) => {
        if ((a.count > b.count)) {
          return -1;
        }
      });
      this.slicedIndustries = this.industriesList;
      this.slicedIndustries = this.slicedIndustries.slice(0, 10);
      this.locationList = this.locationFilterList;
      this.locationList = this.locationList.slice(0, 10);
    }
  }

  getUserAgent(userAgent: string) {
    let ua = userAgent.toLowerCase();
    if (!ua) {
      ua = '-Desktop';
    }
    if (ua.indexOf('(?i).*((android|bb\\d+|meego).+mobile|avantgo|bada\\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge' +
      ' |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\\.(browser|link)' +
      '|vodafone|wap|windows (ce|phone)|xda|xiino).*') > -1 || ua.substring(0, 4).indexOf('(?i)1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|awa|abac|ac(er|oo|s\\-)|' +
      'ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\\-m|r |s)|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb' +
      '|bw\\-(n|u)|c55\\/|capi|ccwa|cdm\\-|cell|chtm|cldc|cmd\\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\\-s|devi|dica|dmob|do(c|p)o|ds(12|\\-d)|el(49|ai)|em(l2|ul)' +
      '|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\\-|_)|g1 u|g560|gene|gf\\-5|g\\-mo|go(\\.w|od)|gr(ad|un)|haie|hcit|hd\\-(m|p|t)|hei\\-|hi(pt|ta)|hp( i|ip)|' +
      'hs\\-c|ht(c(\\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\\-(20|go|ma)|i230|iac( |\\-|\\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|' +
      'kgt( |\\/)|klon|kpt |kwc\\-|kyo(c|k)|le(no|xi)|lg( g|\\/(k|l|u)|50|54|\\-[a-w])|libw|lynx|m1\\-w|m3ga|m50\\/|ma(te|ui|xo)|mc(01|21|ca)|m\\-cr|me(rc|ri)|mi(o8|oa|' +
      'ts)|mmef|mo(01|02|bi|de|do|t(\\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|' +
      'op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\\-2|po(ck|rt|se)|prox|psio|pt\\-g|qa\\-a|qc(07|12|21|32|60|\\-[2-7]|i\\-)|qtek' +
      '|r380|r600|raks|rim9|ro(ve|zo)|s55\\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\\-|oo|p\\-)|sdk\\/|se(c(\\-|0|1)|47|mc|nd|ri)|sgh\\-|shar|sie(\\-|m)|sk\\-0|sl(45|id)|' +
      'sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\\-|v\\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\\-|tdg\\-|tel(i|m)|tim\\-|t\\-mo|to(pl|sh)|ts(70|m\\-|m3|m5)|' +
      'tx\\-9|up(\\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|' +
      'x700|yas\\-|your|zeto|zte\\-') > -1) {
      this.userAgent = '-Mobile';
    } else if (ua.indexOf('(?i).*(android|avantgo|playbook|blackberry|samsung|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge|maemo|midp|mmp|' +
      'opera m(ob|in)i|palm(os)?|phone|p(ixi|re)\\/|plucker|pocket|psp|symbian|treo|up\\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino).*') > -1) {
      this.userAgent = '-Tablet';
    } else {
      this.userAgent = '-Desktop';
    }
    this.setUserAgent(this.userAgent);

    return this.userAgent;
  }

  setUserAgent(userAgent: string) {
    this.userAgentValue.next(userAgent);
  }

  getJobDeatils(activatedRoute: ActivatedRoute, requestService: RequestService, siteService: SiteService, tokenStorage: TokenStorage,
                xmlParser: NgxXml2jsonService, type: string, JobTitle: string, locationDec: string, cookieService: CookieService,
                router: Router, userAgent, cookieDomain, dataShareService: DataSharingService,
                translation: TranslateService, titleService: Title, metaService: Meta, document: Document) {
    this.googleJobResponseList = [];
    this.googleJobDetailResponseList = [];
    let defaultJobSource = '';
    dataShareService.sharedClientConfig.subscribe(res => {
      if (res) {
        defaultJobSource = res.jobSource;
        this.jobMedium = res.jobMedium;
      }
    });
    this.jobMedium = this.jobMedium ? this.jobMedium : 'GCJ';

    activatedRoute.queryParamMap.subscribe(param => {
      if (param.get('applyId')) {
        this.applyId = param.get('applyId');
      } else if (param.get('id')) {
        this.applyId = param.get('id');
      } else {
        this.applyId = '';
      }
      if (param.get('isSponsored')) {
        this.isSponsored = param.get('isSponsored');
      }
      this.jobSource = param.get('jobSource') ? param.get('jobSource') : param.get('jobsource') ? param.get('jobsource') : param.get('source') ? param.get('source') : defaultJobSource;
      if (param.get('specialismId')) {
        this.specialismId = param.get('specialismId');
      }
      if (param.get('subSpecialismId')) {
        this.subSpecialismId = param.get('subSpecialismId');
      }
      if (param.get('q')) {
        this.keyWord = param.get('q');
      }
      if (param.get('location')) {
        this.location = param.get('location');
      } else {
        this.location = '';
      }
    });
    activatedRoute.paramMap.subscribe(param => {
      if (param.get('recordId')) {
        this.recordId = param.get('recordId');
        if (this.recordId.indexOf('job_') > -1 || this.recordId.indexOf('JOB_') > -1) {
          this.applyId = this.recordId;
        }
      }
    });
    if (isPlatformServer(this.platformId)) {
      const urlParams = new URLSearchParams(this.platformLocation.href);
      if (urlParams.get('applyId')) {
        this.applyId = urlParams.get('applyId');
      }
      if (urlParams.get('location')) {
        this.location = urlParams.get('location');
      }
      if (urlParams.get('q')) {
        this.keyWord = urlParams.get('q');
      }
      if (urlParams.get('subSpecialismId')) {
        this.subSpecialismId = urlParams.get('subSpecialismId');
      }
      if (urlParams.get('specialismId')) {
        this.specialismId = urlParams.get('specialismId');
      }
      if (urlParams.get('isSponsored')) {
        this.isSponsored = urlParams.get('isSponsored');
      }
    }
    if (type === 'jobDetails' || type === 'jobApply' || type === 'thankYou') {
      //this.keyWord = '';
      this.isSponsoredValue = this.isSponsored === 'Y';
    }
    this.sharedConfigList.subscribe(domainConfig => {
      if (domainConfig) {
        this.extraFieldConfig = domainConfig;
        this.getJobData(siteService, this.extraFieldConfig, tokenStorage, requestService, userAgent, type, xmlParser, JobTitle, locationDec,
          cookieService, router, cookieDomain, translation, titleService, metaService, document, activatedRoute);
      }
    });
  }


  public getJobDeatilsForSeek(activatedRoute: ActivatedRoute, requestService: RequestService, siteService: SiteService, tokenStorage: TokenStorage,
                              xmlParser: NgxXml2jsonService, type: string, JobTitle: string, locationDec: string,
                              cookieService: CookieService, router: Router, cookieDomain, dataShareService: DataSharingService,
                              translation: TranslateService, titleService: Title, metaService: Meta, document: Document) {
    let userAgent = this.getUserAgent(window.navigator.userAgent);
    this.googleJobResponseList = [];
    this.googleJobDetailResponseList = [];

    activatedRoute.queryParamMap.subscribe(param => {
      if (param.get('state')) {
        this.applyId = decodeURIComponent(param.get('state')).split('applyId=')[1].split('&location=')[0];
        this.jobSource = decodeURIComponent(param.get('state')).split('jobSource=')[1].split('&utm_campaign=')[0];
        this.location = decodeURIComponent(param.get('state')).split('location=')[1].split('&locationId=')[0];
        this.specialismId = decodeURIComponent(param.get('state')).split('specialismId=')[1].split('&subSpecialismId=')[0];
        this.subSpecialismId = decodeURIComponent(param.get('state')).split('subSpecialismId=')[1].split('&locale=')[0];
      }
    });
    dataShareService.sharedClientConfig.subscribe(res => {
      if (res)
        this.jobMedium = res.jobMedium;
    });

    this.jobMedium = this.jobMedium ? this.jobMedium : 'GCJ';

    if (type === 'jobDetails' || type === 'jobApply' || type === 'thankYou') {
      this.keyWord = '';
      this.isSponsoredValue = this.isSponsored === 'Y';
    }
    this.sharedConfigList.subscribe(domainConfig => {
      if (domainConfig) {
        this.extraFieldConfig = domainConfig;

        this.getJobData(siteService, this.extraFieldConfig, tokenStorage, requestService, userAgent, type, xmlParser, JobTitle, locationDec,
          cookieService, router, cookieDomain, translation, titleService, metaService, document, activatedRoute);
      }
    });
  }


  public setExtraConfigField(extraFieldConfig: ExtraFieldsConfig) {
    this.extraConfigDataValue.next(extraFieldConfig);
  }

  private parseXml(xmlStr) {
    let result;
    parseString(xmlStr, (e, r) => {
      result = r
    });
    return result;
  }

  private getJobOwner(xmlValue: any, xmlParser: NgxXml2jsonService) {
    if (xmlValue) {
      if (isPlatformServer(this.platformId)) {

        return this.parseXml(xmlValue);
      } else {
        const parser = new DOMParser();
        const xml = parser.parseFromString(xmlValue, 'text/xml');

        return xmlParser.xmlToJson(xml);
      }
    }
  }

  private getJobData(siteService: SiteService, extraFieldConfig: ExtraFieldsConfig, tokenStorage: TokenStorage, requestService: RequestService,
                     userAgent, type, xmlParser, JobTitle: string, locationDec: string, cookieService: CookieService, router: Router, cookieDomain: string
    , translation: TranslateService, titleService: Title, metaService: Meta, document: Document, activatedRoute: ActivatedRoute) {
    let jobResponse: GoogleJobResponse[];
    let googleJobRequest: GoogleJob;
    let endPoint = '';
    this.siteBaseURL = environment[config.theme + siteService.getSiteLocale()]['siteBaseURL'];
    switch (this.jobMedium) {
      case 'GCJ':
        if (tokenStorage.getLoggedInToken()) {
          endPoint = apiEndPoints.getgooglejobssecure;
        } else {
          endPoint = apiEndPoints.getgooglejobs;
        }
        break;
      case '':
        break;
    }
    if (type === 'jobDetails' || type === 'jobApply' || type === 'thankYou') {
      googleJobRequest = this.getJsonRequest(this.isSponsoredValue, extraFieldConfig, userAgent, siteService, JobTitle, locationDec, type, cookieDomain);
      requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, endPoint, googleJobRequest).subscribe(res => {
        if (res.status === config.SUCCESS) {
          this.googleJobDetailResponseList = this.processNonSponsoredJob(res.data.result, this.extraFieldConfig.crossCountryUrl,
            type, xmlParser, requestService, siteService, cookieService, router);
          if(type==='jobDetails')
            this.setDetailsPageMetaTags(this.googleJobDetailResponseList, siteService, translation, titleService, metaService, document, activatedRoute);
          this.setJobDetailResponse(this.googleJobDetailResponseList);

        } else if (res.status === config.notFoundStatus) {
          let endPoint = environment[config.theme + siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.search];
          let orgName = router.routerState.snapshot.root.firstChild.params['orgName'];
          if (orgName) {
            endPoint = (environment[config.theme + siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.search]).replace(':orgName', orgName);
          }
          if (isPlatformBrowser(this.platformId)) {
            router.navigate([endPoint], {
              queryParams: {
                q: '',
                stat: 'e',
                location: '',
                jobType: '-1'
              }
            });
          }


        }
      });
    } else {
      googleJobRequest = this.getJsonRequest(false, extraFieldConfig, userAgent, siteService, JobTitle, locationDec, type, cookieDomain);
      this.callNonSponsored(false, userAgent, siteService, endPoint, googleJobRequest, xmlParser, requestService, JobTitle, locationDec, cookieService, router);
    }
  }

  setDetailsPageMetaTags(googleJobResponse: GoogleJobResponse[], siteService: SiteService, translation: TranslateService, titleService: Title, metaService: Meta, document: Document, activatedRoute: ActivatedRoute) {
    if (googleJobResponse && googleJobResponse.length > 0) {
      translation.getTranslation(siteService.getSiteLocale()).subscribe((res) => {
        //  const title = googleJobResponse[0].JobTitle + ' ' + res['page_title'];
        const title = googleJobResponse[0].JobTitle;
        const jobDescription = googleJobResponse[0].SearchTextSnippet;
        const author = res['page_title'];
       // const siteBaseURL = environment[config.theme + siteService.getSiteLocale()]['siteBaseURL'];
        const siteBaseURL =  this.siteBaseURL;
        let sharedImageUrl = '';
        let metaURL = siteBaseURL + this.platformLocation.pathname;
        let orgNameList = ['/it/','/cognizant/','/yourrailindustryjob/', '/geniuswithin/'];
        orgNameList.forEach(orgName => {
          console.log("orgName..",orgName);
          console.log("location path....",this.platformLocation.pathname);
          if (this.platformLocation.pathname.indexOf(orgName) > -1) {
            console.log("inside if ");
            metaURL = siteBaseURL + this.platformLocation.pathname.replace(orgName, '/');
            console.log("meta url.. ",metaURL);
          }
        });
        if(siteBaseURL && siteBaseURL.indexOf('response') > -1){
          sharedImageUrl = siteBaseURL + '/assets/images/Hays_R_RGB_OG_Sharing.jpg';
        }
        else{
          sharedImageUrl = siteBaseURL + '/assets/images/hays-og-optimize-image-new.png';
        }
        const type = res['hays_publication_events_text'];
        titleService.setTitle(title);
        const tags = [
          {property: 'og:title', content: title},
          {name: 'description', content: jobDescription},
          {property: 'og:description', content: jobDescription},
          {name: 'author', content: author},
          {property: 'og:url', content: metaURL},
          {property: 'og:type', content: type},
          {property: 'og:image', content: sharedImageUrl}
        ];
        if (siteBaseURL && siteBaseURL.indexOf('response') > -1) {
          tags.push({property: 'og:image:width', content: '200'});
          tags.push({property: 'og:image:width', content: '200'});
        } else {
          tags.push({property: 'og:image:width', content: '382'});
          tags.push({property: 'og:image:width', content: '200'});
        }
        metaService.addTags(tags);
        this.updateCanonicalUrl(metaURL, document);

      });
    }
  }

  updateCanonicalUrl(url: string, document: Document) {
    const head = document.getElementsByTagName('head')[0];
    var element: HTMLLinkElement = document.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element = document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url)
  }

  private getJsonRequest(isSponsored: boolean, extraFieldConfig: ExtraFieldsConfig, userAgent: any, siteService: SiteService, JobTitle: string, locationDec: string, type: string, cookieDomain: string) {
    let googleJobRequest: GoogleJob;
    if (this.recordId) {
      this.recordId = this.recordId.split('_')[1];
      if (this.recordId && this.recordId.indexOf('&') > -1) {
        this.recordId = this.recordId.split('&')[0];
      }
    }
    if (this.applyId && this.applyId.indexOf('&') > -1) {
      this.applyId = this.applyId.split('&')[0];
    }
    let keyWord = '';
    if (this.keyWord) {
      keyWord = this.keyWord;
    }
    let location = '';
    if (this.location) {
      location = this.location;
    }
    if (type === 'similarJob') {
      googleJobRequest = new GoogleJob(locationDec ? locationDec : '', null, null, [''],
        isSponsored, [''],
        null, JobTitle ? JobTitle : keyWord, location, '', '', JobTitle ? 'RELEVANCE_DESC' : null,
        this.specialismId ? this.specialismId : '',
        this.subSpecialismId ? this.subSpecialismId : '', '', userAgent,
        extraFieldConfig.radius, extraFieldConfig.isCrossCountry, extraFieldConfig.isResponseJob,
        extraFieldConfig.responseSiteLocale,
        JobTitle ? '0' : null, !JobTitle ? this.applyId : '',
        (!this.applyId && !JobTitle) ? (this.recordId ? this.recordId : null) : '', extraFieldConfig.crossCountryUrl,
        '', type, cookieDomain);
    } else {
      googleJobRequest = new GoogleJob(locationDec ? locationDec : '', null, null, [''],
        isSponsored, [''],
        null, JobTitle ? JobTitle : keyWord, location, null, null, JobTitle ? 'RELEVANCE_DESC' : null,
        this.specialismId ? this.specialismId : '',
        this.subSpecialismId ? this.subSpecialismId : '', null, userAgent,
        extraFieldConfig.radius, extraFieldConfig.isCrossCountry, extraFieldConfig.isResponseJob,
        extraFieldConfig.responseSiteLocale,
        JobTitle ? '0' : null, !JobTitle && this.applyId ? this.applyId : '',
        (!this.applyId && !JobTitle) ? (this.recordId ? this.recordId : null) : '', extraFieldConfig.crossCountryUrl,
        null, type, cookieDomain);
    }
    return googleJobRequest;
  }

  private callNonSponsored(isSponsored: boolean, userAgent: any, siteService: SiteService, endPoint: string, googleJobRequest: GoogleJob,
                           xmlParser: any, requestService, JobTitle: string, locationDec: string, cookieService: CookieService, router: Router) {
    requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, endPoint, googleJobRequest).subscribe(res => {
      if (res.status === config.SUCCESS) {
        this.countUpdated.next(res.data.result['resultCount']);
        this.googleJobResponseList = this.processNonSponsoredJob(res.data.result, this.extraFieldConfig.crossCountryUrl, 'similarJob', xmlParser,
          requestService, siteService, cookieService, router);
        this.setJobResponse(this.googleJobResponseList);
      }
    });

  }

  public setJobDetailResponse(googleJobDetailResponseList: GoogleJobResponse[]) {
    this.jobDetailsData.next(googleJobDetailResponseList);
  }

  public setJobResponse(googleJobResponse: GoogleJobResponse[]) {
    this.jobResult.next(googleJobResponse);
  }

  private gcsPublishEvent(requestService: RequestService, googleJobResponses: GoogleJobResponse[], type: string, requestId: string) {
    let jobIds: string[] = [];
    if (googleJobResponses && googleJobResponses.length > 0) {
      googleJobResponses.forEach(job => {
        jobIds.push(job.jobName);
      });
    }
    let gcsPublishEventRequest: GCSPublishRequest;
    gcsPublishEventRequest = new GCSPublishRequest(type, jobIds, requestId);
    requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.gcsPublish, gcsPublishEventRequest).subscribe(res => {
    });

  }

  setWorkingPattern(workingPattern: boolean) {
    this.isWorkingPattern.next(workingPattern);
  }

  setScrolledValue(isScrolled: boolean) {
    this.isScrolled.next(isScrolled);
  }

  setSharedSortType(type: string) {
    this.sharedSortType.next(type);
  }
}
