import {Component, EventEmitter, Inject, Input, OnInit, Output, Renderer2} from '@angular/core';
import {ExtraFieldsConfig, GoogleJobResponse} from '../../../../../model/response/google-job-response';
import {JobSearchService} from '../../../../../service/job-search.service';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {DataSharingService} from '../../../../../service/data-sharing.service';
import {config} from '../../../../../../../environments/root/environment';

@Component({
  selector: 'app-jp-social-platform',
  templateUrl: './jp-social-platform.component.html',
  styleUrls: ['./jp-social-platform.component.scss']
})
export class JpSocialPlatformComponent implements OnInit {
  @Input() jobToShare: GoogleJobResponse[];
  public config: ExtraFieldsConfig;
  public hostName: string;
  public addThisUrl: string;
  public addThisDescription: string;
  public emailBodyLink: string;
  private addThisData:string[]=[];
  @Output()public emailBodyLinkShare: EventEmitter<string>=new EventEmitter<string>();

  constructor(private jobSearchService: JobSearchService, private _renderer2: Renderer2,
              @Inject(DOCUMENT) private _document: Document, private activatedRoute: ActivatedRoute, private domSanitizer: DomSanitizer,
              private translateService: TranslateService, private siteService: SiteService,private dataShareService:DataSharingService,
             ) {
    this.translateService.setDefaultLang(this.siteService.getSiteLocale());
  }

  ngOnInit() {
    this.hostName = window.location.hostname;
    this.jobSearchService.sharedConfigList.subscribe(config => this.config = config);
    if (this.jobToShare && this.jobToShare.length > 0) {
      this.addThisDescription = this.domSanitizer.bypassSecurityTrustHtml(this.jobToShare[0].description).toString();
    }
    let lang = '';
    let isSponsored = '';
    this.activatedRoute.queryParamMap.subscribe(param => {
      if (param.get('lang')) {
        lang = param.get('lang');
      }
      if (param.get('isSponsored')) {
        isSponsored = param.get('isSponsored');
      }
    });
    this.hostName = config.baseWebAppUrl;
    this.addThisUrl =this.hostName + window.location.pathname + '?lang=' + lang+ '&isSponsored=' + isSponsored;
    this.makeEmailLink();
    this.makeSocialSharingLink();
  }

  private makeEmailLink() {
    let job_url = this.addThisUrl+'&utm_source=EmailMeMobile&utm_medium=Email&utm_campaign=Emailjob';
    this.emailBodyLink = this.getLink(job_url);
    this.emailBodyLinkShare.emit(this.emailBodyLink);
    //console.log("emailBodyLink..",this.emailBodyLink);
  }
  private makeSocialSharingLink() {
    this.addThisUrl = this.getLink(this.addThisUrl);
  }

  xIndexOf(Val, Str, x) {
    if (x <= (Str.split(Val).length - 1)) {
      let Ot = Str.indexOf(Val);
      if (x > 1) {
        for (let i = 1; i < x; i++) {
          Ot = Str.indexOf(Val, Ot + 1);
        }
      }
      return Ot;
    } else {
      return 0;
    }
  }

  private getLink(job_url: string) {
    let seperator = this.xIndexOf('/', job_url, 5);

    let head_url = encodeURI(job_url.substring(0, seperator + 1));
    job_url = job_url.substring(seperator + 1, job_url.length);

    let queryString;
    if (job_url.indexOf('?') > -1) {
      queryString = job_url.substring(job_url.indexOf('?'), job_url.length);
      job_url = job_url.substring(0, job_url.indexOf('?'));
    }
    return encodeURI(head_url) + job_url + encodeURIComponent(queryString);
  }
}
