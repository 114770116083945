import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {config} from '../../../../../../../environments/root/environment';
import {JobSearchService} from '../../../../../service/job-search.service';
import {ExtraFieldsConfig} from '../../../../../model/response/google-job-response';
import {SiteService} from '../../../../../../global/services/site/site.service';

@Component({
  selector: 'app-video-my-job',
  templateUrl: './video-my-job.component.html',
  styleUrls: ['./video-my-job.component.scss']
})
export class VideoMyJobComponent implements OnInit {

  @Input() public sharedVideoUrl: string;
  public videoUrl: string;
  public videoId: string;
  public config: ExtraFieldsConfig;
  public pattern=config.youtubePattern;

  constructor(private urlSanitize:DomSanitizer, private jobSearchService: JobSearchService,private siteService:SiteService) {
  }

  ngOnInit() {
    this.jobSearchService.sharedConfigList.subscribe(config=>this.config=config);
    this.getVideoIdFromUrl(this.sharedVideoUrl);
    if(this.config.isVideoMyJobEnabled && this.videoId)
      this.videoUrl=<string>this.urlSanitize.bypassSecurityTrustResourceUrl(config.youtubeEmbedUrl+this.videoId+config.youtubeEmbedProperties);
  }

  private getVideoIdFromUrl(url){
    if(url.match(this.pattern)!=null && url.match(this.pattern)!='' )
      this.videoId=url.match(this.pattern)[1];
  }

}
