import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {JpSimilarJobsComponent} from './components/jp-similar-jobs/jp-similar-jobs.component';
import {JpBreadcrumbsComponent} from './components/jp-breadcrumbs/jp-breadcrumbs.component';
import {RECAPTCHA_BASE_URL, RecaptchaFormsModule, RecaptchaModule} from 'ng-recaptcha';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {HttpLoaderFactory} from '../jobportal.module';
import {ErrorPagesComponent} from '../../global/components/shared/error-pages/error-pages.component';
import { CustomLoaderComponent } from './components/custom-loader/custom-loader.component';
import {environment} from '../../../environments/root/environment';
import { MainLoaderComponent } from './components/main-loader/main-loader.component';
import {SentenceCasePipe} from '../pipes/sentence-case.pipe';
import {JpUploadCvComponent} from './components/jp-upload-cv/jp-upload-cv.component';
import {JpSectorComponent} from './components/jp-sector/jp-sector.component';
import {JpOfficeForSpecialismComponent} from './components/jp-office-for-specialism/jp-office-for-specialism.component';
import {QualifyingQuestionsComponent} from './components/qualifying-questions/qualifying-questions.component';
import {JpGetCvComponent} from './components/jp-get-cv/jp-get-cv.component';
import {SuccessPagesComponent} from '../../global/components/shared/success-pages/success-pages.component';
import {JpOfficeComponent} from './components/jp-office/jp-office.component';
import {JpSubspecialismComponent} from './components/jp-subspecialism/jp-subspecialism.component';
import {TermAndPrivacyComponent} from './components/term-and-privacy/term-and-privacy.component';
import {JpLegalConsentPopupComponent} from './components/jp-legal-consent-popup/jp-legal-consent-popup.component';
import {JpCaptchaComponent} from './components/jp-captcha/jp-captcha.component';
import {JpRegisterNewComponent} from './components/jp-register-new/jp-register-new.component';
import {JpRegisterNewKanaComponent} from './components/jp-register-new-kana/jp-register-new-kana.component';
import {JpResetPasswordComponent} from './components/jp-reset-password/jp-reset-password.component';
import {JpAlertThankyouComponent} from './components/jp-alert-thankyou/jp-alert-thankyou.component';
import { JpVerifyAlertComponent } from './components/jp-verify-alert/jp-verify-alert.component';
import { JpLegalConsentCheckboxPopupComponent } from './components/jp-legal-consent-checkbox-popup/jp-legal-consent-checkbox-popup.component';
import {JpWidgetlistComponent} from './jp-widgetlist/jp-widgetlist.component';
import { JpMarketingConsentComponent } from './components/jp-marketing-consent/jp-marketing-consent.component';
import { JpMarketingConsentPopupComponent } from './components/jp-marketing-consent-popup/jp-marketing-consent-popup.component';
import { JpMarketConsentLinkedInPopupComponent } from './components/jp-market-consent-linked-in-popup/jp-market-consent-linked-in-popup.component';
import { JpMarketingLinkedInAlertPopupComponent } from './components/jp-marketing-linked-in-alert-popup/jp-marketing-linked-in-alert-popup.component';
import {SafeHtmlPipe} from "../pipes/safe-html.pipe";
import {JpDkMarketingPrefComponent} from './components/jp-dk-marketing-pref/jp-dk-marketing-pref.component';
import {JpCookiesSettingsComponent} from './gdpr/components/jp-cookies-settings/jp-cookies-settings.component';
import {JpMarketingPrefThankyouComponent} from "./gdpr/components/jp-marketing-pref-thankyou/jp-marketing-pref-thankyou.component";
import {SafeUrlPipe} from "../pipes/safe-url.pipe";
import { JpPrivacyModalComponent } from './components/jp-privacy-modal/jp-privacy-modal.component';
import { PlayStoreComponent } from './components/play-store/play-store.component';
import { JpMarketingSettingComponent } from './components/jp-marketing-setting/jp-marketing-setting.component';


@NgModule({
    declarations: [
      JpSimilarJobsComponent, JpBreadcrumbsComponent,JpRegisterNewComponent,JpRegisterNewKanaComponent,
      ErrorPagesComponent, CustomLoaderComponent, MainLoaderComponent, MainLoaderComponent, SentenceCasePipe,
      JpUploadCvComponent,JpSectorComponent,JpOfficeForSpecialismComponent,TermAndPrivacyComponent,JpCaptchaComponent,
      JpLegalConsentPopupComponent,QualifyingQuestionsComponent,JpGetCvComponent,SuccessPagesComponent,JpOfficeComponent,JpSubspecialismComponent,
      JpResetPasswordComponent,JpAlertThankyouComponent, JpVerifyAlertComponent,JpLegalConsentCheckboxPopupComponent,JpWidgetlistComponent,
      JpMarketingConsentComponent,JpMarketingPrefThankyouComponent , JpMarketingConsentPopupComponent, JpMarketConsentLinkedInPopupComponent,
      JpMarketingLinkedInAlertPopupComponent,SafeHtmlPipe,SafeUrlPipe,JpDkMarketingPrefComponent,JpCookiesSettingsComponent, 
      JpPrivacyModalComponent, PlayStoreComponent, JpMarketingSettingComponent
    ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers:[
    {
      provide: RECAPTCHA_BASE_URL,
      useValue: environment.captchaJs,
    }
  ],
  exports: [JpSimilarJobsComponent, JpBreadcrumbsComponent, ErrorPagesComponent, CustomLoaderComponent, MainLoaderComponent,
    SentenceCasePipe, JpUploadCvComponent, JpSectorComponent, JpOfficeForSpecialismComponent, TermAndPrivacyComponent,SafeHtmlPipe,SafeUrlPipe,
    JpCaptchaComponent,JpMarketingPrefThankyouComponent , JpLegalConsentPopupComponent, QualifyingQuestionsComponent, JpGetCvComponent, SuccessPagesComponent, JpOfficeComponent, JpSubspecialismComponent,
    JpRegisterNewKanaComponent, JpRegisterNewComponent, JpResetPasswordComponent, JpAlertThankyouComponent, JpLegalConsentCheckboxPopupComponent, JpWidgetlistComponent, JpMarketingConsentComponent, 
    JpMarketingConsentPopupComponent, JpMarketConsentLinkedInPopupComponent, JpMarketingLinkedInAlertPopupComponent,JpDkMarketingPrefComponent,JpCookiesSettingsComponent, JpPrivacyModalComponent,
    PlayStoreComponent, JpMarketingSettingComponent]
})
export class SharedModule {
}
