import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';


@Component({
  selector: 'app-jp-register-new-kana',
  templateUrl: './jp-register-new-kana.component.html',
  styleUrls: ['./jp-register-new-kana.component.scss']
})
export class JpRegisterNewKanaComponent implements OnInit {

  @Input() public parent: FormGroup;
  @Input() public formSubmitted: boolean;
  @Input() public katkanalastname: string;
  @Input() public katkanafirstname: string;
  @Input() public firstName: string;
  @Input() public lastName: string;

  constructor() {}

  ngOnInit() {
  }

}

