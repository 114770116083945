<app-custom-loader *ngIf="(jobAlertResponse && jobAlertResponse.length===0) || delete"></app-custom-loader>

<Section class="my-searches">
  <h2 [translate]="'my_job_alerts'"></h2>
  <div class="job-alert-msg" *ngIf="!showAlertList || !jobAlertResponse ||(jobAlertResponse && jobAlertResponse.length===0)">
    <span [translate]="'job_alert_text1'"></span>
    <span [translate]="'job_alert_text2'"></span>

  </div>


  <ul class="alert-listing" *ngIf="showAlertList && jobAlertResponse">
    <li *ngFor="let alert of jobAlertResponse">
      <h4><p [textContent]="alert.alertname"></p><span [textContent]="alert.lastediteddate"></span></h4>
      <div class="btn-cont">
        <button id="gtm_edit_job_alert_btn" class="btn btn-secondary" [translate]="'edit_job_alerts'" (click)="editJobAlert(alert)" [disabled]="delete"></button>
        <span id="gtm_delete_job_alert_btn" class="icon-cancel" (click)="deleteJobAlert(alert)"></span>
      </div>
    </li>

  </ul>
</Section>
