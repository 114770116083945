import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {SharedModule} from '../../../shared/shared.module';
import {UnsubscribeEmailConfirmationRoutingModule} from './unsubscribe-email-confirmation-routing.module';
import {JpUnsubscribeEmailConfirmationComponent} from "./jp-unsubscribe-email-confirmation/jp-unsubscribe-email-confirmation.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { TransferState } from '@angular/platform-browser';
import { translateBrowserLoaderFactory } from 'src/app/global/services/loaders/translate-browser.loader';


@NgModule({
  declarations: [JpUnsubscribeEmailConfirmationComponent],
  imports: [
    CommonModule,
    UnsubscribeEmailConfirmationRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState]
      }
    })
  ],exports:[],
  providers: [DatePipe]
})
export class UnsubscribeEmailConfirmationModule { }
