import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {JpUnsubscribeEmailConfirmationComponent} from "./jp-unsubscribe-email-confirmation/jp-unsubscribe-email-confirmation.component";


const route: Routes = [
  {path: '',component:JpUnsubscribeEmailConfirmationComponent},

];

@NgModule({
  imports: [RouterModule.forChild(route)],
  exports: [RouterModule]
})
export class UnsubscribeEmailConfirmationRoutingModule {
}
