import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RequestService} from 'src/app/global/services/request/request.service';
import {UploadCV, UploadCVAdapt} from 'src/app/jobportal/model/response/uploadCV';
import {map} from 'rxjs/operators';
import {Getcv} from '../../../model/request/getcv';
import {ActivatedRoute, Router} from '@angular/router';
import {CvResponse} from '../../../../global/models/partner/cv-response';
import {FormGroup} from '@angular/forms';
import {DataSharingService} from '../../../service/data-sharing.service';
import {CookieService} from 'ngx-cookie-service';
import {CvData} from '../../../model/request/cv-data';
import {apiEndPoints, config, environment,keywords} from 'src/environments/root/environment';
import {SiteService} from 'src/app/global/services/site/site.service';


@Component({
  selector: 'app-jp-upload-cv',
  templateUrl: './jp-upload-cv.component.html',
  styleUrls: ['./jp-upload-cv.component.scss'],
  providers: [UploadCVAdapt]
})
export class JpUploadCvComponent implements OnInit {
  fileData: any;
  validationFormatFlag: boolean = true;
  validationSizeFlag: boolean = true;
  public localDrive = false;
  fileObject: any;
  public status: string;
  private locationStr: string = '';
  private locationId: string = '';
  private locationSet: string = '';
  private jobSource: string = '';
  private utm_campaign: string = '';
  private extraParam: string = '';
  public cvResponse: CvResponse;
  @Input() parent: FormGroup;
  public dropBoxData: boolean;
  @Output() showValidationMessage = new EventEmitter<any>();
  validationFlag: boolean;
  private SignUpForm: FormGroup;
  private applyForm: FormGroup;
  fileUploadMedium = {
    isActiveLocalDrive: false,
    isActiveDropbox: false,
    isActiveGDrive: false
  };
  public isCVSelected: boolean;
  public uploadedCvCheck=false;
  public maximumSizeMsg:any;
  public updateYourDetailsUrl: string;
  @Input() isGo1Page: boolean = false;


  constructor(private _requestService: RequestService, public uploadCVAdapt: UploadCVAdapt, private route: ActivatedRoute, public router: Router,
              private dataShareService: DataSharingService, private cookieService: CookieService, private siteService: SiteService) {
  }

  ngOnInit() {
    if (localStorage.getItem('showCVOptions') === 'true' || localStorage.getItem('showCVOptions') === 'false')
      this.status = localStorage.getItem('showCVOptions');
    else
      this.showGDriveAndDropbox();
    this.dataShareService.sharedCVSelectedInfo.subscribe(cvSelected => {
      this.isCVSelected = cvSelected;
      if (this.isCVSelected) {
        this.fileUploadMedium.isActiveLocalDrive = false;
        this.fileUploadMedium.isActiveGDrive = false;
        this.fileUploadMedium.isActiveDropbox = false;
      }
    });
    this.dataShareService.sharedCVData.subscribe(res => {
      if (res == true)
        this.uploadedCvCheck = true;
      if (res == false) {
        this.uploadedCvCheck = false;
        this.validationFormatFlag = true;
        this.validationSizeFlag = true;
      }
    });
    let defaultJobSource = '';
    this.dataShareService.sharedClientConfig.subscribe(res => {
        if (res) {
          defaultJobSource = res.jobSource
        }
      }
    );
    this.route.queryParamMap.subscribe(param => {
      //console.log('param..', param.get('path'));
      if (param.get('location')) {
        this.locationStr = param.get('location');
      }
      if (param.get('locationId')) {
        this.locationId = param.get('locationId');
      }
      if (param.get('locationSet')) {
        this.locationSet = param.get('locationSet');
      }

      this.jobSource = param.get('jobSource') ? param.get('jobSource') : param.get('jobsource') ? param.get('jobsource') : param.get('source') ? param.get('source') : defaultJobSource;

      if (param.get('uploadCV')) {
        if (this.fileUploadMedium.isActiveDropbox) {
          this.fileUploadMedium.isActiveDropbox = false;
        }
        if (this.fileUploadMedium.isActiveGDrive) {
          this.fileUploadMedium.isActiveGDrive = false;
        }
        if (this.fileUploadMedium.isActiveLocalDrive) {
          this.fileUploadMedium.isActiveLocalDrive = false;
        }
      }
      if (param.get('utm_campaign')) {
        this.utm_campaign = param.get('utm_campaign');
      }
      if (param.get('medium') || param.get('path') || param.get('fileId') || param.get('accessTokenDB')) {
        this.getFileLink(param.get('medium'), param.get('path'), param.get('fileId'), param.get('accessTokenDB'));
      }
    });
    this.extraParam = '&location=' + this.locationStr + '&locationId=' + this.locationId + '&locationSet=' + this.locationSet + '&jobSource=' + this.jobSource + '&utm_campaign=' + this.utm_campaign;
    this.maximumSizeMsg= environment[config.theme + this.siteService.getSiteLocale()]['config']['maximumSize'];
    this.updateYourDetailsUrl = keywords.updateYourDetailsUrl;
  }

  uploadFile(data) {
    if (data.length !== 0) {
      this.fileUploadMedium.isActiveLocalDrive = true;
      this.fileUploadMedium.isActiveGDrive = false;
      this.fileUploadMedium.isActiveDropbox = false;
      this.dropBoxData = false;
      this.cvResponse = undefined;
      this.fileObject = {};
      this.isCVSelected=false;
      this.dataShareService.setCVSelectedValue(this.isCVSelected);
      this.parent['controls'].cvData.patchValue(null);
      this.localDrive = false;
      if (this.validateFileExt(data[0].name)) {
        if (this.ValidateFileSize(data[0].size)) {
          this.uploadedCvCheck = false;
          const file: File = data[0];
          this.fileObject = data[0];
          const reader: FileReader = new FileReader();
          reader.onload = (e) => {
            this.fileObject.fileData = reader.result;
            this.localDrive = true;
            this.dropBoxData = false;
            this.cvResponse = undefined;
            this.parent['controls'].cvData.patchValue(this._dataTransform(this.fileObject, 'localdrive'));
          };
          if (file) {
            reader.readAsDataURL(data[0]);
          }

        }
      }
      this.validationFlag = this.validationFormatFlag && this.validationSizeFlag;
      this.showValidationMessage.emit(this.validationFlag);
    }
  }

  _dataTransform(data, type) {
    return (type === 'dropbox' || type === 'gdrive') ? new CvData('', '', data.fileName, '', data.fileLink, data.medium)
      : new CvData(data.fileData.split('base64,')[1], data.type, data.name, data.size, '', '');
  }

  validateFileExt(name: String) {
    this.validationFormatFlag = false;
    var ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
    if (ext.includes('pdf') || ext.includes('txt') || ext.includes('doc') || ext.includes('docx') || ext.includes('rtf')) {
      this.validationFormatFlag = true;
    }
    return this.validationFormatFlag;
  }

  ValidateFileSize(size: Number) {
    return this.validationSizeFlag = size < this.maximumSizeMsg;
  }

  selectedFile() {
    document.getElementById('uploadInput').click();
  }

  showGDriveAndDropbox() {
    this._requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getGDriveAndDropbox).pipe(
      map((response: UploadCV) => this.uploadCVAdapt.adapt(response))
    ).subscribe(response => {
      this.status = response.status === config.SUCCESS ? 'true' : 'false';
      localStorage.setItem('showCVOptions', String(this.status));
    }, (error) => {
      this.status = 'false';
      localStorage.setItem('showCVOptions', 'false');
    });
  }

  public connectPartnerForCV(type: string) {
    this.isCVSelected = false;
    this.dataShareService.setCVSelectedValue(this.isCVSelected);
    this.fileUploadMedium.isActiveLocalDrive = false;
    if (type === 'dropbox') {
      this.fileUploadMedium.isActiveDropbox = true;
      this.fileUploadMedium.isActiveGDrive = false;
    } else {
      this.fileUploadMedium.isActiveGDrive = true;
      this.fileUploadMedium.isActiveDropbox = false;
    }
    sessionStorage.removeItem('accesstoken');
    sessionStorage.removeItem('path');
    let applicantDetails: {};
    let applicantCookie: any;
    let emailAddress: string;
    this.dataShareService.sharedSignUpForm.subscribe(formData => {
      if (this.router.url.indexOf('apply') === -1) {
        this.SignUpForm = formData;
        this.dataShareService.sharedEmailAddress.subscribe(email => {
          applicantCookie = decodeURIComponent(this.cookieService.get("applicantDetails"));
          if (applicantCookie && !email) {
            applicantCookie = JSON.parse(applicantCookie);
            emailAddress = applicantCookie.email;
          } else
            emailAddress = email;
          applicantDetails = {
            'email': emailAddress,
            'sector': this.SignUpForm.value.expertise,
            'firstName': this.SignUpForm.value.firstname,
            'katkanafirstname': this.SignUpForm.value.katkanafirstname,
            'lastName': this.SignUpForm.value.lastname,
            'katkanalastname': this.SignUpForm.value.katkanalastname,
            'officeSpecialism': this.SignUpForm.value.officeForSpecialism,
            'marketingConsents': this.SignUpForm.value.privacyconsent1,
            'dialingCode': this.SignUpForm.value.dialingCode ? this.SignUpForm.value.dialingCode: '',
            'mobileNumber': this.SignUpForm.value.mobileNumber ? this.SignUpForm.value.mobileNumber: ''
          };
          this.cookieService.set('applicantDetails', JSON.stringify(applicantDetails), 7, '/');
        });

      } else {
        this.applyForm = formData;
        applicantDetails = {
          'email': this.applyForm.value.emailAddress,
          'firstName': this.applyForm.value.firstname,
          'katkanafirstname': this.applyForm.value.katkanafirstname,
          'lastName': this.applyForm.value.lastname,
          'katkanalastname': this.applyForm.value.katkanalastname,
          'marketingConsents': this.applyForm.value.privacyconsent1
        };
        this.cookieService.set('jobApplicantDetails', JSON.stringify(applicantDetails), 7, '/');
      }
    });
    this.route.queryParams.subscribe(param => {
      let redirectUri = '';
      let endpoint = '';
      if (type === 'dropbox') {
        redirectUri = window.location.origin + '/DropBox';
        endpoint = apiEndPoints.connectdropbox;
      } else {
        redirectUri = window.location.origin + '/GDrive';
        endpoint = apiEndPoints.connectGdrive;
      }

      let url = '';
      if (this.router.url.indexOf('?') > -1) {
        let urlList = this.router.url.split('?');
        url = urlList[0];
        if (urlList[1] && (urlList[1].indexOf('jobSource') > -1 || urlList[1].indexOf('jobsource') > -1) || urlList[1].indexOf('source') > -1) {
          this.extraParam = '&location=' + this.locationStr + '&locationId=' + this.locationId + '&locationSet=' + this.locationSet + '&utm_campaign=' + this.utm_campaign;
        }
        this.extraParam = '&' + urlList[1] + this.extraParam;
      } else
        url = this.router.url;
      let json = new Getcv(this.extraParam,
        redirectUri, url, '', '', '', null, null, null);
      this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, endpoint, json).subscribe(next => {
        window.location.href = next.data;
      });
    });

  }


  private getFileLink(medium: string, path: string, fileId: string, accessToken: string) {
    let json = new Getcv('', '', '', '', '', path, accessToken, medium, fileId);
    this._requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getFileLink, json).subscribe(resp => {
      if (resp.status == config.SUCCESS) {
        this.cvResponse = resp.data;
        if (medium === 'gdrive') {
          this.fileUploadMedium.isActiveGDrive = true;
          this.fileUploadMedium.isActiveDropbox = false;
        } else {
          this.fileUploadMedium.isActiveDropbox = true;
          this.fileUploadMedium.isActiveGDrive = false;
        }
        this.dropBoxData = true;
        this.localDrive = false;
        this.parent['controls'].cvData.patchValue(this._dataTransform(this.cvResponse, this.cvResponse.medium));
        this.fileObject = undefined;
      }
    })

  }

  clearCv() {
    this.parent['controls'].cvData.patchValue(null);
    this.fileObject = undefined;
    this.fileUploadMedium.isActiveLocalDrive = false;
    this.fileUploadMedium.isActiveGDrive = false;
    this.fileUploadMedium.isActiveDropbox = false;
    this.dropBoxData = false;
    this.cvResponse = undefined;
    this.localDrive = false;
  }

}
