<div class="form-group" [formGroup]="parent" *ngIf = "confirmationText && parent">

  <label *ngIf="consentBox1.length>0 && domainName==='CHN'" class="checkbox-container" for = "Policy">
    <input name="Policy" formControlName="privacyconsent1" type="checkbox" value="" (change) = "onCheckboxChange($event, 'parent')">
    <ng-container *ngFor="let item of consentBox1; let i = index;">
      <span *ngIf="i < 4">{{item.consentText1}} <a *ngIf="item.link.indexOf('email-confirmation')>-1" id="gtm_privacy_policy_consentText2"
                                      [href]="item.link">{{item.consentText2}}</a><a *ngIf="item.link.indexOf('email-confirmation')==-1" [href]="item.link" target="_blank">{{item.consentText2}}</a>
      </span>
      <ul class="consent-box-list" [ngClass]="i===4 ?  'mt-3':''" *ngIf="i > 3 && i < 7">
        <label>
          <input type="checkbox" formControlName = "privacyconsent{{item.checkboxIndex}}" id = "{{item.checkboxIndex}}" (change) = "onCheckboxChange($event)"> {{item.consentText1}}
        </label>
      </ul>
      <span *ngIf="i > 6" class="d-block mt-3" [ngClass]="[]">
        {{item.consentText1}}
      </span>
    </ng-container>
  </label>
  <label *ngIf="consentBox1.length>0 && domainName==='TH'" class="checkbox-container">
    <!-- <input name="Policy" formControlName="privacyconsent1" type="checkbox" value="" (change) = 'consentBoxChangeTH()'> -->
    <ng-container *ngFor="let item of consentBox1; let i = index;">
      <span *ngIf="i < 4 ">{{item.consentText1}}
        <a *ngIf="item.link.indexOf('email-confirmation')>-1" id="gtm_privacy_policy_consentText2"
            [href]="item.link">{{item.consentText2}}</a>
        <a *ngIf="item.link.indexOf('email-confirmation')==-1" [href]="item.link"
            target="_blank">{{item.consentText2}}</a>
      </span>
    </ng-container>

    <ng-container *ngFor="let item of consentBox1; let i = index;">
      <div *ngIf="i === 4 ">{{item.consentText1}}
        <a *ngIf="item.link.indexOf('email-confirmation')>-1" id="gtm_privacy_policy_consentText2"
            [href]="item.link">{{item.consentText2}}</a>
        <a *ngIf="item.link.indexOf('email-confirmation')==-1" [href]="item.link"
            target="_blank">{{item.consentText2}}</a>
        </div>
    </ng-container>

    <ng-container *ngFor="let item of consentBox1; let i = index;">
      <ul class="consent-box-list" [ngClass]="i==5 ?  'mt-3':''" *ngIf="i > 4 && i < 8">
        <label class="consent-checkbox consent-box-list-item">
          <input type = 'checkbox' formControlName = "privacyconsent{{item.consentCheckBoxIndex}}" (change) = 'consentBoxChangeTH()'>
            {{item.consentText1}}
          <a *ngIf="item.link" [href]="item.link" target="_blank">{{item.consentText2}}</a>
        </label>
      </ul>
      <span *ngIf="i > 7" class="d-block mt-3" [ngClass]="[]">
          {{item.consentText1}}
        </span>
    </ng-container>
  </label>
  <label *ngIf="consentBox1.length>0 && domainName!=='CHN' && domainName!=='TH'" class="checkbox-container">
    <input name="Policy" formControlName="privacyconsent1" [(ngModel)]="isChecked" type="checkbox" value="">
    <ng-container *ngFor="let item of consentBox1; let i = index;">
      <span >{{item.consentText1}} <a *ngIf="item.link.indexOf('email-confirmation')>-1" id="gtm_privacy_policy_consentText2"
                                      [href]="item.link"> {{item.consentText2}} </a><a *ngIf="item.link.indexOf('email-confirmation')==-1" [href]="item.link" target="_blank"> {{item.consentText2}} </a>
      </span>
    </ng-container>
  </label>
  <span class="error-msg" *ngIf="privacyPolicyErrorMsg" [translate]="'policy_validation_error1'"></span>
  <label *ngIf="consentBox2.length>0" class="checkbox-container">
    <input name="Policy" formControlName="privacyconsent2" type="checkbox" value="">
    <ng-container *ngFor="let item of consentBox2">
      <span> {{item.consentText1}} <a *ngIf="item.link.indexOf('email-confirmation')>-1" id="'gtm_privacy_policy_consentText2"
                                      [href]="item.link">{{item.consentText2}}</a><a *ngIf="item.link.indexOf('email-confirmation')==-1" [href]="item.link" target="_blank">{{item.consentText2}}</a></span>
    </ng-container>
  </label>
  <label *ngIf="consentBox3.length>0" class="checkbox-container">
    <input name="Policy" formControlName="privacyconsent3" type="checkbox" value="">
    <ng-container *ngFor="let item of consentBox3">
      &nbsp;<span [textContent]="item.consentText1"></span>&nbsp;<a id="gtm_privacy_policy_consentText_2" *ngIf="item.link.indexOf('email-confirmation')>-1"
      [routerLink]="item.link" [textContent]="item.consentText2"></a>
      <a id="gtm_privacy_policy_consentText" *ngIf="item.link.indexOf('email-confirmation')==-1" [href]="item.link" target="_blank"
         [textContent]="item.consentText2"></a>
    </ng-container>
  </label>
</div>

<div *ngIf = "confirmationText && !parent">
  <label *ngIf="consentBox1.length>0" class="checkbox-container">
    <input name="Policy" [(ngModel)]="isChecked" type="checkbox" value="">
    <ng-container *ngFor="let item of consentBox1">
      <span> {{item.consentText1}} <a *ngIf="item.link.indexOf('email-confirmation')>-1" id="gtm_privacy_policy_consentText2"
                                      [href]="item.link">{{item.consentText2}}</a><a *ngIf="item.link.indexOf('email-confirmation')==-1" [href]="item.link" target="_blank">{{item.consentText2}}</a></span>
    </ng-container>
  </label>
</div>
