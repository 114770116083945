export class JobAlertRequest {
  constructor(
    public compensationMax:string,
    public keywords:string,
    public jobSource:string,
    public flexibleWorking:string,
    public whatsappnum:string,
    public whatsappcountrycode:string,
    public email:string,
    public specialism:string,
    public subspecialism:string,
    public fullTime:string,
    public partTime:string,
    public saveurl:string,
    public compensationMin:string,
    public jobtypes:string,
    public locations:string,
    public location:string,
    public industry:string,
    public typeOnlyFilter:string,
    public profileid:string,
    public compensationType:string,
  ){}
}

export class JobVerifyAlertRequest {
  constructor(
    public email: string,
    public workflowinstanceid: string,
    public unsubscribe: string,
  ) {
  }
}

  export class ValidateAlertRequest {
  constructor(
    public identifier: string,
    public requestType: string,
  ) {}

}
