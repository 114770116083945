import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {RequestService} from '../../../../global/services/request/request.service';
import {apiEndPoints, config} from '../../../../../environments/root/environment';
import {JobId} from '../../../model/request/jobid';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-qualifying-questions',
  templateUrl: './qualifying-questions.component.html',
  styleUrls: ['./qualifying-questions.component.scss']
})
export class QualifyingQuestionsComponent implements OnInit {

  private jobId='';
  public questionArray = [];
  public questionJson = [];
  @Output() questionData=new EventEmitter<any>();

  constructor(private req: RequestService,private activatedRoute:ActivatedRoute) {
    this.activatedRoute.queryParamMap.subscribe(param => {
      if(param.get('applyId'))
        this.jobId=param.get('applyId');
    });
  }

  ngOnInit() {
    let json = new JobId(this.jobId);
    this.req.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getKillerQuestions, json).subscribe((res) => {
      let arrayResponse = [];
      if(res.data && res.data['results'].length>0) {
        arrayResponse = res.data['results'][0]['xQuestions'].split(';');
        arrayResponse.forEach((item) => {
          if (item.split(',')[0]) {
            this.questionArray.push({
              'id': item.split(',')[0],
              'question': item.split(',')[1],
              'flag': ''
            });
            this.questionJson.push({
              'quesid': item.split(',')[0],
              'quesdescription': item.split(',')[1],
              'autorejectedques': item.split(',')[2],
              'answer': ''
            });
          }
        });
        this.questionData.emit(this.questionJson);
      }
      else
        this.questionData.emit(this.questionJson);
    });
  }

  setQuestionValue(question, value) {
    this.questionJson.forEach((item)=>{
      if(item.quesid==question.id)
        item.answer=value
    });
    this.questionArray.forEach((item)=>{
      if(item.id==question.id)
        item.flag=value
    });
    this.questionData.emit(this.questionJson);
  }

  clearData(){
    this.questionArray.forEach((item)=>{
        item.flag='';
    });
    this.questionJson.forEach((item)=>{
        item.answer='';
    });
    this.questionData.emit(this.questionJson);
  }

}
