<div class="job-box social-share bg-white" *ngIf="config">
    <h3 [translate]="'share_job_text'"></h3>
  <div class="social-share-icon">
    <a *ngIf="config.shareOptions.isLinkedin" [socialShareType]="'linkedin'"  [shareUrl]="addThisUrl" [title]="jobToShare[0].JobTitle"  class="share-mail">
      <span class="linkedIn"><img src="https://www9.hays.com/UI/storybook/assets/img/svg/socials/linkedin.svg" alt="" class="w-24px"></span>
    </a>
    <a *ngIf="config.shareOptions.isFacebook" [socialShareType]="'facebook'"  [shareUrl]="addThisUrl" [title]="jobToShare[0].JobTitle"  class="share-mail ml-3">
        <span class="facebook">
        <svg viewBox="0 0 24 24" class="feather-icon text-white faceBook">
          <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
        </svg>
        </span>
    </a>
    <a *ngIf="config.shareOptions.isTwitter"  [socialShareType]="'twitter'" [shareUrl]="addThisUrl" [title]="jobToShare[0].JobTitle" class="share-mail ml-3">
      <span class="twitter"><img src="https://www9.hays.com/UI/storybook/assets/img/svg/socials/twitter_x.svg" alt="" class="w-100"></span>
    </a>
    <a  *ngIf="config.shareOptions.isEmail" [href]="'mailto:?subject='+jobToShare[0].JobTitle+'- Hays Working for your tomorrow&body='+emailBodyLink" class="share-mail ml-3">
        <span>
          <svg>
            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
            <polyline points="22,6 12,13 2,6"></polyline>
          </svg>
        </span>
    </a>
  </div>
</div>
