<div class="col border">
  <form id="gtm_go1_login" [formGroup]="loginFormGroup" (ngSubmit)="submitSignIn()">
    <h3 class="existing-user">
      <span [translate]="'existing_user_label'"></span>
      <p class="help-text" [translate]="'existing_user_description'"></p>
    </h3>
    <div class="form-group" *ngIf="isAuthFail">
      <span class="error-msg" [translate]="'user_pass_incorrect_text'"></span>
    </div>
    <div class="form-group">
      <label [translate]="'login_username_label'"></label>
      <input type="text" class="form-control" (autocomplete)="false" formControlName="emailAddress" placeholder="{{'login_usernameLabel'|translate}}" required>

    </div>
    <div class="form-group">
      <label [translate]="'login_passLabel'"></label>
      <input type="password" (autocomplete)="false" formControlName="password" class="form-control" placeholder="{{'enter_password_placeholder'|translate}}">
    </div>
    <div *ngIf="loginCounterResponse && loginCounterResponse.Counter==3">
      <app-jp-captcha [parent]="loginFormGroup"></app-jp-captcha>
      <div
        *ngIf="!loginFormGroup['controls'].reCaptchaReactive?.value && formNotValid">
        <span class="error-msg"
              [translate]="'captcha_validation_text'"></span>
      </div>
    </div>

    <div class="action-bar text-center">
      <button id="gtm_signin_btn" [ngClass]="disableBtn?'btn btn-secondary btn-block btn-loading':'btn btn-secondary btn-block'"
              [disabled]="disableBtn" type="n" [translate]="'signIn'"></button>
      <p class="text-right f_password"><a id="gtm_forgot_pwd_redirect" (click)="routingUrl()" [translate]="'forgot_password_label'"></a></p>
    </div>

  </form>
  <div class="benefits">
    <h5 [translate]="'why_create_an_account_text'"></h5>
    <ul>
      <li [translate]="'benefits_text1'"></li>
      <li [translate]="'benefits_text2'"></li>
      <li [translate]="'benefits_text3'"></li>
      <li [translate]="'benefits_text4'"></li>
      <li [translate]="'benefits_text5'"></li>
      <li [translate]="'benefits_text6'"></li>
      <li [translate]="'benefits_text7'"></li>
    </ul>
  </div>
</div>
