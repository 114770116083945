import {Component, Input, OnInit} from '@angular/core';
import {ExtraFieldsConfig, JobSearchFilter} from '../../../../../model/response/google-job-response';
import {JobSearchService} from '../../../../../service/job-search.service';
import {RequestService} from '../../../../../../global/services/request/request.service';
import {apiEndPoints, config} from '../../../../../../../environments/root/environment';
import {JobAlertRequest} from '../../../../../model/request/job-alert-request';
import {ActivatedRoute} from '@angular/router';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {TranslateService} from '@ngx-translate/core';
import {PersonalDetails} from '../../../../../model/response/my-profile';
import {TokenStorage} from '../../../../../../global/services/tokenStorage/token-storage';
import {SaveWhatsappRequest} from '../../../../../model/request/save-whatsapp-request';
import {DataSharingService} from '../../../../../service/data-sharing.service';

@Component({
  selector: 'app-jp-alert',
  templateUrl: './jp-alert.component.html',
  styleUrls: ['./jp-alert.component.scss']
})
export class JpAlertComponent implements OnInit {
  public extraConfig: ExtraFieldsConfig;
  @Input() keyWord: string;
  private jobSearchFilter: JobSearchFilter;
  public email: string;
  public whatsappCountryCode: string;
  public whatsappNumber: string;
  private searchedLocation: string;
  private locationFilter: string;
  private industryFilter: string;
  private specialismId: string;
  private subSpecialismId: string;
  private jobSource: string;
  private saveURL: string;
  public disableEmailButton: boolean;
  public saved: boolean;
  public disableWhatsapp: boolean;
  public waCodeError: boolean;
  public whatsappNumberErrorFlag: boolean;
  public emailErrorFlag: boolean;
  public personalDetails: PersonalDetails;
  public showSingleAlert: boolean;
  public hideWhatsapp: boolean;
  public saveWhatsapp: boolean;
  public emailWhatsappAlertSaved: boolean;
  private alertId: string;
  public isEmailAlertSaved: boolean;
  public isLoggedin: boolean;
  public maximumAlertExceeds: boolean;
  public isEmailAlert:boolean;

  constructor(private jobSearchService: JobSearchService, private requestService: RequestService, private activatedRoute: ActivatedRoute, private siteService: SiteService,
              private translate: TranslateService, private tokenStorage: TokenStorage, private dataSharingService: DataSharingService) {
    this.translate.setDefaultLang(this.siteService.getSiteLocale());
    if (this.tokenStorage.getLoggedInToken()) {
      this.isLoggedin = true;
      this.dataSharingService.sharedPersonalDetails.subscribe(personalDetails => {
        this.personalDetails = personalDetails;
        if(this.personalDetails && this.personalDetails.whatsappNumber) {
            this.showSingleAlert = true;
            this.hideWhatsapp = true;
        }
      });
    }
    this.whatsappNumber = '';
    this.searchedLocation = '';
    this.locationFilter = '';
    this.industryFilter = '';
    this.specialismId = '';
    this.subSpecialismId = '';
    this.email = '';
    this.whatsappCountryCode = '';
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(param => {
      if (param.get('location')) {
        this.searchedLocation = param.get('location');
      }
      if (param.get('locationf')) {
        this.locationFilter = param.get('locationf');
      }
      if (param.get('industryf')) {
        this.industryFilter = param.get('industryf');
      }
      if (param.get('specialismId')) {
        this.specialismId = param.get('specialismId');
      }
      if (param.get('subSpecialismId')) {
        this.subSpecialismId = param.get('subSpecialismId');
      }
      if (param.get('jobSource')) {
        this.jobSource = param.get('jobSource');
      }
      if (param.get('jobsource')) {
        this.jobSource = param.get('jobsource');
      }
      if (param.get('source')) {
        this.jobSource = param.get('source');
      }
      if (param.get('alertid')) {
        this.alertId = param.get('alertid');
      }
      if (param.get('q')) {
        let defaultJobSource='';
        this.dataSharingService.sharedClientConfig.subscribe(res => defaultJobSource = res.jobSource );
        this.jobSource = param.get('jobSource')?param.get('jobSource'):param.get('jobsource')?param.get('jobsource'):param.get('source')?param.get('source'):defaultJobSource;
        this.saved = false;
        this.saveWhatsapp = false;
        this.disableEmailButton = false;
        this.disableWhatsapp = false;
      }
      this.saveURL = 'specialismId=' + this.specialismId + '&' + 'subSpecialismId=' + this.subSpecialismId;
    });

    this.jobSearchService.sharedJobSearchFilterData.subscribe(searchFilter => {
      this.jobSearchFilter = searchFilter;
    });
    this.jobSearchService.sharedConfigList.subscribe(searchFilter => {
      this.extraConfig = searchFilter;
      if (this.extraConfig) {
        this.whatsappCountryCode = this.extraConfig.wspCountryCode;
        this.isEmailAlert=this.extraConfig.isEmailAlert;
      }
    });

  }

  saveJobAlert(type: string) {
    this.whatsappNumberErrorFlag = false;
    if (this.personalDetails) {
      if (type === 'email') {
        this.email = this.personalDetails.email;
        this.whatsappCountryCode = '';
      }
    }

    if (type === 'whatsapp') {
      if (!this.whatsappCountryCode || !this.whatsappNumber || this.waCodeError || this.whatsappNumberErrorFlag) {
        this.whatsappNumberErrorFlag = true;
        return false;
      } else {
        this.disableWhatsapp = true;
      }
    } else {
      if (!this.email.trim() || this.emailErrorFlag) {
        this.emailErrorFlag = true;
        return false;
      } else {
        this.disableEmailButton = true;
      }
    }
    if (this.tokenStorage.getLoggedInToken() && type === 'whatsapp') {
      let saveWhatsappNumber = new SaveWhatsappRequest(this.whatsappNumber, this.whatsappCountryCode, '');
      this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.saveWhatsapp, saveWhatsappNumber).subscribe(res => {
        // console.log("res save WhatsappNumber ..", res);
        if (res.status === config.SUCCESS) {
          this.email = '';
          //this.saved = true;
            this.saveWhatsapp = true;
            this.whatsappNumber = '';
            this.whatsappCountryCode = '';
          if (this.saved && this.saveWhatsapp) {
            this.emailWhatsappAlertSaved = true;
          }else{
            this.hideWhatsapp = true;
            this.showSingleAlert=true;
          }
        }

      }, error => {

      });

    } else {
      let saveAlertJson = new JobAlertRequest(this.jobSearchFilter ? this.jobSearchFilter.maxSalValue : '', this.keyWord, this.jobSource,
        this.jobSearchFilter ? this.jobSearchFilter.flexibleworking : '', this.whatsappNumber,
        (type === 'whatsapp' && this.extraConfig.wspCountryCode && !this.whatsappCountryCode) ? this.extraConfig.wspCountryCode : this.whatsappCountryCode, this.email,
        this.specialismId, this.subSpecialismId, this.jobSearchFilter ? this.jobSearchFilter.fullTime : '', this.jobSearchFilter ? this.jobSearchFilter.partTime : '',
        encodeURIComponent(this.saveURL), this.jobSearchFilter ? this.jobSearchFilter.minSalValue : '', this.jobSearchFilter ? this.jobSearchFilter.jobType : '',
        this.searchedLocation, this.locationFilter, this.industryFilter,
        (this.jobSearchFilter && this.jobSearchFilter.maxSalValue) ? 'N' : '', this.alertId ? this.alertId : '',this.jobSearchFilter.payTypeValue);
      let endPoint = '';
      if (this.alertId) {
        endPoint = apiEndPoints.editAlert;
      } else {
        endPoint = apiEndPoints.saveAlert;
      }

      this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, endPoint, saveAlertJson).subscribe(res => {
        if (res.status === config.SUCCESS) {
          if (type === 'email') {
            this.email = '';
            this.saved = true;
            if (this.tokenStorage.getLoggedInToken() && this.showSingleAlert ) {
              if (res.data.statuscode === 'WC023'){
                this.maximumAlertExceeds = true;
                this.emailWhatsappAlertSaved = true;
              }else
                this.emailWhatsappAlertSaved = true;
            }else if(this.tokenStorage.getLoggedInToken() && res.data.statuscode === 'WC023'){
                this.maximumAlertExceeds = true;
            }
          } else {
            this.saveWhatsapp = true;
            this.whatsappNumber = '';
            this.whatsappCountryCode = '';
          }
          if (this.saved && this.saveWhatsapp) {
             this.emailWhatsappAlertSaved = true;
          }

        }

      }, error => {
        this.disableEmailButton = false;
        this.disableWhatsapp = true;
      });
    }
  }

  validateInput(email: string, whatsappCountryCode: string, whatsappNumber: string, event) {
    let regexWACode = /^([+]{1})[0-9][0-9]*$/;
    if (event) {
      if ((event.which != 8 && event.which != 0 && event.which < 48) || (event.which > 57 && event.which < 96) || event.which > 105) {
        event.preventDefault();
      }
    }
    if (whatsappCountryCode && whatsappCountryCode.trim()) {
      if (!regexWACode.test(whatsappCountryCode)) {
        this.waCodeError = true;
        return false;
      } else {
        this.waCodeError = false;
      }

    }
    if (email && email.trim()) {
      let emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
      if (!emailRegex.test(email)) {
        this.emailErrorFlag = true;
        return false;
      } else {
        this.emailErrorFlag = false;
      }
    }
  }


  getSavedEmailAlertValue($event: boolean) {
    this.isEmailAlertSaved = $event;
  }

  isAlertClosed($event: boolean) {
    if($event)
      this.keyWord='';
  }

  isMaxAlert($event: boolean) {
    this.maximumAlertExceeds=$event;
  }
}
