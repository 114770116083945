<section *ngIf="!verifyAlert" class="thank-you-msg">

  <div *ngIf="unsubscribe==='Y' && !isErpVerifyAlert" class="success">
  <div class="success-msg-cont" *ngIf="showGDPRText">
    <i></i>
    <div [translate]="'gdpr_unsubscribe_Sucess_text1'"></div><span *ngIf="isgdprCountry" [translate]="'gdpr_unsubscribe_Sucess_text2'"></span>
    <a *ngIf="isgdprCountry" id="gtm_unsubscribealert_marketingpref_redirect" [href]="marketingPrefUrl" [translate]="'marketing_preference_heading_text'"></a>
    <span [translate]="'gdpr_unsubscribe_Sucess_text3'"></span>
    <a id="gtm_unsubscribealert_login_redirect"  [href]="loginUrl" [translate]="'success_msg_on_err_page1'"></a>
    <span [translate]="'gdpr_unsubscribe_Sucess_text4'"></span>
  </div>
    <div class="success-msg-cont" *ngIf="!showGDPRText">
    <i></i>
    <div><span>{{'standardmsg_thankyou'|translate}} {{'alert_unsubscribe_text'|translate}}</span></div>
    <span>{{'success_msg_on_err_page'|translate}} {{'error_page_search_jobs'|translate}} {{'login_form_register_text2'|translate}}
      <a id="gtm_unsubscribealert_login_redirect1"  [href]="loginUrl">{{'success_msg_on_err_page1'|translate}}</a> {{'success_msg_on_err_page2'|translate}}</span>

  </div>
  </div>

  <!-- ANZ - unsubscribe-->
  <div *ngIf="unsubscribe==='Y' && isErpVerifyAlert" class="success">
    <div class="success-msg-cont">
      <i></i>
      <h4 [translate]="'erp_alert_unsubscribe_1'"></h4>
      <h4>{{'erp_alert_unsubscribe_2'|translate}}<a  [href]="manageJobAlertUrl">{{'erp_alert_unsubscribe_3_url'|translate}}</a></h4>
      <h4>{{'erp_alert_unsubscribe_4'|translate}}<a  [href]="erpMailConfirmUrl">{{'erp_alert_unsubscribe_5_url'|translate}}</a>  {{'erp_alert_unsubscribe_6'|translate}}</h4>
      <h4>{{'erp_alert_unsubscribe_7'|translate}}<a  [href]="loginUrl">{{'erp_alert_unsubscribe_8_url'|translate}}</a>  {{'erp_alert_unsubscribe_9'|translate}}</h4><br><br><br><br>
    </div>
  </div>

  <div *ngIf="unsubscribe==='N' ">
    <div class="success">
      <div class="success-msg-cont">
        <i></i>
    <div><span>{{'standardmsg_thankyou'|translate}} {{'alert_msg_unsubscribe'|translate}} {{'success_msg_on_err_page'|translate}} {{'error_page_search_jobs'|translate}} {{'login_form_register_text2'|translate}}
        <a id="gtm_unsubscribealert_login_redirect"  [href]="loginUrl" [translate]="'success_msg_on_err_page1'"></a> {{'success_msg_on_err_page2' | translate}}</span>
    </div>
    </div>
    </div>
  </div>
  <div *ngIf="unsubscribe!='Y' && unsubscribe!='N'" class="success">
  <div class="success-msg-cont" *ngIf="showGDPRText">
    <i></i>
    <div [translate]="'gdpr_unsubscribe_Sucess_text1'"></div><span *ngIf="isgdprCountry" [translate]="'gdpr_unsubscribe_Sucess_text2'"></span>
    <a *ngIf="isgdprCountry" id="gtm_unsubscribealert_marketingpref_redirect" [href]="marketingPrefUrl" [translate]="'marketing_preference_heading_text'"></a>
    <span [translate]="'gdpr_unsubscribe_Sucess_text3'"></span>
    <a id="gtm_unsubscribealert_login_redirect"  [href]="loginUrl" [translate]="'success_msg_on_err_page1'"></a>
    <span [translate]="'gdpr_unsubscribe_Sucess_text4'"></span>
  </div>
    <div class="success-msg-cont" *ngIf="!showGDPRText">
    <i></i>
    <div><span>{{'standardmsg_thankyou'|translate}} {{'alert_unsubscribe_text'|translate}}</span></div>
    <span>{{'success_msg_on_err_page'|translate}} {{'error_page_search_jobs'|translate}} {{'login_form_register_text2'|translate}}
      <a id="gtm_unsubscribealert_login_redirect1"  [href]="loginUrl">{{'success_msg_on_err_page1'|translate}}</a> {{'success_msg_on_err_page2'|translate}}</span>

  </div>
  </div>
</section>
<section *ngIf="verifyAlert" class="thank-you-msg">
  <div class="success">
    <div class="success-msg-cont">
      <div *ngIf="activated==='E'">
        <i></i>
        <div><span>{{'alert_previously_active_text'|translate}}</span>
        <span [translate]="'alert_already_active_sub_text'"></span>
          <a id="gtm_verifyalert_errorpage_login_redirect" [href]="loginUrl" >{{'success_msg_on_err_page1'|translate}}</a>
          <span [translate]="'alert_previously_active_general'"></span></div>
      </div>
      <div *ngIf="activated==='Y' && !isErpVerifyAlert">
        <i></i>
        <div><span [translate]="'standardmsg_thankyou'"></span>
        <span  [translate]="'alert_activation_text'"></span><span [translate]="'alert_msg_string_new1'"></span>
        <span [translate]="'alert_already_active_sub_text'"></span>
        <a id="gtm_verifyalert_successpage_login_redirect" [href]="loginUrl">{{'success_msg_on_err_page1'|translate}}</a> <span [translate]="'alert_msg_string_new3'"></span></div>
      </div>
      <div *ngIf="activated==='Y' && isErpVerifyAlert">
        <i></i>
        <div><span>{{'erp_alert_activated_text_1'|translate}}</span></div>
        <div><span  [translate]="'erp_alert_activated_text_2'"></span></div>
        <span><a  [href]="manageJobAlertUrl">{{'erp_alert_activated_text_3'|translate}}</a>  {{'erp_alert_activated_text_4'|translate}}
        <a  [href]="loginUrl">{{'erp_alert_activated_text_5'|translate}}</a>  {{'erp_alert_activated_text_6'|translate}}</span><br><br><br><br>
      </div>
    </div>
  </div>
</section>


