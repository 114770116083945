import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {JobportalRoutingModule} from './jobportal-routing.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {environment} from '../../environments/root/environment';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    JobportalRoutingModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ]
})
export class JobportalModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    environment.translations,
    '.json'
  );
}
