import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AppRouter} from './app-route';
import {SiteLocaleComponent} from './global/components/shared/site-locale/site-locale.component';
import {AuthGuard} from './global/guards/authGuard';
import {JpDetailComponent} from './jobportal/shared/job/jobdetail/components/jp-detail/jp-detail.component';
import {JpSearchComponent} from './jobportal/shared/job/search/components/jp-search/jp-search.component';
import {JpApplyComponent} from './jobportal/shared/job/apply/components/jp-apply/jp-apply.component';
import {JpThankYouComponent} from './jobportal/shared/job/apply/components/jp-thank-you/jp-thank-you.component';
import {JpVerifyAlertComponent} from './jobportal/shared/components/jp-verify-alert/jp-verify-alert.component';
import {JpAlertThankyouComponent} from './jobportal/shared/components/jp-alert-thankyou/jp-alert-thankyou.component';
import {JpUpdateConsentComponent} from './global/anonymous/jp-update-consent/jp-update-consent.component';
import {JpPartnerUploadCvComponent} from './global/anonymous/jp-partner-upload-cv/jp-partner-upload-cv.component';
import {SeekApplyComponent} from './global/anonymous/seek-apply/seek-apply.component';
import {GlobalConsentComponent} from './global/anonymous/global-consent/global-consent.component';
import {JpViewEmailLinkAlertComponent} from './global/anonymous/jp-view-email-link-alert/jp-view-email-link-alert.component';
import {DMSThankYouPageComponent} from './global/anonymous/dms-thank-you-page/dms-thank-you-page.component';
import {JpDkMarketingPrefComponent} from './jobportal/shared/components/jp-dk-marketing-pref/jp-dk-marketing-pref.component';
import {JpMarketingPrefThankyouComponent} from './jobportal/shared/gdpr/components/jp-marketing-pref-thankyou/jp-marketing-pref-thankyou.component';
import {DmsVerifyAlertComponent} from './global/anonymous/dms-verify-alert/dms-verify-alert.component';
import {DmsSubmitAlertComponent} from './global/anonymous/dms-submit-alert/dms-submit-alert.component';
import {JobPortalRouter} from './jobportal/jobportal-route';
import {ErpIntegrationRouter} from './erp-integration/erp-integration-route';
import {ErrorPagesComponent} from './global/components/shared/error-pages/error-pages.component';
import {JpOfficeLocatorComponent} from './jobportal/office/office-locator/components/jp-office-locator/jp-office-locator.component';
import {JpOfficeLocatorResultComponent} from './jobportal/office/office-locator/components/jp-office-locator-result/jp-office-locator-result.component';
import {JpMySavedJobComponent} from './jobportal/shared/savedjob/components/jp-my-saved-job/jp-my-saved-job.component';
import {JpRegisterNewComponent} from './jobportal/shared/components/jp-register-new/jp-register-new.component';
import {JpResetPasswordComponent} from './jobportal/shared/components/jp-reset-password/jp-reset-password.component';
import {SuccessPagesComponent} from './global/components/shared/success-pages/success-pages.component';
import {JpChangeEmailComponent} from './jobportal/secure/candidate/components/jp-change-email/jp-change-email.component';
import {JpChangePasswordComponent} from './jobportal/secure/candidate/components/jp-change-password/jp-change-password.component';
import {JpManageCvComponent} from './jobportal/secure/candidate/components/jp-manage-cv/jp-manage-cv.component';
import {JpMyProfileComponent} from './jobportal/secure/candidate/components/jp-my-profile/jp-my-profile.component';
import {JpMySearchesComponent} from './jobportal/secure/candidate/components/jp-my-searches/jp-my-searches.component';
import {JpMarketingPrefComponent} from './jobportal/shared/gdpr/components/jp-marketing-pref/jp-marketing-pref.component';
import {JpSaveWhatsappComponent} from './jobportal/shared/whatsapp/component/jp-save-whatsapp/jp-save-whatsapp.component';
import {JpUpdateWhatsappComponent} from './jobportal/shared/whatsapp/component/jp-update-whatsapp/jp-update-whatsapp.component';
import {JpForgotPasswordComponent} from './jobportal/notsecure/candidate/signin/components/jp-forgot-password/jp-forgot-password.component';
import {JpLoginComponent} from './jobportal/notsecure/candidate/signin/components/jp-login/jp-login.component';
import {Go1SigninRegisterComponent} from './partner/go1-app/go1-notsecure/components/go1-signin/go1-signin-register/go1-signin-register.component';
import {Go1SpecCvComponent} from './partner/go1-app/go1-notsecure/components/go1-signin/go1-spec-cv/go1-spec-cv.component';
import {Go1WidgetComponent} from './partner/go1-app/go1-notsecure/components/go1-widget/go1-widget.component';
import {JpAllOfficesComponent} from './jobportal/office/components/jp-all-offices/jp-all-offices.component';
import {JpRecruitingNowComponent} from './jobportal/notsecure/candidate/recruiting-now/components/jp-recruiting-now/jp-recruiting-now.component';
import {JpWhatsappComponent} from './jobportal/shared/whatsapp/component/jp-whatsapp/jp-whatsapp.component';
import {JpUnsubscribeComponent} from './jobportal/notsecure/candidate/alerts/jp-unsubscribe/jp-unsubscribe.component';
import {JpEmailConfirmationComponent} from './jobportal/notsecure/candidate/gdpr/component/jp-email-confirmation/jp-email-confirmation.component';
import {JpUnsubscribeEmailConfirmationComponent} from './jobportal/notsecure/candidate/components/jp-unsubscribe-email-confirmation/jp-unsubscribe-email-confirmation.component';
import {SpecPsCvComponent} from './partner/hays-career/people-soft/people-soft-notsecure/components/spec-ps-cv/spec-ps-cv.component';
import {ConfirmationMsgComponent} from './partner/hays-career/people-soft/people-soft-notsecure/components/confirmation-msg/confirmation-msg.component';
import { JpUpdatePrivacyAcceptanceComponent } from './jobportal/notsecure/candidate/privacy-acceptance/components/jp-update-privacy-acceptance/jp-update-privacy-acceptance.component';
import {ThPrivacyPolicyUpdateComponent } from './jobportal/notsecure/candidate/privacy-acceptance/components/jp-update-privacy-acceptance/th-privacy-policy-update/th-privacy-policy-update.component'
import { HcpatchaComponent } from './hcpatcha/hcpatcha.component';

const routes: Routes = [
  {
    path: AppRouter.update_consent_link,
    canActivate: [AuthGuard],
    component: JpUpdateConsentComponent
  },
  {
    path: AppRouter.dropbox_link,
    component: JpPartnerUploadCvComponent
  },
  {
    path: AppRouter.dropbox_link,
    component: JpPartnerUploadCvComponent
  },
  {
    path: AppRouter.gdrive_link,
    component: JpPartnerUploadCvComponent
  },
  {
    path: AppRouter.seek_apply_link,
    component: SeekApplyComponent
  },
  {
    path: AppRouter.global_consent_link,
    canActivate: [AuthGuard], component: GlobalConsentComponent
  },
  {
    path: AppRouter.view_email_link,
    canActivate: [AuthGuard],
    component: JpViewEmailLinkAlertComponent
  },
  {
    path: 'DMS',
    canActivate: [AuthGuard],
    component: DMSThankYouPageComponent
  },
  {
    path: AppRouter.dk_gdpr_marketing_pref_link,
    canActivate: [AuthGuard],
    component: JpDkMarketingPrefComponent
  },
  {
    path: AppRouter.dk_thnku_marketing_pref_link,
    component: JpMarketingPrefThankyouComponent
  },
  {
    path: 'DocExSignIn/beginSigningSession',
    canActivate: [AuthGuard],
    component: DmsVerifyAlertComponent
  },
  {
    path: 'DocExSignIn/ThankYou',
    canActivate: [AuthGuard],
    component: DmsSubmitAlertComponent
  },
  {
    path: AppRouter.job_detail_route_link,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: JpDetailComponent
      }
    ],
  },
  {
    path: AppRouter.job_detail_seek_route_link,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: JpDetailComponent
      }
    ],
  },
  {
    path: AppRouter.search_link,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: JpSearchComponent
      }
    ],
  },
  {
    path: AppRouter.job_route,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    children: [
      {path: ':recordId/apply', component: JpApplyComponent},
      {path: 'apply', component: JpApplyComponent},
      {path: 'totalJobs/:recordId', component: JpApplyComponent},
      {path: 'detail/:recordId', component: JpDetailComponent},
      {path: 'apply/details', component: JpThankYouComponent},
      {path: 'VerifyAlertV2', component: JpVerifyAlertComponent},
      {path: 'VerifyAlert', component: JpVerifyAlertComponent},
      {path: 'VerifyAlert/success', component: JpAlertThankyouComponent},
    ],
  },
  {
    path: AppRouter.job_apply_route,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    children: [
      {path: ':recordId/apply', component: JpApplyComponent},
      {path: 'apply', component: JpApplyComponent},
      {path: 'totalJobs/:recordId', component: JpApplyComponent},
      {path: 'detail/:recordId', component: JpDetailComponent},
      {path: 'apply/details', component: JpThankYouComponent},
      {path: 'VerifyAlertV2', component: JpVerifyAlertComponent},
      {path: 'VerifyAlert', component: JpVerifyAlertComponent},
      {path: 'VerifyAlert/success', component: JpAlertThankyouComponent},
    ],
  },
  {
    path: AppRouter.spec_cv_link,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    //loadChildren: () => import('./jobportal/notsecure/candidate/register/register.module').then(m => m.RegisterModule)
    children: [
      {path: '', component: JpRegisterNewComponent}
    ],
  },
  {
    path: AppRouter.register_link,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    //loadChildren: () => import('./jobportal/notsecure/candidate/register/register.module').then(m => m.RegisterModule)
    children: [
      {path: '', component: JpRegisterNewComponent},
      {path: 'test-captcha', component: HcpatchaComponent}
    ],
  },
  {
    path: AppRouter.forgot_password_link_1,
    redirectTo: AppRouter.forgot_password_redirect_link,
    pathMatch: 'full'
  },
  {
    path: AppRouter.forgot_password_link_2,
    redirectTo: AppRouter.forgot_password_redirect_link,
    pathMatch: 'full'
  },
  {
    path: AppRouter.reset_link,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    //loadChildren: () => import('./jobportal/notsecure/candidate/reset/reset.module').then(m => m.ResetModule)
    children: [
      {path: '', component: JpResetPasswordComponent}
    ]
  },
  {
    path: AppRouter.my_profile_link,
    redirectTo: AppRouter.my_profile_redirect_link,
    pathMatch: 'full'
  },

  {
    path: AppRouter.secure_link,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    //loadChildren: () => import('./jobportal/secure/secure-candidate/secure-candidate.module').then(m => m.SecureCandidateModule)
    children: [
      {path: 'my-profile', component: JpMyProfileComponent},
      {path: 'saved-searches', component: JpMySearchesComponent},
      {path: 'manage-cv', component: JpManageCvComponent},
      {path: 'change-email', component: JpChangeEmailComponent},
      {path: 'change-password', component: JpChangePasswordComponent},
      {path: 'marketing-preferences', component: JpMarketingPrefComponent},
      {path: 'success', component: SuccessPagesComponent},
      {path: 'error', component: ErrorPagesComponent},
      {path: 'alert', component: JpAlertThankyouComponent},
      {path: 'update-whatsapp', component: JpUpdateWhatsappComponent},
      {path: 'save-whatsapp', component: JpSaveWhatsappComponent},
      {path: 'dk-marketing-preferences', component: JpDkMarketingPrefComponent},
      {path: 'dk-marketing-preferences/thankyou', component: JpMarketingPrefThankyouComponent},
    ]
  },
  {
    path: AppRouter.login_link,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    //loadChildren: () => import('./jobportal/notsecure/candidate/signin/signin.module').then(m => m.SigninModule)
    children: [
      {path: '', component: JpLoginComponent},
      {path: 'azureLogout', component: JpLoginComponent},
      {path: 'forgot-password', component: JpForgotPasswordComponent},
    ]
  },
  {
    path: AppRouter.go1_link,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    //loadChildren: () => import('./partner/go1-app/go1-notsecure/components/go1-signin/go1-signin.module').then(m => m.Go1SigninModule)
    children: [
      {path: 'go1/training/login', component: Go1SigninRegisterComponent},
      {path: 'go1/training/register', component: Go1SpecCvComponent},
      {path: 'go1/training', component: Go1WidgetComponent}
    ]
  },
  {
    path: AppRouter.all_office_link,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    // loadChildren: () => import('./jobportal/office/office.module').then(m => m.OfficeModule)
    children: [
      {path: '', component: JpAllOfficesComponent},
    ]
  },
  {
    path: AppRouter.office_locator_link,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    //loadChildren: () => import('./jobportal/office/office-locator/office-locator.module').then(m => m.OfficeLocatorModule)
    children: [
      {path: '', component: JpOfficeLocatorComponent},
      {path: 'result', component: JpOfficeLocatorResultComponent},
    ],
  },
  {
    path: AppRouter.saved_jobs_link,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    //loadChildren: () => import('./jobportal/shared/savedjob/savedjob.module').then(m => m.SavedjobModule)
    children: [
      {path: '', component: JpMySavedJobComponent},
    ],
  },
  {
    path: AppRouter.recruiting_now_link,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    //loadChildren: () => import('./jobportal/notsecure/candidate/recruiting-now/recruiting-now.module').then(m => m.RecruitingNowModule)
    children: [
      {path: '', component: JpRecruitingNowComponent},
    ]
  },
  {
    path: AppRouter.gdpr_marketing_pref_link,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    // loadChildren: () => import('./jobportal/shared/gdpr/gdpr.module').then(m => m.GdprModule)
    children: [
      {path: '', component: JpMarketingPrefComponent},
      {path: 'thankyou', component: JpMarketingPrefThankyouComponent},
    ]
  },
  {
    path: AppRouter.whatsapp_alert_link,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    //loadChildren: () => import('./jobportal/shared/whatsapp/whatsapp.module').then(m => m.WhatsappModule)
    children: [
      {path: 'updateAlert', component: JpWhatsappComponent},
    ]
  },
  {
    path: AppRouter.unsubscribe_link,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    //loadChildren: () => import('./jobportal/notsecure/candidate/alerts/alert.module').then(m => m.AlertModule)
    children: [
      {path: '', component: JpUnsubscribeComponent},
    ]
  },
  {
    path: AppRouter.gdpr_email_confirm_link,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    // loadChildren: () => import('./jobportal/notsecure/candidate/gdpr/email-confirmation/email-confirmation.module').then(m => m.EmailConfirmationModule)
    children: [
      {path: '', component: JpEmailConfirmationComponent},
    ]
  },
  {
    path: JobPortalRouter.appmodule_link,
    component: SiteLocaleComponent, canActivate: [AuthGuard],
    loadChildren: () => import('./jobportal/jobportal.module').then(m => m.JobportalModule)
  },
  {
    path: ErpIntegrationRouter.appmodule_link,
    component: SiteLocaleComponent, canActivate: [AuthGuard],
    loadChildren: () => import('./erp-integration/erp-integration.module').then(m => m.ErpIntegrationModule)
  },
  {
    path: AppRouter.update_your_details_link,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    //loadChildren: () => import('./jobportal/notsecure/candidate/register/register.module').then(m => m.RegisterModule)
    children: [
      {path: '', component: JpRegisterNewComponent}
    ]
  },
  {
    path: AppRouter.unsubscribe_email_confirmation,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    //loadChildren: () => import('./jobportal/notsecure/candidate/components/unsubscribe-email-confirmation.module').then(m => m.UnsubscribeEmailConfirmationModule)
    children: [
      {path: '', component: JpUnsubscribeEmailConfirmationComponent}
    ]
  },
  {
    path: AppRouter.update_your_privacy_link,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', component: JpUpdatePrivacyAcceptanceComponent},

    ]
  },
  {
    path: AppRouter.update_your_privacy_link_th,
    component: SiteLocaleComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', component:ThPrivacyPolicyUpdateComponent }
    ]
  },

  {
    path: AppRouter.orgName,
    component: SiteLocaleComponent, canActivate: [AuthGuard],
    //loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule),
    children: [
      {
        path: AppRouter.job_detail_route_link,
        children: [
          {path: '', component: JpDetailComponent}
        ]
      },
      {
        path: AppRouter.search_link,
        children: [
          {path: '', component: JpSearchComponent}
        ]
      },
      {
        path: AppRouter.spec_cv_link,
        children: [
          {path: '', component: JpRegisterNewComponent}
        ]
      },
      {
        path: AppRouter.register_link,
        children: [
          {path: '', component: JpRegisterNewComponent}
        ]
      },
      {
        path: AppRouter.job_route,
        children: [
          {path: ':recordId/apply', component: JpApplyComponent},
          {path: 'apply', component: JpApplyComponent},
          {path: 'totalJobs/:recordId', component: JpApplyComponent},
          {path: 'detail/:recordId', component: JpDetailComponent},
          {path: 'apply/details', component: JpThankYouComponent},
          {path: 'VerifyAlertV2', component: JpVerifyAlertComponent},
          {path: 'VerifyAlert', component: JpVerifyAlertComponent},
          {path: 'VerifyAlert/success', component: JpAlertThankyouComponent}
        ]
      },
      {
        path: AppRouter.job_apply_route,
        children: [
          {path: ':recordId/apply', component: JpApplyComponent},
          {path: 'apply', component: JpApplyComponent},
          {path: 'totalJobs/:recordId', component: JpApplyComponent},
          {path: 'detail/:recordId', component: JpDetailComponent},
          {path: 'apply/details', component: JpThankYouComponent},
          {path: 'VerifyAlertV2', component: JpVerifyAlertComponent},
          {path: 'VerifyAlert', component: JpVerifyAlertComponent},
          {path: 'VerifyAlert/success', component: JpAlertThankyouComponent}
        ]
      },
      {
        path: AppRouter.reset_link,
        children: [
          {path: '', component: JpResetPasswordComponent}
        ]
      },
      {
        path: AppRouter.my_profile_link,
        redirectTo: AppRouter.my_profile_redirect_link,
        pathMatch: 'full'
      },
      {
        path: AppRouter.secure_link,
        children: [
          {path: 'my-profile', component: JpMyProfileComponent},
          {path: 'saved-searches', component: JpMySearchesComponent},
          {path: 'manage-cv', component: JpManageCvComponent},
          {path: 'change-email', component: JpChangeEmailComponent},
          {path: 'change-password', component: JpChangePasswordComponent},
          {path: 'marketing-preferences', component: JpMarketingPrefComponent},
          {path: 'success', component: SuccessPagesComponent},
          {path: 'error', component: ErrorPagesComponent},
          {path: 'alert', component: JpAlertThankyouComponent},
          {path: 'update-whatsapp', component: JpUpdateWhatsappComponent},
          {path: 'save-whatsapp', component: JpSaveWhatsappComponent},
          {path: 'dk-marketing-preferences', component: JpDkMarketingPrefComponent},
          {path: 'dk-marketing-preferences/thankyou', component: JpMarketingPrefThankyouComponent}
        ]
      },
      {
        path: AppRouter.login_link,
        children: [
          {path: '', component: JpLoginComponent},
          {path: 'azureLogout', component: JpLoginComponent},
          {path: 'forgot-password', component: JpForgotPasswordComponent}
        ]
      },
      {
        path: AppRouter.go1_link,
        children: [
          {path: 'go1/training/login', component: Go1SigninRegisterComponent},
          {path: 'go1/training/register', component: Go1SpecCvComponent},
          {path: 'go1/training', component: Go1WidgetComponent}
        ]
      },
      {
        path: AppRouter.all_office_link,
        children: [
          {path: '', component: JpAllOfficesComponent}
        ]
      },
      {
        path: AppRouter.office_locator_link,
        children: [
          {path: '', component: JpOfficeLocatorComponent},
          {path: 'result', component: JpOfficeLocatorResultComponent}
        ]
      },
      {
        path: AppRouter.saved_jobs_link,
        children: [
          {path: '', component: JpMySavedJobComponent}
        ]
      },
      {
        path: AppRouter.recruiting_now_link,
        children: [
          {path: '', component: JpRecruitingNowComponent}
        ]
      },
      {
        path: AppRouter.gdpr_marketing_pref_link,
        children: [
          {path: '', component: JpMarketingPrefComponent},
          {path: 'thankyou', component: JpMarketingPrefThankyouComponent},
        ]
      },
      {
        path: AppRouter.whatsapp_alert_link,
        children: [
          {path: 'updateAlert', component: JpWhatsappComponent}
        ]
      },
      {
        path: AppRouter.unsubscribe_link,
        children: [
          {path: '', component: JpUnsubscribeComponent}
        ]
      },
      {
        path: AppRouter.gdpr_email_confirm_link,
        children: [
          {path: '', component: JpEmailConfirmationComponent}
        ]
      },
      {
        path: JobPortalRouter.appmodule_link,
        loadChildren: () => import('./jobportal/jobportal.module').then(m => m.JobportalModule)
      },
      {
        path: ErpIntegrationRouter.appmodule_link,
        loadChildren: () => import('./erp-integration/erp-integration.module').then(m => m.ErpIntegrationModule)
      },
      {
        path: AppRouter.hcp_link,
        children: [
          {path: 'submit/spec-cv', component: SpecPsCvComponent},
          {path: 'success', component: ConfirmationMsgComponent},
          {path: 'error', component: ConfirmationMsgComponent}
        ]
      }
    ]
  },
  {
    path: '**',
    component: SiteLocaleComponent, canActivate: [AuthGuard],
    //loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule),
    children: [{path: '', component: ErrorPagesComponent}]
  },



];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ]
})
export class AppRoutingModule {
}
