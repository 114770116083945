import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {JpAlertComponent} from './components/jp-alert/jp-alert.component';
import {JpSearchComponent} from './components/jp-search/jp-search.component';
import {HistogramComponent} from './components/histogram/histogram.component';
import {JobtypeFilterComponent} from './components/jobtype-filter/jobtype-filter.component';
import {JobCardComponent} from './components/job-card/job-card.component';
import {FormsModule} from '@angular/forms';
import {JpNoResultFoundComponent} from './components/jp-no-result-found/jp-no-result-found.component';
import {ClickOutsideModule} from 'ng-click-outside';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpLoaderFactory} from '../../../jobportal.module';
import {HttpClient} from '@angular/common/http';
import {ResizeImageDirective} from '../../../directives/resize-image.directive';
import {RouterModule} from '@angular/router';
import {SharedModule} from "../../shared.module";
import { JpEmailAlertComponent } from './components/jp-email-alert/jp-email-alert.component';
import { JpEmailWhatsappAlertComponent } from './components/jp-email-whatsapp-alert/jp-email-whatsapp-alert.component';
import {SearchRoutingModule} from './search-routing.module';
import { ModelComponent } from 'src/app/erp-integration/shared/component/customModals/modal.component';
import { ErpSharedModule } from 'src/app/erp-integration/shared/erp-shared.module';



@NgModule({
  declarations: [ResizeImageDirective,JpAlertComponent,JpSearchComponent, HistogramComponent, JobtypeFilterComponent, JobCardComponent, JpNoResultFoundComponent, JpEmailAlertComponent, JpEmailWhatsappAlertComponent],
  imports: [
    CommonModule,
    SearchRoutingModule,
    FormsModule,
    ClickOutsideModule,
    InfiniteScrollModule,
    SharedModule,
    RouterModule,
    ErpSharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports:[ResizeImageDirective,JpSearchComponent,HistogramComponent,JobtypeFilterComponent,JobCardComponent,JpAlertComponent,JpNoResultFoundComponent,JpEmailAlertComponent,JpEmailWhatsappAlertComponent]
})
export class SearchModule { }
