
<div class="search-container">
  <form id="gtm_search_box">
    <div class="form-row align-items-center ">
      <div class="col-sm-12 col-md-4 my-1">
        <label class="sr-only" for="inlineFormInputName" [textContent]="'search_placeholder'"></label>
        <i class="icon-search"></i>
            <input type="text" autocomplete="off" class="form-control" id="inlineFormInputName" [(ngModel)]="keyWord"
               [ngModelOptions]="{standalone: true}" placeholder="{{'google_job_search_placeholder' | translate}}"
               (keyup)="getAutoComplete($event)">

        <div *ngIf="autoSuggestion && showSuggetionList" class="custom-list" (clickOutside)="this.showSuggetionList=false">
          <!--<ul *ngFor="let suggestion of autoSuggestion ; index as i">-->
          <ul *ngFor="let suggestion of autoSuggestion ;let i = index;">
            <!--<li [textContent]="suggestion.suggestion" (click)="getKeywordValue(suggestion.suggestion)"
                (keyup.enter)="getKeywordValue((i===arrowkeyLocation)?suggestion.suggestion:'')"></li>-->
            <li [class.activeSearchLink]="i === arrowkeyLocation"
                (click)="getKeywordValue(suggestion.suggestion)"
                (keyup.enter)="getKeywordValue((i===arrowkeyLocation)?suggestion.suggestion:'')">{{suggestion.suggestion}}</li>
          </ul>
        </div>
        <div *ngIf="showRecentSearches && recentSearches && recentSearches.length>0" class="custom-list" (clickOutside)="this.showRecentSearches=false">
          <ul *ngFor="let suggestion of recentSearches">
            <li [textContent]="suggestion" (click)="getKeywordValue(suggestion)"></li>
          </ul>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 my-1">
        <label class="sr-only" for="inlineFormInputGroupUsername"
               [translate]="'google_location_placeholer'"></label>
        <i class="icon-location"></i>
          <input type="text" autocomplete="off" class="form-control" id="inlineFormInputGroupUsername" [(ngModel)]="location"
                 [ngModelOptions]="{standalone: true}"
                 placeholder="{{'google_location_placeholer' | translate}}"  (keyup.enter)="redirectToSearch('searchPerformed')">
      </div>

      <div class="col-sm-12 col-md-2 my-1">
        <button id="gtm_search_btn" type="submit" [ngClass]="(route.url.indexOf('search')===-1 && searching)?'btn btn-primary btn-block btn-loading':'btn btn-primary btn-block'" [translate]="'search_button_text'" (click)="redirectToSearch('searchPerformed')"></button>
      </div>

    </div>
  </form>
</div>
