import { Injectable } from "@angular/core";
import { environment } from "../../../../../environments/root/environment";
import { RequestService } from "src/app/global/services/request/request.service";
import { Observable } from "rxjs";


@Injectable({
  providedIn: "root"
})
export class LandingService {
  // apiUrl = environmentLocal.hla_GB.getLocations;
  // uri = "s/gb/en/master/browse/v1";
  dataObj = {
    userJobTitle: "",
    userJobLocation: "",
    userSkills: []
  };
  data = {
    skill: [],
    skilSuggestion: "",
    skills: []
  };

  constructor(private _requestService: RequestService) {}

  // getLandingData(): Observable<any> {
  //   const uri = this.uri + "";
  //   return this._requestService.getRequestData(this.apiUrl, uri);
  //   //.map(data=>{return data.body}
  // }
  // postLocationData(endpoint, payload) {
  //   // const uri = this.apiUrl
  //   return this._requestService.postRequestData( endpoint, payload);
  // }
  // postJobData(payload) {
  //   const uri = this.uri + "/roles";
  //   return this._requestService.postRequestData(this.apiUrl, uri, payload);
  // }
  // postRelatedJobData(payload) {
  //   const uri = this.uri + "/relatedRoles";
  //   return this._requestService.postRequestData(this.apiUrl, uri, payload);
  // }
  // postSkill(payload) {
  //   const uri = this.uri + "/skills";
  //   return this._requestService.postRequestData(this.apiUrl, uri, payload);
  // }
  // postSkillSuggestion(payload) {
  //   const uri = this.uri + "/skillsuggestions";
  //   return this._requestService.postRequestData(this.apiUrl, uri, payload);
  // }
  // postSkillLocSubmit(payload) {
  //   const uri = this.uri + "";
  //   return this._requestService.postSubmitData(this.apiUrl, uri, payload);
  // }
  // getSkillLevelType(): Observable<any> {
  //   const uri = this.uri + "";
  //   return this._requestService.getSkillLevel(this.apiUrl,uri)
  // }
}
