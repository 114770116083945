<section class="saved-jobs">
  <h2 [translate] ="'my_shortlist'"></h2>
  <app-custom-loader *ngIf="jobDeletionCalled"></app-custom-loader>
  <div *ngIf = "!empty">
    <ul class="alert-listing">
      <li *ngFor="let savedJob of savedJobData">
        <div><a id="gtm_savedjob_jobTitle" [href]="savedJob.jobUrl" [textContent]="savedJob.jobTitle"></a></div>
        <div><span [textContent]="savedJob.createdDate | date: 'dd MMM yyyy'"></span>
          <span id="gtm_delete_savedjob" class="icon-cancel" (click) = "deleteRow(savedJob.jobRef)" ></span></div>
      </li>
    </ul>
  </div>
  <div *ngIf = "empty">
    <p [translate] = "'saved_job_emptyText'"></p>
  </div>
</section>

