import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {RequestService} from '../../../../../../global/services/request/request.service';
import {Employee, EmployeeAdapt, EmployeeData} from '../../../../../model/response/employeetype';
import {map} from 'rxjs/operators';
import {apiEndPoints, config} from '../../../../../../../environments/root/environment';

@Component({
  selector: 'app-employment',
  templateUrl: './employment.component.html',
  styleUrls: ['./employment.component.scss'],
  providers:[EmployeeAdapt]
})
export class EmploymentComponent implements OnInit {

  @Input() parent: FormGroup;
  public employeeType : EmployeeData[];

  constructor(private requestService:RequestService, private adapter:EmployeeAdapt) { }

  ngOnInit() {
    this.getEmployeeType();
  }

  private getEmployeeType(){
    this.requestService.getRequest(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.employeeType,null,null).pipe(
      map((res: Employee) => this.adapter.adapt(res)))
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data){
          this.employeeType = res.data.reverse();
          this.employeeType.forEach(item => {
            item.lookuptableText1 = decodeURIComponent(item.lookuptableText1);
            item.lookuptableText3 = decodeURIComponent(item.lookuptableText3);
          }, (error) => {
          });
        }
      },(error)=>{

      });
  }

}
