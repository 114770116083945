import {Component, Input, OnInit} from '@angular/core';
import {Go1WidgetListResponse} from '../../../model/response/go1WidgetListResponse';
import {TokenStorage} from '../../../../../global/services/tokenStorage/token-storage';
import {SiteService} from '../../../../../global/services/site/site.service';
import {RequestService} from '../../../../../global/services/request/request.service';
import {apiEndPoints, config, environment, routingEndpoint} from '../../../../../../environments/root/environment';
import {ActivatedRoute, Router} from '@angular/router';



@Component({
  selector: 'app-go1-widget',
  templateUrl: './go1-widget.component.html',
  styleUrls: ['./go1-widget.component.scss']
})
export class Go1WidgetComponent implements OnInit {

  public training : Go1WidgetListResponse[]=[];
  @Input() public jobTitle:string;
  @Input() public specialism:string;
  @Input() public subspecialism:string;
  public baseUrl : string;
  public redirectUrl : string;
  private isAzureEnabled: boolean;

  constructor(private requestService:RequestService,private siteService: SiteService,private tokenStorage: TokenStorage,public router:Router,private activatedRoute:ActivatedRoute) { }

  ngOnInit() {
    localStorage.removeItem("requstedRedirectURL");
    if(this.router.url.indexOf('Job/Detail')>-1)
      this.getTraining();
    else{
      this.activatedRoute.queryParamMap.subscribe(param=>{
        if(param.get('trainingId')){
          this.generateLinkUrl(param.get('trainingId'));
        }else
          this.generateLinkUrl('');
      });

    }
  }

  generateLinkUrl(trainingId){
    this.isAzureEnabled = environment[config.theme + this.siteService.getSiteLocale()]['config']['azure_enable'];
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    if(orgName){
      this.baseUrl=environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org];
      this.redirectUrl= (this.baseUrl[routingEndpoint.goLoginPage]+"?trainingId=").replace(':orgName',orgName);
    }else {
      this.baseUrl=environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
      this.redirectUrl= this.baseUrl[routingEndpoint.goLoginPage]+"?trainingId=";
    }
    if(this.tokenStorage.getLoggedInToken()){
      let json:any;
      if(this.isAzureEnabled) {
        localStorage.setItem("training_id",trainingId?trainingId:"redirectFromMyLearning");
        let trainingId_local = localStorage.getItem("training_id");
        if (trainingId_local === "redirectFromMyLearning")
          trainingId_local='';
          json = {trainingId: trainingId_local};
      }
      else
        json ={trainingId:trainingId};
      this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.go1generateLink,json).subscribe(res=> {
        if (res.status === config.SUCCESS) {
          window.location.href=res.data;
        }
      },error => {

      });
    }else{
      if(this.isAzureEnabled){
        localStorage.setItem("training_id",trainingId?trainingId:"redirectFromMyLearning");
        let azureLoginURL = environment[config.theme + this.siteService.getSiteLocale()]['config']['auzureLoginURL'] ? environment[config.theme + this.siteService.getSiteLocale()]['config']['auzureLoginURL'] : null;
        let orgName= this.siteService.getOrgName();
        if(orgName==='jobportal')
          orgName='hays';
        if(azureLoginURL.indexOf('{orgName}')>-1)
          azureLoginURL=azureLoginURL.replace('{orgName}',orgName);
        localStorage.setItem("requstedRedirectURL",window.location.origin+"/partner/go1/training?redirectUrl=https://hayslearning.mygo1.com/&trainingId="+trainingId);
        window.location.href=`${azureLoginURL}?workFlow=go1_login`;
      }else
        window.location.href=window.location.origin+this.redirectUrl+trainingId;
    }

  }

  private getTraining() {
    let requestJson={
      'jobTitle':this.jobTitle,
      'specialism' : this.specialism,
      'subspecialism': this.subspecialism
    };
    this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getGo1Trainings,requestJson)
      .subscribe((res)=>{
        if(res.data && res.status === config.SUCCESS)
          this.training=res.data;
      })
  }
}
