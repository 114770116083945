
  <section class="change-password">

    <form id="gtm_forgot_passowrd" [formGroup]="emailForm" (ngSubmit)="confirm()">

      <div class="row" *ngIf="failFlag">
        <div class="col-12 col-md-4 offset-md-4 error-msg-bdr" >
          <span  class="error-msg"  [translate]="'forget_failure_msg_text'"></span>
          </div>
      </div>

      <div class="row">
      <div class="col-12 col-md-4 offset-md-4 border">
        <h3 class="password-icon border-bottom">
         <div [translate] = "'forgotten_your_password'"></div>
          <span *ngIf="expireFlag" class="error-msg"  [translate]="'reset_password_expiry_msg_text'"></span>
          </h3>

          <div class="form-group">
            <label [translate] = "'email_text'"></label>
            <input type="text" class="form-control" id="filed3" autocomplete="off" placeholder="{{'login_usernameLabel' | translate}}"
              formControlName="emailaddress" required >
              <div class="form-group" *ngIf = "formSubmitted">
                <span class = "error-msg" *ngIf="patternValidation" [translate] = "'invalid_email_msg'"></span>
              </div>
          </div>

          <div class="action-bar">
            <button id="gtm_forgot_pwd_submit_btn" type="submit" [ngClass]="disableButton?'btn btn-secondary btn-block btn-loading':'btn btn-secondary btn-block'" [translate] = "'submit_button'"></button>
          </div>

      </div>
      </div>
  </form>

  </section>

