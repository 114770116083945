<app-custom-loader *ngIf="showAjxLoader || showOfficeLoader"></app-custom-loader>
<section class="office-locator" *ngIf="!showAjxLoader || !showOfficeLoader">
  <h2 [translate]="'find_nearest_office'">
  </h2>
  <div class="row">
    <div class="col-12 col-md-6 offset-md-3 border">
      <form id="gtm_office_locator" class="form">
        <div *ngIf="domainConfigResult && domainConfigResult.findNearMe">
          <app-jp-sector-for-office-locator (click)="showSubSpecialism('findNearMe');"
                                            (selectedSpecialism)="getSpecialism($event)"
                                            (showLoader)="showAjaxLoader($event)"
                                            [isSubspecialism]="isSubspecialism"></app-jp-sector-for-office-locator>
          <app-jp-subspecialism *ngIf="showFindNearMeSubSpecialism"  (selectedSubSpecialism)="getSubSpecialism($event)"
                                [specialismId]="specialismnid"></app-jp-subspecialism>
          <button id="gtm_find_near_me_btn" [ngClass]="nearMeBtn?'btn btn-secondary btn-loading':'btn btn-secondary'" *ngIf="!showAjxLoader" (click)="findNearMeCall($event)" [disabled]="nearMeBtn"
                  [translate]="'find_near_me'"></button>
          <p class="form-separator"><span [translate]="'or_text'"></span></p>
        </div>

        <app-jp-sector-for-office-locator (showLoader)="showAjaxLoader($event)"  [isSubspecialism]="isSubspecialism"
                                          (selectedSpecialism)="getSpecialism($event)"  (click)="showSubSpecialism('findUs');"></app-jp-sector-for-office-locator>
        <app-jp-subspecialism *ngIf="showFindUsSubSpecialism" (selectedSubSpecialism)="getSubSpecialism($event)"
                              [specialismId]="specialismnid"></app-jp-subspecialism>
        <app-jp-office (showLoaderOffice)="showOfficeLoaderComponent($event)" (selectedlocation)="getLocationId($event)"></app-jp-office>
        <div class="action-bar" *ngIf="!showOfficeLoader">
          <button id="gtm_find_submit_btn" [ngClass]="findClicked?'btn btn-secondary btn-loading':'btn btn-secondary'" [disabled]="findClicked"
                  (click)="findCall($event);" [translate]="'Find_text'"></button>
        </div>

      </form>
    </div>
  </div>
</section>
