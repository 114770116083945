<div class="modal fade marketing-consent-popup" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="card-img-overlay"></div>
    <div class="modal-dialog modal-dialog-centered " role="document">
      <div class="modal-content">
        <div class="modal-body">
            <p [translate] = "'hays_th_privacy_consent_massage'"> </p>
            <div *ngIf="displayValidationMessage">
                <span  class="error-msg">
                Please accept the terms and conditions
                </span>
            </div>
            <button type="button" class="btn btn_yes"  data-bs-dismiss="modal" (click)="updateMarketingResponse('Y')"  [class.active]="marketingConsentResponse == 'Y'"rel="tab1">Yes</button>
            <button type="button" class="btn btn_no" data-bs-dismiss="modal" (click)="updateMarketingResponse('N')"  [class.active]="marketingConsentResponse === 'N'"rel="tab1">No</button>
        </div>
        <div class="modal-footer">
          <button id="gtm_remove_commnt_ok_btn" type="button" (click) = "closeMarketingConsentPopUp()"
                  [ngClass]="'btn btn-primary'"
                  [translate]="'remove_comment_ok'"></button>
        </div>
      </div>
  </div>
  </div>