<section class="sign-in" *ngIf="!isAzureEnabled">

  <ng-container>
    <div *ngIf="forgetFlag || resetFlag || changePassSuccFlag || changeEmail || changePassSuccFlagV2" class="col-12 col-md-10 offset-md-1 msg-success">
    <span> <em [translate]="forgetFlag?'forgot_password_success_message':resetFlag?'password_reset_success_text':changePassSuccFlag?'password_change_success_text':changeEmail?'email_change_success_text':changePassSuccFlagV2?'user_pass_change_azure':''"></em>
      <a (click)="cancel()" class="icon-cancel"></a>
    </span>
    </div>
  </ng-container>


  <div class="row">
    <div class="col-12 col-md-4 offset-md-1" #login>
      <app-jp-signin></app-jp-signin>
    </div>
    <div class="col-12 new-to-hays" *ngIf="this.router.url.indexOf('login')>-1 && !showRegister">
      <a (click)="scrollToBottom(register,'register')" [translate]="'new_to_hays_text'"></a>
    </div>
    <div [ngClass]="showRegister?'col-12 col-md-6 register-new show':'col-12 col-md-6 register-new'" #register>
      <app-jp-register-new></app-jp-register-new>
      <div class="existing-user-link text-center" *ngIf="!showLogin"><a (click)="scrollToBottom(login,'login')" [translate]="'existing_user_label'"></a></div>
    </div>

</div>
</section>
