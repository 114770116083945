export const gb_conf = {
    country_name: 'United Kingdom of Great Britain',
    createToken: 'https://moat.hays.co.uk/online/api/hla/int/ns/gb/en/usermanagement/create/v1/token', //Token Creation
    getJobs:'https://moat.hays.co.uk/online/api/hla/int/s/gb/en/master/browse/v1/jobs', //
    getLocations: 'https://moat.hays.co.uk/online/api/hla/int/s/gb/en/master/browse/v1/locationData', //Post call for getting location
    getskillLevels: 'https://moat.hays.co.uk/online/api/hla/int/s/gb/en/master/browse/v1/lookup/skillLevelType', //get skill levels
    validatePage: 'https://moat.hays.co.uk/online/api/hla/int/s/gb/en/master/browse/v1/page', // validate page
    saveDataInDB: 'https://moat.hays.co.uk/online/api/hla/int/s/gb/en/usermanagement/create/v1/userprofile', // Post call to submit the whole form
getRelatedJobTitles: 'https://moat.hays.co.uk/online/api/hla/int/s/gb/en/master/browse/v1/relatedRoles', //post call to fetch related roles
  getRoles: 'https://moat.hays.co.uk/online/api/hla/int/s/gb/en/master/browse/v1/roles', // post call to fetch roles
  getSkills: 'https://moat.hays.co.uk/online/api/hla/int/s/gb/en/master/browse/v1/skills', // post call to fetch skills
  getSkillSuggestions: 'https://moat.hays.co.uk/online/api/hla/int/s/gb/en/master/browse/v1/skillSuggestions', // post call to fetch skill suggestions
  getTrainingSuggestions: 'https://moat.hays.co.uk/online/api/hla/int/s/gb/en/usertraining/browse/v1/training', // post call for fetching trainings according to the skill
}

// https://moat.hays.co.uk/online/api/hla/int/ns/gb/en/usermanagement/create/v1/token
