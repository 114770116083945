<div class="row cv-listing-cont">
  <div class="col-sm-12 col-md-8 offset-md-2">
    <section class="" >
      <div class="row">
        <div class="col-sm-12 col-md-8 offset-md-2">

          <p class="heading ">{{'upload_file_text'|translate}} {{'maximum_file_size_text'|translate}}</p>
          <div class="breadcrumb-cont">
            <ul class="" *ngIf="showBackButton">
              <li><a href="javascript:void(0)" (click)="getFiles(path.substring(0,path.lastIndexOf('/')),this.accessTokenDB)"
                     [translate]="'back_msg'"></a></li>
              <li> <a href="javascript:void(0)" [textContent]="path.substring(0,1).indexOf('/')>-1?path.replace('/',''):this.path"></a></li>
            </ul>

          </div>
          <app-custom-loader *ngIf="!showFiles"></app-custom-loader>
          <div class="cv-listing" *ngIf="(gDriveResponseModified && gDriveResponseModified.length>0) || (dropBoxResponseModifies && this.dropBoxResponseModifies.length>0)">
            <dl [ngClass]="showClassSelected?'selected':''">
              <dt>
                <div class="big" [translate]="'title_text'"></div>
                <div [translate]="'modified_text'"></div>
              </dt>
              <div *ngIf="dropBoxResponseModifies.length>0">
                <dd *ngFor="let item of dropBoxResponseModifies">
                  <div [ngClass]="(showClassSelected && item.path_display===cv)?'selected':''">

                  <div class="big" *ngIf="item.name.indexOf('.')<0 ||((item.name.indexOf('.txt')>-1 || item.name.indexOf('.doc')>-1 || item.name.indexOf('.docx')>-1
 || item.name.indexOf('.rtf')>-1 || item.name.indexOf('.pdf')>-1) && item.size<=maximumSize
 && item.name.indexOf('Getting Started.pdf')<0)">
                    <a
                      (click)="item.name.indexOf('.')<0?getFiles(item.path_display,this.accessTokenDB):getFileDetails(item.path_display,this.accessTokenDB,item.id)"
                      [textContent]="item.name"></a><span
                    [translate]="item.name.indexOf('.')<0?'folder_text':bytesToSize(item.size)"></span>
                  </div>
                  <div class="modify-date" *ngIf="(item.name.indexOf('.txt')>-1 || item.name.indexOf('.doc')>-1 || item.name.indexOf('.docx')>-1
 || item.name.indexOf('.rtf')>-1 || item.name.indexOf('.pdf')>-1) && item.size<=maximumSize
 && item.name.indexOf('Getting Started.pdf')<0" [textContent]="item.server_modified | date :'MM/dd/yyyy'"></div>
                    </div>
                </dd>
              </div>
              <div *ngIf="gDriveResponseModified.length>0">
                <dd *ngFor="let item of gDriveResponseModified">
                  <div [ngClass]="(showClassSelected && item.title===cv)?'selected':''">
                  <div class="big" *ngIf="item.title.indexOf('.')<0 ||((item.title.indexOf('.txt')>-1 || item.title.indexOf('.doc')>-1 || item.title.indexOf('.docx')>-1
 || item.title.indexOf('.rtf')>-1 || item.title.indexOf('.pdf')>-1) && item.fileSize<=maximumSize
 && item.title.indexOf('Getting Started.pdf')<0)">
                    <a
                      (click)="item.title.indexOf('.')<0?getFiles(item.id,this.accessTokenGD):getFileDetails(item.title,this.accessTokenGD,item.id)"
                      [textContent]="item.title"></a><span
                    [translate]="item.title.indexOf('.')<0?'folder_text':bytesToSize(item.fileSize)"></span>
                  </div>
                  <div class="modify-date" *ngIf="(item.title.indexOf('.txt')>-1 || item.title.indexOf('.doc')>-1 || item.title.indexOf('.docx')>-1
 || item.title.indexOf('.rtf')>-1 || item.title.indexOf('.pdf')>-1) && item.fileSize<=maximumSize
 && item.title.indexOf('Getting Started.pdf')<0" [textContent]="item.modifiedDate | date :'MM/dd/yyyy'"></div>
                  </div>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div class="footer" *ngIf="(gDriveResponseModified && gDriveResponseModified.length>0) || (dropBoxResponseModifies && this.dropBoxResponseModifies.length>0)">
        <button [ngClass]="showClassSelected?'btn btn-default':fileSelected?'btn btn-disabled btn-loading':'btn btn-disabled'" [disabled]="fileSelected || !showClassSelected" type="submit" (click)="getSelectedFileV1()"
                [translate]="'choose_dropbox'"></button>
        <button [ngClass]="buttonCancelled?'btn btn-white btn-loading':'btn btn-white'" type="submit" [translate]="'cancel_dropbox'" [disabled]="buttonCancelled" (click)="cancel()"></button>
      </div>
    </section>
  </div>
</div>
