<div class="form-group" *ngIf="legalConsentVisible">
<!-- Modal -->
<div class="modal fade terms-conditions-popup" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="card-img-overlay"></div>
  <div class="modal-dialog modal-dialog-centered " role="document">
    <div class="modal-content">
      <div class="modal-body">
        <p [translate] = "'popup_consent_text1'"></p>
        <p [translate] = "'popup_consent_text2'"></p>
        <div class="toggle-btn">
          <a href="javascript:void(0)" (click) = "onClick(true)"
          [ngClass]="selectedValue === true ? 'active' : ''" [translate]="'yes_text'"></a>
          <a href="javascript:void(0)"  (click) = "onClick(false)"
          [ngClass]="selectedValue === false ? 'active' : ''" [translate]="'no_text'"></a>
        </div>
        <span class="error-msg" *ngIf="showValidationError" [translate] = "'policy_validation_error'"></span>
      </div>
      <div class="modal-footer">
        <button id="gtm_remove_commnt_ok_btn" type="button" (click)="onSubmit()" [disabled]="validFlag"
                [ngClass]="(validFlag && selectedValue)?'btn btn-primary btn-loading':'btn btn-primary'"
                [translate]="'remove_comment_ok'"></button>
      </div>
    </div>
</div>

</div>
</div >

