<div class="form-group" [formGroup]="parent">
  <label [translate] = "router.url.indexOf(updateYourDetailsUrl) > -1 ? 'update_your_details_hays_office_label':'hays_office_label'"></label>
    <select class="form-control" id="gtm_office_field" formControlName="officeForSpecialism" (change)="onChangeSpecilsm($event)" [disabled]="!isOfficeList">
      <option *ngIf="isSectorClicked" [translate]="'loading_text'"></option>
      <option *ngIf="officeName" value="" selected="selected" [textContent]="officeName" [(ngModel)]="officeName" [ngModelOptions]="{standalone: true}" ngDefaultControl></option>
      <option *ngIf="!officeName && !isSectorClicked" value="" selected="selected" [translate]="router.url.indexOf(updateYourDetailsUrl) > -1 ? 'update_your_details_select_location_text' :'select_location_text'" [(ngModel)]="officeName" [ngModelOptions]="{standalone: true}" ngDefaultControl></option>
      <option *ngFor= "let office of officeList" [value] = "office.office_id+':'+office.office_email_address" id="gtm_office"
              [ngClass]="selectedSpecilsmValue === office.office_id+':'+office.office_email_address ? 'selected': ''"
              [textContent]="office.office_name"></option>
    </select>
</div>
