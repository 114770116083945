import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {JpSearchComponent} from './components/jp-search/jp-search.component';


const routes: Routes = [
  {path:'',component:JpSearchComponent}


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SearchRoutingModule { }
