import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadDocumentService {

  constructor() { }

  downloadDocument(fileName:string,content:string){

    if(fileName && content){
      const linkSource = 'data:application/pdf;base64,' + content;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }

}
