
  <app-custom-loader *ngIf="showLoader"></app-custom-loader>

<section *ngIf="officeLocatorListData.length>0" class="all-office">

    <h2 class="office-locator-results-sector"><div [textContent]="sector | uppercase"></div>
    <h4 *ngIf="locName || country" [textContent]="locName ? (officeNearText+' '+locName | uppercase) : (officeNearText+' '+country | uppercase) " ></h4>
    <h4 [translate]="'your_nearest_offices'" *ngIf="!locName && !country"></h4>
    </h2>
  <ul class="office-address">

    <li  *ngFor="let officeLocatorList of officeLocatorListData">
      <div class="office-address-cont" >
        <div class="miles" *ngIf="officeLocatorList.isDistanceBoxVisible && officeLocatorList.distance" ><span [textContent]="officeLocatorList.distance |  number:'0.0-1'"></span>
          <div [translate]="'miles_text'"></div>
      </div>
        <div class="address">
          <h4 class="value" [textContent]="officeLocatorList.officename"></h4>
          <div><a id="gtm_officelocator_officename"[href]="officeLocatorList.googleLink" target="_blank" [textContent]="officeLocatorList.address"></a></div>
        </div>
      </div>
      <div  class="other-info">
        <dl *ngIf="officeLocatorList.phone">
          <dt class="label" [translate]="'phone_text'"></dt>
          <dd><a id="gtm_officelocator_phone" [href]="telText+officeLocatorList.phone"  [textContent]="officeLocatorList.phone"></a></dd>
        </dl>
        <dl *ngIf="officeLocatorList.email">
          <dt [translate]="'email_text'"></dt>
          <dd><a id="gtm_officelocator_email" [href]="mailtoText+officeLocatorList.email"  [textContent]="officeLocatorList.email"></a></dd>
        </dl>
        <dl *ngIf="officeLocatorList.sector">
          <dt [translate]="'sector_text'"></dt>
          <dd [textContent]="officeLocatorList.sector"></dd>
        </dl>
      </div>
      <div class="action-bar" *ngIf="officeLocatorList.googleLink && siteService.getDomain()!='CHN'">
        <a class="find-location" id="gtm_officelocator_googlelink_officename" title="view office" [href]="officeLocatorList.googleLink" target="_blank" [translate]="'view_on_google'"></a>
      </div>
    </li>
  </ul>
 <div class="text-center mt-3">
  <button  id="gtm_search_again_btn" class="btn btn-secondary" (click)="searchAgain();" [translate]="'search_again'"></button>
 </div>
</section>

<section *ngIf="officeLocatorListData.length<=0 && !showLoader">
  <!--no Result found-->

    <div class="no-data-found">
      <i class="icon-warning"></i>
      <p [translate]="'no_results_found'"></p>
      <button id="gtm_searchAgain_btn" [ngClass]="searchAgainClicked?'btn btn-secondary btn-disabled':'btn btn-secondary'" (click)="searchAgain();" [disabled]="searchAgainClicked" [translate]="'search_again'"></button>
    </div>

</section>


