import {Adapter} from '../../../global/adapter/adapter';

export class OfficeForSpecialism {
  constructor(public status: string,
              public message: string,
              public data: OfficeForSpecialismData[]) {
  }
}

export class OfficeForSpecialismData {
  constructor(public office_id: string,
              public office_phone_number: string,
              public AddressLine2: string,
              public AddressLine1: string,
              public office_name: string,
              public office_specialism: string,
              public Suburb: string,
              public State: string,
              public office_fax_number: string,
              public office_email_address: string,
  ) {
  }
}

export class OfficeForSpecialismAdapt implements Adapter<OfficeForSpecialism> {
  adapt(item: any): OfficeForSpecialism {
    return new OfficeForSpecialism(
      item.status,
      item.message,
      item.data
    );
  }
}
