import {Component, OnInit} from '@angular/core';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {WidgetListAdapt, WidgetListData} from '../../model/response/widget-list';
import {HeaderAdapter, HeaderMenu, HeaderRequestJson} from '../../../global/models/header/header-menu';
import {RequestService} from '../../../global/services/request/request.service';
import {SiteService} from '../../../global/services/site/site.service';
import {DataSharingService} from '../../service/data-sharing.service';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../environments/root/environment';
import {JsonRequest} from '../../model/response/office-locator';

@Component({
  selector: 'app-jp-widgetlist',
  templateUrl: './jp-widgetlist.component.html',
  styleUrls: ['./jp-widgetlist.component.scss'],
  providers:[WidgetListAdapt,HeaderAdapter]
})
export class JpWidgetlistComponent implements OnInit {
  public pageName : string;
  public followHaysText : string;
  public officeLocatorText : string;
  public recentSearchText : string;
  public recruitingNowText : string;
  public salaryGuideText : string;
  public specCvText : string;
  public timesheetText : string;
  public recruitingTextUrl : string;
  public salaryGuideTextUrl : string;
  public specCvTextUrl : string;
  public followHaysUrl : string;
  public timesheetTextUrl : string;
  public specialismnid : string;
  public specialismname : string;
  public subspecialismID : string;
  public showAjxLoader : boolean;
  public nearMeBtn : boolean;
  public  distUnit: string;
  public  radius: number;
  public latitude: string;
  public longitude: string;
  public buttonUrl: string;
  public registerVacancy: string;
  public enquireNow: string;
  public widgetList : WidgetListData[]=[];
  public recentArr : any[]=[];
  specPsCvTextUrl: any;
  constructor(private requestService : RequestService,private widgetListAdapt: WidgetListAdapt, private router: Router, private _translate: TranslateService,
              private siteService : SiteService, private headerAdapter: HeaderAdapter, private dataSharing : DataSharingService, private cookieService:CookieService){
    this._translate.setDefaultLang(this.siteService.getSiteLocale());
    this.showAjxLoader=false;
    this.nearMeBtn=false;
    this.nearMeBtn=true;
    this.followHaysText =keywords.followHaysText;
    this.officeLocatorText =keywords.officeLocatorText;
    this.recentSearchText =keywords.recentSearchText;
    this.recruitingNowText =keywords.recruitingNowText;
    this.salaryGuideText =keywords.salaryGuideText;
    this.specCvText =keywords.specCvText;
    this.timesheetText =keywords.timesheetText;
    this.specCvTextUrl=environment[config.theme + this.siteService.getSiteLocale()]['routing_url']['speculativeCV'];
    this.specPsCvTextUrl=environment[config.theme + this.siteService.getSiteLocale()]['routing_url']['spec-cv'];
    this.salaryGuideTextUrl=environment[config.theme + this.siteService.getSiteLocale()]['config']['salaryGuideUrl'];
    this.registerVacancy=environment[config.theme + this.siteService.getSiteLocale()]['config']['haystech_register_vacancy_link'];
    this.enquireNow=environment[config.theme + this.siteService.getSiteLocale()]['config']['haystech_enquire_now_link'];
    let orgName =  this.router.routerState.snapshot.root.firstChild.params["orgName"];
    this.getRecruitingTextUrl(orgName);
    this.followHaysUrl= config.followHaysUrl;
    this.timesheetTextUrl= environment[config.theme + this.siteService.getSiteLocale()]['config']['timesheetUrl'];
  }

  ngOnInit() {
    this.dataSharing.getWidgetListPageName.subscribe((res) => {
        this.pageName = res;
        if (this.pageName && this.pageName != keywords.searchPageName && this.pageName!=keywords.jobDetailWidget) {
          this.getWidgetList(this.pageName);
        } else this.widgetList = [];
        this.dataSharing.sharedHeaderData.subscribe(data => {
          if (!data)
            this.getheader();
        })

    });
  }
  showAjaxLoader(event){
    this.showAjxLoader=event;
  }
  getSpecialism(event){
    this.specialismnid=event.specialism_Id;
    this.specialismname=event.specialism_Name;
  }
  getSubSpecialism(subspecialismId){
    this.subspecialismID=subspecialismId;
  }
  findNearMeCall($event){
    this.nearMeBtn=true;
    $event.preventDefault();
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude=position.coords.latitude.toString();
        this.longitude=position.coords.longitude.toString();
        this._translate.get(keywords.distanceUnit).subscribe(val => this.distUnit = val);
        this._translate.get(keywords.defaultRadius).subscribe(val => this.radius = val);
        let routUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.officeLocatorResultPage];
        let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
        if(orgName)
          routUrl = (environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.officeLocatorResultPage]).replace(':orgName',orgName);
        this.router.navigate([routUrl], { queryParams: { distUnit: this.distUnit, radius: this.radius,locId: "",loc:""
            ,lat: this.latitude, long: this.longitude,geoLoc:"" ,spec:this.specialismname,specId: this.specialismnid,subSpecId:  this.subspecialismID } });
        this.latitude="";
        this.longitude="";
      });
    }
  }
  getheader(){
    let headerJson= new HeaderRequestJson(
      '',
      environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.header]
    );
    let endPoint=apiEndPoints.pagemanagement;

    this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, endPoint, headerJson).pipe(
      map((res: HeaderMenu) => this.headerAdapter.adapt(res)))
      .subscribe((res) => {
        if (res.status == config.SUCCESS && res.data &&   res.data['PageData']['Menu']['LeftTop']) {
          res.data['PageData']['Menu']['LeftTop'].forEach((item) => {
            if ((item['resource_url'].indexOf('all-offices') > 0))
              this.buttonUrl = item.resource_url;
            if ((item['resource_url'].indexOf('office-locator') > 0))
              this.buttonUrl=this.officeLocatorText;
          });
        }
      })
  }
  getWidgetList(pageName){
        let json= new JsonRequest("",pageName);
    this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,apiEndPoints.getWidgetList,json).pipe(
      map((res: WidgetListData) => this.widgetListAdapt.adapt(res)))
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          if(res.data["results"].length >0) {
            this.widgetList=res.data["results"];
            let orgName= this.siteService.getOrgName();
            if(orgName==='it') {
              this.widgetList.forEach(list => {
                if (list.WIDGET_LABEL.indexOf('HTS 2019') > -1)
                  list.WIDGET_LINK = this.enquireNow;
                if (list.WIDGET_LABEL.indexOf('Reg vacancy') > -1)
                  list.WIDGET_LINK = this.registerVacancy;
              });
            }
            let recentSearchindex=this.widgetList.findIndex(x => x.WIDGET_NAME.toLowerCase() === keywords.recentSearchText);
            if(recentSearchindex>=0)
              this.widgetList.splice(recentSearchindex,1);
          }else this.widgetList=[];
        }
      }, (error) => {

      });
  }

  private getRecruitingTextUrl(orgName: string){
    if (orgName === 'it') {
      this.recruitingTextUrl = environment[config.theme + this.siteService.getSiteLocale()]['liferayUrl']+`/${orgName}/register-a-vacancy`;
    } 
    else if(orgName && orgName !== 'it') {
      this.recruitingTextUrl = window.location.origin + environment[config.theme + this.siteService.getSiteLocale()]['routing_url_org']['recruitingNow'].replace(':orgName', orgName);
    }
    else {
      this.recruitingTextUrl = window.location.origin + environment[config.theme + this.siteService.getSiteLocale()]['routing_url']['recruitingNow'];
    }
  }
}
