import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {JpApplyComponent} from './components/jp-apply/jp-apply.component';
import {JpThankYouComponent} from './components/jp-thank-you/jp-thank-you.component';
import {JpVerifyAlertComponent} from "../../components/jp-verify-alert/jp-verify-alert.component";
import {JpAlertThankyouComponent} from "../../components/jp-alert-thankyou/jp-alert-thankyou.component";
import {JpDetailComponent} from '../jobdetail/components/jp-detail/jp-detail.component';


const routes: Routes = [
  {path:':recordId/apply',component:JpApplyComponent},
  {path:'apply',component:JpApplyComponent},
  {path:'totalJobs/:recordId',component:JpApplyComponent},
  {path:'detail/:recordId',component:JpDetailComponent},
  {path:'apply/details',component:JpThankYouComponent},
  {path:'VerifyAlertV2',component:JpVerifyAlertComponent},
  {path:'VerifyAlert',component:JpVerifyAlertComponent},
  {path:'VerifyAlert/success',component:JpAlertThankyouComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ApplyRoutingModule { }
