<div id = 'play-store-links-container' class = 'job-box bg-white' *ngIf = 'domainConfig && (domainConfig.appleBadgeFlag || domainConfig.gplayBadgeFlag)'>
    <div>
        <h3>
            {{'download_hays_app_text' | translate}}
        </h3>
        <span *ngIf = 'domainConfig.appleBadgeFlag'>
            <a [href] = 'domainConfig.appleBannerURL'>
                <img src = '../assets/images/apple-play-store-icon.svg' alt = "{{'apple_play_alt_text' | translate}}" class = 'apple-play-image'>
            </a>
        </span>
        <span *ngIf = 'domainConfig.gplayBadgeFlag'>
            <a [href] = 'domainConfig.gplayBannerURL'>
                <img src = '../assets/images/google-play-badge.png' alt = "{{'google_play_alt_text' | translate}}" class = 'gplay-image'>
            </a>
        </span>
    </div>
</div>
