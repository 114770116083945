<section class="update-whatsapp-number">


  <div class="border save-whatsapp-number"  *ngIf="!showThankyou">
    <h1 [translate]="'whatsApp_job_alert_text'" class="text-center"></h1>
    <p class="whatsapp-info" [translate]="'WhatsApp_alert_number_text'" class="text-center"></p>
<div class="form-inline">
  <div class="form-group isd-code-1">
    <i class="icon-phone"></i>
    <input class="form-control" id="wappCountryCode"  type="text" maxlength="5"  [(ngModel)]="wappcountryCode" (keyup)="validate(wappcountryCode,wappNumber,$event)" />

  </div>
  <div class="form-group number">
    <input class="form-control" id="number" type="text" maxlength="20" placeholder="WhatsApp Number" [(ngModel)]="wappNumber"  (keyup)="validate(wappcountryCode,wappNumber,$event);" />
  </div>
  <button class="btn btn-default" type="submit" [disabled]="isdisable" (click)="submit()" id="gtm_saveWhtsAppNumber_btn" [translate]="'whatsApp_save_text'"></button>
</div>


      <label id="code-error-msg" class="error-msg" *ngIf="codeError" [translate]="'whatsapp_country_code_error_text'"></label>
      <label  class="error-msg" id="number-error-msg" *ngIf="WANumberError" [translate]="'whatsApp_contact_validation_text'"></label>

  </div>
  <div class="whatsapp-container" *ngIf="showThankyou">
    <i class="tick"></i>
    <div class="thankyou" [translate]="'whatsApp_thankyou_text'"></div>
    <div [translate]="'whatsApp_subscribtion_success_text'"></div>
  </div>

</section>



