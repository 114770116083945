import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {DataSharingService} from './jobportal/service/data-sharing.service';
import {Router} from '@angular/router';
import {SiteService} from './global/services/site/site.service';
import {config, environment} from '../environments/root/environment';
import { Title } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer, PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = '';
  public containerClass: any;
  private isAzureEnabled: boolean;

  constructor(private dataSharing: DataSharingService, public route: Router, private siteService: SiteService, private titleService: Title,private platformLocation: PlatformLocation,@Inject(PLATFORM_ID) private platformId: object
      ) {
    let queryPathName = '';
    let queryParamList = [];
    if(isPlatformServer(this.platformId)){
      queryPathName = this.platformLocation.pathname;
    }else {
       queryPathName = window.location.pathname;
       queryParamList = window.location.search.split('?');
    }
    this.titleService.setTitle(this.title);
    if (queryPathName.indexOf('&') > -1 && queryPathName.indexOf('globalConsent/OTId') <= -1) {
      let extraParam = queryPathName.substring(queryPathName.indexOf('&')).split('&');
      extraParam.forEach(x => queryParamList[1] = queryParamList[1] + '&' + x);
      queryPathName = queryPathName.split('&')[0];
    }

    if (queryPathName.indexOf('globalConsent/OTId') > -1) {
      let globalConsentRouteList = queryPathName.split('/OTId=');
      if (globalConsentRouteList && globalConsentRouteList.length > 0) {
        let globalConsentRout = globalConsentRouteList[0] + '?OTId=' + globalConsentRouteList[1];
        this.route.navigateByUrl(globalConsentRout);
        return;

      }
    }
    if (queryPathName.indexOf('consent/') > -1) {
      if (queryPathName.includes('expressConsent')) {
        let consentRouteList = queryPathName.split('/expressConsent');
        let identifier = consentRouteList[1].split('/');
        let consentRout = consentRouteList[0] + '?type=expressConsent&identifier=' + identifier[1]+"&reference="+identifier[2];
        this.route.navigateByUrl(consentRout);
        return;
      }
    }
    let logoutUrl = '/login/?logout=success';
    if ('jobportal' != this.siteService.getOrgName()) {
      logoutUrl = '/' + this.siteService.getOrgName() + logoutUrl;
    }

    if (queryPathName.indexOf('logout') > -1 && queryPathName.indexOf('/jobportal/') === -1 && isPlatformBrowser(this.platformId)) {
      window.location.href = window.location.origin + logoutUrl;
      if ((queryPathName.indexOf('logout') > -1 || queryPathName.indexOf('azureLogout') > -1 || window.location.search.indexOf("logout") > -1) && queryPathName.indexOf('/jobportal/') === -1) {
        let isAzureB2CEnabled = environment[config.theme + this.siteService.getSiteLocale()]['config']['azure_enable'];
        let azureLoginURL = environment[config.theme + this.siteService.getSiteLocale()]['config']['auzureLoginURL'] ? environment[config.theme + this.siteService.getSiteLocale()]['config']['auzureLoginURL'] : null;
        if (isAzureB2CEnabled) {
          let orgName= this.siteService.getOrgName();
          if(orgName==='jobportal')
            orgName='hays';
          if(azureLoginURL.indexOf('{orgName}')>-1)
            azureLoginURL=azureLoginURL.replace('{orgName}',orgName);
         /* if (queryPathName.indexOf('logout') > -1) {
            this.tokenStorage.clearCacheAndCookie();
            window.location.href = azureLoginURL;
          }*/
          if (queryPathName.indexOf('azureLogout') > -1 || queryPathName.indexOf('logout') > -1) {
            window.location.href = azureLoginURL+'?action=logout';
          }
        } else {
          window.location.href = window.location.origin + logoutUrl;
        }
      }
      this.route.navigate(['login'], {queryParams: {logout: 'success'}});
    }


      queryPathName = decodeURIComponent(queryPathName);

      let pathName = window.location.pathname;
      let query = window.location.search;
      let identifier = '';
      if (query.indexOf('identifier') > -1) {
        identifier = query.split('identifier=')[1];
      } else if (query.indexOf('signedurl') > -1) {
        identifier = query.split('signedurl=')[1];
      }
      if (identifier.indexOf('+') > -1) {
        //let newId = identifier.replace(/\+/gi, '%2B');
        let encryptedIdentifier= encodeURI(identifier.split("&")[0]);
        localStorage.setItem('encryptedIdentifier',encryptedIdentifier);
        this.route.navigateByUrl(pathName + '?identifier=' +identifier);
      }
      if (queryPathName.indexOf('browse/details') > -1 || queryPathName.indexOf('Job/Detail') > -1 || queryPathName.indexOf('/apply') > -1) {
        if (queryPathName.indexOf('%28') > -1) {
          queryPathName = queryPathName.replace('%28', '%2528');
        }
        if (queryPathName.indexOf('%29') > -1) {
          queryPathName = queryPathName.replace('%29', '%2529');
        }
        if (queryPathName.indexOf('(') > -1) {
          let queryParamReplaceTo = queryPathName.split('(');
          queryParamReplaceTo.forEach((data, i) => {
            queryPathName = queryPathName.replace('(', '%28');
          });

        }
        if (queryPathName.indexOf(')') > -1) {
          let queryParamReplaceToNext = queryPathName.split(')');
          queryParamReplaceToNext.forEach((data, i) => {
            queryPathName = queryPathName.replace(')', '%29');
          });
        }
        if ((queryPathName.indexOf('Job/Detail') > -1 && queryParamList[1]) || (queryPathName.indexOf('/apply') > -1 && queryParamList[1])) {
         // if(isPlatformBrowser(this.platformId))
            this.route.navigateByUrl(queryPathName + '?' + queryParamList[1]);
        } else {
         // if(isPlatformBrowser(this.platformId))
            this.route.navigateByUrl(queryPathName);
        }
      }
      let pathVariable = '';
      let lang = '';
      let jobSource = '';
      if ((queryPathName.indexOf('browse/details') > -1 || queryPathName.indexOf('Job/Detail') > -1) && queryPathName.indexOf('&lang=') > -1) {
        let queryPathnameList = queryPathName.split('&');
        queryPathnameList.forEach(item => {
          if (item.indexOf('Job/Detail') > -1) {
            pathVariable = item;
          }
          if (item.indexOf('lang') > -1) {
            lang = item;
          }
          if (item.indexOf('jobSource') > -1) {
            jobSource = item;
          }
          if (item.indexOf('jobsource') > -1) {
            jobSource = item;
          }
          if (item.indexOf('source') > -1) {
            jobSource = item;
          }
        });
        if (!jobSource) {
          this.dataSharing.sharedClientConfig.subscribe(res => jobSource = res.jobSource);
        }
        if (lang && jobSource) {
          if(isPlatformBrowser(this.platformId))
            window.location.href = window.location.origin + pathVariable + '?' + jobSource + '&' + lang;
        } else if (lang) {
          if(isPlatformBrowser(this.platformId))
            window.location.href = window.location.origin + pathVariable + '?' + lang;
        } else if (jobSource) {
          if(isPlatformBrowser(this.platformId))
            window.location.href = window.location.origin + pathVariable + '?' + jobSource;
        } else {
          if(isPlatformBrowser(this.platformId))
            window.location.href = window.location.origin + pathVariable;
        }


      }

      localStorage.setItem('queryParamPartner', decodeURIComponent(window.location.search));
      this.dataSharing.sharedMainComponentData.subscribe((res) => {
        this.containerClass = res;
      });

    }

    ngOnInit(){
      
    }
}
