import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {LogsService} from '../logger/logs.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SiteService} from '../site/site.service';
import {TokenStorage} from '../tokenStorage/token-storage';
import {HmacSecurity} from '../HmacService/HmacSecurity';
import {RequestService} from '../request/request.service';
import {DataSharingService} from '../../../jobportal/service/data-sharing.service';

const TOKEN_HEADER_KEY = 'Authorization';
@Injectable()
export class HttpServiceInterceptor implements HttpInterceptor {
  private isTokenExpired: boolean = false;

  constructor( private router: Router, private route: ActivatedRoute,
              private _logger: LogsService, private _translate: TranslateService,
              private _siteService: SiteService, private token: TokenStorage,
              private _hmacSecurity: HmacSecurity,private dataSharing:DataSharingService,private requestService:RequestService
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{

      return this.validateRequest(request,next);
  }
  private addTokenToRequest(request: HttpRequest<any>, token: string) {
    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!token) {
      return request;
    }


    // We clone the request, because the original request is immutable
    let loggedinToken=this.token.getLoggedInToken();
    let anonymousToken=this.token.getAnonymousToken();
    return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' +
        (loggedinToken?loggedinToken:anonymousToken))});
    /*return request.clone({
     headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer' + token )
    });*/
  }
  private validateRequest(request,next):Observable<HttpEvent<any>>{
    request = request.clone({headers: request.headers.set('activityurl', this.router.url)});
    request = request.clone({headers: request.headers
        .set('cache-control', 'no-cache')

    });
    // HMAC
    const timestamp = this._hmacSecurity.getRequestTime();
    const digest = this._hmacSecurity.generateHash(request, timestamp);
    const refererUrl  = this._hmacSecurity.getRefererUrl();
    if (timestamp && digest) {
      request = request.clone({
        headers: request.headers.set('x-auth', digest)
      });
      request = request.clone({
        headers: request.headers.set('x-date', timestamp)
      });
      if(refererUrl){
        request = request.clone({
          headers: request.headers.set('referer', refererUrl)
        });
      }
    } else {
      this._logger.info(
        'HMAC header not adding as this is not a valid HMAC call'
      );
    }
    if(this.token.getAnonymousSessionId() && !this.token.getLoggedInSessionId()){
      request = request.clone({ headers: request.headers.set('x-session', this.token.getAnonymousSessionId()) });
    }
    if(this.token.getLoggedInSessionId())
      request = request.clone({ headers: request.headers.set('x-session', this.token.getLoggedInSessionId()) });
    request = request.clone({
      headers: request.headers
        .set('Accept', 'application/json')
      // .set('cache-control', 'no-cache')
    });
    // request = request.clone({headers: request.headers.set('activityurl', this.router.url)});
    let token='';
    if(this.token.getLoggedInToken())
      token=this.token.getLoggedInToken();
    else
      token=this.token.getAnonymousToken();
    return next.handle(this.addTokenToRequest(request,token))
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // display its headers
            const key = event.headers.get('x-token');
            if (key != null) {
              if(this.token.getLoggedInToken())
                this.token.saveLoggedInToken(key);
              else
                this.token.saveAnonymousToken(key);
            }
          }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          let errorMessage = '';
          this._logger.error('error.... : ', error);

          if (error) {
            if (error.status === 401 || error.status === 403) {
             // console.log("Exception occured..",error.status);
              if(this.token.getLoggedInToken())
                this.token.loggedInSignOut('q','unauthorised',this.dataSharing,this.requestService,'loggedin',error.status);
              else if (error.status === 401 && (error.error.includes('JWT: 401 - The token is missing/invalid') || error.error.includes('JWT: 401 - The session id is missing/invalid'))) {
                if(!this.isTokenExpired){
                  this.isTokenExpired = true;
                  this.token.refreshAnonymousToken();
                  location.reload();
                }
              }
              else
              this.token.login('q','unauthorised','','','anonymous',error.status);
              this._logger.debug('this is unauth call or page access not allowed :');
              return throwError(error);
            }
            else if(error.status !== 422 && error.status !== 500 && error.status!==400 && error.status!==404 && error.status !== 0 && error.status !== undefined && error.status !== 504 && error.status !== 502){
              if(this.token.getLoggedInToken())
                this.token.loggedInSignOut('q','unauthorised',this.dataSharing,this.requestService,'loggedin',error.status);
              else
                this.token.login('q','unauthorised','','','anonymous',error.status);
            }
            else if (error.status === 500 && error.error.message.includes('No user found with username')) {
              if(!this.isTokenExpired){
                this.isTokenExpired = true;
                this.token.refreshAnonymousToken();
                location.reload();
              }
            }
          }
          // console.log('error.... : ', error);
          if (error.error instanceof HttpErrorResponse) {
            // client-side error
            this._logger.debug('In client-side error : ');
            // console.log('In client-side error : ');
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            this._logger.debug('In server-side error : ');
            // console.log('In server-side error : ');
            // this.router.navigate(['/error'], { queryParams: { errorCode: error.status } , queryParamsHandling: 'merge' });
            this._logger.error('errorUrl ------ error?errorCode=' + error.status);
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
          this._logger.error('Error :-- ', errorMessage);
          // window.alert(errorMessage);
          this._logger.debug('End of interceptor in catcherror');
          return throwError(error);
        })
      );

  }

}
