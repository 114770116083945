<section class="thank-you-msg-docx">
  <div class="hays_logo_docx">
    <a href=""></a>
  </div>
  <div class="success">
    <div class="success-msg-cont" *ngIf="query">
      <div *ngIf="message === 'SUCCESS'">
        <i></i>
        <p [translate]="'docex_thank_you_msg'"></p>
      </div>
      <div *ngIf="message === 'FAIL'" class="error">
        <i class="icon-warning"></i>
        <p [translate]="'docex_thank_you_msg'"></p>
      </div>
    </div>
  </div>
</section>


