import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './../hla-app/secure/assessskill/components/home/home.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {routeConstant} from './routes/routes';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {HlaAppRoutingModule} from './hla-app-routing.module';
import {HomeCanDeactivateGuard} from './guards/home-can-deactivate-guard.service';
import { LandingPageComponent } from './secure/assessskill/components/landing-page/landing-page.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SkillComponent } from './secure/assessskill/components/skill/skill.component';
import { SkillLevelComponent } from './secure/assessskill/components/skill-level/skill-level.component';
import { AnalysisComponent } from './secure/assessskill/components/analysis/analysis.component';
import { DashboardComponent } from './secure/assessskill/components/dashboard/dashboard.component';
import { AssessskillRoutingModule } from './secure/assessskill/assessskill-routing.module';
import { Page404hlaComponent } from './page404hla/page404hla.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes  } from 'ngx-loading';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HlaAppRoutingModule,
    NgxLoadingModule.forRoot({
     }),

  ],
  providers: [
    HomeCanDeactivateGuard
  ],
  declarations: [HomeComponent, LandingPageComponent, SkillComponent, SkillLevelComponent, AnalysisComponent, DashboardComponent, Page404hlaComponent],
  exports: [
    LandingPageComponent
  ]

})
export class HlaAppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    routeConstant.translations_me,
    '.json'
  );
}
