<section class="sign-in">
  <h2 [translate]="'go_one_login_heading'">
  </h2>
  <ng-container >
    <div *ngIf="forgetFlag || resetFlag || changePassSuccFlag || changeEmail" class="col-12 col-md-10 offset-md-1 msg-success">
    <span> <em [translate]="forgetFlag?'forgot_password_success_message':resetFlag?'password_reset_success_text':changePassSuccFlag?'password_change_success_text':changeEmail?'email_change_success_text':''"></em>
      <a (click)="cancel()" class="icon-cancel"></a>
    </span>
    </div>
  </ng-container>


  <div class="row">
    <div class="col-12 col-md-4 offset-md-1" #login>
      <app-go1-login></app-go1-login>
    </div>
    <div class="col-12 new-to-hays" *ngIf="this.router.url.indexOf('login')>-1 && !showRegister">
      <a (click)="scrollToBottom(register,'register')" [translate]="'new_to_hays_text'"></a>
    </div>
    <div [ngClass]="showRegister?'col-12 col-md-6 register-new show':'col-12 col-md-6 register-new'" #register>
      <app-go1-spec-cv></app-go1-spec-cv>
      <div class="existing-user-link text-center" *ngIf="!showLogin"><a (click)="scrollToBottom(login,'login')" [translate]="'existing_user_label'"></a></div>
    </div>

  </div>
</section>
