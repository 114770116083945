import {Component, Input, OnInit} from '@angular/core';
import {ExtraFieldsConfig, Industries, JobSearchFilter, LocationFilter} from '../../../../../model/response/google-job-response';
import {DataSharingService} from '../../../../../service/data-sharing.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {JobSearchService} from '../../../../../service/job-search.service';
import {TranslateService} from '@ngx-translate/core';
import {RequestService} from '../../../../../../global/services/request/request.service';
import {apiEndPoints, config, environment} from '../../../../../../../environments/root/environment';

@Component({
  selector: 'app-histogram',
  templateUrl: './histogram.component.html',
  styleUrls: ['./histogram.component.scss']
})
export class HistogramComponent implements OnInit {
  @Input() industryList :Industries[]=[];
  @Input() locationList :LocationFilter[]=[];
  @Input() slicedLocation :LocationFilter[]=[];
  @Input() tempLocationList :LocationFilter[]=[];
  @Input() slicedIndustries :Industries[]=[];
  @Input() extraConfig : ExtraFieldsConfig;
  private slicedLocationLength: number;
  public showLocation: boolean=true;
  public showIndustry: boolean=true;
  private slicedIndusryLength: number;
  public location: string="";
  public industry: string;
  @Input() tempIndustry: Industries[]=[];
  private locationf:string;
  private industryf:string;
  private jobSearchFilter: JobSearchFilter;
  private searchPageTitle: string;
  private searchPageDesc: string;
  private keyword: string="";
  isHTSOrg: boolean;

  constructor(private dataSharingService:DataSharingService,private activatedRoutr:ActivatedRoute,private siteService:SiteService,
              private route:Router,private jobSearchService:JobSearchService,private sharedService:DataSharingService,
              private translate:TranslateService, private requestService:RequestService) {
    this.translate.setDefaultLang(this.siteService.getSiteLocale());
    activatedRoutr.queryParamMap.subscribe(param => {
      if (param.get('q')) {
        this.keyword=param.get('q');
      }if (param.get('location')) {
        this.location=param.get('location');
      }
      if(param.get('orgName')){
        this.isHTSOrg = param.get('orgName') == 'hts' ? true : false;
      }
    });
    this.isHTSOrg =  this.route.url.includes('hts') ? true : false;
    this.translate.getTranslation(this.siteService.getSiteLocale()).subscribe((res) => {
      let jobTitle=this.keyword?this.keyword+" ":" ";
      let loc=this.location?this.location+" ":environment[config.theme+this.siteService.getSiteLocale()]['config']['country_name']+" ";
      if (res['search_result_title'])
        this.searchPageTitle = res['search_result_title'];
      if (res['search_page_description_text'])
        this.searchPageDesc = res['search_page_description_text'];
      if (res['search_result_title1'])
        this.searchPageTitle = jobTitle+res['search_result_title1']+loc+res['search_result_title2'];
      if (res['meta_desc_text1'])
        this.searchPageDesc = res['meta_desc_text1']+jobTitle+res['meta_desc_text2']+loc+res['meta_desc_text3']+jobTitle+res['meta_desc_text4'];
    });
  }

  ngOnInit() {
    this.sharedService.sharedLocation.subscribe(location => this.locationf = location);
    this.sharedService.sharedIndustry.subscribe(industry => this.industryf = industry);
  }

  getMoreLocation(type: string) {
    if(type==='Show_more') {
      if (this.slicedLocation.length < this.locationList.length) {
        this.slicedLocationLength = this.slicedLocation.length;
        let x = this.locationList.length - this.slicedLocation.length;
        if (x < 10) {
          this.slicedLocation = this.locationList;
          this.slicedLocation = this.slicedLocation.slice(0, this.slicedLocationLength + x);
        } else {
          this.slicedLocation = this.locationList;
          this.slicedLocation = this.slicedLocation.slice(0, this.slicedLocationLength + 10);
        }
      } else {
        this.slicedLocation.length = this.locationList.length;
      }
    }else
      this.slicedLocation=this.slicedLocation.slice(0,10);
  }

  getMoreIndustries(type: string) {
    if(type==='Show_more') {
      if (this.slicedIndustries.length < this.industryList.length) {
        this.slicedIndusryLength = this.slicedIndustries.length;
        let x = this.industryList.length - this.slicedIndustries.length;
        if (x < 10) {
          this.slicedIndustries = this.industryList;
          this.slicedIndustries = this.slicedIndustries.slice(0, this.slicedIndusryLength + x);
        } else {
          this.slicedIndustries = this.industryList;
          this.slicedIndustries = this.slicedIndustries.slice(0, this.slicedIndusryLength + 10);
        }
      } else {
        this.slicedIndustries.length = this.industryList.length;
      }
    }else
      this.slicedIndustries=this.slicedIndustries.slice(0,10);
  }

  searchHistogram(type:string) {
    if (type === 'location') {
      if (this.location.trim()) {
        this.slicedLocation = this.locationList.filter(loc => loc.locationName.toLowerCase().includes(this.location.toLowerCase()));
      //  console.log("inside trim");
      }
      else {
        /*console.log("inside else");
        console.log("this.tempLocationList..",this.tempLocationList);*/
        this.slicedLocation = this.tempLocationList.length > 0 ? this.tempLocationList : this.slicedLocation;
      }
    }else{
      if (this.industry.trim())
        this.slicedIndustries = this.slicedIndustries.filter(industry => industry.industryName.toLowerCase().includes(this.industry.toLowerCase()));
      else
        this.slicedIndustries = this.tempIndustry.length>0?this.tempIndustry:this.slicedIndustries;
    }
  }

  getHistogrameFilter(type: string, filterText: string) {
    if(type==='location'){
      this.jobSearchService.setSerchPerfomedValue(false);
      this.locationf=filterText;
      this.dataSharingService.sendLocation(filterText);
    }else {
      this.jobSearchService.setSerchPerfomedValue(false);
      this.industryf=filterText;
      this.dataSharingService.sendIndustry(filterText);
    }
    this.getParamValues();



  }
  private getParamValues() {
    this.jobSearchService.routeNavigate(this.route,this.activatedRoutr,this.siteService.getSiteLocale(),null,null,null,
      this.locationf,this.industryf,null,null,null,'filtered',this.searchPageTitle,this.searchPageDesc, this.dataSharingService)
  }

  clearAllFilter() {
    this.industryf='';
    this.locationf='';
    this.sharedService.sendIndustry(this.industryf);
    this.sharedService.sendLocation(this.locationf);
    this.jobSearchService.setWorkingPattern(false);
    this.jobSearchFilter=new JobSearchFilter(this.industryf,this.locationf,'0','','',
      '','','','false',
      'false','false','RELEVANCE_DESC','','','-1','-1','-1',
      '-1','0','');
    this.jobSearchService.setJobSearchFilter(this.jobSearchFilter);
    sessionStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
   // localStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
    this.jobSearchService.routeNavigate(this.route,this.activatedRoutr,this.siteService.getSiteLocale(),'-1','0','-1',
      this.locationf,this.industryf,'-1','-1','-1','filtered',this.searchPageTitle,this.searchPageDesc, this.dataSharingService);
  }
}
