export class UpdateWhatsappRequest {
  constructor(
    public portalProfileId:string,
    public wspNumber:string,
    public wspNewNumber:string,
    public domainId:string,
    public alert:boolean,
    public whatsappcountrycode:string,
    public whatsappnewcountrycode:string,
  ){}
}
;
