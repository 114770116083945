import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {SiteService} from '../site/site.service';
import {TokenStorage} from '../tokenStorage/token-storage';
import {LogsService} from '../logger/logs.service';
import {apiEndPoints, config, environment} from '../../../../environments/root/environment';
import {ValidatePageRequest} from '../../models/auth/validate-page-request';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class RequestService {
public location = new BehaviorSubject('working');
private validatePageAccessUrl: string;
private domainMappingList: Object;
private testBrowser: boolean;

  constructor(private http: HttpClient, private _logger: LogsService, private _siteService: SiteService,
              private tokenStorage: TokenStorage,private cookieService :CookieService,private router :Router, @Inject(PLATFORM_ID) private platformId: string ) {
    this.testBrowser = isPlatformBrowser(platformId);
    if (this.tokenStorage.getDebugMode() === 'true') {
      this._logger.level = 1;
    }
  }

  getAPIUrl (endpoint) {
      let conf = environment['hla_' + this._siteService.getDomain().toUpperCase()];
      return conf[endpoint];
  }

  getRequest(theme: string, baseendpoint:string,endpoint: string, requestParams?: object, pathVariable?: string) {
    if(this.testBrowser || isPlatformServer(this.platformId)) {
      if (this.tokenStorage.getDebugMode() === 'true') {
        this._logger.level = 1;
      }
      let url = environment[theme + this._siteService.getSiteLocale()][baseendpoint][endpoint];
      if(url.indexOf('{orgname}')>-1)
        url= url.replace('{orgname}',this._siteService.getOrgName());
      this._logger.debug('Calling API : ', url);
      let params = new HttpParams();
      if (requestParams) {
        Object.keys(requestParams).forEach(function (key) {
          params = params.append(key, requestParams[key]);
        });

      }
      this._logger.debug('pathVariable : ', pathVariable);
      if (pathVariable) {
        url = url + pathVariable;
        this._logger.debug('Inside pathVariable : ', url);
      }
      return this.http.get<any>(url, {params: params});
    } else {
      return null;
    }
  }

  postRequest(theme: string, endpoint: string, jsonBody: object): Observable<any> {
    if(this.testBrowser || isPlatformServer(this.platformId)) {
      this._logger.debug('Calling API : ', environment[theme + this._siteService.getSiteLocale()][endpoint]);
      return this.http.post<any>(environment[theme + this._siteService.getSiteLocale()][endpoint], jsonBody);
    } else {
      return null;
    }
  }
  postRequestGateWay(theme: string, baseendpoint:string,endpoint: string, jsonBody: object): Observable<any> {
    if(this.testBrowser || isPlatformServer(this.platformId)) {
      this._logger.debug('Calling API : ', environment[theme + this._siteService.getSiteLocale()][baseendpoint][endpoint]);
      let url = environment[theme + this._siteService.getSiteLocale()][baseendpoint][endpoint];
      let orgName = this._siteService.getOrgName();
      if(url && url.indexOf('{orgname}')>-1)
        url= url.replace('{orgname}',orgName);
      if(url && jsonBody){
        return this.http.post<any>(url, jsonBody);
      }else {
        return null;
      } 
    } else {
      return null;
    }
  }

  getRequestData( endpoint: string): Observable<any> {
    if(this.testBrowser || isPlatformServer(this.platformId)) {
      return this.http.get<any>(endpoint);
    } else {
      return null;
    }

  }
  validatePageAccessRequest(uri: string) {
  if(this.testBrowser || isPlatformServer(this.platformId)) {
    try {
      this.validatePageAccessUrl = environment[config.theme + this._siteService.getSiteLocale()][apiEndPoints.gateWayAPI][apiEndPoints.validatePage];
      const body = new ValidatePageRequest(uri);
      return this.http.post<any>(this.validatePageAccessUrl, body);
    }catch (e) {
      let cookieName = this.cookieService.get('multi_country');
      let lang='';
      if(cookieName) {
        if (cookieName.split('-')[0] === 'hrcs')
          lang = 'hrcz';
        else
          lang = cookieName.split('-')[0];
        this._siteService.setSite('/' + cookieName.split('-')[1] + '/' + lang + '/');
        this.validatePageAccessUrl = environment[config.theme + this._siteService.getSiteLocale()][apiEndPoints.gateWayAPI][apiEndPoints.validatePage];
        const body = new ValidatePageRequest(uri);
        return this.http.post<any>(this.validatePageAccessUrl, body);
      }else{
          let hostName = location.hostname;
          let len = hostName.length;
          let n = hostName.indexOf(".");
          if(hostName.indexOf(".")>-1)
            hostName = hostName.substring(n, len);
          if (hostName === 'localhost')
            this._siteService.setSite('/GB/en/jobportal/');
          else {
            this.http.get("assets/domain-mapping.json").subscribe(domain =>{
              this.domainMappingList = domain;
              let defaultlangAPI = environment['baseAPIURL'] + this.domainMappingList[hostName] + environment['getDefaultLang'];
              this.getRequestData(defaultlangAPI).subscribe((res) => {
                if (res.status === config.SUCCESS && res.data) {
                  this._siteService.setSite('/' + res.data.sitelocale.split('_')[1] + '/' + res.data.sitelocale.split('_')[0] + '/');
                  this.validatePageAccessUrl = environment[config.theme + this._siteService.getSiteLocale()][apiEndPoints.gateWayAPI][apiEndPoints.validatePage];
                  let uriList = uri.split('jobportal');
                  if(uriList[0].split('/')[1]+'_'+uriList[0].split('/')[0]!==this._siteService.getSiteLocale()){
                    uri='/'+this._siteService.getDomain()+'/'+this._siteService.getLocale()+'/jobportal'+uriList[1];
                    this.router.navigateByUrl(uri);
                  }
                }
              });
            });
          }
      }

    }
} else {
      return null;
    }
  }
   postRequestData( endpoint: string, payload): Observable<any> {
     if(this.testBrowser || isPlatformServer(this.platformId)) {
    return this.http.post<any>(this.getAPIUrl(endpoint), payload);
    } else {
       return null;
     }
  }

getValue(theme: string, endpoint: string){
  return environment[theme + this._siteService.getSiteLocale()][endpoint];
}

   /*createAnonymousToken(theme: string, baseendpoint: string, endpoint: string, jsonBody: object){
     const promise = this.http.post<any>(environment[config.theme + this._siteService.getSiteLocale()][apiEndPoints.gateWayAPI][apiEndPoints.createToken], {}).toPromise();
     promise.finally(data=>{
       console.log("data..",JSON.stringify(data));
     })
       /!*.pipe(map(res=>{
      if (res.status === config.SUCCESS) {
        localStorage.setItem('AhaysToken', res.data.token);
        localStorage.setItem('Asessionid', res.data.sessionGuid);
        this.postRequestGateWay(theme,baseendpoint,endpoint,jsonBody);
        //return true;
      }
    }));*!/
  }*/
}
