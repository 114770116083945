import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {JobSummaryComponent} from './components/job-summary/job-summary.component';
import {JpDetailComponent} from './components/jp-detail/jp-detail.component';
import {JpSocialPlatformComponent} from './components/jp-social-platform/jp-social-platform.component';
import {TalkToConsultantComponent} from './components/talk-to-consultant/talk-to-consultant.component';
import {JpJobDescriptionComponent} from './components/jp-job-description/jp-job-description.component';
import {SharedModule} from '../../shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {VideoMyJobComponent} from './components/video-my-job/video-my-job.component';
import { NgxJsonLdModule } from 'ngx-json-ld';
import {GoogleForJobsComponent} from './components/google-for-jobs/google-for-jobs.component';
import {Go1WidgetComponent} from '../../../../partner/go1-app/go1-notsecure/components/go1-widget/go1-widget.component';
import {JobdetailRoutingModule} from './jobdetail-routing.module';
import {SocialShareDirective} from '../../directive/social-share.directive';


@NgModule({
  declarations: [Go1WidgetComponent, JobSummaryComponent, JpDetailComponent, JpSocialPlatformComponent, TalkToConsultantComponent, JpJobDescriptionComponent, VideoMyJobComponent, GoogleForJobsComponent, SocialShareDirective],
  imports: [
    JobdetailRoutingModule,
    CommonModule,
    SharedModule,
    TranslateModule,
    NgxJsonLdModule
  ],
  exports: [JobSummaryComponent, JpDetailComponent, JpSocialPlatformComponent, TalkToConsultantComponent, JpJobDescriptionComponent, VideoMyJobComponent, GoogleForJobsComponent, Go1WidgetComponent]
})
export class JobdetailModule { }
