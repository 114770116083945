import { Component, OnInit } from '@angular/core';
import {DecryptIdentifierJsonRequest} from '../../../../model/response/decrypt-identifier';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../../../environments/root/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {SiteService} from '../../../../../global/services/site/site.service';
import {RequestService} from '../../../../../global/services/request/request.service';
import {WhatsappAlertResponse} from '../../../../model/response/whatsapp-alert-response';

@Component({
  selector: 'app-jp-whatsapp',
  templateUrl: './jp-whatsapp.component.html',
  styleUrls: ['./jp-whatsapp.component.scss']
})
export class JpWhatsappComponent implements OnInit {
  public whatsappAlertResult: WhatsappAlertResponse;
  public showSaveWhatsapp: boolean;
  public showUpdateWhatsapp: boolean;
  public showLoader= true;

  constructor(private activatedRoute:ActivatedRoute,private siteService:SiteService,private requestService:RequestService, private _router: Router) { }

  ngOnInit() {
    this.getWhatsAppAlerts(this.activatedRoute.snapshot.queryParamMap.get('identifier'));
  }
  getWhatsAppAlerts(identifier) {
    let jsonData = new DecryptIdentifierJsonRequest(identifier, 'whatsAppAlert');
    let orgName = this._router.routerState.snapshot.root.firstChild.params["orgName"];
    let errorPage = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.errorPage];
    if(orgName)
      errorPage = (environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.errorPage]).replace(':orgName',orgName);
    this.requestService.postRequestGateWay(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getWhatsAppAlerts, jsonData)
      .subscribe((res) => {
        if (res.status === config.SUCCESS && res.data) {
          this.whatsappAlertResult = res.data;
          if (this.whatsappAlertResult.redirectionPage == routingEndpoint.saveWhatsappPage) {
            this.showSaveWhatsapp=true;
            this.showUpdateWhatsapp=false;
            this.showLoader=false;
          } else if (this.whatsappAlertResult.redirectionPage == routingEndpoint.updateWhatsappPage) {
            this.showSaveWhatsapp=false;
            this.showUpdateWhatsapp=true;
            this.showLoader=false;

          }
          localStorage.setItem('emailID', this.whatsappAlertResult.emailId);
        }
      }, (error) => {
        window.location.href=window.location.origin+errorPage+"?whatsAppFlag="+keywords.invalid;


      });
  }
}
