
  <section class="change-password" *ngIf="!isAzureEnabled">
    <form id="gtm_reset_pass" [formGroup]="resetPassForm" (ngSubmit)="submit()">


      <div class="col-12 col-md-4 offset-md-4 border mb-5">
        <h3 class="password-icon border-bottom" [translate] = "'Create_password_text'">
        </h3>
          <p class="help-text" [translate] = "'password_criteria_text_new'">
          </p>

          <div class="form-group">
            <label  [translate]="'login_passLabel'"></label>
            <input class="form-control" maxlength="15" placeholder = "{{'cp_changed_password_placeholder' | translate}}"
              formControlName="newPassword" type="password" required >
          </div>

          <div class="form-group">
            <label  [translate]="'Confirm_password_text'"></label>
            <input type="password" class="form-control" placeholder= "{{'cp_confirm_password_placeholder' | translate}}"
              formControlName="confirmPassword" required >
          </div>

          <div class="form-group" *ngIf = "formSubmitted">
            <span class="error-msg" *ngIf="validationFlag.required" [translate] = "'password_blank_text'"></span>
            <span class = "error-msg" *ngIf="validationFlag.pattern && !validationFlag.required" [translate] = "'password_criteria_text_new'"></span>
            <span class="error-msg" *ngIf="validationFlag.passwordMatch && !validationFlag.required && !validationFlag.pattern" [translate]="'password_do_not_match_new'"></span>
          </div>

          <div class="action-bar">
            <button id="gtm_reset_pwd_submit_btn" type="submit" [ngClass]="disableButton?'btn btn-secondary btn-block btn-loading':'btn btn-secondary btn-block'" [disabled]="disableButton" [translate] = "'submit_button'"></button>
          </div>


      </div>
  </form>
  </section>


