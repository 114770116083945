import {Adapter} from '../../../global/adapter/adapter';
import {Injectable} from '@angular/core';

export class Location{
  constructor(public status: string, public message: string, public data: LocationData[]){

  }
}
export class LocationData{
  constructor(public lookuptableText1: string, public lookuptableText3:string){
  }
}

@Injectable({
  providedIn:'root'
})

export class LocationAdapt implements Adapter<Location>{
  adapt(item: any): Location {
    return new Location(
      item.status,
      item.message,
      item.data
    );
  }

}
