<h2 *ngIf="showServerError" [translate]="'error_message_unavailable'"></h2>

<section class="manage-cv">
  <h2 [translate]="'manage_cv_heading'"></h2>
  <div class="row">
    <div class="cont-center">
      <app-jp-get-cv [flow]="'manageCvFlow'"></app-jp-get-cv>
      <form id="gtm_manage_cv" [formGroup]="uploadCvFormGroup" (ngSubmit)="uploadCV()">
        <div class="mt-3">
      <app-jp-upload-cv [parent]=uploadCvFormGroup (showValidationMessage)="showValidationMessage($event)"></app-jp-upload-cv>
        </div>
        <span *ngIf="errorMsgShow && !existingMsgError" class="error-msg" [translate]="'invalid_cv_msg'"></span>
        <span *ngIf="showPopUp" class="error-msg" [translate] = "'password_protected_warning'"></span>
      <div class="action-bar text-center">
        <button id="gtm_upload_cv_btn" [ngClass]="uploadButtonClicked?'btn btn-default btn-loading':'btn btn-default'" type="submit" [disabled]="disableUpload" [translate]="'upload_cv'"></button>
      </div>

      </form>
    </div>
  </div>
</section>
