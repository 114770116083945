import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {RequestService} from '../../../../global/services/request/request.service';
import {GetCVData} from '../../../model/response/manage-cv-response';
import {SiteService} from '../../../../global/services/site/site.service';
import {DataSharingService} from '../../../service/data-sharing.service';
import {DownloadDocumentService} from '../../../service/download-document.service';
import {apiEndPoints, config} from '../../../../../environments/root/environment';
import {UploadCvRequest} from '../../../model/request/upload-cv-request';

@Component({
  selector: 'app-jp-get-cv',
  templateUrl: './jp-get-cv.component.html',
  styleUrls: ['./jp-get-cv.component.scss']
})
export class JpGetCvComponent implements OnInit {

  public cvData: GetCVData[] = [];
  @Input() public flow:string;
  @Output() selectedFileData = new EventEmitter<any>();
  private cvSelected:boolean;
  private removeCVConfirmMsg:string;
  public loader=false;

  constructor(private requestService: RequestService, private downloadDocumentService: DownloadDocumentService,
              private translation:TranslateService,private siteservice:SiteService,
              private activatedRoute:ActivatedRoute,private dataSharingService:DataSharingService) {
    this.translation.setDefaultLang(this.siteservice.getSiteLocale());
    this.translation.getTranslation(this.siteservice.getSiteLocale()).subscribe((res)=>{
      this.removeCVConfirmMsg=(res['confirm_cv_removal_msg']);
    });
  }

  ngOnInit() {
    this.dataSharingService.sharedCVSelectedInfo.subscribe(cvSelected => {
      this.cvSelected = cvSelected;
      if(!this.cvSelected){
        this.cvData.forEach(res=>{
          res.selectedFile=false;
        });
      }
    });
    this.activatedRoute.queryParamMap.subscribe(param=>{
      if(param.get('uploadCV') && param.get('uploadCV')==='Y'){
        this.getCV();
      }else
        this.getCV();
    });
  }

  downloadcv(docname, content) {
    if (docname && content)
      this.downloadDocumentService.downloadDocument(docname, content);
  }

  removecv(docname,docid) {
    if(confirm(this.removeCVConfirmMsg)){
      this.loader=true;
      if (docid){
        let deleteCvJson=new UploadCvRequest(
          docname,
          docid,
          null,
          null
        );
        let cvDataArray=[];
        this.requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI, apiEndPoints.deleteCv, deleteCvJson).
        subscribe((res)=>{
          if(res.data && res.status == config.SUCCESS)
            if(res.data['statuscode']==config.portalSuccessCode){
              this.loader=false;
              cvDataArray=this.cvData;
              this.getCV();
            }
        });
      }
    }
  }

  private getCV() {
    this.loader=true;
    this.requestService.getRequest(config.theme, apiEndPoints.gateWayAPI, apiEndPoints.getCvs, null, null)
      .subscribe((res) => {
        if (res.data && res.status == config.SUCCESS) {
          this.cvData = res.data['cvlist'];
          this.loader=false;
          if(this.cvData.length>3) {
            this.cvData = this.cvData.slice(0, 3);
          }
        }
        this.loader=false;
      }, (error) => {
        this.loader=false;
      });
  }

  selectCv(cvname,cvid,index){

    this.cvData.forEach(res=>{
      res.selectedFile=false;
    });
    this.selectedFileData.emit([cvname,cvid]);
    //this.cvSelected=!this.cvSelected;
    this.cvData[index].selectedFile=!this.cvData[index].selectedFile;
    this.dataSharingService.setCVSelectedValue(this.cvData[index].selectedFile);

  }


}
