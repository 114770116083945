import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SiteService} from 'src/app/global/services/site/site.service';
import {config, environment, keywords, routingEndpoint} from '../../../../../../../environments/root/environment';
import {TranslateService} from '@ngx-translate/core';
import {SetTitleService} from '../../../../../service/set-title.service';
import {DataSharingService} from '../../../../../service/data-sharing.service';
import {DOCUMENT} from '@angular/common';
import {TokenStorage} from '../../../../../../global/services/tokenStorage/token-storage';
import {RequestService} from '../../../../../../global/services/request/request.service';

@Component({
  selector: 'app-jp-login',
  templateUrl: './jp-login.component.html',
  styleUrls: ['./jp-login.component.scss']
})
export class JpLoginComponent implements OnInit {
  public query: any;
  public forgetFlag = false;
  public resetFlag = false;
  public changePassSuccFlag = false;
  public changePassFailFlag = false;
  public changeEmail: boolean;
  public showRegister: boolean;
  public showLogin: boolean;
  public changePassSuccFlagV2: boolean;
  public isAzureEnabled: boolean;

  constructor(public activateRoute: ActivatedRoute, private dataSharingService: DataSharingService,
              public router: Router, private siteService: SiteService, private translate: TranslateService,
              private titleService: SetTitleService, @Inject(DOCUMENT) private document: Document, private tokenStorage: TokenStorage, private requestService: RequestService) {
    this.translate.getTranslation(this.siteService.getSiteLocale()).subscribe((res) => {
      this.titleService.setTitleForPage(res['logIn'] + ' - ' + res['page_title']);
    });
    this.isAzureEnabled = environment[config.theme + this.siteService.getSiteLocale()]['config']['azure_enable'];
    this.dataSharingService.getWidgetListName(keywords.loginPageWidget);
  }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe(params => {
      if (params) {
        this.query = params;
        if (!this.isAzureEnabled && this.query.logout === 'success') {
          this.tokenStorage.loggedInSignOut('', '', this.dataSharingService, this.requestService, 'loggedin', 0);
        }
        if (this.router.url.indexOf('logout') > -1 || (this.router.url.indexOf('azureLogout') > -1 && this.query.e !== 'failure')) {
          let azureLoginURL = environment[config.theme + this.siteService.getSiteLocale()]['config']['auzureLoginURL'] ? environment[config.theme + this.siteService.getSiteLocale()]['config']['auzureLoginURL'] : null;

          if (this.isAzureEnabled) {
            let orgName = this.siteService.getOrgName();
            if (orgName === 'jobportal') {
              orgName = 'hays';
            }
            if (azureLoginURL.indexOf('{orgName}') > -1) {
              azureLoginURL = azureLoginURL.replace('{orgName}', orgName);
            }
            this.tokenStorage.clearCacheAndCookie();

            window.location.href = azureLoginURL;

          } else {
            this.tokenStorage.loggedInSignOut('', '', this.dataSharingService, this.requestService, 'loggedin', 0);
          }
        }
        if ((this.query.e === 'failure' || this.query.ChangeEmail === 'Y' || this.query.ChangeEmail === 'N' || this.query.logout === 'success' || this.query.q === 'unauthorised') && this.isAzureEnabled) {
          let azureLogout = environment[config.theme + this.siteService.getSiteLocale()]['config']['auzureLoginURL'] ? environment[config.theme + this.siteService.getSiteLocale()]['config']['auzureLoginURL'] : null;
          let orgName = this.siteService.getOrgName();
          if (orgName === 'jobportal') {
            orgName = 'hays';
          }
          if (azureLogout.indexOf('{orgName}') > -1) {
            azureLogout = azureLogout.replace('{orgName}', orgName) + '?action=logout';
          }
          this.tokenStorage.clearCacheAndCookie();
          window.location.href = azureLogout;
        }
        if (this.query.status === 'forgotSuccess') {
          this.forgetFlag = true;
        }
        if (this.router.url.indexOf('lang') > -1 || this.router.url.indexOf('?_ga') > -1) {
          let azureLoginURL = environment[config.theme + this.siteService.getSiteLocale()]['config']['auzureLoginURL'] ? environment[config.theme + this.siteService.getSiteLocale()]['config']['auzureLoginURL'] : null;
          if (this.isAzureEnabled) {
            this.tokenStorage.clearCacheAndCookie();
            let orgName = this.siteService.getOrgName();
            if (orgName === 'jobportal') {
              orgName = 'hays';
            }
            if (azureLoginURL.indexOf('{orgName}') > -1) {
              azureLoginURL = azureLoginURL.replace('{orgName}', orgName);
            }
             var gaworkflow=this.activateRoute.snapshot.queryParamMap.get('_ga');
             var lang=this.activateRoute.snapshot.queryParamMap.get('lang');
            if(gaworkflow !=null ||  gaworkflow !=undefined)
            {
              window.location.href = azureLoginURL +'?lang='+lang+'&_ga='+gaworkflow;
             }
            else
            {
              window.location.href = azureLoginURL;
            }

            //window.location.href = azureLoginURL;

          }
        }
        if (this.query.status === 'resetPasswordSuccess') {
          this.resetFlag = true;
        }
        if (this.query.ChangePassword === 'Y') {
          this.changePassSuccFlag = true;
        }
        if (this.query.ChangeEmail === 'Y') {
          this.changeEmail = true;
        }
        if (this.query.ChangePasswordV2 === 'Y') {
          this.changePassSuccFlagV2 = true;
        }
        if (Object.keys(this.query).length === 0 && this.isAzureEnabled) {
          let azureLoginURL = environment[config.theme + this.siteService.getSiteLocale()]['config']['auzureLoginURL'] ? environment[config.theme + this.siteService.getSiteLocale()]['config']['auzureLoginURL'] : null;
          let orgName = this.siteService.getOrgName();
          if (orgName === 'jobportal') {
            orgName = 'hays';
          }


          if (azureLoginURL.indexOf('{orgName}') > -1) {
            azureLoginURL = azureLoginURL.replace('{orgName}', orgName);
          }

            window.location.href = azureLoginURL;


        }

      }
    });

  }

  scrollToBottom(e: HTMLElement, type: string): void {
    try {
      type === 'register' ? this.showRegister = true : this.showLogin = true;
      setTimeout(() => {
        e.scrollIntoView();
      }, 200);

    } catch (err) {
    }
  }


  cancel() {
    this.forgetFlag = false;
    this.resetFlag = false;
    this.changePassSuccFlag = false;
    this.changePassFailFlag = false;
    this.changeEmail = false;
    this.changePassSuccFlagV2 = false;
    let baseRoute = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url];
    this.router.navigate([baseRoute[routingEndpoint.login]]);
  }

}
