<div class="form-group privacy-acceptance-section" [formGroup]="parent" *ngIf="consentBox1.length>0 && parent">
  <label class="checkbox-container">
    <input name="policy" formControlName="privacyconsent" type="checkbox" value="" (change)="privacyCheck($event, 'parentCheckBox')">
    <ng-container *ngFor="let item of consentBox1; let i = index;">
      <span *ngIf="i < 4">{{item.consentText1}}
        <a *ngIf="item.link.indexOf('email-confirmation')>-1" id="gtm_privacy_policy_consentText2"
          [href]="item.link">{{item.consentText2}}</a>
        <a *ngIf="item.link.indexOf('email-confirmation')==-1" [href]="item.link"
          target="_blank">{{item.consentText2}}</a>
      </span>
    </ng-container>
  </label>
  <ng-container *ngFor="let item of consentBox1; let i = index;">
    <ul class="consent-box-list" [ngClass]="i===4 ?  'mt-3':''" *ngIf="i > 3 && i < 7">
      <li class="consent-checkbox">
        <label class="checkbox-container">
          <input name="subPolicy" formControlName="privacyconsent{{item.checkboxNo}}" type="checkbox" value="" (change)="privacyCheck($event, '')">
          {{item.consentText1}}
        </label>
      </li>
    </ul>
    <span *ngIf="i > 6" class="d-block mt-3" [ngClass]="[]">
      {{item.consentText1}}
    </span>
  </ng-container>
</div>