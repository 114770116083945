<div class="new-user bg-white">
<div class="registration_title">
    <h2 class="spec-cv-h2" [translate]="'update_your_privacy_title_text'"></h2>
  </div>

  <div [ngClass]="'col border spec-cv'">
  <form class="form-group privacy-acceptance-section" [formGroup]="parent" *ngIf="consentBox1.length>0 && parent">
   <div class="checkbox-container">
    <ng-container *ngFor="let item of consentBox1; let i = index;">
      <span *ngIf="i < 4 ">{{item.consentText1}}
        <a *ngIf="item.link.indexOf('email-confirmation')>-1" id="gtm_privacy_policy_consentText2"
            [href]="item.link">{{item.consentText2}}</a>
        <a *ngIf="item.link.indexOf('email-confirmation')==-1" [href]="item.link"
            target="_blank">{{item.consentText2}}</a>
      </span>
    </ng-container>

    <ng-container *ngFor="let item of consentBox1; let i = index;">
      <div *ngIf="i === 4 ">{{item.consentText1}}
        <a *ngIf="item.link.indexOf('email-confirmation')>-1" id="gtm_privacy_policy_consentText2"
            [href]="item.link">{{item.consentText2}}</a>
        <a *ngIf="item.link.indexOf('email-confirmation')==-1" [href]="item.link"
            target="_blank">{{item.consentText2}}</a>
        </div>
    </ng-container>

    <ng-container *ngFor="let item of consentBox1; let i = index;">
      <ul class="consent-box-list" [ngClass]="i==5 ?  'mt-3':''" *ngIf="i > 4 && i < 8">
        <label class="consent-checkbox consent-box-list-item">
          <input type = 'checkbox' formControlName = "privacyconsent{{item.consentCheckBoxIndex}}" (change) = 'consentBoxChangeTH()'>
            {{item.consentText1}}
          <a *ngIf="item.link" [href]="item.link" target="_blank">{{item.consentText2}}</a>
        </label>
      </ul>
      <span *ngIf="i > 7" class="d-block mt-3" [ngClass]="[]">
          {{item.consentText1}}
        </span>
    </ng-container>
   </div>

  </form>

    <form [formGroup]="signUpFormGroup" (ngSubmit)="updatePrivacyAcceptance()" id="update_your_privacy_form">
    <ng-container *ngIf="!isCaptchaDisable">
      <app-jp-captcha [parent]="signUpFormGroup">
      </app-jp-captcha>
      <div *ngIf="signUpFormGroup['controls'].reCaptchaReactive?.value === '' && formSubmitted">
        <span class="error-msg" [translate]="'captcha_validation_text'"></span>
      </div>
      <div *ngIf="signUpFormGroup['controls'].reCaptchaReactive?.value === null"><span class="error-msg" [translate]="'captcha_validation_text'"></span>
      </div>
    </ng-container>
      <div class="action-bar text-center">
        <button id="gtm_submit_btn"
                [ngClass]="disableButton?'btn btn-default btn-block btn-loading':'btn btn-default btn-block'" type="submit"
                [disabled]="disableButton" [translate]="'submit_button'"></button>
      </div>
    </form>


    <div *ngIf="displayPopUp" class="modal fade terms-conditions-popup" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="card-img-overlay"></div>
      <div class="modal-dialog modal-dialog-centered " role="document">
        <div class="modal-content">
          <div class="modal-body">
            <a href="javascript:void(0)" id="closePolicy" class="close" (click)="closePopUp()">+</a>
            <p [innerHTML] = "'privacy_thailand_popup_text' | translate"></p>
          </div>
          <div class="modal-footer">
            <button id="gtm_remove_commnt_ok_btn" type="button" (click)="closePopUp()"
                    [ngClass]="'btn btn-primary'"
                    [translate]="'remove_comment_ok'"></button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="newsLetterPopup" class="modal fade terms-conditions-popup" id="exampleModalCenter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="card-img-overlay"></div>
      <div class="modal-dialog modal-dialog-centered " role="document">
        <div class="modal-content">
          <div class="modal-body">
<!--            <a href="javascript:void(0)" id="closePolicy2" class="close" (click)="closePopUp()">+</a>-->
            <p [translate] = "'hays_th_privacy_consent_massage'">
            </p>
            <div *ngIf="showError">
            <span  class="error-msg">
              Please accept the terms and conditions
            </span>
            </div>
            <button type="button" class="btn btn_yes"  data-bs-dismiss="modal" (click)="newsLetterSubscription='Y';showError=false"  [class.active]="viewMode=='tab1'"rel="tab1"(click)="viewMode='tab1'">Yes</button>
            <button type="button" class="btn btn_no" data-bs-dismiss="modal" (click)="newsLetterSubscription='N';showError=false"  [class.active]="viewMode=='tab2'"rel="tab1"(click)="viewMode='tab2'">No</button>

          </div>
          <div class="modal-footer">
            <button id="gtm_remove_commnt_ok_btn2" type="button" (click)="checkNewsSub()"
                    [ngClass]="'btn btn-primary'"
                    [translate]="'remove_comment_ok'"></button>
          </div>
        </div>
      </div>
    </div>

</div>
</div>

