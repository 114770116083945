import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {apiEndPoints, config, environment, keywords, routingEndpoint} from '../../../../environments/root/environment';
import {SiteService} from '../site/site.service';
import {HeaderRequestJson} from '../../models/header/header-menu';
import {CookieService} from 'ngx-cookie-service';
import { isPlatformServer } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';


const TOKEN_KEY_SECURE = 'haysToken';
const USR_FIRST_NAME = 'firstName';
const USR_LAST_NAME = 'lastName';
const USR_EMAIL_ADD = 'emailAdd';
const USR_ISWHATSAPP = 'isWhatsapp';
const TOKEN_KEY_ANONYMOUS = 'AhaysToken';
const DEBUG_MODE = 'mode';
const SESSION_GUID_SECURE = 'userSession';
const SESSION_GUID_ANONYMOUS = 'Asessionid';
// const EXPIRY_TIME = 'expires_at';

@Injectable()
export class TokenStorage {

  constructor(private router: Router,private siteService:SiteService,private cookieService:CookieService, @Inject(PLATFORM_ID) private platformId: object,
              @Optional() @Inject(REQUEST) private request: any) { }
  public removeSecureToken() {
    localStorage.removeItem(TOKEN_KEY_SECURE);
    localStorage.removeItem(SESSION_GUID_SECURE);
  }
  public signOut() {
    localStorage.removeItem(TOKEN_KEY_SECURE);
    localStorage.removeItem(SESSION_GUID_SECURE);
    //this.cookieService.delete('usrname','/');
    this.cookieService.delete('alerts','/');
    let cookieDomainName=environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.cookieDomain];
    this.cookieService.deleteAll('/',cookieDomainName);
    this.login('q','unauthorised','','','loggedin',0);
    this.removeLoggedInUserDetails();
  }

  public saveToken(token: string) {
    // localStorage.removeItem(TOKEN_KEY_SECURE);
    localStorage.setItem(TOKEN_KEY_SECURE,  token);
  }
  public saveLoggedInUserDetails(firstName,lastName,email,isWhatsapp){
    localStorage.setItem(USR_FIRST_NAME,  firstName);
    localStorage.setItem(USR_LAST_NAME,  lastName);
    localStorage.setItem(USR_EMAIL_ADD,  email);
    localStorage.setItem(USR_ISWHATSAPP,  isWhatsapp);
  }
  public removeLoggedInUserDetails(){
    localStorage.removeItem(USR_FIRST_NAME);
    localStorage.removeItem(USR_LAST_NAME);
    localStorage.removeItem(USR_EMAIL_ADD);
    localStorage.removeItem(USR_ISWHATSAPP);
  }
  public saveLoggedInToken(token: string) {
    localStorage.setItem(TOKEN_KEY_SECURE,  token);
  }
  public saveAnonymousToken(token: string) {
    localStorage.setItem(TOKEN_KEY_ANONYMOUS,  token);
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY_SECURE)?localStorage.getItem(TOKEN_KEY_SECURE):localStorage.getItem(TOKEN_KEY_ANONYMOUS);
  }
  public getLoggedInToken(): string {
    return localStorage.getItem(TOKEN_KEY_SECURE);
  }
  public getAnonymousToken(): string {
    return localStorage.getItem(TOKEN_KEY_ANONYMOUS);
  }
  public getAnonymousTokenFromCookies(): string {
    if(isPlatformServer(this.platformId)) {
      return this.cookieService.get(TOKEN_KEY_ANONYMOUS);
    }else {
      return localStorage.getItem(TOKEN_KEY_ANONYMOUS);
    }
  }

  public saveDebugMode(debugMode: string) {
    localStorage.setItem(DEBUG_MODE,  debugMode);
  }
  public getDebugMode(): string {
    return localStorage.getItem(DEBUG_MODE);
  }
  public saveSessionId(sessionId: string) {
    localStorage.setItem(SESSION_GUID_SECURE,  sessionId);
  }
  public getSessionId(): string {
    return localStorage.getItem(SESSION_GUID_SECURE)?localStorage.getItem(SESSION_GUID_SECURE):localStorage.getItem(SESSION_GUID_ANONYMOUS);
  }
  public getLoggedInSessionId(): string {
    return localStorage.getItem(SESSION_GUID_SECURE);
  }

  public getAnonymousSessionId(): string {
      return localStorage.getItem(SESSION_GUID_ANONYMOUS);
  }
  public getAnonymousSessionIdFromCookies(): string {
    if(isPlatformServer(this.platformId)) {
      return this.cookieService.get(SESSION_GUID_ANONYMOUS);
    }else {
      return localStorage.getItem(SESSION_GUID_ANONYMOUS);
    }
  }

  public loggedInSignOut(key,value,dataSharing,requestService,status,code){
    const multi_country = this.cookieService.get('multi_country');
    localStorage.removeItem(TOKEN_KEY_SECURE);
    localStorage.removeItem(SESSION_GUID_SECURE);
    let cookieDomainName=environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.cookieDomain];
    this.cookieService.delete('alerts','/');
    this.cookieService.deleteAll('/',cookieDomainName);
    this.removeLoggedInUserDetails();
    if(multi_country){
      this.cookieService.set('multi_country', multi_country, 0, '/', cookieDomainName);
    }
    this.login(key,value,dataSharing,requestService,status,code);

  }
  public clearCacheAndCookie(){
    localStorage.removeItem(TOKEN_KEY_SECURE);
    localStorage.removeItem(SESSION_GUID_SECURE);
    let cookieDomainName=environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.cookieDomain];
    this.cookieService.delete('alerts','/');
    this.cookieService.deleteAll('/',cookieDomainName);
    this.removeLoggedInUserDetails();
  }

  public login(key:string,param: string,dataSharing,requestService,status,code) {
    if (status !== 'loggedin') {
      localStorage.removeItem(TOKEN_KEY_ANONYMOUS);
      localStorage.removeItem(SESSION_GUID_ANONYMOUS);
    }
    if (dataSharing && requestService)
      this.headerCallAfterSignOut(dataSharing, requestService);
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    if (key && param) {
      if (status === 'loggedin' || this.router.url.indexOf('my-profile')>-1 || this.router.url.indexOf('/s/')>-1) {
        let azureLoginURL = environment[config.theme + this.siteService.getSiteLocale()]['config']['auzureLoginURL'] ? environment[config.theme + this.siteService.getSiteLocale()]['config']['auzureLoginURL'] : null;
        let isAzureEnabled = environment[config.theme + this.siteService.getSiteLocale()]['config']['azure_enable'];
        if (isAzureEnabled) {
          let orgName = this.siteService.getOrgName();
          if (orgName === 'jobportal') {
            orgName = 'hays';
          }
          if (azureLoginURL.indexOf('{orgName}') > -1) {
            azureLoginURL = azureLoginURL.replace('{orgName}', orgName);
          }
          window.location.href = azureLoginURL + "?isSuccess"+ "=" + param+"&workFlow="+key+"&action=logout";
        }else{
          let routingUrl = '';
          if(orgName){
            let url = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url_org][routingEndpoint.login];
            routingUrl = url.replace(':orgName',orgName);
          }
          else
            routingUrl = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.login];
          window.location.href = window.location.origin + routingUrl + "?" + key + "=" + param;
        }

      } else if(code==403){
        let baseRoute = environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.errorPage];
        this.router.navigate([baseRoute],{queryParams:{'orgName':'Incorrect'}});
      }else {
        window.location.reload();
      }
    }
  }

  private headerCallAfterSignOut(dataSharing,requestService){
      let headerJson= new HeaderRequestJson(
        '',
        environment[config.theme + this.siteService.getSiteLocale()][routingEndpoint.routing_url][routingEndpoint.header]
      );
      let endPoint=apiEndPoints.pagemanagement;
      requestService.postRequestGateWay(config.theme,apiEndPoints.gateWayAPI,endPoint,headerJson)
        .subscribe((res)=>{
          dataSharing.clearHeader();
          if(res.status==config.SUCCESS && res.data) {
            dataSharing.sendHeaderData(res);
          }
        });
    }
    public refreshAnonymousToken() {
      localStorage.removeItem(SESSION_GUID_ANONYMOUS);
      localStorage.removeItem(TOKEN_KEY_ANONYMOUS);
      sessionStorage.removeItem(SESSION_GUID_ANONYMOUS);
      sessionStorage.removeItem(TOKEN_KEY_ANONYMOUS);
      this.cookieService.delete(SESSION_GUID_ANONYMOUS);
      this.cookieService.delete(TOKEN_KEY_ANONYMOUS);
      if(isPlatformServer(this.platformId)){
       this.request.session.anonymousToken = '';
       this.request.session.anonymousSessionId = '';
      }
    }
  }
