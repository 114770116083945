import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataSharingService} from '../../../../../service/data-sharing.service';
import {
  JobSearchFilter,
  JobTypeFilter,
  PayTypeDataList,
  SortBy,
  WorkingPatternFilter
} from '../../../../../model/response/google-job-response';
import {JobSearchService} from '../../../../../service/job-search.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SiteService} from '../../../../../../global/services/site/site.service';
import {TranslateService} from '@ngx-translate/core';
import {config, environment} from "../../../../../../../environments/root/environment";

@Component({
  selector: 'app-jobtype-filter',
  templateUrl: './jobtype-filter.component.html',
  styleUrls: ['./jobtype-filter.component.scss']
})
export class JobtypeFilterComponent implements OnInit,OnDestroy {
  public showPayType: boolean;
  public showJobType: boolean;
  public showWorkingPattern: boolean;
  public showSortType: boolean;
  public jobTypeFilter: JobTypeFilter[] = [];
  public sortTypeFilter: SortBy[] = [];
  public payTypeList: string[];
  public workingPatternFilter: WorkingPatternFilter[] = [];
  public paytypeData: any[];
  public annualDataMin: any;
  private payTypeDataList: PayTypeDataList[] = [];
  public minPayRate: string;
  public maxPayRate: string;
  private jobSearchFilter: JobSearchFilter;
  public payTypeId: any;
  private minPayeValue: any;
  private maxPayValue: any;
  public minPayIndex: any;
  public maxPayIndex: any;
  private jobTypeValue: string;
  private flexiWorkingPattern = 'false';
  private fullTime = 'false';
  private partTime = 'false';
  private sortTypeValue: string;
  private workingPattern: string;
  private jobType: string;
  private jobTypeIndex: string;
  private sortTypeIndex: string;
  private workingTypeIndex: string;
  private jobSearchFilterData: JobSearchFilter;
  public sortTypeAppliedText: string;
  private isWorkingPattern: boolean;
  private payTypeValue: string;
  public isSortTypeNotVisible: boolean;
  private searchPageTitle: string;
  private searchPageDesc: string;
  private keyword: string="";
  private location: string="";
  isOrgName: boolean;

  constructor(private sharedService: DataSharingService, private jobSearchService: JobSearchService, private router: Router,public translateService:TranslateService,
              public activatedRoute: ActivatedRoute, private siteService: SiteService) {
    this.translateService.setDefaultLang(this.siteService.getSiteLocale());
    // this.isOrgName = (this.router.url.indexOf(config.HaysCareer) == 1 || this.router.url.indexOf(config.HaysHTS) == 1) ? true : false;
    config.ExpertClientsName.forEach(a => { if (this.router.url.includes(a)) this.isOrgName = true; })
    activatedRoute.queryParamMap.subscribe(param => {
      if (param.get('q')) {
        this.keyword=param.get('q');
      }
      if (param.get('location')) {
        this.location = param.get('location');
      }
    });
    this.translateService.getTranslation(this.siteService.getSiteLocale()).subscribe((res) => {
      let jobTitle=this.keyword?this.keyword+" ":" ";
      let loc=this.location?this.location+" ":environment[config.theme+this.siteService.getSiteLocale()]['config']['country_name']+" ";
      if (res['search_result_title'])
        this.searchPageTitle = res['search_result_title'];
      if (res['search_page_description_text'])
        this.searchPageDesc = res['search_page_description_text'];
      if (res['search_result_title1'])
        this.searchPageTitle = jobTitle+res['search_result_title1']+loc+res['search_result_title2'];
      if (res['meta_desc_text1'])
        this.searchPageDesc = res['meta_desc_text1']+jobTitle+res['meta_desc_text2']+loc+res['meta_desc_text3']+jobTitle+res['meta_desc_text4'];
    });
  }

  ngOnInit() {
    this.sharedService.sharedJobTypeFilter.subscribe(jobType => this.jobTypeFilter = jobType);
    this.sharedService.sharedSortTypeFilter.subscribe(sortType => {
      this.sortTypeFilter = sortType;
    });
    this.activatedRoute.queryParamMap.subscribe(param=>{
      this.isSortTypeNotVisible = !param.get("sortType");
    });
    this.sharedService.sharedWorkingPattern.subscribe(workingPattern => this.workingPatternFilter = workingPattern);
    this.sharedService.sharedpayTypeList.subscribe(payType => this.payTypeList = payType);
    this.jobSearchService.sharedJobSearchFilterData.subscribe(jobSearchFilter => this.jobSearchFilterData = jobSearchFilter);
    this.jobSearchService.sharedIsWorkingPattern.subscribe(isWorkingPattern => this.isWorkingPattern = isWorkingPattern);
    this.sharedService.sharedpayTypeDateList.subscribe(payTypeData => {
      this.paytypeData = payTypeData;
    });
  }

  showJobFilter(type) {
    switch (type) {
      case 'pay':
        this.showPayType = !this.showPayType;
        this.showJobType = false;
        this.showWorkingPattern = false;
        this.showSortType = false;
        break;
      case 'jobType':
        this.showPayType = false;
        this.showJobType = !this.showJobType;
        this.showWorkingPattern = false;
        this.showSortType = false;
        break;
      case 'workingPattern':
        this.showPayType = false;
        this.showJobType = false;
        this.showWorkingPattern = !this.showWorkingPattern;
        this.showSortType = false;
        break;
      case 'sortType':
        this.showPayType = false;
        this.showJobType = false;
        this.showWorkingPattern = false;
        this.showSortType = !this.showSortType;
        break;
      case 'outsideClick':
        this.showPayType = false;
        this.showJobType = false;
        this.showWorkingPattern = false;
        this.showSortType = false;
        break;
    }
  }

  getFilterType(filterType: string, filterValue: string, index: number, dataValue: string) {
    this.jobSearchService.sharedJobSearchFilterData.subscribe(jobSearchFilter => {
      this.jobSearchFilterData = jobSearchFilter;
    });
    switch (filterType) {
      case 'jobType':
        this.jobTypeIndex = index.toString();
        this.jobSearchService.setSerchPerfomedValue(false);
        this.jobType = filterValue;
        this.jobTypeValue = dataValue;
        this.jobSearchFilter = new JobSearchFilter(null, null, this.payTypeId, this.jobSearchFilterData?this.jobSearchFilterData.minSal:'',
          this.jobSearchFilterData?this.jobSearchFilterData.minSalValue:'',
          this.jobSearchFilterData?this.jobSearchFilterData.maxSal:'',
          this.jobSearchFilterData?this.jobSearchFilterData.maxSalValue:''
          , this.jobTypeValue ,
          this.jobSearchFilterData ? this.jobSearchFilterData.flexibleworking : 'false'
          , this.jobSearchFilterData ? this.jobSearchFilterData.fullTime : 'false',
          this.jobSearchFilterData ? this.jobSearchFilterData.partTime : 'false'
          , this.jobSearchFilterData?this.jobSearchFilterData.sortType:'RELEVANCE_DESC',this.jobSearchFilterData?this.jobSearchFilterData.workingPattern:'',
          this.jobType,this.jobSearchFilterData?this.jobSearchFilterData.workingTypeIndex:'-1',this.jobTypeIndex,
          this.jobSearchFilterData?this.jobSearchFilterData.minPayIndex:'-1',this.jobSearchFilterData?this.jobSearchFilterData.maxPayIndex:'-1'
          ,this.jobSearchFilterData?this.jobSearchFilterData.sortTypeIndex:'0',this.jobSearchFilterData?this.jobSearchFilterData.payTypeValue:'');
        this.jobSearchService.setJobSearchFilter(this.jobSearchFilter);
        //localStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
        sessionStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
        this.jobSearchService.routeNavigate(this.router, this.activatedRoute, this.siteService.getSiteLocale(),
          this.jobTypeIndex, this.jobSearchFilterData?this.jobSearchFilterData.sortTypeIndex:'0',
          this.jobSearchFilterData?this.jobSearchFilterData.workingTypeIndex:'-1', null, null,
          this.jobSearchFilterData?this.jobSearchFilterData.payTypeIndex:'-1', this.jobSearchFilterData?this.jobSearchFilterData.minPayIndex:'-1',
          this.jobSearchFilterData?this.jobSearchFilterData.maxPayIndex:'-1', 'filtered',this.searchPageTitle,this.searchPageDesc, this.sharedService);
        break;
      case 'sortType':
        this.jobSearchService.setSerchPerfomedValue(false);
        this.sortTypeIndex = index.toString();
        this.sortTypeAppliedText=filterValue;
        this.sortTypeValue = dataValue;
        this.jobSearchFilter = new JobSearchFilter(null, null, this.jobSearchFilterData?this.jobSearchFilterData.payTypeIndex:'-1',
          this.jobSearchFilterData ? this.jobSearchFilterData.minSal:'',
          this.jobSearchFilterData ? this.jobSearchFilterData.minSalValue : '',
          this.jobSearchFilterData ? this.jobSearchFilterData.maxSal : '',
          this.jobSearchFilterData ? this.jobSearchFilterData.maxSalValue : ''
          , this.jobSearchFilterData?this.jobSearchFilterData.jobType:'',
          this.jobSearchFilterData ?this.jobSearchFilterData.flexibleworking : 'false'
          , this.jobSearchFilterData?this.jobSearchFilterData.fullTime : 'false',
          this.jobSearchFilterData?this.jobSearchFilterData.partTime : 'false'
          , this.sortTypeValue,
          this.jobSearchFilterData?this.jobSearchFilterData.workingPattern:'', this.jobSearchFilterData?this.jobSearchFilterData.jobTypeSelected:'',
          this.jobSearchFilterData?this.jobSearchFilterData.workingTypeIndex:'-1',this.jobSearchFilterData?this.jobSearchFilterData.jobtypeIndex:'-1',
          this.jobSearchFilterData?this.jobSearchFilterData.minPayIndex:'-1',this.jobSearchFilterData?this.jobSearchFilterData.maxPayIndex:'-1',
          this.sortTypeIndex,this.jobSearchFilterData?this.jobSearchFilterData.payTypeValue:'');
        this.jobSearchService.setJobSearchFilter(this.jobSearchFilter);
        //localStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
        sessionStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
        this.jobSearchService.routeNavigate(this.router, this.activatedRoute, this.siteService.getSiteLocale(),
          this.jobSearchFilterData?this.jobSearchFilterData.jobtypeIndex:'-1', this.sortTypeIndex, this.jobSearchFilterData?this.jobSearchFilterData.workingTypeIndex:'-1', null, null,
          this.jobSearchFilterData?this.jobSearchFilterData.payTypeIndex:'-1', this.jobSearchFilterData?this.jobSearchFilterData.minPayIndex:'-1',
          this.jobSearchFilterData?this.jobSearchFilterData.maxPayIndex:'-1', 'filtered',this.searchPageTitle,this.searchPageDesc, this.sharedService);
        break;
      case 'workingType':
        this.jobSearchService.setSerchPerfomedValue(false);
        this.jobSearchService.setWorkingPattern(true);
        this.workingTypeIndex = index.toString();
        this.workingPattern = filterValue;
        switch (this.workingTypeIndex) {
          case '0':
            this.flexiWorkingPattern = 'true';
            this.fullTime = 'false';
            this.partTime = 'false';
            break;
          case '1':
            this.fullTime = 'true';
            this.flexiWorkingPattern = 'false';
            this.partTime = 'false';
            break;
          case '2':
            this.partTime = 'true';
            this.fullTime = 'false';
            this.flexiWorkingPattern = 'false';
            break;
        }
        this.jobSearchFilter = new JobSearchFilter(null, null, this.jobSearchFilterData?this.jobSearchFilterData.payTypeIndex:'-1',
          this.jobSearchFilterData ? this.jobSearchFilterData.minSal:'',
          this.jobSearchFilterData ? this.jobSearchFilterData.minSalValue : '',
          this.jobSearchFilterData ? this.jobSearchFilterData.maxSal : '',
          this.jobSearchFilterData ? this.jobSearchFilterData.maxSalValue : ''
          , this.jobSearchFilterData?this.jobSearchFilterData.jobType:'',
          this.flexiWorkingPattern
          , this.fullTime,
          this.partTime
          , this.jobSearchFilterData?this.jobSearchFilterData.sortType:'RELEVANCE_DESC',
          this.workingPattern, this.jobSearchFilterData?this.jobSearchFilterData.jobTypeSelected:'',
          this.workingTypeIndex,this.jobSearchFilterData?this.jobSearchFilterData.jobtypeIndex:'-1',
          this.jobSearchFilterData?this.jobSearchFilterData.minPayIndex:'-1',this.jobSearchFilterData?this.jobSearchFilterData.maxPayIndex:'-1',
          this.jobSearchFilterData?this.jobSearchFilterData.sortTypeIndex:'0',this.jobSearchFilterData?this.jobSearchFilterData.payTypeValue:'');
        this.jobSearchService.setJobSearchFilter(this.jobSearchFilter);
        //localStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
        sessionStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
        this.jobSearchService.routeNavigate(this.router, this.activatedRoute, this.siteService.getSiteLocale(),
          this.jobSearchFilterData?this.jobSearchFilterData.jobtypeIndex:'-1', this.jobSearchFilterData?this.jobSearchFilterData.sortTypeIndex:'0',
          this.workingTypeIndex, null, null,
          this.jobSearchFilterData?this.jobSearchFilterData.payTypeIndex:'-1',
          this.jobSearchFilterData?this.jobSearchFilterData.minPayIndex:'-1',
          this.jobSearchFilterData?this.jobSearchFilterData.maxPayIndex:'-1', 'filtered',this.searchPageTitle,this.searchPageDesc, this.sharedService);
        break;
      default:
        this.jobTypeIndex = '-1';
        this.sortTypeIndex = '0';
        this.workingTypeIndex = '-1';
        break;
    }
    this.showJobFilter('outsideClick');

  }

  getPayTypeData(type, id) {
    if(type) {
      let jsonDataMin = JSON.parse(type);
      this.payTypeId = jsonDataMin.id;
      type=jsonDataMin.key;
      switch (this.payTypeId) {
        case 0:
          this.payTypeValue='SALARY';
          break;
        case 1:
          this.payTypeValue='OTHER_TYPE';
          break;
        case 2:
          this.payTypeValue='HOURLY';
          break;
        case 3:
          this.payTypeValue='JOB_COMPENSATION_TYPE_UNSPECIFIED';
          break;

      }
    }
    this.payTypeDataList = [];
    this.annualDataMin = undefined;
   this.sharedService.sharedpayTypeDateList.subscribe(payTypeData => {
   this.paytypeData = payTypeData;
      for (let paytypeDataKey in this.paytypeData) {
        if (type) {
          if (this.paytypeData.hasOwnProperty(type)) {
            this.payTypeDataList.push(new PayTypeDataList(type, this.paytypeData[type].MaxPayRate,
              this.paytypeData[type].MinPayRate));
          }
          return;
        } else {
          if (this.paytypeData.hasOwnProperty(paytypeDataKey)) {
            this.payTypeDataList.push(new PayTypeDataList(paytypeDataKey, this.paytypeData[paytypeDataKey].MaxPayRate,
              this.paytypeData[paytypeDataKey].MinPayRate));
          }
        }
      }

    });

    if (!type) {
      this.payTypeDataList = this.payTypeDataList.sort((a, b) => a.payType.localeCompare(b.payType));

    }
    let index = 0;
    let flag= false;
    /*if(type==""){
      if(this.payTypeId!==undefined){
        index=this.payTypeId;
        flag=true;
      }
      else if(this.payTypeId===undefined){
        flag=true;
      }
    }*/
    if(type=="" && this.jobSearchFilterData.payTypeValue){
      flag=true;
      switch (this.jobSearchFilterData.payTypeValue) {
        case 'SALARY': index=0;
          break;
        case 'OTHER_TYPE':index=1;
          break;
        case 'HOURLY':index=2;
          break;
        case 'JOB_COMPENSATION_TYPE_UNSPECIFIED':index=3;
          break;
      }
    }
    this.annualDataMin = this.payTypeDataList[index];
    this.minPayRate = this.payTypeDataList[index].MinPayRate[0]._key;
    this.minPayeValue = this.payTypeDataList[index].MinPayRate[0]._value;
    this.maxPayRate = this.annualDataMin.MaxPayRate[this.annualDataMin.MaxPayRate.length - 1]._key;
    this.maxPayValue = this.annualDataMin.MaxPayRate[this.annualDataMin.MaxPayRate.length - 1]._value;
    this.payTypeValue = this.annualDataMin.MaxPayRate[0].payTypeValue;
    if(flag){
      this.minPayIndex = this.jobSearchFilterData.minPayIndex;
      this.maxPayIndex = this.jobSearchFilterData.maxPayIndex;
    }
    else{
      this.minPayIndex = '0';
      this.maxPayIndex = this.annualDataMin.MaxPayRate.length - 1;
    }
    this.payTypeId =index;

  }

  getSalaryRange(type: string, value: any) {
    switch (type) {
      case 'minSal':
        let jsonDataMin = JSON.parse(value);
        this.minPayRate = jsonDataMin.key;
        this.minPayeValue = jsonDataMin.value;
        this.minPayIndex = jsonDataMin.id;
        break;
      case 'maxSal':
        let jsonDataMax = JSON.parse(value);
        this.maxPayRate = jsonDataMax.key;
        this.maxPayValue = jsonDataMax.value;
        this.maxPayIndex = jsonDataMax.id;
        break;
      default:
        this.showPayType = !this.showPayType;
        this.jobSearchFilter = new JobSearchFilter(null, null, this.payTypeId, this.minPayRate,
          this.minPayeValue,
          this.maxPayRate,
          this.maxPayValue
          , this.jobSearchFilterData?this.jobSearchFilterData.jobType:'',
          this.jobSearchFilterData?this.jobSearchFilterData.flexibleworking : 'false'
          , this.jobSearchFilterData?this.jobSearchFilterData.fullTime : 'false',
          this.jobSearchFilterData?this.jobSearchFilterData.partTime : 'false'
          , this.jobSearchFilterData?this.jobSearchFilterData.sortType:'RELEVANCE_DESC',
          this.jobSearchFilterData?this.jobSearchFilterData.workingPattern:'',
          this.jobSearchFilterData?this.jobSearchFilterData.jobTypeSelected:'',
          this.jobSearchFilterData?this.jobSearchFilterData.workingTypeIndex:'-1',
          this.jobSearchFilterData?this.jobSearchFilterData.jobtypeIndex:'-1',
          this.minPayIndex,this.maxPayIndex,
          this.jobSearchFilterData?this.jobSearchFilterData.sortTypeIndex:'0',this.payTypeValue);
        this.jobSearchService.setJobSearchFilter(this.jobSearchFilter);
        //localStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
        sessionStorage.setItem("jobTypeFilter",JSON.stringify(this.jobSearchFilter));
        this.jobSearchService.routeNavigate(this.router, this.activatedRoute, this.siteService.getSiteLocale(),
          this.jobSearchFilterData?this.jobSearchFilterData.jobtypeIndex:'-1', this.jobSearchFilterData?this.jobSearchFilterData.sortTypeIndex:'0',
          this.workingTypeIndex, null, null,
          this.jobSearchFilterData?this.jobSearchFilterData.payTypeIndex:'-1', this.jobSearchFilterData?this.jobSearchFilterData.minPayIndex:'-1',
          this.jobSearchFilterData?this.jobSearchFilterData.maxPayIndex:'-1', 'filtered',this.searchPageTitle,this.searchPageDesc, this.sharedService);
        break;

    }


  }
  ngOnDestroy() {
    this.sharedService.clearSearchData();
    this.jobSearchService.clearFilter();
    this.payTypeDataList=[];
    this.workingPatternFilter=[];
    this.payTypeList=[];
    this.sortTypeFilter=[];
    this.jobTypeFilter=[];
    this.paytypeData=[];

  }
}
