import {Adapter} from '../../../global/adapter/adapter';
import {Injectable} from '@angular/core';

export class MyProfileRequest {
  constructor(public sections:string, public resource_uris:string){
  }
}

export class MyProfileData{
  constructor(public status:string, public message:string, public data:MyProfilePageData[]){
  }
}

export class MyProfilePageData{
  constructor(public linkName:string, public resource_url:string) {
  }
}


@Injectable({
  providedIn:'root'
})
export class MyProfileAdapter implements Adapter<MyProfileData>{
  adapt(item: any): MyProfileData {
    return new MyProfileData(
      item.status,
      item.message,
      item.data
    );
  }
}

