import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RequestService} from 'src/app/global/services/request/request.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {apiEndPoints, config, environment} from '../../../../../environments/root/environment';
import {SiteService} from '../../../../global/services/site/site.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import { timeStamp } from 'console';

@Component({
  selector: 'app-term-and-privacy',
  templateUrl: './term-and-privacy.component.html',
  styleUrls: ['./term-and-privacy.component.scss'],
})
export class TermAndPrivacyComponent implements OnInit {
  public confirmationText=[];
  public siteLocale: any;
  public consentBox1=[];
  public consentBox2=[];
  public consentBox3=[];
  public domainName: string;

  @Input() public parent: FormGroup;
  @Input() public isChecked: boolean;
  @Input() public type: string;
  @Input() public privacyPolicyErrorMsg: boolean;
  @Output() public allCheckBox=new EventEmitter<any>();
  @Output() public isConsentChecked=new EventEmitter<boolean>();
  @Output() public privacyconsent2=new EventEmitter<boolean>();
  /**
   * Captures the current user flow
   */
  @Input() public currentUserFlow: string = '';

  constructor(private _requestService: RequestService,  private _translate: TranslateService,private siteService:SiteService, private router:Router) {
      this.siteLocale = this.siteService.getSiteLocale();
      this._translate.setDefaultLang(this.siteLocale);
     }

  ngOnInit() {
    this.getConfirmation();
  }
  ngDoCheck(){
    if (this.parent) {
      if(this.domainName !== 'CHN' && this.domainName !== 'TH'){
        this.isChecked = this.parent.controls.privacyconsent1.value;
        this.isConsentChecked.emit(this.isChecked);
      }
      if (this.parent.controls.privacyconsent2)
        this.privacyconsent2.emit(this.parent.controls.privacyconsent2.value);
    } else {
      this.isConsentChecked.emit(this.isChecked);
    }
  }

  getConfirmation(){
    this.domainName = localStorage.getItem('domain');
    let orgName = this.router.routerState.snapshot.root.firstChild.params["orgName"];
    let liferayBaseUrl="";
    if(orgName=='nhs')
      liferayBaseUrl = environment[config.theme + this.siteService.getSiteLocale()][apiEndPoints.liferayclientBaseUrl];
    else if(orgName=='cognizant')
      liferayBaseUrl = environment[config.theme + this.siteService.getSiteLocale()][apiEndPoints.liferayCognizantUrl];
    else if(orgName == 'yourrailindustryjob' || orgName === 'geniuswithin')
      liferayBaseUrl = environment[config.theme + this.siteService.getSiteLocale()][apiEndPoints.liferayclientBaseUrl];
    else
      liferayBaseUrl = environment[config.theme + this.siteService.getSiteLocale()][apiEndPoints.liferaybaseUrl];
    let endpoint="";
    if(this.type==="Go1"){
      endpoint=apiEndPoints.go1getTermsAndConditions;
    }
    else{
      if(environment[config.theme + this.siteService.getSiteLocale()]['config']['flowSpecificConsentStatement']){
        endpoint = this.getAPIEndPoint();
      }
      else{
        endpoint = apiEndPoints.getTermsAndConditions;
      }
    }
   // let liferayBaseUrl = environment[config.theme+this.siteService.getSiteLocale()][apiEndPoints.liferaybaseUrl];
    this._requestService.getRequest(config.theme, apiEndPoints.gateWayAPI,endpoint).subscribe(response => {
      if(response.status === config.SUCCESS && response.data ){
        this.confirmationText = response.data;
        this.allCheckBox.emit(this.confirmationText);
        let checkboxIndex = 3;
        let consentCheckBoxIndex = 4;
        this.confirmationText['checkBox1'].forEach((data, index)=>{
           if(data['link'].indexOf('email')==-1) {
             if (orgName)
                data['link'] = (orgName == 'hts' && data['link'].indexOf("/terms-and-conditions") > -1) ? liferayBaseUrl + data['link']
                                : (orgName == 'hts' && data['link'].indexOf("/privacy-policy-uk-careers") > -1) ? liferayBaseUrl + '/careers' + data['link']
                                :liferayBaseUrl+'/'+orgName + data['link'];
             else
               data['link'] = liferayBaseUrl + data['link'];

           }
           else {
             if (orgName)
               data['link'] = orgName + data['link'];
           }
           data['consentText1']= decodeURI(data['consentText1']);
           data['consentText2']= decodeURI(data['consentText2']);
           if(index > 3 && index < 7 && this.domainName.toUpperCase() === 'CHN'){
             checkboxIndex++;
             data['checkboxIndex'] = checkboxIndex;
             this.parent.addControl(`privacyconsent${checkboxIndex}`, new FormControl('', { validators : [Validators.requiredTrue], updateOn : 'change'}));
           }
           if(index > 4 && index < 8 && this.domainName.toUpperCase() === 'TH'){
            consentCheckBoxIndex++;
            data['consentCheckBoxIndex'] = consentCheckBoxIndex;
            this.parent.addControl(`privacyconsent${consentCheckBoxIndex}`, new FormControl('', { validators : [Validators.requiredTrue], updateOn : 'change'}));
          }
           this.consentBox1.push(data);
         });

        this.confirmationText['checkBox2'].forEach((data)=>{
          if(data['link'].indexOf('email')==-1)
            data['link']=liferayBaseUrl+data['link'];
          else {
            if (orgName)
              data['link'] = orgName + data['link'];
          }
          data['consentText1']= decodeURI(data['consentText1']);
          data['consentText2']= decodeURI(data['consentText2']);
          this.consentBox2.push(data);
        });

        this.confirmationText['checkBox3'].forEach((data)=>{
          if(data['link'].indexOf('email')==-1)
            data['link']=liferayBaseUrl+data['link'];
          else {
            if (orgName)
              data['link'] = orgName + data['link'];
          }
          data['consentText1']= decodeURI(data['consentText1']);
          data['consentText2']= decodeURI(data['consentText2']);
          this.consentBox3.push(data);
        });
      }
    }, (error) => {
    });
  }
  /**
   * Method to be executed when checkbox value changes for CHN domain
   * @param event 
   * @param type 
   */
  public onCheckboxChange(event, type?){
    if(type === 'parent'){
      const isCheckboxChecked = this.parent.controls.privacyconsent1.value;
      this.parent.patchValue({
        privacyconsent4 : isCheckboxChecked,
        privacyconsent5 : isCheckboxChecked,
        privacyconsent6 : isCheckboxChecked
      });
    }
    if(this.parent.controls.privacyconsent1.value && this.parent.controls.privacyconsent4.valid && this.parent.controls.privacyconsent5.valid && this.parent.controls.privacyconsent6.valid){
      this.isConsentChecked.emit(true);
    }
    else{
      this.isConsentChecked.emit(false);
    }
  }
  /**
   * Called for TH domain:
   * 1. Captures the value of check box and assign it to instance property
   * 2. Emits the event
   */
  public consentBoxChangeTH(){
    if(this.parent.controls.privacyconsent5.value && this.parent.controls.privacyconsent6.value && this.parent.controls.privacyconsent7.value){
      this.parent.patchValue({
        privacyconsent1 : true
      });
    }
    else{
      this.parent.patchValue({
        privacyconsent1 : false
      });
    }
    this.isChecked = this.parent.controls.privacyconsent1.value;
    this.isConsentChecked.emit(this.isChecked);
  }
  /**
   * Create flow specific end point
   */
  private getAPIEndPoint(){
    if(this.currentUserFlow === 'job_apply'){
      return apiEndPoints['getTermsAndConditionsApply'];
    }
    else if(this.currentUserFlow === 'user_register'){
      return apiEndPoints['getTermsAndConditionsRegister'];
    }
    else{
      return apiEndPoints.getTermsAndConditions;
    }
  }
}
