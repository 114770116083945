import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SiteService} from '../../../services/site/site.service';
import {TokenStorage} from '../../../services/tokenStorage/token-storage';
import {LogsService} from '../../../services/logger/logs.service';
import {RequestService} from '../../../services/request/request.service';
import {CookieService} from 'ngx-cookie-service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-site-locale',
  templateUrl: './site-locale.component.html',
  styleUrls: ['./site-locale.component.sass']
})
export class SiteLocaleComponent implements OnInit {
  constructor(public router: Router, private route: ActivatedRoute, private _translate: TranslateService,
              private _logger: LogsService, private _siteService: SiteService,
              private tokenStorage: TokenStorage, private requestService: RequestService, private cookieService: CookieService, private httpClient: HttpClient) {
    //console.log('In site locale component');
    if (this.route.snapshot.queryParamMap.has('logLevel') && this.route.snapshot.queryParamMap.get('logLevel') != null
      && this.tokenStorage.getDebugMode() === 'true') {
      this._logger.level = <any> this.route.snapshot.queryParamMap.get('logLevel');
    }
      if (this.router.url.indexOf('/jobportal/')>-1) {
        let cookieName = this.cookieService.get('multi_country');
        let lang = '';
        let urlList = this.router.url.split('/jobportal');
        if (!cookieName) {
          lang = this.route.snapshot.queryParamMap.get('lang');
          if (lang) {
            this.route.params.subscribe(param => {
              if (param) {
                if (lang === 'hrcs') {
                  lang = 'hrcz';
                }
                this._siteService.setSite('/' + param.country + '/' + lang + '/');
                this.router.navigateByUrl('/' + param.country + '/' + lang + '/jobportal' + urlList[1]);
              }
            });

          } else {
            if (this.router.url.indexOf('/browse/details') > -1) {
              this.route.params.subscribe(param => {
                if (param) {
                  this.router.navigateByUrl('/' + param.country + '/' + param.locale + '/jobportal' + urlList[1]);
                }
              });
            } else {
              let path = [];
              this.route.queryParamMap.subscribe(param => {
                if (param.get('path')) {
                  path = param.get('path').split('/');
                  this._siteService.setSite('/' + path[0] + '/' + path[1]);
                } else {
                  this._siteService.setSite(this.router.url);
                }
              });

            }
          }
        } else {
          lang = this.route.snapshot.queryParamMap.get('lang');
          if (lang) {
            this.route.params.subscribe(param => {
              if (param) {
                if (lang === 'hrcs') {
                  lang = 'hrcz';
                }
                this._siteService.setSite('/' + param.country + '/' + lang + '/');
                if (this.router.url.indexOf('/browse/details') > -1) {
                  this.router.navigateByUrl('/' + param.country + '/' + lang + '/jobportal' + urlList[1]);
                } else {
                  this.router.navigateByUrl('/' + param.country + '/' + lang + '/jobportal' + urlList[1]);
                }
              }
            });

          } else {
            let cookieNameList = cookieName.split('-');
            if (cookieNameList[0] === 'hrcs') {
              lang = 'hrcz';
            } else {
              lang = cookieNameList[0];
            }
            this.route.params.subscribe(param => {
              if (param) {
                if ((param.locale + '-' + param.country) !== cookieName) {
                  //console.log("inside param cookie condition");
                  this._siteService.setSite('/' + cookieNameList[1] + '/' + lang + '/');
                  if (this.router.url.indexOf('/browse/details')) {
                    this.router.navigateByUrl('/' + cookieNameList[1] + '/' + lang + '/jobportal' + urlList[1]);
                  } else {
                    this.router.navigateByUrl('/' + cookieNameList[1] + '/' + lang + '/jobportal' + urlList[1]);
                  }
                }
              }
            });
          }
        }
        this._translate.setDefaultLang(this._siteService.getSiteLocale());
      }
  }

  ngOnInit() {
  }


}
