import {Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[appResizeImage]'
})
export class ResizeImageDirective {
  @Input() appResizeImage: string;

  constructor(renderer: Renderer2, elmRef: ElementRef) {
    let roundVal = `${this.appResizeImage}`;
    //console.log("appResizeImage..",roundVal);
    renderer.setStyle(elmRef.nativeElement,'height', '80px');
    renderer.setStyle(elmRef.nativeElement, 'width', '80px');
  }

}
