import {Adapter} from '../../../global/adapter/adapter';



export class AuStateList {
  constructor(public status: string,
              public message: string,
              public data: AuStateListData[]) {

  }
}

export class AuStateListData {
  constructor(public state_description: string,
              public State_id: number
           ) {
  }
}


export class AuStateListDataAdapt implements Adapter<AuStateList> {
  adapt(item: any): AuStateList {
    return new AuStateList(
      item.status,
      item.message,
      item.data
    );
  }
}
